import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './styles'

export default function ShowAudios({ materialUrl }) {
  const classes = useStyles()

  return (
    <>
      <iframe
        className={classes.mainIframe}
        title="video-or-audio-material"
        src={materialUrl}
        allowFullScreen
        width="100%"
      />
    </>
  )
}

ShowAudios.propTypes = {
  materialUrl: PropTypes.string.isRequired
}
