import { stopwords, siglas } from '../exceptions'
import { BOOK_FORMAT } from '../../constants/Book'

function testSpecialChars(param, list) {
  if (param.includes(',') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes('.') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes('(') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes(')') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes(':') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  return false
}

function toCamelCase(string) {
  if (!string || string === '') return string
  const words = string.split(' ')
  const lWords = words.map((word) => word.toLowerCase())

  const formatedTitle = lWords
    .map((word) => {
      if (siglas.includes(word) || testSpecialChars(word, siglas))
        return word.toUpperCase()
      return stopwords.includes(word)
        ? word
        : word && word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

  return formatedTitle.charAt(0).toUpperCase() + formatedTitle.slice(1)
}

function formatAbntText(abnt) {
  const bookTitle = abnt.match(/<strong[^>]*>([^<]+)<\/strong>/)[1]
  const bookTitleFormated = `<strong>${toCamelCase(bookTitle)}</strong>`
  return abnt.replace(
    /<strong[^>]*>([^<]+)<\/strong>/,
    `<strong>${bookTitleFormated}</strong>`
  )
}

function getAuthors(book, showAllAuthors = true) {
  let authors = book && book.authors
  /* Comentado para não exibir autor desconhecido por erro do back end */
  // if (!authors || authors.length === 0) return 'Autor Desconhecido'
  if (!authors || authors.length === 0) return ''
  authors = authors.filter((author) => author !== '')

  if (authors.length === 1) return toCamelCase(authors[0])

  if (showAllAuthors) {
    return `${authors.map((author) => toCamelCase(author)).join(', ')}`
  }

  return 'Vários autores'
}

function bookUrl(book) {
  if (book.crmStatus === 'Disponivel') {
    return `/books/${book.productCaeId}`
  }

  return '/books/unavailable'
}

function getPrice(priceCover) {
  const price = priceCover.toFixed(2).toString().replace('.', ',')
  return `R$ ${price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`
}

function formatAuthorsName(books) {
  const bookCatalogs = books.map((item) => {
    const booksInfo = {
      ...item
    }

    const author = getAuthors(item, false)

    booksInfo.authors = [toCamelCase(author)]

    return booksInfo
  })

  return bookCatalogs
}

function isTheBookActive(caeId, library) {
  if (Array.isArray(library)) {
    if (library.length === 0) {
      return false
    }

    return library.some((book) => {
      return parseInt(book.productCaeId, 10) === caeId
    })
  }

  if (!library || !library.products) {
    return false
  }

  return library.products.some(
    (book) =>
      parseInt(book.cod_produto, 10) === caeId || book.productCaeId === caeId
  )
}

function getBookFavoriteStatus(favorites = [], caeId) {
  if (!favorites) return null
  const index = favorites.findIndex((item) => {
    return item.favorite_id === Number(caeId)
  })
  if (index !== -1) return favorites[index].id
  return null
}

function getCodProducts(products) {
  const codeProducts = []
  products.forEach((code) => {
    if (code && code.cod_produto !== undefined) {
      codeProducts.push(code.cod_produto)
    }
  })
  return codeProducts
}

function getBookReaderUrl(book) {
  switch (book.format) {
    case BOOK_FORMAT.EPUB:
      return `/epub/${book.productCaeId}?title=${book.titleCover}`
    case BOOK_FORMAT.PDF:
      return `/pdf/${book.productCaeId}?title=${book.titleCover}`
    default:
      // return '/books/unavailable'
      return `/epub/${book.productCaeId}?title=${book.titleCover}`
  }
}

export {
  toCamelCase,
  getAuthors,
  bookUrl,
  getPrice,
  formatAbntText,
  formatAuthorsName,
  isTheBookActive,
  getBookFavoriteStatus,
  getCodProducts,
  getBookReaderUrl
}
