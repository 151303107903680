import {
  PROFILE_TYPE_LOADING,
  PROFILE_TYPE_SUCCESS,
  PROFILE_TYPE_ERROR
} from '../../../utils/actionTypes'

import { Users, UserService } from '../../../services'

export const getProfileTypeAction = async (dispatch) => {
  dispatch({
    type: PROFILE_TYPE_LOADING
  })

  try {
    const response = await UserService.getProfileType(Users.getUserId())

    if (!response.ok) {
      throw new Error(response.ok)
    } else {
      const result = await response.json()

      dispatch({
        type: PROFILE_TYPE_SUCCESS,
        payload: result.data
      })

      return {
        ok: response.ok,
        data: result.data
      }
    }
  } catch (error) {
    dispatch({
      type: PROFILE_TYPE_ERROR,
      payload: error
    })

    return {
      ok: error.message
    }
  }
}
