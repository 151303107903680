import {
  LIBRARY_BOOKS_LOADING,
  LIBRARY_BOOKS_SUCCESS,
  LIBRARY_BOOKS_ERROR
} from '../../../utils/actionTypes'
import { LibraryService, BookService } from '../../../services'
import { formatAuthorsName, getCodProducts } from '../../../utils'

export const getLibraryBooksAction = async (dispatch, payload = {}) => {
  let libraryBooks = []
  let libraryData = null

  dispatch({
    type: LIBRARY_BOOKS_LOADING
  })

  try {
    const libraryResponse = await LibraryService.getLibrary(payload.lastUpdate)

    if (!libraryResponse.ok) {
      throw new Error(
        `libraryResponse error: ${libraryResponse.status} ${libraryResponse.statusText}`
      )
    }

    libraryData = await libraryResponse.json()

    if (libraryData && libraryData.products.length) {
      const codeProducts = getCodProducts(libraryData.products)
      const bookResponse = await LibraryService.loadBook(codeProducts)

      if (!bookResponse.ok) {
        throw new Error(
          `bookResponse error: ${bookResponse.status} ${bookResponse.statusText}`
        )
      }

      const bookData = await bookResponse.json()

      const getBooksRate = await BookService.getBooksRate(
        bookData && bookData.map((i) => i.productCaeId)
      )

      if (!getBooksRate.ok) {
        throw new Error(
          `getBooksRate error: ${getBooksRate.status} ${getBooksRate.statusText}`
        )
      }

      const ratesByBook = await getBooksRate.json()

      ratesByBook.data.map((rate) => {
        bookData.filter((book) => {
          if (book.productCaeId === rate.book_id) book.rating = rate
          return null
        })

        return null
      })

      libraryBooks = formatAuthorsName(bookData)

      libraryBooks.forEach((book) => {
        libraryData.products.forEach((product) => {
          if (book.productCaeId === Number(product.cod_produto)) {
            book.perfil = product.perfil
          }
        })
      })
    }

    if (payload.cae) {
      const [libraryBooksFiltered] = libraryBooks.filter(
        (libraryBook) => libraryBook.productCaeId === Number(payload.cae)
      )

      if (!libraryBooksFiltered) {
        throw new Error(`libraryBooksFiltered error`)
      }
    }

    // Validando se usuario possui sa_token
    // caso não tenha validando se o usuario ja existe
    // caso não existe criando ele no AppAprova e pegando o token
    // caso exista pegando o token do usuario cadastrado
    // if (!libraryData.sa_token) {
    //   const loggedUserResponse = await MaterialsService.loginUser()
    //   const loggedUser = await loggedUserResponse.json()

    //   if (!loggedUser.access_token) {
    //     const createdUserResponse = await MaterialsService.createUser()
    //     const createdUser = await createdUserResponse.json()
    //     libraryData.sa_token = createdUser.access_token
    //   } else {
    //     libraryData.sa_token = loggedUser.access_token
    //   }
    // }

    dispatch({
      type: LIBRARY_BOOKS_SUCCESS,
      payload: {
        products: libraryBooks,
        lastUpdate: libraryData.updated_at,
        saToken: libraryData.sa_token
      }
    })

    return {
      ok: true,
      data: libraryBooks
    }
  } catch (err) {
    dispatch({
      type: LIBRARY_BOOKS_ERROR,
      payload: {
        products: [],
        lastUpdate: null,
        saToken: null
      }
    })

    return {
      ok: false,
      error: err.message
    }
  }
}
