import { makeStyles } from '@material-ui/core/styles'

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3)
    }
  }
}))

export const useMiddleWidgetStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  }
}))

export const useSandwichItemsStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 32
    }
  },
  wrapperActive: {
    '& svg': {
      color: theme.palette.primary.main
    },

    '& span': {
      color: theme.palette.primary.main
    }
  },
  button: {
    marginTop: 32,
    marginBottom: 8,
    background: 'rgba(129, 106, 177, 0.3)',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: 'rgba(129, 106, 177, 0.6)'
    }
  },
  boxActive: {
    display: 'block',
    width: '100%',
    marginTop: 30
  },
  menuHelp: {
    marginTop: 8
  }
}))

export const useSearchBarFilterStyles = makeStyles((theme) => ({
  searchWrapper: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%'
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '60%'
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: 696
    }
  }
}))

export const useNotificationsStyles = makeStyles((theme) => ({
  notification: {
    padding: theme.spacing(1.5, 1),

    '& .MuiIconButton-label svg + span': {
      right: theme.spacing(1)
    }
  }
}))

export const useUserWidgetStyles = makeStyles((theme) => ({
  user: {
    padding: theme.spacing(1.5, 1.5, 1.5, 1)
  }
}))

export const useBackdropStyles = makeStyles((theme) => ({
  root: {
    top: 56,

    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}))
