/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { GlobalContext } from '../contexts/GlobalContext'
import {
  getBooksAction,
  getFavoriteBooksAction,
  getLibraryBooksSimpleAction
} from '../contexts/actions'
import { TrackingsService } from '../services'
import { isAuthenticated } from '../auth'
import {
  searchToObject,
  objectIsEmpty,
  compareTwoObjects,
  getOffset,
  getDefaultSearhParams,
  getDefaultFiltersSelects,
  checkSearchParams
} from '../utils'

const DEFAULT_PARAMS = getDefaultSearhParams()

export function useBooks(isSearch, showPageItems) {
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const {
    data: booksData,
    loading: loadingBooks,
    error: errorBooks
  } = globalState.books

  const { data: libraryBooksSimple, loading: libraryLoading } =
    globalState.libraryBooksSimple

  const { data: favorites, loading: favoriteLoading } =
    globalState.favoriteBooks

  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const location = useLocation()
  const { categoria, subcategoria } = useParams()
  const [page, setPage] = useState(1)
  const [oldTerm, setOldTerm] = useState('')
  const { selectsListToUpdate, updatedParams } = checkSearchParams(
    location,
    getDefaultFiltersSelects()
  )
  const [selectsList, setSelectsList] = useState(selectsListToUpdate)
  const [searchParams, setSearchParams] = useState(
    updatedParams || DEFAULT_PARAMS
  )

  const queryString = searchToObject(location.search)
  const termOfSearch = isSearch && queryString.q ? queryString.q : null

  if (
    localStorage.getItem('saraiva-conecta-orderby') &&
    localStorage.getItem('saraiva-conecta-orderby').length > 0 &&
    localStorage.getItem('saraiva-conecta-orderby') !== 'null' &&
    localStorage.getItem('saraiva-conecta-orderby') !== 'undefined'
  ) {
    DEFAULT_PARAMS.orderBy = localStorage.getItem('saraiva-conecta-orderby')
  }

  const scrollToCatalog = useCallback(() => {
    if (document.getElementById('scroll-catalog')) {
      const { y } = getOffset(document.getElementById('scroll-catalog'))
      const titleOffset = isMobile ? 48 : 124

      window.scroll(0, y - titleOffset)
    }
  }, [isMobile])

  const trackingSearchParams = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.toString()) {
      TrackingsService.sendTrack(
        'filtroBusca',
        {},
        {
          filtro_busca: decodeURI(queryParams.toString())
        }
      )
    }
  }, [location])

  const trackingNotificationFromRedirect = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)
    const queryParamsObj = Object.fromEntries([...queryParams])

    if (
      Object.prototype.hasOwnProperty.call(
        queryParamsObj,
        'redirectFromNotification'
      )
    ) {
      TrackingsService.sendTrack('clicou_notificacao_email')
      history.push(location.pathname)
    }
  }, [location])

  const handleClickPage = (_event, value) => {
    const currentPage = value

    if (currentPage !== page) {
      setPage(Number(currentPage))
    }
  }

  const resetSelectsLabels = () => {
    setSelectsList([...getDefaultFiltersSelects()])
  }

  const paramsHaveChanged = () =>
    JSON.stringify(updatedParams) !== JSON.stringify(searchParams)

  const resetSearchFilters = useCallback(
    (prevSearchTerm, newSearchTerm) => {
      if (isSearch && oldTerm) {
        const filterParams = new URLSearchParams(location.search)
        filterParams.delete('q')
        const filterParamsObj = Object.fromEntries([...filterParams])

        if (
          objectIsEmpty(filterParamsObj) &&
          !compareTwoObjects(DEFAULT_PARAMS, searchParams)
        ) {
          const previousSearchParam = new URLSearchParams(prevSearchTerm)
          const previousSearchTermValue = previousSearchParam.get('q')

          if (previousSearchTermValue !== newSearchTerm) {
            resetSelectsLabels()
            setSearchParams(getDefaultSearhParams())
          }
        } else if (paramsHaveChanged()) {
          resetSelectsLabels()
          setSearchParams(updatedParams)
        }
      }
    },
    [location, isSearch, oldTerm, searchParams]
  )

  const getBooks = useCallback(async () => {
    if (history.location.search !== oldTerm) {
      setOldTerm(history.location.search)
      resetSearchFilters(oldTerm, termOfSearch)
    } else {
      const booksResponse = await getBooksAction(globalDispatch, {
        page,
        itemsPerPage: searchParams.itemsPerPage,
        orderBy: searchParams.orderBy,
        filters: searchParams.filters,
        categoria,
        subcategoria,
        termOfSearch
      })

      if (isSearch && termOfSearch && termOfSearch.length && page === 1) {
        TrackingsService.sendTrack(
          'busca',
          {},
          {
            termo_busca: termOfSearch,
            numero_resultados: booksResponse.data.totalElements || 0
          }
        )
      }
    }

    if (page > 1 || searchParams.filter) {
      scrollToCatalog()
    }
  }, [
    globalDispatch,
    page,
    searchParams,
    location,
    isSearch,
    termOfSearch,
    oldTerm,
    history,
    categoria,
    subcategoria,
    resetSearchFilters,
    scrollToCatalog
  ])

  useEffect(() => {
    getBooks()
  }, [getBooks, page, location, searchParams, oldTerm])

  useEffect(() => {
    if (updatedParams.filters && paramsHaveChanged()) {
      trackingSearchParams()
    }

    trackingNotificationFromRedirect()
  }, [searchParams, trackingSearchParams, trackingNotificationFromRedirect])

  const getComplementData = useCallback(async () => {
    if (isAuthenticated()) {
      getFavoriteBooksAction(globalDispatch)
      getLibraryBooksSimpleAction(globalDispatch, {}, true)
    }
  }, [globalDispatch])

  useEffect(() => {
    getComplementData()
  }, [getComplementData])

  return {
    books: booksData.books,
    page: booksData.page,
    totalPages: booksData.totalPages,
    totalItems: booksData.totalElements,
    loadingBooks,
    errorBooks,
    isSearch,
    showPageItems,
    searchParams,
    categoria,
    subcategoria,
    selectsList,
    libraryBooks: libraryBooksSimple,
    libraryLoading,
    favorites,
    favoriteLoading,
    handleClickPage,
    setSearchParams,
    setSelectsList
  }
}
