/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'
import { Card, Popover, IconButton } from '@material-ui/core'
import { Share } from '@material-ui/icons'
import { TrackingsService } from '../../services'
import useStyles from './styles'
import { toCamelCase } from '../../utils'

export default function ShareButton({ book }) {
  const [anchorShare, setAnchorShare] = useState(null)
  const openShareButton = (event) => {
    setAnchorShare(event.currentTarget)
  }

  const closeShareButton = useCallback(() => {
    setAnchorShare(null)
  }, [setAnchorShare])

  const handleTrackingClick = useCallback(
    (rede) => {
      TrackingsService.sendTrack('compartilharObra', book, {
        rede_social: rede
      })
    },
    [book]
  )

  const handleEmailSharing = () => {
    handleTrackingClick('email')

    const url = window.location.href
    const subject = 'Encontrei um livro no Saraiva Conecta!'
    const body = `Olhe esse livro que eu encontrei no Saraiva Conecta!%0D%0A${toCamelCase(
      book.titleCover
    )} - ${url}`

    document.location = `mailto:?subject=${subject}&body=${body}`
  }

  useEffect(() => {
    window.onscroll = () => {
      closeShareButton()
    }
  }, [closeShareButton])

  const classes = useStyles()

  const shareTitle = () => {
    return `Olhe esse livro que eu encontrei no Saraiva Conecta!\n${toCamelCase(
      book.titleCover
    )}`
  }

  return (
    <>
      <IconButton className={classes.shareButton} onClick={openShareButton}>
        <Share />
      </IconButton>

      <Popover
        open={Boolean(anchorShare)}
        anchorEl={anchorShare}
        onClose={closeShareButton}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Card className={classes.cardShare}>
          <WhatsappShareButton
            url={window.location.href}
            title={shareTitle()}
            separator=": "
            onClick={() => handleTrackingClick('whatsapp')}
            className={classes.iconShare}
          >
            <WhatsappIcon size={42} round />
          </WhatsappShareButton>

          <FacebookShareButton
            url={window.location.href}
            quote={shareTitle()}
            onClick={() => handleTrackingClick('facebook')}
            className={classes.iconShare}
          >
            <FacebookIcon size={42} round />
          </FacebookShareButton>

          <TwitterShareButton
            url={window.location.href}
            title={shareTitle()}
            onClick={() => handleTrackingClick('twitter')}
            className={classes.iconShare}
          >
            <TwitterIcon size={42} round />
          </TwitterShareButton>

          <EmailShareButton
            onClick={handleEmailSharing}
            className={classes.iconShare}
          >
            <EmailIcon size={42} round />
          </EmailShareButton>
        </Card>
      </Popover>
    </>
  )
}

ShareButton.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}
