import httpClient from '../gateway/generalHttpRequest'

export const LibraryTreeviewService = {
  async getMenu() {
    const path = `contents/api/v1/legislation/get_menu`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async getNormsYear(id) {
    const path = `contents/api/v1/legislation/get_norms_year/${id}`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async getNorms(subdivisionId, year) {
    const path = `contents/api/v1/legislation/get_norms?subdivision_id=${subdivisionId}&year=${year}`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async get_articles(normId) {
    const path = `contents/api/v1/legislation/get_articles/${normId}`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async get_article(rota, articleId) {
    const path = `contents/api/v1/legislation/get_article?rota=${rota}&article_id=${articleId}`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async get_notas(ids) {
    const path = `contents/api/v1/legislation/get_notas?ids=${ids}`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  }
}
