import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TabScrollButton from '@material-ui/core/TabScrollButton'
import { Tabs as MUITabs, Tab, Box, withStyles } from '@material-ui/core'
import useStyles from './styles'
import { TrackingsService } from '../../services'

export const BookTabsHeader = ({
  children,
  screenSize,
  color,
  variant,
  book,
  tabValue,
  setTabValue
}) => {
  const classes = useStyles()
  const isMobile = window.innerWidth <= 960
  const HandleChange = (_event, newValue) => {
    _event.preventDefault()

    if (_event.target.innerHTML === 'Avaliações') {
      TrackingsService.sendTrack('abaAvalicao', book)
    }

    if (_event.target.innerHTML === 'Materiais') {
      TrackingsService.sendTrack('materiais', book)
    }

    if (_event.target.innerHTML === 'Atualize seu código') {
      TrackingsService.sendTrack('atualizeSeuCodigo', book)
    }

    if (_event.target.innerHTML === 'Legislação adicional') {
      TrackingsService.sendTrack('legislacaoAdicional', book)
    }

    setTabValue(newValue)
  }

  const screenSizeMobile = screenSize === 'mobile'

  const MyTabScrollButton = withStyles((theme) => ({
    root: {
      width: 24,
      overflow: 'hidden',
      borderRadius: '100%',
      color: 'transparent',
      transition: 'width 0.5s',
      '&.Mui-disabled': {
        width: 0
      },
      '& .MuiSvgIcon-fontSizeSmall': {
        fill: theme.palette.text.secondary,
        fontSize: '1.5rem'
      }
    }
  }))(TabScrollButton)

  useEffect(() => {
    const button = document.getElementById('tab-1')
    const span = button.querySelector('span')

    if (span) {
      span.style.flexDirection = 'row'
    }
  }, [])

  return (
    <div className={classes.container} data-testid="book-tabs-header">
      <Box>
        <MUITabs
          value={isMobile ? tabValue - 1 : tabValue}
          selectionFollowsFocus
          onChange={HandleChange}
          indicatorColor={color}
          textColor={color}
          variant={variant}
          scrollButtons={screenSizeMobile ? 'on' : 'off'}
          ScrollButtonComponent={MyTabScrollButton}
          aria-label="scrollable book tab"
        >
          {children.map((tab, index) => (
            <Tab
              key={tab.label}
              aria-label="TabBook"
              id={`tab-${index + 1}`}
              label={tab.label}
              classes={{ root: classes.tab }}
              style={
                tab.testid === 'chat-bot' && isMobile ? { display: 'none' } : {}
              }
              data-testid={tab.testid}
            />
          ))}
        </MUITabs>
      </Box>
    </div>
  )
}

BookTabsHeader.propTypes = {
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
  children: PropTypes.oneOf(Array),
  screenSize: PropTypes.oneOf(['desktop', 'mobile']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['scrollable', null]),
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}

BookTabsHeader.defaultProps = {
  screenSize: 'desktop',
  color: 'primary',
  variant: 'scrollable',
  children: []
}

export default BookTabsHeader
