import { useEffect } from 'react'
import { useContextSelector } from 'use-context-selector'

import { isAuthenticated } from '../auth'
import { GlobalContext } from '../contexts/GlobalContext'
import { getRecommendedBooksAction } from '../contexts/actions'

export function useRecommendations() {
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const { data: recommendedBooks, loadingRecommendedBooks } =
    globalState.recommendedBooks

  useEffect(() => {
    if (isAuthenticated()) {
      getRecommendedBooksAction(globalDispatch)
    }
  }, [globalDispatch])

  return {
    recommendedBooks,
    loadingRecommendedBooks
  }
}
