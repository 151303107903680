import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowBackRounded } from '@material-ui/icons'
import useStyles from './styles'

export default function ReturnButton({ setMaterialUrl }) {
  const { caeId } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const handleReturn = () => {
    setMaterialUrl('')
    history.push(`/livro-ativado/${caeId}`, { from: 'biblioteca' })
  }

  return (
    <>
      <button
        type="button"
        className={classes.returnButton}
        onClick={() => handleReturn()}
        data-testid="bookactived-accordion-back"
      >
        <ArrowBackRounded /> VOLTAR
      </button>
    </>
  )
}

ReturnButton.propTypes = {
  setMaterialUrl: PropTypes.func.isRequired
}
