import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  pageCard: {
    flex: '1 1 129px',
    borderRadius: '17px',
    padding: theme.spacing(2, 2, 2.75),
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(129, 106, 177, 0.1)',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
      '& $icon': {
        background: theme.palette.primary.main,
        color: 'white',
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 0
      },
      '& $title': {
        color: theme.palette.primary.main,
        fontWeight: 600
      }
    },

    [theme.breakpoints.up('sm')]: {
      flex: '1 1 217px'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  icon: {
    position: 'relative',
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      width: 'calc(100% - 6px)',
      height: 'calc(100% - 6px)',
      background: 'transparent'
    }
  },

  title: {
    ...theme.typography.subtitle1,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {}
  },

  description: {
    ...theme.typography.body2,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body2,
      textAlign: 'center'
    }
  }
}))

export { useStyles }
