import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  mainIframe: {
    width: '100%',
    backgroundColor: 'green',
    minHeight: 500,
    border: 'none',
    padding: 0
  }
}))

export default useStyles
