import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: 12
    }
  },
  containerAvaliacao: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 21,
      paddingRight: 20,
      paddingBottom: 51
    }
  },
  NomeUsuario: {
    color: '#363636',
    marginLeft: 12,
    marginTop: 4,
    [theme.breakpoints.down('md')]: {
      marginTop: -4
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginTop: 2
    }
  },
  GridAvaliado: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: -8,
      marginLeft: 40,
      fontSize: 10
    },
    [theme.breakpoints.down('md')]: {
      marginTop: -6,
      marginLeft: -4
    }
  },
  UsuarioEdata: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  GridNota: {
    marginLeft: -4
  },
  AvaliadoEm: {
    color: theme.palette.text.secondary,
    marginLeft: 12,
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      marginLeft: 16
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16
    }
  },
  Rating: {
    marginLeft: 53,
    [theme.breakpoints.down('xs')]: {
      marginTop: 5
    }
  },
  GridNotaLabel: {
    margin: 2,
    [theme.breakpoints.down('xs')]: {
      marginTop: 7
    }
  },
  notaLabel: {
    color: theme.palette.text.secondary,
    marginLeft: 12,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  Avaliacao: {
    textAlign: 'justify',
    marginLeft: 53,
    marginTop: 2,
    [theme.breakpoints.down('xs')]: {
      marginTop: 4,
      fontSize: 12
    }
  },
  Denunciar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    textDecoration: 'none',
    cursor: 'pointer'
  },

  divider: {
    maxWidth: 1300,
    margin: '0px 0px 15px 0px',
    [theme.breakpoints.down('xl')]: {
      width: '100%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  DenunciarComentarioContainer: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
    marginBottom: 8,
    justifyContent: 'flex-end'
  },
  headRating: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10
    }
  },
  emptyRatings: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12
    }
  },
  textDialog: {
    color: theme.palette.text.primary
  },
  textArea: {
    marginTop: 22,
    marginBottom: 20
  }
}))

export default useStyles
