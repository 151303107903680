import React from 'react'
import { useStyles } from './style'
import emptyWishlistWeb from '../../../static/emptyWishlistWeb.png'
import emptyWishlistMobile from '../../../static/emptyWishlistMobile.png'

export function EmptyWishList() {
  const classes = useStyles()

  function getImageByDevice() {
    if (window.innerWidth >= 960) return emptyWishlistWeb
    return emptyWishlistMobile
  }

  return (
    <div className={classes.containerEmpty} data-testid="wishlist-empty">
      <img src={getImageByDevice()} alt="Lista de Desejos Vazia" />
    </div>
  )
}
