import {
  LIBRARY_MATERIAL_LOADING,
  LIBRARY_MATERIAL_SUCCESS,
  LIBRARY_MATERIAL_ERROR
} from '../../../utils/actionTypes'
import { MaterialsService } from '../../../services'

export const getLibraryMaterialAction = async (dispatch, payload = {}) => {
  dispatch({
    type: LIBRARY_MATERIAL_LOADING
  })

  const materialResponse = await MaterialsService.getMaterials(payload.cae)
  let materialData = await materialResponse.json()

  if (materialResponse.ok) {
    materialData = materialData.result.length
      ? materialData.result[0].materiais
      : []

    dispatch({
      type: LIBRARY_MATERIAL_SUCCESS,
      payload: {
        material: materialData,
        lastCaeId: payload.cae
      }
    })
  } else {
    dispatch({
      type: LIBRARY_MATERIAL_ERROR,
      payload: materialData.error
    })
  }

  return {
    ok: materialResponse.ok
  }
}
