import React from 'react'
import { Box, Typography } from '@material-ui/core'
import activeLink from '../../static/activeLink.png'
import { useStyles } from './styles'

export default function LinkActivated() {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box>
          <Typography variant="h4" className={classes.title} color="primary">
            Link já ativado
          </Typography>
          <Typography variant="body1" className={classes.body}>
            O link que você clicou já foi utilizado para ativação dos e-books!
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            className={classes.footer}
          >
            Faça login no{' '}
            <a href="/" onClick="history.push('/');">
              Conecta{' '}
            </a>
            com a conta em que o link foi ativado e leia seus livros!
          </Typography>
        </Box>
      </Box>

      <Box className={classes.image}>
        <img
          className={classes.img}
          src={activeLink}
          alt="imagem de ativacao"
        />
      </Box>
    </Box>
  )
}
