import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Clear, Check } from '@material-ui/icons'
import useStyles from './styles'

const Indexes = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E'
}

const QuestionList = ({
  selectedAnswer,
  correctAnswer,
  currentQuestion,
  answerSent,
  setSelectedAnswer
}) => {
  const classes = useStyles()

  const getAnswerStatus = (alternative) => {
    if (correctAnswer) {
      if (selectedAnswer.id === alternative.id) {
        return correctAnswer.id === alternative.id && correctAnswer.correct
          ? ' success'
          : ' error'
      }
      return ''
    }

    if (alternative.correct === null) {
      if (selectedAnswer && selectedAnswer.id === alternative.id) {
        return ' selected'
      }
      return ''
    }

    if (alternative.correct === true) {
      return ' success'
    }

    return ' error'
  }

  const getAnswerStatusIcon = (alternative) => {
    if (correctAnswer === undefined) return <span />

    if (correctAnswer) {
      if (selectedAnswer.id === alternative.id) {
        return correctAnswer.id === alternative.id && correctAnswer.correct ? (
          <Check className="success-svg" />
        ) : (
          <Clear className="error-svg" />
        )
      }
    }

    return <span />
  }

  return (
    <>
      <div className={classes.questionGrid}>
        {currentQuestion.alternatives.map((alternative, itemKey) => (
          <Grid
            container
            className={classes.questionBox + getAnswerStatus(alternative)}
            key={alternative.id}
            onClick={() => setSelectedAnswer(alternative)}
            data-testid="bookactived-question-item"
          >
            <Grid item xs={2}>
              <span
                className={classes.boxLetter + getAnswerStatus(alternative)}
              >
                {Indexes[itemKey]}
              </span>
            </Grid>
            <Grid item xs={8}>
              <span
                className={getAnswerStatus(alternative)}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: alternative.contents }}
              />
            </Grid>
            <Grid item xs={2}>
              {getAnswerStatusIcon(alternative)}
            </Grid>
          </Grid>
        ))}
      </div>

      {answerSent ? (
        <div>
          <h2 className={classes.feedbackTitle}>Feedback</h2>
          <span>
            <p>A resposta correta é:</p>
            <span
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: correctAnswer.contents }}
            />
          </span>
        </div>
      ) : null}
    </>
  )
}

QuestionList.propTypes = {
  selectedAnswer: PropTypes.oneOfType([PropTypes.object]),
  correctAnswer: PropTypes.oneOfType([PropTypes.object]),
  currentQuestion: PropTypes.oneOfType([PropTypes.object]),
  answerSent: PropTypes.bool,
  setSelectedAnswer: PropTypes.func
}

QuestionList.defaultProps = {
  selectedAnswer: {},
  correctAnswer: null,
  currentQuestion: {},
  answerSent: false,
  setSelectedAnswer: () => null
}

export default QuestionList
