import {
  UPLOAD_TEACHER_FILE_LOADING,
  UPLOAD_TEACHER_FILE_SUCCESS,
  UPLOAD_TEACHER_FILE_ERROR
} from '../../../utils/actionTypes'
import { TeacherService } from '../../../services'

export const uploadTeacherFileAction = async (dispatch, payload) => {
  dispatch({
    type: UPLOAD_TEACHER_FILE_LOADING
  })

  const uploadResponse = await TeacherService.uploadFile(payload)

  if (uploadResponse.ok) {
    const uploadData = await uploadResponse.json()

    dispatch({
      type: UPLOAD_TEACHER_FILE_SUCCESS,
      payload: uploadData
    })
  } else {
    dispatch({
      type: UPLOAD_TEACHER_FILE_ERROR,
      payload: true
    })
  }

  return {
    ok: uploadResponse.ok
  }
}
