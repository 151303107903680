/* eslint-disable no-console */
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Avatar, Grid, Typography } from '@material-ui/core'
import { Dialog } from '@grupogen/saraiva-ui'

import { Rating } from '@material-ui/lab'
import { StarRounded } from '@material-ui/icons'
import { GeneralContext } from '../../../contexts/GeneralContext'

import useStyles from './styles'
import { getDeviceType } from '../../../utils'
import Messages from '../../../config/Messages'
import { isAuthenticated } from '../../../auth'
import { BookService, TrackingsService } from '../../../services'

export default function Avaliacao({ avaliacao, book }) {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const {
    first_name: nome,
    rating: nota,
    inserted_at: data,
    comment: comentario
  } = avaliacao
  const iniciais = nome && nome.split(' ').map((n) => n[0])
  const { setSuccessMessage, setErrorMessage, setDialogNotAuthenticated } =
    useContext(GeneralContext)

  const handleToggleDialog = () => {
    if (isAuthenticated()) {
      setOpenDialog(!openDialog)
    } else {
      TrackingsService.sendTrack('denunciarComentario', book)
      setDialogNotAuthenticated(Messages.commentReport.notAuthenticate)
    }
  }

  const handleSendReport = async (event, id) => {
    event.preventDefault()

    await TrackingsService.sendTrack('denunciarComentario', book)

    const body = {
      comment_id: id,
      browser: 'chrome',
      ip: '127.0.0.1'
    }

    try {
      const response = await BookService.reportReview(body)
      const result = await response.json()

      if (result) {
        if (response.status === 406) {
          setErrorMessage(Messages.commentReport.unauthorized)
        } else {
          setSuccessMessage(Messages.commentReport.success)
        }
      }

      setOpenDialog(false)
    } catch (e) {
      setErrorMessage(Messages.commentReport.error)
    }

    return null
  }
  return (
    <Grid container tabindex={0} data-testid="book-avaliation-item">
      <Grid item>
        <Avatar>{iniciais}</Avatar>
      </Grid>
      <div className={classes.UsuarioEdata}>
        <Grid item>
          <Typography variant="h6" className={classes.NomeUsuario}>
            {nome}
          </Typography>
        </Grid>
        <Grid item className={classes.GridAvaliado}>
          <Typography variant="caption" className={classes.AvaliadoEm}>
            Avaliado em {new Date(data).toLocaleDateString()}
          </Typography>
        </Grid>
      </div>
      <Grid container>
        <Grid item className={classes.GridNota}>
          <Rating
            readOnly
            name="simple-controlled"
            value={nota}
            title="Avaliação do Livro"
            className={classes.Rating}
            size="small"
            icon={<StarRounded className={classes.RatingStar} />}
          />
        </Grid>
        <Grid item className={classes.GridNotaLabel}>
          <Typography variant="body2" className={classes.notaLabel}>
            ({nota}/5)
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 2 }}>
        <Typography variant="body2" className={classes.Avaliacao}>
          {comentario}
        </Typography>
      </Grid>
      <div className={classes.DenunciarComentarioContainer}>
        <span>
          <Typography
            variant="subtitle2"
            className={classes.Denunciar}
            onClick={handleToggleDialog}
            data-testid="book-avaliation-item-complaint"
          >
            Denunciar Comentário
          </Typography>
        </span>
      </div>

      <Dialog
        open={openDialog}
        title="Denunciar Comentário"
        primaryButton="CONFIRMAR"
        secondaryButton="FECHAR"
        size={getDeviceType() === 'mobile' ? 'sm' : 'md'}
        handleClose={handleToggleDialog}
        handleConfirm={(event) => handleSendReport(event, avaliacao.id)}
      >
        <Typography variant="body1">
          Se você considera esse comentário impróprio e acha que deve ser
          removido da nossa plataforma, informe-nos clicando no botão de
          confirmar abaixo
        </Typography>
      </Dialog>
    </Grid>
  )
}

Avaliacao.propTypes = {
  avaliacao: PropTypes.arrayOf.isRequired,
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}
