import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tab: {
    color: theme.palette.text.secondary,
    minWidth: 133,
    marginTop: '0px',
    width: 'auto',
    display: 'inline-flex'
  },
  indicator: {
    top: '46px'
  }
}))

export default useStyles
