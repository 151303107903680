import { Box } from '@material-ui/core'
import React from 'react'
import { ActivateBook, HelpActivationBook } from '../../../../components'
import { useStyles } from './styles'

export default function SandwichContent() {
  const classes = useStyles()

  return (
    <Box className={classes.sandwichContent}>
      <ActivateBook />

      <Box className={classes.helpActivationBook}>
        <HelpActivationBook />
      </Box>
    </Box>
  )
}
