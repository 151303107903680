import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@grupogen/saraiva-ui'

import { useSnackbarStyle } from './styles'

export default function SnackBar({
  children,
  show,
  severity,
  handleClose,
  timer
}) {
  const snackbarStyles = useSnackbarStyle()
  const [showSnackBar, setShowSnackBar] = useState(true)

  useEffect(() => {
    let snackBarTimeout

    if (timer) {
      setShowSnackBar(true)
      snackBarTimeout = setTimeout(() => setShowSnackBar(false), timer)
    }

    return () => clearTimeout(snackBarTimeout)
  }, [timer])

  return (
    <>
      {showSnackBar && (
        <Snackbar
          severity={severity}
          show={show}
          handleClose={handleClose}
          classes={() => snackbarStyles}
        >
          {children}
        </Snackbar>
      )}
    </>
  )
}

SnackBar.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  timer: PropTypes.number
}

SnackBar.defaultProps = {
  children: null,
  timer: 0
}
