import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  stepWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4)
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginTop: 0
    }
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    height: '35px',
    maxWidth: '118px',

    [theme.breakpoints.up('sm')]: {
      height: '54px',
      maxWidth: '182px'
    }
  },
  contentWrapper: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5)
    }
  },
  introText: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.secondary,
    ...theme.typography.body2,

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6.5),
      ...theme.typography.body1
    },

    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  middleText: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    ...theme.typography.body2,

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body1
    },

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3)
    }
  },
  textHighlight: {
    color: theme.palette.primary.light,
    fontWeight: 500
  },
  list: {
    paddingLeft: theme.spacing(3)
  },
  endText: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    ...theme.typography.body2,

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body1
    }
  }
}))

export default useStyles
