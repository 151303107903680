import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useContextSelector } from 'use-context-selector'
import { ErrorOutline } from '@material-ui/icons'
import { Typography, Button } from '@material-ui/core'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import {
  uploadTeacherFileAction,
  resetUploadTeacherFileAction
} from '../../../../contexts/actions'
import { TrackingsService } from '../../../../services'

import useStyles from './styles'
import { Loading } from '../../../../components'
import DragFileUpload from './DragFileUpload'
import UploadError from './UploadError'
import UploadSuccess from './UploadSuccess'

const BookProfessorRequest = ({ book }) => {
  const classes = useStyles()
  const [file, setFile] = useState(null)
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (teacherUpload) => teacherUpload
  )
  const { data, loading, error } = globalState.teacherUpload

  const handleUploadFile = () => {
    if (!file) return
    TrackingsService.sendTrack('subiu_documento_professor', book)
    uploadTeacherFileAction(globalDispatch, file)
  }

  const resetUploadStatus = useCallback(() => {
    resetUploadTeacherFileAction(globalDispatch)
  }, [globalDispatch])

  useEffect(() => {
    resetUploadStatus()
  }, [resetUploadStatus])

  return (
    <div className={classes.content}>
      {!data ? (
        <>
          <Typography variant="subtitle1" className={classes.titleDialog}>
            Este livro possui material especial para professores!
          </Typography>
          <div className={classes.BoxAlertUpload}>
            <div className={classes.BoxAlert}>
              <Typography variant="subtitle1" className={classes.textDialog}>
                Se você é um professor, faça o upload de um comprovante válido
                de vínculo com uma instituição para ter acesso ao conteúdo
                exclusivo. Nosso suporte irá validar o comprovante e enviará uma
                resposta por email.
              </Typography>

              <div className={classes.baseAlert}>
                <div className={classes.iconContent}>
                  <ErrorOutline />
                </div>
                <div>
                  <Typography
                    variant="subtitle1"
                    className={classes.baseAlertText}
                  >
                    Tipo de documento válido: foto do crachá/carteirinha da
                    instituição onde trabalha, folha de pagamento/ holerite
                    recente (até os 3 últimos meses) ou uma foto da sua carteira
                    de trabalho.
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.uploadContent}>
              <div className={classes.uploadBox}>
                {error && <UploadError resetUploadStatus={resetUploadStatus} />}
                {loading && <Loading isCenter />}
                {!error && !error && !loading && (
                  <DragFileUpload setFile={setFile} />
                )}
              </div>
            </div>
          </div>
          <div className={classes.buttonContent}>
            <Button
              variant="contained"
              color="primary"
              disabled={!file}
              onClick={handleUploadFile}
            >
              ENVIAR COMPROVANTE
            </Button>
          </div>
        </>
      ) : (
        <UploadSuccess />
      )}
    </div>
  )
}

BookProfessorRequest.propTypes = {
  book: PropTypes.shape({}).isRequired
}

export default BookProfessorRequest
