import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerFilterButton: {
    display: 'flex'
  },
  filterButton: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: 0
  },
  containerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    marginBottom: 56,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 20
    }
  },
  title: {
    color: theme.palette.text.secondary,
    marginTop: 24,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
      marginBottom: 24
    }
  },
  subTitle: {
    ...theme.typography.caption,
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: 64,
    marginBottom: 8,

    [theme.breakpoints.up('sm')]: {
      top: '-17px',
      position: 'relative'
    },
    [theme.breakpoints.up('md')]: {
      ...theme.typography.caption,
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: 12,
      position: 'relative',
      right: '-10px',
      top: 0
    }
  },
  body: {
    ...theme.typography.body2,
    width: 276,
    height: 20,
    marginBottom: 12,
    color: '#4E3C74',

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.h6,

      width: '100%',
      marginBottom: 16,
      color: '#4E3C74',
      position: 'relative',
      textAlign: 'initial',
      top: '-24px'
    },

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h6,
      width: '884px',
      marginBottom: 16,
      color: '#4E3C74',
      position: 'relative',
      right: '-10px',
      top: 0
    }
  },
  headerDivider: {
    marginBottom: 16,
    marginLeft: 10,
    width: '928px',
    [theme.breakpoints.down(769)]: {
      marginBottom: 16,
      marginLeft: 0,
      width: '928px'
    }
  },
  containerCatalog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 16,
    marginTop: 40,
    [theme.breakpoints.down(200)]: {
      width: 10,
      marginLeft: 5
    }
  },
  button: {
    height: 24,
    width: 170,
    background: '#f0f0f0',
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      width: 60
    }
  },
  rating: {
    margin: '10px 0 16px 0',
    fontSize: 19
  },
  headerGrid: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'auto'
  },
  grid: {
    position: 'relative',
    flex: `0 1 50%`,
    marginLeft: 16,

    [theme.breakpoints.up(532)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up('sm')]: {
      flex: `0 1 50%`
    },

    [theme.breakpoints.up(856)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up('md')]: {
      flex: `0 1 50%`
    },

    [theme.breakpoints.up(1064)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up(1280)]: {
      flex: `0 1 25%`
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    marginBottom: -13
  },
  bookTitle: {
    maxWidth: '160px',
    height: 70,
    marginTop: -30,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    '&::before': {
      content: '...'
    },
    [theme.breakpoints.down(600)]: {
      margin: '-24px -8px !important'
    },
    [theme.breakpoints.down(420)]: {
      maxWidth: '120px',
      margin: '-18px -8px !important'
    },
    [theme.breakpoints.down(395)]: {
      maxWidth: '93px'
    }
  },
  authors: {
    whiteSpace: 'nowrap',
    maxWidth: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    marginTop: 14,
    [theme.breakpoints.down(600)]: {
      margin: '50px -8px 12px !important',
      maxWidth: '115px',
      fontSize: 12
    },
    [theme.breakpoints.down(420)]: {
      margin: '50px -8px 12px !important',
      maxWidth: '115px',
      fontSize: 12
    },
    [theme.breakpoints.down(395)]: {
      maxWidth: '93px'
    }
  },
  image: {
    width: '95%',
    [theme.breakpoints.down(420)]: {
      margin: '12px 16px 10px !important'
    }
  },
  textSubHeader: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '12px'
  },
  containerDivider: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: 1000,
      width: '100%'
    }
  },
  divider: {
    marginTop: 60,
    maxWidth: 1300,
    [theme.breakpoints.down('xl')]: {
      width: '100%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 4
    }
  },
  pagination: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '22px 0 58px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  cardContainer: {
    background: 'black'
  },
  favoriteIcon: {
    fill: theme.palette.common.white
  },
  favoriteButton: {
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
      right: '11px !important',
      top: '13px !important',
      '& .MuiSvgIcon-root': {
        width: 16,
        height: 16
      }
    }
  }
}))

export const useCardStyles = makeStyles((theme) => ({
  root: {
    width: 156,
    height: 361,
    margin: '0 auto',
    padding: theme.spacing(2, 2, 1.5),

    [theme.breakpoints.up('sm')]: {
      width: 216,
      height: 474,
      margin: 'initial',
      padding: theme.spacing(3, 3, 2)
    }
  }
}))

export const useMediaStyles = makeStyles((theme) => ({
  root: {
    height: 167,

    [theme.breakpoints.up('sm')]: {
      height: 235
    }
  }
}))

export const useContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.25)
    }
  }
}))
