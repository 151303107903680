import React from 'react'
import { Link } from '@material-ui/core'
import { useStyles } from './styles'
import Links from '../../../config/Links'

import emptyLibraryWeb from '../../../static/emptyLibraryWeb.png'
import emptyLibraryMobile from '../../../static/emptyLibraryMobile.png'

export default function EmptyLibrary() {
  const classes = useStyles()

  function getImageByDevice() {
    if (window.innerWidth >= 960) return emptyLibraryWeb
    return emptyLibraryMobile
  }

  return (
    <>
      <div
        className={classes.containerEmptyLibrary}
        data-testid="library-empty"
      >
        <img
          src={getImageByDevice()}
          className={classes.emptyLibrary}
          alt="Biblioteca Vazia"
        />
        <Link variant="body1" href={Links.home} className={classes.button}>
          Ir para o Catálogo
        </Link>
      </div>
    </>
  )
}
