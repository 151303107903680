import {
  PROFILE_INTERESTS_LOADING,
  PROFILE_INTERESTS_SUCCESS,
  PROFILE_INTERESTS_ERROR,
  PROFILE_INTERESTS_ADD_ERROR,
  PROFILE_INTERESTS_ADD_SUCCESS
} from '../../../utils/actionTypes'

const userInterestsReducer = (state, { payload, type }) => {
  switch (type) {
    case PROFILE_INTERESTS_LOADING:
      return {
        ...state,
        error: false,
        loading: true
      }

    case PROFILE_INTERESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_INTERESTS_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload]
      }

    case PROFILE_INTERESTS_ADD_ERROR:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_INTERESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default userInterestsReducer
