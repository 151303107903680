import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@material-ui/core'
import { useStyles } from './style'
import { getDeviceType } from '../../../utils'

export default function ItemsCount({
  pageActual = 0,
  booksQuantity = 0,
  filters = {}
}) {
  const classes = useStyles()
  const itemsPerPage = filters.itemsPerPage ? filters.itemsPerPage : 12
  const actualPage = booksQuantity ? pageActual : 0
  const startItems =
    actualPage > 1 ? itemsPerPage * actualPage - itemsPerPage : actualPage
  let finalItems = 0

  if (actualPage > 0) {
    if (startItems > 1) {
      finalItems = startItems + itemsPerPage
    } else {
      finalItems = itemsPerPage
    }

    if (finalItems > booksQuantity) {
      finalItems = booksQuantity
    }
  }

  return (
    <Box className={classes.itemsCountContent} data-testid="catalog-itemscount">
      <Typography
        variant={getDeviceType() === 'mobile' ? 'subtitle2' : 'h6'}
        className={classes.countTitle}
      >
        Exibindo {startItems}-{finalItems} de {booksQuantity} livros
      </Typography>

      <div className={classes.containerDivider}>
        <Divider className={classes.divider} />
      </div>
    </Box>
  )
}

ItemsCount.propTypes = {
  booksQuantity: PropTypes.number.isRequired,
  pageActual: PropTypes.number.isRequired,
  filters: PropTypes.shape({}).isRequired
}
