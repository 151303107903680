import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core'
import { Button } from '@grupogen/saraiva-ui'
import { useStyles } from './styles'
import { Messages } from '../../../../config'

export default function ProfileStep({
  setCurrentStep,
  setSnackbar,
  setProfilesSelected
}) {
  const [state, setState] = useState({
    Reader: false,
    Teacher: false,
    Author: false
  })

  const classes = useStyles()

  const disableButton = () => {
    return !Object.keys(state).some((profile) => state[profile])
  }

  const handleChangeProfile = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const handleSubmitProfile = async () => {
    const profiles = Object.keys(state).filter((profile) => state[profile])
    const payload = { profiles }

    setProfilesSelected(payload)
    setSnackbar({
      children: Messages.profile.setProfileSuccess,
      severity: 'success',
      show: true
    })
    setCurrentStep('interests')
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Typography component="h1" className={classes.title}>
          Para começar, conte-nos qual o perfil você se identifica:
        </Typography>

        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.Reader}
                onChange={handleChangeProfile}
                name="Reader"
                color="primary"
              />
            }
            label="Sou uma pessoa leitora"
            className={classes.formControlLabel}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.Teacher}
                onChange={handleChangeProfile}
                name="Teacher"
                color="primary"
              />
            }
            label="Sou uma pessoa professora"
            className={classes.formControlLabel}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.Author}
                onChange={handleChangeProfile}
                name="Author"
                color="primary"
              />
            }
            label="Sou uma pessoa autora da Saraiva Educação"
            className={classes.formControlLabel}
          />
        </FormGroup>
      </Box>

      <Box className={classes.buttonWrapper}>
        <Button
          color="primary"
          onClick={() => handleSubmitProfile()}
          variant="contained"
          disabled={disableButton()}
        >
          Próximo
        </Button>
      </Box>
    </>
  )
}

ProfileStep.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  setProfilesSelected: PropTypes.func.isRequired
}
