import { isAuthenticated } from '../../../auth'
import Messages from '../../../config/Messages'
import {
  LibraryService,
  RecommendedService,
  TrackingsService,
  Users,
  BookService
} from '../../../services'
import {
  RECOMMENDED_BOOKS_LOADING,
  RECOMMENDED_BOOKS_SUCCESS,
  RECOMMENDED_BOOKS_ERROR,
  AUTHENTICATED_ERROR
} from '../../../utils/actionTypes'

export const getRecommendedBooksAction = async (
  dispatch,
  payload = { action: 'recommend' }
) => {
  dispatch({
    type: RECOMMENDED_BOOKS_LOADING
  })
  TrackingsService.sendTrack('getRecommendedBooksAction')

  const libraryResponse = await RecommendedService.getRecommendedBooks({
    action: payload.action,
    users: [Users.getUserId()]
  })

  const libraryData = await libraryResponse.json()

  let booksData = []
  if (
    libraryData.data.length &&
    libraryData.data[0].collaborative_list.length
  ) {
    const caeIds = libraryData.data[0].collaborative_list.map((list) => {
      return list.cae
    })

    const recommendedBooksResponse = await LibraryService.loadBook(
      caeIds.join(',')
    )

    booksData = await recommendedBooksResponse.json()

    if (booksData && booksData.length) {
      const getBooksRate = await BookService.getBooksRate(
        booksData.map((i) => i.productCaeId)
      )

      const ratesByBook = await getBooksRate.json()

      ratesByBook.data.map((rate) => {
        booksData.filter((book) => {
          if (book.productCaeId === rate.book_id) book.rating = rate
          return null
        })
        return null
      })
    }
  }

  if (isAuthenticated()) {
    if (libraryResponse.ok) {
      dispatch({
        type: RECOMMENDED_BOOKS_SUCCESS,
        payload: booksData
      })
    } else {
      dispatch({
        type: RECOMMENDED_BOOKS_ERROR,
        error: true,
        payload: booksData
      })
    }
  } else {
    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }

  return {
    ok: libraryResponse.ok,
    isAuthenticated: isAuthenticated()
  }
}
