import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Links from '../config/Links'

export function useError() {
  const history = useHistory()

  const redirectOnError = useCallback(
    (errors) => {
      if (errors) {
        if (errors.status >= 400 && errors.status < 500) {
          history.push(Links.error, { code: '400' })
        } else {
          history.push(Links.error, { code: '500' })
        }
      }
    },
    [history]
  )

  return {
    redirectOnError
  }
}
