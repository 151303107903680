import {
  PROFILE_TYPE_LOADING,
  PROFILE_TYPE_SUCCESS,
  PROFILE_TYPE_ERROR,
  PROFILE_TYPE_ADD_ERROR,
  PROFILE_TYPE_ADD_SUCCESS
} from '../../../utils/actionTypes'
// import baseReducer from '../../../utils/baseReducer'

const userProfileTypeReducer = (state, { payload, type }) => {
  switch (type) {
    case PROFILE_TYPE_LOADING:
      return {
        ...state,
        error: false,
        loading: true
      }

    case PROFILE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_TYPE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...[payload]]
      }

    case PROFILE_TYPE_ADD_ERROR:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
  /* return baseReducer(state, payload, type, [
    PROFILE_TYPE_LOADING,
    PROFILE_TYPE_SUCCESS,
    PROFILE_TYPE_ERROR
  ]) */
}

export default userProfileTypeReducer
