import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  containerContentTabs: {
    marginTop: 32
  },
  contato: {
    padding: '20px 24px 20px 24px',
    marginTop: 32,

    [theme.breakpoints.down('xs')]: {
      padding: '16px 12px 16px 12px',
      marginTop: 24
    }
  },
  email: {
    marginTop: 12,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export default useStyles
