import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@material-ui/core'
import { TreeView } from '@grupogen/saraiva-ui'
import useStyles from './styles'

const Menu = ({ materials, handleSelectContent }) => {
  const classes = useStyles()
  let materialsFiltered = materials[0].materiais.filter(
    (material) => material.materiais.length > 0
  )

  materialsFiltered.forEach((material) => {
    if (material.materiais) {
      material.materiais = material.materiais.sort((a, b) => {
        return a.codigoexterno > b.codigoexterno ? 1 : -1
      })
    }
  })
  materialsFiltered = materialsFiltered.reverse()

  const getMenu = (materialsMenu, parent) => {
    if (materialsMenu) {
      const items = materialsMenu.map((material) => {
        if (Array.isArray(material.materiais)) {
          return {
            id: material.id,
            name: material.titulo || material.nome,
            url: material.url,
            children: getMenu(material.materiais, material)
          }
        }

        return {
          id: material.id,
          name: material.titulo || material.nome,
          url: material.url,
          mesAno: parent.titulo
        }
      })

      return items
    }

    return []
  }

  return (
    <>
      {materials.length ? (
        <Card className={classes.menu} variant="outlined">
          <span
            className={classes.menuItems}
            data-testid="bookactived-actualize-menu"
          >
            <TreeView
              data={getMenu(materialsFiltered)}
              onLabelClick={handleSelectContent}
            />
          </span>
        </Card>
      ) : null}
    </>
  )
}

Menu.propTypes = {
  handleSelectContent: PropTypes.func.isRequired,
  materials: PropTypes.oneOfType(Array).isRequired
}

export default Menu
