const baseReducer = (state, payload, type, listOfTypes) => {
  switch (type) {
    case listOfTypes[0]:
      return {
        ...state,
        error: false,
        loading: true
      }

    case listOfTypes[1]:
      return {
        ...state,
        loading: false,
        error: false,
        data: payload
      }

    case listOfTypes[2]:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default baseReducer
