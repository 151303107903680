import {
  LAST_BOOKS_ERROR,
  LAST_BOOKS_LOADING,
  LAST_BOOKS_SUCCESS
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const lastBooksReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    LAST_BOOKS_LOADING,
    LAST_BOOKS_SUCCESS,
    LAST_BOOKS_ERROR
  ])
}

export default lastBooksReducer
