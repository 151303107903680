import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerEmptyLibrary: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 900,
    maxHeight: 500,
    paddingTop: 50,
    margin: '0 auto',
    '& > img': {
      maxWidth: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 450,
      maxHeight: 550
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
      maxHeight: 370,
      paddingTop: 25
    }
  },
  button: {
    color: theme.palette.primary.dark,
    marginLeft: '50%',
    marginTop: -15,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 0
    }
  }
}))
