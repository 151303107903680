import {
  PROFILE_INTERESTS_EXIST_LOADING,
  PROFILE_INTERESTS_EXIST_ERROR,
  PROFILE_INTERESTS_EXIST_SUCCESS
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const userInterestsExistReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    PROFILE_INTERESTS_EXIST_LOADING,
    PROFILE_INTERESTS_EXIST_SUCCESS,
    PROFILE_INTERESTS_EXIST_ERROR
  ])
}

export default userInterestsExistReducer
