import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Toolbar
} from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  ListAlt,
  MenuBook,
  Reorder,
  Favorite,
  HomeSharp,
  BookRounded
} from '@material-ui/icons'
import { DropDownItem } from '@grupogen/saraiva-ui'
import Links from '../../../config/Links'
import useStyles from './styles'
import { isAuthenticated } from '../../../auth'
import {
  DialogActivateBook,
  ActivateBook,
  HelpActivationBook
} from '../../../components'
import { TrackingsService } from '../../../services'

export default function TabletDrawer({ categories }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [isCategoryPage, setIsCategoryPage] = useState(false)

  function handleTracking(event) {
    TrackingsService.sendTrack(event)
    window.scrollTo(0, 0)
  }

  const changeCategory = (categoria, subcategoria) => {
    let path = `/categoria/${encodeURIComponent(categoria)}`
    if (subcategoria) {
      path += `/${encodeURIComponent(subcategoria)}`
    }
    history.push(path)
    window.scrollTo(0, 0)
  }

  function isSelected(page) {
    if (window.location.pathname === page) return classes.menuItemSelected
    return classes.menuItem
  }

  const renderDropdownCategories = () => {
    const categoryContent = categories.map((category) => {
      if (!category.subcategoria) {
        return (
          <DropDownItem cascaded text={category.name} key={category.name} />
        )
      }
      const subcategoriaContent = category.subcategoria.map((subcategoria) => {
        return (
          <DropDownItem
            key={subcategoria.name}
            text={subcategoria.name}
            onClick={() => changeCategory(category.name, subcategoria.name)}
          />
        )
      })
      return (
        <DropDownItem
          cascaded
          text={category.name}
          key={category.name}
          onClick={() => changeCategory(category.name)}
        >
          {subcategoriaContent}
        </DropDownItem>
      )
    })

    return categoryContent
  }

  useEffect(() => {
    const { pathname } = location

    if (pathname.includes('/categoria')) {
      setIsCategoryPage(true)
    } else {
      setIsCategoryPage(false)
    }
  }, [location])

  return (
    <Toolbar className={classes.toolbar}>
      <BottomNavigation
        showLabels
        classes={{ root: classes.toolbarNavigationContainer }}
      >
        <Box className={classes.activeBookWrapper}>
          <ActivateBook />

          <Box className={classes.helpActivationBook}>
            <HelpActivationBook column />
          </Box>
        </Box>

        <Divider className={classes.divider} />

        <DialogActivateBook />

        {isAuthenticated() ? (
          <BottomNavigationAction
            classes={{
              root: isSelected(Links.home)
            }}
            component={Link}
            to={Links.home}
            onClick={() => handleTracking('abaInicial')}
            label="Página inicial"
            icon={<HomeSharp />}
          />
        ) : null}

        <BottomNavigationAction
          classes={{
            root: isSelected(Links.catalog)
          }}
          component={Link}
          to={Links.catalog}
          onClick={() => handleTracking('abaCatalogo')}
          label="Catálogo"
          icon={<ListAlt />}
        />
        {isAuthenticated() ? (
          <BottomNavigationAction
            classes={{
              root: isSelected(Links.ebooks)
            }}
            component={Link}
            to={Links.ebooks}
            onClick={() => handleTracking('abaMeusEbooks')}
            label="Meus E-books"
            icon={<BookRounded />}
          />
        ) : null}
        {isAuthenticated() ? (
          <BottomNavigationAction
            classes={{
              root: isSelected(Links.library)
            }}
            component={Link}
            to={Links.library}
            onClick={() => handleTracking('abaMeusMateriais')}
            label="Meus Materiais"
            icon={<MenuBook />}
          />
        ) : null}
        {isAuthenticated() ? (
          <BottomNavigationAction
            classes={{
              root: isSelected(Links.wishlist)
            }}
            component={Link}
            onClick={() => handleTracking('abaFavoritos')}
            to={Links.wishlist}
            label="Lista de Desejos"
            icon={<Favorite />}
          />
        ) : null}
        {categories && categories.length ? (
          <div
            className={
              isCategoryPage
                ? `${classes.categoriesButton} ${classes.categoriesButtonActive}`
                : classes.categoriesButton
            }
          >
            <DropDownItem
              cascaded
              text={
                <span className={classes.categoriesLink}>
                  <Reorder />
                  <span>Categorias</span>
                </span>
              }
              key="category.name"
              onClick={() => changeCategory('category.name')}
            >
              {renderDropdownCategories()}
            </DropDownItem>
          </div>
        ) : null}
      </BottomNavigation>
    </Toolbar>
  )
}

TabletDrawer.propTypes = {
  categories: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    ordem: PropTypes.number.isRequired,
    subcategoria: PropTypes.arrayOf({
      id: PropTypes.number,
      name: PropTypes.string,
      ordem: PropTypes.number
    })
  })
}

TabletDrawer.defaultProps = {
  categories: []
}
