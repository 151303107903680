/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Button
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getDeviceType,
  formatDate,
  getAuthors,
  toCamelCase
} from '../../../../utils'
import saraivajur from '../../../../static/saraivajur.png'
import saraivauni from '../../../../static/saraivauni.png'
import erica from '../../../../static/erica.png'
import benvira from '../../../../static/benvira.png'
import useStyles from './styles'
import { getBookReaderUrl } from '../../../../utils/utilsGroup/books'
import { BookService, TrackingsService, Users } from '../../../../services'
// Bloco do chatbot
// import { GeneralContext } from '../../../../contexts/GeneralContext'
// Bloco do chatbot
// import Chatbot from '../../../../components/Chatbot'

const BookDescriptionActivated = ({ book }) => {
  const { caeId } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))
  // Bloco do chatbot
  // const isMobile = window.innerWidth <= 962
  const [activateEbookbyMaterial, setActivateEbookbyMaterial] = useState(false)
  // Bloco do chatbot
  // const { setOpenChatBotDrawer } = useContext(GeneralContext)
  // substituir pela validação se existe livro digital ou não
  // const livroDigital = true
  const {
    titleCover,
    productCategory,
    edition,
    editionYear,
    area,
    label,
    closingDate,
    subtitle
  } = book

  const location = useLocation()

  const getSelo = (areaOjb) => {
    const areas = {
      Universitario: saraivauni,
      Juridico: saraivajur,
      'Ficcao / Nao Ficcao': benvira,
      'Livros Tecnicos': erica
    }

    return areas[areaOjb]
  }

  const displayEditionAndYear = () => {
    return (edition && editionYear) || false
  }

  async function reportLastBook(bookId) {
    try {
      const response = await BookService.reportLastBook(bookId)
      await response.json()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }

  const onReadButton = () => {
    const payload = {
      categoria: book.category,
      user_name: Users.getFullName(),
      user_email: Users.getEmail()
    }
    TrackingsService.sendTrack('openBook', book, payload)

    reportLastBook(caeId)

    history.push(getBookReaderUrl(book))
  }

  const vade36 = location.pathname === '/livro-ativado/830027'
  const vade37 = location.pathname === '/livro-ativado/845387'
  const segEmed = location.pathname === '/livro-ativado/845279'
  const activateVade = () => {
    if (vade36 || vade37 || segEmed) {
      setActivateEbookbyMaterial(true)
    }
  }

  const onClickVade = () => {
    if (vade36) {
      window.open(
        'https://conecta.grupogen.com.br/inscricao/11dc60a6-dbd4-4a77-9710-5449f989e645'
      )
      setActivateEbookbyMaterial(false)
    }
    if (vade37) {
      window.open(
        'https://conecta.grupogen.com.br/inscricao/1d65782b-3415-4a45-a314-a4fdaf791e7e'
      )
      setActivateEbookbyMaterial(false)
    }
    if (segEmed) {
      window.open(
        'https://conecta.grupogen.com.br/inscricao/6d474fe8-84df-4428-bb6a-cce74573f6b7'
      )
      setActivateEbookbyMaterial(false)
    }
  }

  // Bloco do chatbot
  // const handleOpenChatBot = () => {
  //   setOpenChatBotDrawer(true)
  // }

  useEffect(() => {
    activateVade()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // fim do bloco

  // Bloco do chatbot
  // const betaTag = () => {
  //   return (
  //     <div className={classes.tagBeta}>
  //       <Typography className={classes.betaText}>beta</Typography>
  //     </div>
  //   )
  // }

  // Bloco do chatbot
  // const chatBotFilter = () => {
  //   const idsValidos = [
  //     '752276',
  //     '735246',
  //     '818247',
  //     '818248',
  //     '600259',
  //     '626983',
  //     '830027',
  //     '830028',
  //     '819774',
  //     '820199'
  //   ]
  //   const { pathname } = location

  //   const idChatBot = idsValidos.map((id) => pathname.includes(id))
  //   if (idChatBot.includes(true)) {
  //     return true
  //   }
  //   return false
  // }

  return (
    <Grid item className={classes.bookDescription}>
      {/* Bloco do chatbot */}
      {/* {isMobile && <Chatbot book={book} />} */}
      <Typography
        variant={getDeviceType() === 'mobile' ? 'body1' : 'h5'}
        className={classes.title}
        title={toCamelCase(titleCover)}
        data-testid="book-title"
      >
        {`${toCamelCase(titleCover)} ${
          subtitle && subtitle !== undefined ? `- ${toCamelCase(subtitle)}` : ``
        }`}
      </Typography>
      <Typography
        variant={getDeviceType() === 'mobile' ? 'subtitle2' : 'h6'}
        className={classes.authors}
        title={getAuthors(book)}
        data-testid="book-authors"
      >
        {getAuthors(book)}
      </Typography>

      {displayEditionAndYear() && (
        <Typography
          variant="body2"
          title={`${edition}ª edição | ${editionYear}`}
          className={classes.bookProperties}
          data-testid="book-edition"
        >
          {`${edition}ª edição | ${editionYear}`}
        </Typography>
      )}

      {closingDate && (
        <Typography
          variant="body2"
          title={`Data de fechamento: ${formatDate(closingDate)} `}
          className={classes.bookProperties}
          data-testid="book-date"
        >
          {`Data de fechamento: ${formatDate(closingDate)} `}
        </Typography>
      )}
      {productCategory && !activateEbookbyMaterial && (
        <div className={classes.logoContainer}>
          <Typography
            variant="body2"
            title={`Formato: ${productCategory}`}
            className={classes.bookProperties}
          >
            {`Formato: ${productCategory}`}
          </Typography>
          {!mobile && productCategory !== 'Livro Impresso' && (
            <Grid item className={classes.selo}>
              {area && (
                <img
                  src={getSelo(area)}
                  alt={label}
                  title={`${label}${' '}${area}`}
                  className={classes.publisherLogo}
                />
              )}
            </Grid>
          )}
        </div>
      )}

      <div>
        {mobile && productCategory !== 'Livro Impresso' && (
          <Grid item className={classes.seloMobile}>
            {area && (
              <img
                src={getSelo(area)}
                alt={label}
                title={`${label}${' '}${area}`}
                className={classes.publisherLogo}
              />
            )}
          </Grid>
        )}
        {activateEbookbyMaterial && (
          <div className={classes.logoContainer}>
            <Typography
              variant="body2"
              title={`Formato: ${productCategory}`}
              className={classes.bookProperties}
            >
              {`Formato: ${productCategory}`}
            </Typography>
            <Grid item className={classes.selo}>
              {area && (
                <img
                  src={getSelo(area)}
                  alt={label}
                  title={`${label}${' '}${area}`}
                  className={classes.publisherLogo}
                />
              )}
            </Grid>
          </div>
        )}
        <Divider className={classes.divider} />

        {productCategory !== 'Livro Impresso' ? (
          <div className={classes.buttonsContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.readButton}
              fullWidth={!!mobile}
              onClick={() => onReadButton()}
            >
              Ler livro
            </Button>
            {/* Bloco do chatbot */}
            {/* {isMobile && chatBotFilter() && (
              <Button
                variant="outlined"
                fullWidth
                className={classes.chatBotButton}
                onClick={handleOpenChatBot}
              >
                Perguntar ao Livro{betaTag()}
              </Button>
            )} */}
          </div>
        ) : activateEbookbyMaterial ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onClickVade()}
          >
            ATIVAR VERSÃO DIGITAL
          </Button>
        ) : (
          <Grid item className={classes.selo}>
            {area && (
              <img
                src={getSelo(area)}
                alt={label}
                title={`${label}${' '}${area}`}
                className={classes.publisherLogo}
              />
            )}
            {/* Bloco do chatbot */}
            {/* {isMobile && chatBotFilter() && (
              <Button
                variant="outlined"
                fullWidth
                className={classes.chatBotButton}
                onClick={handleOpenChatBot}
              >
                Perguntar ao Livro{betaTag()}
              </Button>
            )} */}
          </Grid>
        )}
      </div>
    </Grid>
  )
}

BookDescriptionActivated.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default BookDescriptionActivated
