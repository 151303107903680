import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sandwichContent: {
    padding: theme.spacing(4, 2, 1),
    marginBottom: theme.spacing(1.5),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  helpActivationBook: {
    marginTop: theme.spacing(1)
  }
}))
