import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const header = {
  'session-token': getSessionToken(),
  'application-token': process.env.REACT_APP_TOKEN
}
const buildedHeader = httpClient.buildHeader(header)

export const ContentsService = {
  async getCatalog(
    currentPage,
    booksPerPage,
    category,
    subcategory,
    termOfSearch,
    orderBy,
    filters,
    caeIds
  ) {
    let path = 'contents/api/v1/library'

    if (currentPage) {
      path += `?page=${currentPage}`
    } else {
      path += '?page=1'
    }

    if (booksPerPage) {
      path += `&per_page=${booksPerPage}`
    } else if (booksPerPage === null) {
      path += '&per_page=12'
    }

    if (termOfSearch && !category) {
      path += `&query=${termOfSearch}`
    }

    let filtersQuery = ''

    if (Array.isArray(filters) && filters.length) {
      filtersQuery = filters.reduce((previous, filter) => {
        return `${previous}&filter[][]=${filter.type}:${encodeURI(
          filter.value
        )}`
      }, '')

      path += filtersQuery
    }

    if (category) {
      path += `&category=${category}`

      if (subcategory) {
        path += `&subcategory=${subcategory}`
      }

      path += '&match_phrase=true'
    }

    if (orderBy) {
      path += `&sort=${orderBy}`
    }

    if (caeIds) {
      const tempPath = caeIds.map((caeId) => {
        if (Object.prototype.hasOwnProperty.call(caeId, 'favorite_id')) {
          return `&product_cae_id[]=${caeId.favorite_id}`
        }

        return `&product_cae_id[]=${caeId}`
      })

      path += tempPath.map((item) => item).join('')
    }

    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },
  async getMocks(caeId) {
    const path = `contents/api/v1/practice_test/?cae_id=${caeId}`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },
  async getPracticeQuestions(mockId) {
    const path = `/contents/api/v1/practice_test/?id=${mockId}`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },
  async practiceTestAttempt(payload, userId, testId) {
    const path = `/contents/api/v1/practice_test/attempt?user_id=${userId}&test_id=${testId}`
    const response = httpClient.post(path, payload, buildedHeader)
    return response
  },
  async getPracticeTestAttempt(userId, testId) {
    const path = `/contents/api/v1/practice_test/attempt?user_id=${userId}&test_id=${testId}`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },
  async getCampaignBanner() {
    const path = '/contents/api/v1/campaign_upload'
    const response = httpClient.get(path, null, buildedHeader)
    return response
  }
}
