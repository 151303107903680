import { makeStyles } from '@material-ui/core/styles'
// import planoDeFundo from '../../static/planoDeFundo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    zIndex: 0,

    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      zIndex: 0
    }
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
  },
  title: {
    ...theme.typography.h5,
    paddingBottom: 16,
    paddingTop: 16,
    color: 'rgba(0, 0, 0, 0.54)',

    [theme.breakpoints.up('sm')]: {
      paddingBottom: 8,
      ...theme.typography.h5
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 36
    }
  },
  titleConecta: {
    marginBottom: 40,
    color: '#624C92',
    fontSize: 36,
    fontWeight: 550,
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
      marginBottom: 40,
      fontWeight: 550
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      marginBottom: 64,
      fontWeight: 550
    }
  },
  subTitle: {
    ...theme.typography.caption,
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 8,

    [theme.breakpoints.up('sm')]: {
      top: '-17px',
      position: 'relative'
    },
    [theme.breakpoints.up('md')]: {
      ...theme.typography.caption,
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: 12,
      position: 'relative',
      right: '-10px',
      top: 0
    }
  },
  body: {
    ...theme.typography.body2,
    width: 276,
    height: 20,
    marginBottom: 12,
    color: '#4E3C74',

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.h6,

      width: '100%',
      marginBottom: 16,
      color: '#4E3C74',
      position: 'relative',
      textAlign: 'initial',
      top: '-24px'
    },

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h6,
      width: '884px',
      marginBottom: 16,
      color: '#4E3C74',
      position: 'relative',
      right: '-10px',
      top: 0
    }
  },
  divider: {
    marginBottom: 16,
    width: '928px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 16,
      width: '928px'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
      width: '928px'
    }
  },
  cardcontainer: {
    width: '100%',
    overflow: 'scroll',

    [theme.breakpoints.up('sm')]: {
      overflow: 'scroll'
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'unset'
    }
  },

  cards: {
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap',
    width: 928,
    paddingBottom: 20,

    [theme.breakpoints.up('sm')]: {
      width: 928
    }
  },
  lastCard: {
    marginRight: 0
  }
}))

const useCardStyles = makeStyles((theme) => ({
  root: {
    width: 156,
    height: 395,
    padding: theme.spacing(2, 2, 1.5),

    [theme.breakpoints.up('sm')]: {
      width: 156,
      height: 395,
      padding: theme.spacing(2, 2, 1.5)
    }
  }
}))

const useMediaStyles = makeStyles((theme) => ({
  root: {
    height: 167,

    [theme.breakpoints.up('sm')]: {
      height: 167
    }
  }
}))

const useContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.5)
    }
  }
}))

export { useStyles, useCardStyles, useContentStyles, useMediaStyles }
