import {
  BOOK_LOADING,
  BOOK_SUCCESS,
  BOOK_ERROR
} from '../../../utils/actionTypes'
import { BookService } from '../../../services'

export const getBookAction = async (dispatch, payload = {}) => {
  let hasError = false

  dispatch({
    type: BOOK_LOADING
  })

  try {
    const bookResponse = await BookService.getBook(payload.caeId)

    if (!bookResponse.ok) {
      const errors = await bookResponse.json()
      hasError = true

      dispatch({
        type: BOOK_ERROR,
        payload: errors
      })
    }

    const bookData = await bookResponse.json()

    dispatch({
      type: BOOK_SUCCESS,
      payload: bookData[0]
    })
  } catch (error) {
    hasError = true

    dispatch({
      type: BOOK_ERROR,
      payload: error
    })
  }

  return {
    ok: !hasError
  }
}
