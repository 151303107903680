import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'

import useStyles from './styles'

export default function Loading({ isCenter }) {
  const classes = useStyles()

  return (
    <div className={classes.root} isCenterPosition={isCenter}>
      <CircularProgress />
      <span>Carregando...</span>
    </div>
  )
}

Loading.propTypes = {
  isCenter: PropTypes.bool
}

Loading.defaultProps = {
  isCenter: false
}
