/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'html-react-parser'
import Button from '@material-ui/core/Button'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Radio,
  Tooltip
} from '@material-ui/core'
import {
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
  CheckCircleOutline,
  CheckCircle,
  Cancel,
  Spellcheck
} from '@material-ui/icons'
import { Dialog } from '@grupogen/saraiva-ui'
import {
  ContentsService,
  TrackingsService,
  Users
} from '../../../../../services'
import { useStyles } from './styles'

export default function Questao({
  mockId,
  onNextMock,
  disabledNextMock,
  showResults,
  setShowResults,
  showNextMock,
  setShowNextMock,
  // answeredQuestions,
  setAnsweredQuestions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  selectedAlternative,
  setSelectedAlternative,
  userAnswers,
  setUserAnswers
}) {
  const classes = useStyles()
  const [questions, setQuestions] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [userAnswerPayload, setUserAnswerPayload] = useState([])
  const showResultsView = showResults && showNextMock
  const alternativeIdKey = 'alternative_id'
  const questionIdKey = 'database_question_id'

  const currentQuestion = questions[currentQuestionIndex]

  useEffect(() => {
    async function fetchMockQuestions() {
      try {
        const response = await ContentsService.getPracticeQuestions(mockId)
        const result = await response.json()
        const filteredQuestions = result.practice_questions.filter(
          (question) => question.status !== 'not_found'
        )
        setQuestions(filteredQuestions)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
    setUserAnswerPayload([])
    fetchMockQuestions()
  }, [mockId])

  useEffect(() => {
    async function getMockAnswers() {
      const userId = Users.getUserId()
      try {
        const response = await ContentsService.getPracticeTestAttempt(
          userId,
          mockId
        )
        if (!response.ok) {
          return
        }

        const result = await response.json()
        if (result.practice_test_id === mockId) {
          const answers = {}

          result.logs.forEach((log, index) => {
            answers[index] = log.alternative_id
          })
          setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            ...answers
          }))
          setShowResults(true)
          setShowNextMock(true)
        }
      } catch (err) {
        console.error(err)
      }
    }
    getMockAnswers()
  }, [mockId])

  async function sendMockResults() {
    const userId = Users.getUserId()
    try {
      const payload = {
        user_id: userId,
        practice_test_id: mockId,
        logs: userAnswerPayload
      }

      const response = await ContentsService.practiceTestAttempt(
        payload,
        userId,
        mockId
      )

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
  }

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1)
  }

  const handleActiveQuestion = (index) => {
    setCurrentQuestionIndex(index)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
    TrackingsService.sendTrack(
      'refez_simulados',
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleUndoMock = () => {
    setUserAnswers({})
    setCurrentQuestionIndex(0)
    setOpenDialog(false)
    setShowNextMock(false)
    setShowResults(false)
    TrackingsService.sendTrack(
      'abandonou_simulado',
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  const updateUserAnswerPayload = (questionIdPayload, alternativeIdPayload) => {
    setUserAnswerPayload((prevPayload) => {
      const updatedPayload = prevPayload.filter(
        (entry) => entry[questionIdKey] !== questionIdPayload
      )
      updatedPayload.push({
        [alternativeIdKey]: alternativeIdPayload,
        [questionIdKey]: questionIdPayload
      })
      return updatedPayload
    })
  }

  const handleSelectAlternative = (selectedValue) => {
    if (!showResults) {
      setAnsweredQuestions((prevAnswered) => ({
        ...prevAnswered,
        [mockId]: {
          ...prevAnswered[mockId],
          [currentQuestionIndex]: true
        }
      }))

      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestionIndex]: selectedValue
      }))

      updateUserAnswerPayload(currentQuestion.id, selectedValue)
    }
  }

  const QuestionAnswered = (questionIndex) => {
    // eslint-disable-next-line no-prototype-builtins
    return userAnswers.hasOwnProperty(questionIndex)
  }

  const allQuestionsAnswered = () => {
    const questionsIndexes = Object.keys(userAnswers)
    return questionsIndexes.length === questions.length
  }

  const compareAnswers = () => {
    const correctAlternatives = questions.map(
      (correct) => correct.correct_alternative_id
    )
    const result = questions.map((question, index) => {
      const userAnswerId = userAnswers[index]
      const correctAlternativesId = correctAlternatives[index]
      const isCorrect = userAnswerId === correctAlternativesId
      return {
        index: index + 1,
        isCorrect
      }
    })
    return result
  }

  const questionClassName = (index) => {
    if (index === currentQuestionIndex && !showResults) {
      return classes.questionItemActive
    }
    if (showResultsView && QuestionAnswered(index)) {
      const questionResult = compareAnswers().find(
        (result) => result.index === index + 1
      )
      return questionResult && questionResult.isCorrect
        ? classes.questionItemCorrect
        : classes.questionItemIncorrect
    }
    if (QuestionAnswered(index) && index !== currentQuestionIndex) {
      return classes.questionItemAnswered
    }
    return classes.questionItem
  }

  const alternativeClassName = (alternativeId) => {
    if (!showResultsView) {
      return classes.alternatives
    }
    const questionResult = compareAnswers().find(
      (result) => result.index === currentQuestionIndex + 1
    )

    if (selectedAlternative === alternativeId) {
      return questionResult && questionResult.isCorrect
        ? classes.alternativeCorrect
        : classes.alternativeIncorrect
    }
    return null
  }

  function indexToAlternative() {
    let tempIndex = 0
    let correctLetter = ''
    const correctAlternative = currentQuestion.correct_alternative_id
    // eslint-disable-next-line array-callback-return
    currentQuestion.question_alternatives.map((alternatives, index) => {
      if (alternatives.id === correctAlternative) {
        tempIndex = index
        correctLetter = String.fromCharCode(65 + tempIndex)
      }
    })
    return correctLetter
  }

  const showJustification = (alternativeId, justification) => {
    if (!showResultsView) {
      return null
    }
    const questionResult = compareAnswers().find(
      (result) => result.index === currentQuestionIndex + 1
    )

    if (selectedAlternative === alternativeId) {
      return questionResult && questionResult.isCorrect ? (
        <>
          <div className={classes.justificationHeader}>
            <CheckCircle
              style={{ color: '#1F8563' }}
              className={classes.justificationIcon}
            />
            <Typography variant="body2">Resposta correta!</Typography>
          </div>
          <div>
            <Typography varaiant="body2" className={classes.justification}>
              {justification}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div className={classes.justificationHeader}>
            <Cancel
              style={{ color: '#A42C31' }}
              className={classes.justificationIcon}
            />
            <Typography variant="body2">
              Resposta incorreta! A alternativa correta é a letra{' '}
              {indexToAlternative()}.
            </Typography>
          </div>
          <div>
            <Typography varaiant="body2" className={classes.justification}>
              {justification}
            </Typography>
          </div>
        </>
      )
    }
    return null
  }

  const handleResultCheck = () => {
    setShowResults(true)
    setShowNextMock(true)
    sendMockResults()
    TrackingsService.sendTrack(
      'realizou_simulados',
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  const calculatePercentage = () => {
    const correctCount = compareAnswers().filter((result) => result.isCorrect)
    const totalQuestions = questions.length

    if (totalQuestions === 0) {
      return 0
    }
    return Math.round((correctCount.length / totalQuestions) * 100)
  }

  const correctAnswersCount = compareAnswers().filter(
    (result) => result.isCorrect
  ).length

  const nextMockClick = () => {
    onNextMock()
    setCurrentQuestionIndex(0)
    TrackingsService.sendTrack(
      'navegou_simulados',
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  useEffect(() => {
    setSelectedAlternative(userAnswers[currentQuestionIndex] || null)
  }, [currentQuestionIndex, setSelectedAlternative, userAnswers])

  if (!questions.length || currentQuestionIndex >= questions.length) {
    return null
  }

  return (
    <>
      {showResults && (
        <div className={classes.correctPercentage}>
          <Spellcheck className={classes.percentageIcon} />
          <Typography variant="body1">
            Simulado finalizado! Você acertou {calculatePercentage()}% das
            questões ({correctAnswersCount} de {questions.length}).
          </Typography>
        </div>
      )}
      <Dialog
        open={openDialog}
        title="Refazer o simulado"
        secondaryButton="Cancelar"
        primaryButton="Refazer"
        label="Deseja refazer todas as questões?"
        handleClose={handleCloseDialog}
        handleConfirm={handleUndoMock}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.dialogTitle}>
            Deseja refazer todas as questões?
          </Typography>
          <Typography>
            Ao refazer o simulado suas atuais respostas serão perdidas.
          </Typography>
        </div>
      </Dialog>
      <div className={classes.container}>
        <div className={classes.feedbackCard}>
          <div className={classes.questionHeader}>
            <Typography className={classes.questionsTitle}>
              Todas as Questões
            </Typography>
          </div>
          <div className={classes.questionsContainer}>
            {questions.map((question, index) => {
              return (
                <span
                  key={question.id}
                  className={questionClassName(index)}
                  onClick={() => handleActiveQuestion(index)}
                >
                  {index + 1}
                </span>
              )
            })}
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div className={classes.card}>
            <div className={classes.yearSource}>
              <div className={classes.questionBadge}>
                <Typography variant="subtitle2">
                  Questão {currentQuestionIndex + 1}
                </Typography>
              </div>
              <Typography
                color="textSecondary"
                variant="caption"
                style={{ marginTop: 8 }}
              >
                {currentQuestion.question_source &&
                  `Fonte: ${currentQuestion.question_source} | `}
                Ano: {currentQuestion.year}
              </Typography>
            </div>
            <Typography variant="body1">
              {ReactHtmlParser(currentQuestion.content)}
            </Typography>
            <FormControl style={{ width: '100%' }}>
              <RadioGroup
                aria-labelledby="alternativas-da-questão"
                className={classes.questionRadioGroup}
                value={selectedAlternative}
              >
                {currentQuestion.question_alternatives.map((alternatives) => (
                  <div className={alternativeClassName(alternatives.id)}>
                    <div
                      key={alternatives.id}
                      onClick={() => handleSelectAlternative(alternatives.id)}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checked={selectedAlternative === alternatives.id}
                          />
                        }
                        label={alternatives.content}
                      />
                      {showResults &&
                        selectedAlternative === alternatives.id &&
                        showJustification(
                          alternatives.id,
                          alternatives.justification
                        )}
                    </div>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          {!showNextMock && (
            <div className={classes.prevNextButtons}>
              <Button
                onClick={handlePreviousQuestion}
                color="secondary"
                disabled={currentQuestionIndex === 0}
                startIcon={<ArrowBackIos />}
              >
                Questão Anterior
              </Button>
              {currentQuestionIndex < questions?.length - 1 ? (
                <Button
                  onClick={handleNextQuestion}
                  color="secondary"
                  disabled={currentQuestionIndex > questions?.length - 2}
                  endIcon={<ArrowForwardIos />}
                >
                  Próxima Questão
                </Button>
              ) : (
                <Tooltip
                  title="Para ver o resultado você deve responder todas as questões"
                  disableHoverListener={allQuestionsAnswered()}
                >
                  <span>
                    <Button
                      onClick={handleResultCheck}
                      disabled={!allQuestionsAnswered()}
                      variant="contained"
                      color="secondary"
                      startIcon={<CheckCircleOutline />}
                    >
                      Ver Resultados
                    </Button>
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          {showNextMock && (
            <div className={classes.nextMockButton}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleOpenDialog}
              >
                Refazer Simulado
              </Button>
              <Button
                style={{ marginLeft: 16 }}
                onClick={nextMockClick}
                variant="contained"
                color="secondary"
                endIcon={<ArrowForward />}
                disabled={disabledNextMock}
              >
                Próximo Simulado
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

Questao.propTypes = {
  mockId: PropTypes.number.isRequired,
  onNextMock: PropTypes.func.isRequired,
  disabledNextMock: PropTypes.bool.isRequired,
  showResults: PropTypes.bool.isRequired,
  setShowResults: PropTypes.func.isRequired,
  showNextMock: PropTypes.bool.isRequired,
  setShowNextMock: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // answeredQuestions: PropTypes.object.isRequired,
  setAnsweredQuestions: PropTypes.func.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired,
  setCurrentQuestionIndex: PropTypes.func.isRequired,
  selectedAlternative: PropTypes.number.isRequired,
  setSelectedAlternative: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userAnswers: PropTypes.object.isRequired,
  setUserAnswers: PropTypes.func.isRequired
}
