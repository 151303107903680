/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import {
  LIBRARY_TREEVIEW_LOADING,
  LIBRARY_TREEVIEW_ARTICLE_SUCCESS,
  LIBRARY_TREEVIEW_ERROR
} from '../../../../utils/actionTypes'
import { LibraryTreeviewService } from '../../../../services'

export const getLibraryTreeviewArticlesAction = async (dispatch, payload) => {
  dispatch({
    type: LIBRARY_TREEVIEW_LOADING
  })

  const treeviewResponse = await LibraryTreeviewService.get_articles(payload)
  const treeviewData = await treeviewResponse.json()

  if (treeviewResponse.ok) {
    dispatch({
      type: LIBRARY_TREEVIEW_ARTICLE_SUCCESS,
      payload: {
        data: treeviewData,
        key: payload
      }
    })
  } else {
    dispatch({
      type: LIBRARY_TREEVIEW_ERROR,
      payload: treeviewData.error
    })
  }

  return {
    ok: treeviewResponse.ok
  }
}
