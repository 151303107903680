import httpClient from '../gateway/generalHttpRequest'

export const EpubService = {
  async getEpubs() {
    const path = `contents/api/v1/public/epubs`
    let headers = { 'application-token': process.env.REACT_APP_TOKEN }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  }
}
