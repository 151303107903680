import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AccordionDetails, Typography, Divider } from '@material-ui/core'
import {
  InsertDriveFileRounded,
  VideocamRounded,
  Mic,
  FormatListNumberedRounded
} from '@material-ui/icons'
import { getSessionToken, parseJwt } from '../../../../auth'
import useStyles from './styles'
import {
  MaterialsService,
  TrackingsService,
  EpubService,
  Users
} from '../../../../services'

const AccordionItem = ({
  material,
  index,
  length,
  categories,
  hasChildren,
  setMaterialUrl,
  setCategoriesNames,
  setMaterialType,
  book
}) => {
  const classes = useStyles()
  const {
    tipo: type,
    nome: name,
    titulo: title,
    formato: format,
    url,
    id
  } = material

  const [standardType, setStandardType] = useState()

  const checkPlaylistType = useCallback(() => {
    return (
      material.materiais &&
      material.materiais.length &&
      material.materiais[0].tipo === 'video'
    )
  }, [material])

  const saveCategories = useCallback(
    (materialName, materialType) => {
      if (standardType === 'playlist' && !checkPlaylistType())
        setMaterialType('audio')
      if (
        standardType === 'video' ||
        (standardType === 'playlist' && checkPlaylistType())
      )
        setMaterialType('video')
      if (standardType === 'banco-de-questoes')
        setMaterialType('banco-de-questoes')

      const categoriesTemp = {
        titulo: categories.titulo ? categories.titulo : categories
      }

      if (categories.materiais && categories.materiais[0].titulo) {
        categoriesTemp.materiais = [
          {
            titulo: categories.materiais
              ? categories.materiais[0].titulo
              : categories,
            materiais: [{ nome: materialName, tipo: materialType }]
          }
        ]
      } else {
        categoriesTemp.materiais = [{ nome: materialName, tipo: materialType }]
      }

      return setCategoriesNames(categoriesTemp)
    },
    [
      categories,
      checkPlaylistType,
      setCategoriesNames,
      setMaterialType,
      standardType
    ]
  )

  const defineMaterialIcon = () => {
    if (
      standardType === 'video' ||
      (standardType === 'playlist' && checkPlaylistType())
    )
      return <VideocamRounded className={classes.icon} />
    if (
      standardType === 'pdf' ||
      standardType === 'documento' ||
      standardType === 'arquivo' ||
      standardType === 'epub-google'
    )
      return <InsertDriveFileRounded className={classes.icon} />
    if (standardType === 'playlist' && !checkPlaylistType())
      return <Mic className={classes.icon} />
    if (standardType === 'banco-de-questoes')
      return <FormatListNumberedRounded className={classes.icon} />
    return null
  }

  const defineMaterialName = () => {
    if (material.materiais) return <span>{title}</span>
    return <span>{name}</span>
  }

  const getUserToken = () => {
    const token = getSessionToken()
    const { user } = parseJwt(token)
    const utoken = window.btoa(user.id)

    return utoken
  }

  const openMaterial = useCallback(
    async (hash) => {
      let src = ''
      const utoken = getUserToken()

      const urlForEachFileType = {
        documento: `${url.replace(':HASH', hash)}?download=true`,
        video: `${url}&idApplication=${process.env.REACT_APP_SARAIVA_DIGITAL_API_TOKEN}&token=${utoken}&custom=aHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL2dydXBvZ2VuLWNvbmVjdGEtc3J2L2JhYXMvcGxheWVyL2NvbmZpZy5qc29ucA`,
        playlist: `${url}&idApplication=${process.env.REACT_APP_SARAIVA_DIGITAL_API_TOKEN}&token=${utoken}&custom=aHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL2dydXBvZ2VuLWNvbmVjdGEtc3J2L2JhYXMvcGxheWVyL2NvbmZpZy5qc29ucA`,
        audio: `${url}&idApplication=${process.env.REACT_APP_SARAIVA_DIGITAL_API_TOKEN}&token=${utoken}&custom=aHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL2dydXBvZ2VuLWNvbmVjdGEtc3J2L2JhYXMvcGxheWVyL2NvbmZpZy5qc29ucA`,
        compacto: url.replace(':HASH', hash),
        arquivo: url.replace(':HASH', hash)
      }

      src = urlForEachFileType[standardType] || null

      if (standardType === 'banco-de-questoes') {
        const subCategoryName = material.materiais ? title : name
        saveCategories(subCategoryName, type)
        setMaterialUrl(url)
        return
      }

      if (['playlist', 'video', 'audio'].includes(standardType)) {
        const subCategoryName = material.materiais ? title : name
        saveCategories(subCategoryName, type)
        setMaterialUrl(src)
        return
      }

      if (['compacto', 'arquivo', 'documento'].includes(standardType)) {
        window.location.assign(src)
        return
      }

      if (standardType === 'epub-google') {
        const response = await EpubService.getEpubs()
        if (response.ok) {
          const data = await response.json()
          const epub = data.filter(
            (epubData) => epubData.name === 'material-complementar-lei.epub'
          )
          if (epub.length) {
            window.open(epub[0].url, '_blank')
          }
          return
        }
        return
      }

      window.open(src, '_blank')
    },
    [
      material,
      name,
      saveCategories,
      setMaterialUrl,
      standardType,
      title,
      type,
      url
    ]
  )

  const handleOpenMaterial = useCallback(() => {
    TrackingsService.sendTrack('conteudo', book, {
      material_id: id,
      format: type,
      formatType: format || 'Não encontrado',
      user_name: Users.getFullName(),
      user_email: Users.getEmail()
    })
    const hash = MaterialsService.getURLHash()
    openMaterial(hash)
    return null
  }, [book, id, type, format, openMaterial])

  useEffect(() => {
    const standardizeType = () => {
      if (format === 'zip') return setStandardType('compacto')
      if (type === 'documento' && format !== 'pdf')
        return setStandardType('arquivo')

      return setStandardType(type)
    }

    standardizeType()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (material.isOpen && standardType) {
      material.isOpen = false
      localStorage.removeItem('materialId')
      handleOpenMaterial()
    }
  }, [standardType, material, handleOpenMaterial])

  return (
    <>
      <AccordionDetails
        className={classes.contentAccordion}
        style={{ marginBottom: !hasChildren ? 15 : 'auto' }}
        data-testid="bookactived-accordion-details"
      >
        <Typography className={classes.contentText}>
          <button
            type="button"
            className={classes.contentButton}
            onClick={() => handleOpenMaterial()}
          >
            {defineMaterialIcon()}
            {defineMaterialName()}
          </button>
        </Typography>
      </AccordionDetails>
      {index < length && hasChildren ? (
        <Divider className={classes.divider} />
      ) : null}
    </>
  )
}

AccordionItem.propTypes = {
  material: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  categories: PropTypes.oneOfType([PropTypes.object]),
  hasChildren: PropTypes.bool,
  setMaterialUrl: PropTypes.func.isRequired,
  setCategoriesNames: PropTypes.func.isRequired,
  setMaterialType: PropTypes.func.isRequired,
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}

AccordionItem.defaultProps = {
  index: 2,
  length: 1,
  categories: {},
  hasChildren: false
}

export default AccordionItem
