import React from 'react'
import { Typography, Divider } from '@material-ui/core'

import { useWishlist, useError } from '../../hooks'

import { useStyles } from './styles'
import { Catalog, Loading, SEO } from '../../components'
import { EmptyWishList } from './EmptyWishList'

export default function Wishlist() {
  const {
    books,
    page,
    totalPages,
    totalItems,
    favorites,
    loadingBooks,
    favoriteLoading,
    libraryLoading,
    libraryBooks,
    errorBooks,
    handleClickPage
  } = useWishlist()

  const classes = useStyles()
  const { redirectOnError } = useError()

  redirectOnError(errorBooks)

  if (loadingBooks || favoriteLoading || libraryLoading) {
    return <Loading />
  }

  return (
    <>
      <SEO title="Saraiva Conecta - Favoritos" description="Lista de desejos" />

      <Typography
        variant="h6"
        className={classes.title}
        data-testid="wishlist-title"
      >
        Lista de Desejos
      </Typography>

      <Typography variant="body1" className={classes.subTitle}>
        Aqui você poderá acessar sua lista de desejos.
      </Typography>

      <Divider className={classes.divider} />

      {books && books.length ? (
        <div data-testid="wishlist-items">
          <Catalog
            listBooks={books}
            libraryBooks={libraryBooks}
            totalPages={totalPages}
            totalItems={totalItems}
            page={page}
            handleClickPage={handleClickPage}
            showPageItems={false}
            isLoading={loadingBooks}
            searchParams={null}
            setSearchParams={() => null}
            showFilters={false}
            favorites={favorites}
            isSearch={false}
            categoria={null}
            subcategoria={null}
            selectsList={[]}
            setSelectsList={() => null}
          />
        </div>
      ) : (
        <EmptyWishList />
      )}
    </>
  )
}
