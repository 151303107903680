import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Typography } from '@material-ui/core'
import { Check, Remove } from '@material-ui/icons'
import { useStyles } from './styles'

export default function BreadCrumb({ currentStep }) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.breadCrumb}>
        <Box className={classes.breadCrumbItem}>
          <Typography
            className={
              currentStep !== 'welcome'
                ? clsx(classes.breadCrumbNumber, classes.breadCrumbNumberActive)
                : classes.breadCrumbNumber
            }
          >
            {currentStep === 'profile' ? '1' : <Check />}
          </Typography>

          <Typography
            className={
              currentStep !== 'welcome'
                ? clsx(classes.breadCrumbText, classes.breadCrumbTextActive)
                : classes.breadCrumbText
            }
          >
            Perfil
          </Typography>
        </Box>

        <Remove className={classes.breadCrumbSeparator} />

        <Box className={classes.breadCrumbItem}>
          <Typography
            className={
              currentStep === 'interests'
                ? clsx(classes.breadCrumbNumber, classes.breadCrumbNumberActive)
                : classes.breadCrumbNumber
            }
          >
            2
          </Typography>

          <Typography
            className={
              currentStep === 'interests'
                ? clsx(classes.breadCrumbText, classes.breadCrumbTextActive)
                : classes.breadCrumbText
            }
          >
            Interesses
          </Typography>
        </Box>
      </Box>
    </>
  )
}

BreadCrumb.propTypes = {
  currentStep: PropTypes.string.isRequired
}
