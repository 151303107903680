/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import {
  LIBRARY_TREEVIEW_LOADING,
  LIBRARY_TREEVIEW_CONTENT_SUCCESS,
  LIBRARY_TREEVIEW_ERROR
} from '../../../../utils/actionTypes'
import { LibraryTreeviewService } from '../../../../services'

const stripTags = (input, allowed) => {
  allowed = (
    `${allowed || ''}`.toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
  ).join('')
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
  return input.replace(commentsAndPhpTags, '').replace(tags, ($0, $1) => {
    return allowed.indexOf(`<${$1.toLowerCase()}>`) > -1 ? $0 : ''
  })
}

export const getLibraryTreeviewArticleContentAction = async (
  dispatch,
  payload
) => {
  dispatch({
    type: LIBRARY_TREEVIEW_LOADING
  })

  let contentFull = ''
  const articleResponse = await LibraryTreeviewService.get_article(
    payload.parent_id,
    payload.id
  )
  const article = await articleResponse.json()

  if (articleResponse.ok) {
    const notesCodes = []
    const objectCodes = []
    const images = [...payload.texto.matchAll('(<img.*?>)')]
    contentFull = payload.texto

    images.forEach((image) => {
      const titleMatch = image[0].match(/title="([^"]*)"/i)
      const code = titleMatch && titleMatch[1] ? titleMatch[1] : ''
      const hasNote = image[0].search('NOTA') >= 0

      if (hasNote) {
        notesCodes.push(code)
      } else {
        objectCodes.push(code)
      }
    })

    if (notesCodes.length) {
      const notesResponse = await LibraryTreeviewService.get_notas(
        notesCodes.join(',')
      )
      const notesData = await notesResponse.json()

      images.forEach((image) => {
        let text = ''
        const titleMatch = image[0].match(/title="([^"]*)"/i)
        const code = titleMatch && titleMatch[1] ? titleMatch[1] : ''
        const note = notesData.filter(
          (noteFilter) => noteFilter.id === Number(code)
        )

        if (note.length) {
          let bullet = '<span class="BULLET">&#8226&#8226</span>'
          if (note[0].category_type === 0) {
            bullet = '<span class="BULLET">&#8226</span>'
          }
          text += `${bullet}  ${stripTags(note[0].texto, '<em><br><br/>')}`
          text = `<p class="NOTA_SARAIVA_ACESSORIA">${text}</p>`
          contentFull = contentFull.replace(image[0], text)
        }
      })
    }

    contentFull = contentFull.replaceAll(' id="paragrafo"', '')
    contentFull = contentFull.replaceAll(' id="paragrafo1"', '')
    contentFull = contentFull.replaceAll(' id="tabela"', '')
    contentFull = contentFull.replaceAll(' id="titulo"', '')
    contentFull = contentFull.replaceAll(' id="boxtext"', '')
    contentFull = contentFull.replaceAll(' id="nota_editorial"', '')
    contentFull = contentFull.replaceAll('<div>', '')
    contentFull = contentFull.replaceAll('</div>', '')
    contentFull = contentFull.replaceAll('<div', '<p')
    contentFull = contentFull.replaceAll('</div>', '</p>')
    contentFull = contentFull.replaceAll(
      '../../../../media_legis/js/tiny_mce/img/',
      '/images/legislacao/'
    )

    const treeviewData = article.norms

    let normsContent = treeviewData.reduce((acc, norm) => {
      if (norm.descricao) {
        norm.descricao = `<div id="h6">${norm.descricao}</div>`
      }

      return (acc += `${norm.titulo}<div></div>${norm.descricao}`)
    }, '')

    normsContent = normsContent.replaceAll(
      '../../../../media_legis/js/tiny_mce/img/',
      '../../../../images/legislacao/'
    )

    contentFull = `${normsContent}<div></div>${contentFull}`

    dispatch({
      type: LIBRARY_TREEVIEW_CONTENT_SUCCESS,
      payload: contentFull
    })
  } else {
    dispatch({
      type: LIBRARY_TREEVIEW_ERROR,
      payload: articleResponse.error
    })
  }

  return {
    ok: articleResponse.ok,
    data: contentFull
  }
}
