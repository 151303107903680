import {
  BOOKS_LOADING,
  BOOKS_SUCCESS,
  BOOKS_ERROR
} from '../../../utils/actionTypes'

const booksReducer = (state, { payload, type }) => {
  switch (type) {
    case BOOKS_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      }

    case BOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case BOOKS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default booksReducer
