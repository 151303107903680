import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import useStyles from './styles'

const UploadError = ({ resetUploadStatus }) => {
  const classes = useStyles()

  return (
    <div className={classes.errorContent}>
      <Typography variant="h6" className={classes.titleText}>
        Erro ao fazer o upload
      </Typography>
      <div className={classes.iconContent}>
        <Error />
      </div>
      <Typography
        variant="caption"
        className={classes.linkText}
        onClick={resetUploadStatus}
      >
        TENTAR NOVAMENTE
      </Typography>
    </div>
  )
}

UploadError.propTypes = {
  resetUploadStatus: PropTypes.func.isRequired
}

export default UploadError
