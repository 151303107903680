import combineReducers from 'react-combine-reducers'
import bookReducer from './book/bookReducer'
import booksReducer from './book/booksReducer'
import libraryActualizeYourCodeReducer from './library/libraryActualizeYourCodeReducer'
import libraryBooksReducer from './library/libraryBooksReducer'
import libraryBooksSimpleReducer from './library/libraryBooksSimpleReducer'
import libraryMaterialReducer from './library/libraryMaterialReducer'
import uploadTeacherFileReducer from './library/uploadTeacherFileReducer'
import libraryTreeviewReducer from './library/libraryTreeviewReducer'
import favoriteBooksReducer from './favorites/favoriteBooksReducer'
import initialStates from '../initialStates'
import recommendedBooksReducer from './recommended/recommendedBooksReducer'
import categoriesReducer from './categories/categoriesReducer'
import userProfileTypeReducer from './user/userProfileTypeReducer'
import userInterestsReducer from './user/userInterestsReducer'
import userInterestsNotificationReducer from './user/userInterestsNotificationReducer'
import userInterestsExistReducer from './user/userInterestsExistReducer'
import notificationsReducer from './notifications/notificationsReducer'
import lastBooksReducer from './lastbooks/lastBooksReducer'

const [combinedState, combinedInitialStates] = combineReducers({
  book: [bookReducer, initialStates.book],
  books: [booksReducer, initialStates.books],
  libraryActualizeYourCode: [
    libraryActualizeYourCodeReducer,
    initialStates.libraryActualizeYourCode
  ],
  libraryBooks: [libraryBooksReducer, initialStates.libraryBooks],
  libraryBooksSimple: [
    libraryBooksSimpleReducer,
    initialStates.libraryBooksSimple
  ],
  libraryMaterial: [libraryMaterialReducer, initialStates.libraryMaterial],
  favoriteBooks: [favoriteBooksReducer, initialStates.favoriteBooks],
  recommendedBooks: [recommendedBooksReducer, initialStates.recommendedBooks],
  categories: [categoriesReducer, initialStates.categories],
  teacherUpload: [uploadTeacherFileReducer, initialStates.teacherUpload],
  libraryTreeview: [libraryTreeviewReducer, initialStates.libraryTreeview],
  userProfileType: [userProfileTypeReducer, initialStates.userProfileType],
  userInterests: [userInterestsReducer, initialStates.userInterests],
  userInterestsNotification: [
    userInterestsNotificationReducer,
    initialStates.userInterestsNotification
  ],
  userInterestsExist: [
    userInterestsExistReducer,
    initialStates.userInterestsExist
  ],
  notifications: [notificationsReducer, initialStates.notifications],
  lastBooks: [lastBooksReducer, initialStates.lastBooks]
})

export { combinedState, combinedInitialStates }
