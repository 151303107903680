import { useEffect } from 'react'
import { useContextSelector } from 'use-context-selector'

import { GlobalContext } from '../contexts/GlobalContext'
import { getLastBooksAction } from '../contexts/actions'

export function useLastBooks() {
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const { data: lastBooks, loading: loadingLastBooks } = globalState.lastBooks

  useEffect(() => {
    if (lastBooks.length === 0) {
      getLastBooksAction(globalDispatch)
    }
  }, [globalDispatch])

  return {
    lastBooks,
    loadingLastBooks
  }
}
