import React from 'react'
import { Box, Typography } from '@material-ui/core'
import SaraivaConecta from '../../../../static/saraivaconecta.png'
import useStyles from './styles'

export default function FinalStep() {
  const classes = useStyles()

  return (
    <Box className={classes.stepWrapper}>
      <Box className={classes.imageWrapper}>
        <img
          src={SaraivaConecta}
          alt="Logo do Saraiva Conecta"
          className={classes.image}
        />
      </Box>

      <Box className={classes.contentWrapper}>
        <Typography className={classes.introText}>
          <span className={classes.textHighlight}>
            Agora é só aproveitar sua jornada de estudos e atualizações!
          </span>
        </Typography>

        <Typography className={classes.middleText}>
          Esperamos que este tutorial tenha ajudado a ativar seus materiais.
        </Typography>

        <Typography className={classes.endText}>
          Caso não tenha conseguido ativar seu material ou tenha alguma dúvida
          sobre o processo, você pode entrar em contato com nosso{' '}
          <strong>Suporte</strong> no botão encontrado a direita da sua tela.
        </Typography>
      </Box>
    </Box>
  )
}
