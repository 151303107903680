import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import useStyles from './styles'
import { GeneralContext } from '../../contexts/GeneralContext'

export default function HelpActivationBook({ column }) {
  const classes = useStyles({ column })

  const { setDialogHelpActivationBook } = useContext(GeneralContext)

  const handleOpenHelper = () => {
    setDialogHelpActivationBook(true)
  }

  return (
    <>
      <Button className={classes.menuHelp} onClick={handleOpenHelper}>
        <HelpRoundedIcon color="action" className={classes.helpIcon} />

        <Typography variant="body1" className={classes.menuHelpText}>
          Como ativar meus materiais
        </Typography>
      </Button>
    </>
  )
}

HelpActivationBook.propTypes = {
  column: PropTypes.bool
}

HelpActivationBook.defaultProps = {
  column: false
}
