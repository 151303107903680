import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  imageActivateBook: {
    maxHeight: 250,

    [theme.breakpoints.down('sm')]: {
      maxHeight: 180
    },

    [theme.breakpoints.down('xs')]: {
      maxHeight: 136,
      maxWidth: 121,
      margin: '0 auto'
    }
  },
  imageDiv: {
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  containerActivateBook: {
    display: 'flex',
    flexDirection: 'row',
    paddingInline: 0,
    margin: 0,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingInline: 0,
      margin: 0
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      flexWrap: 'wrap'
    }
  },
  dialogAlert: {
    marginTop: 20,
    marginBottom: 20
  },
  paragraph: {
    marginTop: 0,
    marginBottom: 3,
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
      fontSize: 14
    }
  },
  card: {
    marginTop: 15,
    marginBottom: 15
  },
  infoIcon: {
    marginLeft: 15,
    position: 'absolute',
    top: 0,
    color: '#624C92'
  },
  cardFront: {
    display: 'flex',
    position: 'relative',
    textAlign: 'center'
  },
  hidden: {
    display: 'none'
  },
  dialogContent: {
    padding: theme.spacing(0, 0, 0, 7.75),
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0, 0, 0),
      overflow: 'initial'
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0)
    }
  },
  instructionsBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    },

    '& .MuiTypography-root': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px'
      }
    }
  },
  instructionsIntro: {
    [theme.breakpoints.down('xs')]: {
      order: 0
    }
  },
  instructionsComplement: {
    [theme.breakpoints.down('xs')]: {
      order: 1
    }
  },
  instructionsList: {
    [theme.breakpoints.down('xs')]: {
      order: 2,
      margin: theme.spacing(1, 0, 3),
      paddingLeft: theme.spacing(3)
    }
  },
  instructionsMiddle: {
    [theme.breakpoints.down('xs')]: {
      order: 5
    }
  },
  instructionsEnd: {
    [theme.breakpoints.down('xs')]: {
      order: 3
    }
  },
  textInput: {
    marginTop: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      marginBottom: theme.spacing(3),
      order: 4
    }
  },
  helpButton: {
    padding: 0,
    textTransform: 'none',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 400
  }
}))

export default useStyles
