async function getUserIp() {
  const userIp = localStorage.getItem('userIp')

  if (userIp) {
    return userIp
  }

  return fetch('https://api.ipify.org/?format=json')
    .then((res) => res.json())
    .then((data) => {
      localStorage.setItem('userIp', data.ip)
      return data.ip
    })
}

export { getUserIp }
