import {
  LIBRARY_BOOKS_SIMPLE_LOADING,
  LIBRARY_BOOKS_SIMPLE_SUCCESS,
  LIBRARY_BOOKS_SIMPLE_ERROR
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const libraryBooksSimpleReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    LIBRARY_BOOKS_SIMPLE_LOADING,
    LIBRARY_BOOKS_SIMPLE_SUCCESS,
    LIBRARY_BOOKS_SIMPLE_ERROR
  ])
}

export default libraryBooksSimpleReducer
