import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from './styles'
import useMaterialStyles from '../styles'
import { MaterialsService } from '../../../../../../services'
import { Loading } from '../../../../../../components'
import QuestionList from '../QuestionList'

const Question = ({ step, currentQuestion, handleNextQuestion, pathname }) => {
  const classes = useStyles()
  const materialClasses = useMaterialStyles()
  const [selectedAnswer, setSelectedAnswer] = useState()
  const [answerSent, setAnswerSent] = useState()
  const [loading, setLoading] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState()

  const handleSendAnswer = async () => {
    setLoading(true)
    const data = await MaterialsService.sentQuestions(
      currentQuestion.id,
      selectedAnswer.id,
      pathname
    )

    const question = await data.json()
    const [correctAwnser] = question.data.current_question.alternatives.filter(
      (alternative) => alternative.correct === true
    )
    setCorrectAnswer(correctAwnser)
    setAnswerSent(true)
    setLoading(false)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={materialClasses.mainIframe}>
      <h1 className={classes.tilte}>{step.name}</h1>

      <div>
        <span>
          ( {currentQuestion.bibliography} / {currentQuestion.year} )
          <span
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: currentQuestion.contents }}
          />
        </span>
      </div>

      <QuestionList
        selectedAnswer={selectedAnswer}
        correctAnswer={correctAnswer}
        currentQuestion={currentQuestion}
        answerSent={answerSent}
        setSelectedAnswer={setSelectedAnswer}
      />

      <div className={`${classes.buttonsGrids} ${answerSent && 'center'}`}>
        {answerSent ? (
          <button
            type="button"
            className={materialClasses.stepButton}
            onClick={handleNextQuestion}
          >
            Continuar
          </button>
        ) : (
          <button
            type="button"
            className={materialClasses.stepButton}
            disabled={!selectedAnswer}
            onClick={handleSendAnswer}
            data-testid="bookactived-question-answer"
          >
            Responder
          </button>
        )}
      </div>
    </div>
  )
}

Question.propTypes = {
  currentQuestion: PropTypes.oneOfType([PropTypes.object]),
  step: PropTypes.oneOfType([PropTypes.object]),
  handleNextQuestion: PropTypes.func,
  pathname: PropTypes.string.isRequired
}

Question.defaultProps = {
  currentQuestion: {},
  step: {},
  handleNextQuestion: () => null
}

export default Question
