import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

import { Users } from './users'

export const LibraryService = {
  async getLibrary(updated) {
    const path = `/accounts/api/v1/users/${Users.getUserId()}/library/${
      updated !== undefined ? updated : '0'
    }`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },
  async getLibraryBook(updated) {
    const path = `/accounts/api/v1/users/${Users.getUserId()}/library_ebooks/${
      updated !== undefined ? updated : '0'
    }`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },
  async loadBook(payload) {
    const path = `contents/api/v1/books_info/${payload}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  }
}
