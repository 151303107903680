import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textSubHeader: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: 'normal'
  },
  drawerList: {
    marginTop: theme.spacing(8),
    width: '304px',
    padding: '12px'
  },
  menuItem: {
    color: theme.palette.text.secondary
  },
  menuItemSelected: {
    color: theme.palette.primary.main
  },
  divider: {
    marginTop: '8px',
    marginBottom: '16px'
  },
  activeBookWrapper: {
    padding: theme.spacing(5.5, 1.5, 0)
  },
  helpActivationBook: {
    marginTop: theme.spacing(1)
  },
  dialogSubtitle: {
    color: '#624C92'
  },
  dialogText: {
    marginTop: 8,
    marginBottom: 16
  }
}))

export default useStyles
