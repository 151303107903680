import {
  LIBRARY_BOOKS_SIMPLE_LOADING,
  LIBRARY_BOOKS_SIMPLE_SUCCESS,
  LIBRARY_BOOKS_SIMPLE_ERROR
} from '../../../utils/actionTypes'
import { LibraryService } from '../../../services'

export const getLibraryBooksSimpleAction = async (dispatch, payload = {}) => {
  dispatch({
    type: LIBRARY_BOOKS_SIMPLE_LOADING
  })

  try {
    const libraryResponse = await LibraryService.getLibrary(payload.lastUpdate)

    if (!libraryResponse.ok) {
      throw new Error(
        `libraryResponse error: ${libraryResponse.status} ${libraryResponse.statusText}`
      )
    }

    const libraryData = await libraryResponse.json()

    dispatch({
      type: LIBRARY_BOOKS_SIMPLE_SUCCESS,
      payload: {
        products: libraryData.products,
        lastUpdate: libraryData.updated_at,
        saToken: libraryData.sa_token
      }
    })

    return {
      ok: libraryResponse.ok,
      data: libraryData.products
    }
  } catch (error) {
    dispatch({
      type: LIBRARY_BOOKS_SIMPLE_ERROR,
      payload: {
        products: [],
        lastUpdate: null,
        saToken: null
      }
    })

    return {
      ok: false,
      error: error.message
    }
  }
}
