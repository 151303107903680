import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { NotificationsNoneRounded } from '@material-ui/icons'
import { Dialog } from '@grupogen/saraiva-ui'

import { useNotificationStyles } from './styles'

export default function NotificationsDialog({
  // eslint-disable-next-line no-unused-vars
  notification,
  openNotificationDialog,
  setOpenNotificationDialog
}) {
  const saraivaTheme = useTheme()
  const isDesktop = useMediaQuery(saraivaTheme.breakpoints.up('sm'))
  const notificationClasses = useNotificationStyles()

  return (
    <Dialog
      open={openNotificationDialog}
      title="Notificações"
      primaryButton="FECHAR"
      handleClose={() => setOpenNotificationDialog(false)}
      handleConfirm={() => setOpenNotificationDialog(false)}
      size={isDesktop ? 'lg' : 'md'}
    >
      <Box className={notificationClasses.notificationWrapper}>
        <NotificationsNoneRounded
          color="primary"
          className={notificationClasses.notificationIcon}
        />
        <Typography
          variant="body1"
          className={notificationClasses.notificationContent}
        >
          Boas-vindas às notificações do Saraiva Conecta! É por aqui que você
          receberá as atualizações sobre a nossa plataforma e conteúdos do
          catálogo Saraiva Educação. Assim, você acompanhará de perto as nossas
          novidades!
        </Typography>
      </Box>
    </Dialog>
  )
}

NotificationsDialog.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isUnread: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onClick: PropTypes.func
  }).isRequired,
  openNotificationDialog: PropTypes.bool.isRequired,
  setOpenNotificationDialog: PropTypes.func
}

NotificationsDialog.defaultProps = {
  setOpenNotificationDialog: () => null
}
