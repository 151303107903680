import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  toolbarNavigationContainer: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    height: 'auto',
    width: '100%'
  },
  toolbar: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 118,
    height: '100%'
  },
  menuItem: {
    paddingBottom: 20,
    paddingLeft: 24,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    '& span': {
      ...theme.typography.body1
    },

    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  menuItemSelected: {
    paddingBottom: 20,
    paddingLeft: 24,
    paddingRight: 24,
    color: theme.palette.primary.main,
    textAlign: 'center',
    '& span': {
      ...theme.typography.body1
    },

    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  categoriesLink: {
    opacity: 1,
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    ...theme.typography.body1
  },
  categoriesButton: {
    '& li': {
      padding: '6px 24px 20px',
      maxWidth: 168,
      minWidth: 80,
      display: 'inline-flex',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      margin: '0 auto',
      justifyContent: 'center',
      height: 'auto'
    },

    '&:hover, &:focus': {
      '& span': {
        color: theme.palette.primary.main
      },

      '& svg': {
        fill: theme.palette.primary.main
      }
    }
  },
  categoriesButtonActive: {
    '& span': {
      color: theme.palette.primary.main
    },

    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  button: {
    background: 'rgba(129, 106, 177, 0.3)',
    color: theme.palette.primary.dark,
    maxWidth: 95,
    margin: '56px 12px 24px 12px',
    padding: '8px 6.5px',
    '&:hover': {
      backgroundColor: 'rgba(129, 106, 177, 0.6)'
    }
  },
  divider: {
    marginBottom: theme.spacing(2.5)
  },
  activeBookWrapper: {
    padding: theme.spacing(7, 1.5, 3)
  },
  helpActivationBook: {
    marginTop: theme.spacing(3)
  }
}))

export default useStyles
