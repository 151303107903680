import {
  CATEGORIES_ERROR,
  CATEGORIES_LOADING,
  CATEGORIES_SUCCESS
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const categoriesReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    CATEGORIES_LOADING,
    CATEGORIES_SUCCESS,
    CATEGORIES_ERROR
  ])
}

export default categoriesReducer
