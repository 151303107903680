/*
 * Referencia de mapeamento no link
 * Todo trackeamento é feito pelos campos disponiveis  na hora da requisição
 * Caso não aja o campo da planilha disponivel, não é necessario envia-lo
 * https://kroton.sharepoint.com/:x:/r/sites/SETS-SSAeBDS/_layouts/15/doc2.aspx?sourcedoc=%7B7f380422-2257-41d2-b0b6-cae3748c9052%7D&action=edit&activeCell=%27Pr%C3%A9-login%27!C3&wdinitialsession=617acac8-74fe-4833-9f73-0b573fe4715b&wdrldsc=7&wdrldc=1&wdrldr=AccessTokenExpiredWarning%2CRefreshingExpiredAccessT
 */
/* eslint-disable */
const dictionary = {
  favoritar: {
    false: 'clicou_favoritos_não_logado',
    true: 'clicou_favoritar_logado'
  },
  favoritarLogin: {
    false: 'clicou_fazer_login_favoritos',
    true: 'clicou_fazer_login_favoritos'
  },
  abaAvalicao: {
    false: 'clicou_aba_avaliacoes_nao_logado',
    true: 'clicou_aba_avaliacoes_logado'
  },
  fazerAvaliacao: {
    false: 'clicou_avaliacao_nao_logado',
    true: 'deixou_avaliacao'
  },
  fazerAvaliacaoLogin: {
    false: 'clicou_fazer_login_avaliacao_nao_logado',
    true: 'clicou_fazer_login_avaliacao'
  },
  ativacaoLivro: {
    false: 'clicou_ativacao_nao_logado',
    true: 'clicou_ativacao_logado'
  },
  ativacaoLivroLogin: {
    false: 'clicou_fazer_login_ativacao',
    true: 'clicou_fazer_login_ativacao'
  },
  ativacaoLivroPorCupom: {
    false: 'ativou_por_cupom',
    true: 'ativou_por_cupom'
  },
  ativacaoLivroPorCupomError: {
    false: 'ativou_por_cupom_erro',
    true: 'ativou_por_cupom_erro'
  },
  ativacaoLivroPorLink: {
    false: 'ativou_por_link',
    true: 'ativou_por_link'
  },
  consultaMaterialPorLink: {
    // nao foi feito ainda a funcionalidade
    false: 'consulta_material_por_link',
    true: 'consulta_material_por_link'
  },
  visualizarAmostra: {
    false: 'clicou_visualizar_amostra_nao_logado',
    true: 'clicou_visualizar_amostra_logado'
  },
  visualizarAmostraLogin: {
    false: 'clicou_fazer_login_amostra',
    true: 'clicou_fazer_login_amostra'
  },
  busca: {
    false: 'realizou_busca_nao_logado',
    true: 'realizou_busca_logado'
  },
  filtro: {
    false: 'clicou_filtro_nao_logado',
    true: 'clicou_filtro_logado'
  },
  queroAdquirir: {
    false: 'clicou_quero_adquirir_nao_logado',
    true: 'clicou_quero_adquirir_logado'
  },
  compartilharObra: {
    false: 'compartilhou_obra_nao_logado',
    true: 'compartilhou_obra_logado'
  },
  referenciaBibliografica: {
    false: 'copiou_referencia_bibliografica_nao_logado',
    true: 'copiou_referencia_bibliografia_logado'
  },
  reportarErro: {
    false: 'clicou_reportar_erro_nao_logado',
    true: 'clicou_reportar_erro_logado'
  },
  suporte: {
    false: 'contatou_suporte_nao_logado',
    true: 'contatou_suporte_logado'
  },
  suporteError: {
    false: 'tentou_contatar_suporte_nao_logado',
    true: 'tentou_contatar_suporte_logado'
  },
  abaCatalogo: {
    false: 'clicou_aba_catalogo_nao_logado',
    true: 'clicou_aba_catalogo_logado'
  },
  tagCategoria: {
    false: 'clicou_tag_categoria_nao_logado',
    true: 'clicou_tag_categoria_logado'
  },
  subCategoriaBreadcrumb: {
    false: 'clicou_subcategoria_breadcrumb_nao_logado',
    true: 'clicou_subcategoria_breadcrumb_logado'
  },
  categoriaBreadcrumb: {
    false: 'clicou_categoria_breadcrumb_nao_logado',
    true: 'clicou_categoria_breadcrumb_logado'
  },
  okBarraCookie: {
    false: 'clicou_ok_barra_cookies_nao_logado',
    true: 'clicou_ok_barra_cookies_logado'
  },
  politicaPrivacidade: {
    false: 'clicou_politica_privacidade_nao_logado',
    true: 'clicou_politica_privacidade_logado'
  },
  denunciarComentario: {
    false: 'denunciou_comentario_nao_logado',
    true: 'denunciou_comentario_logado'
  },
  login: {
    false: 'realizou_login',
    true: 'realizou_login'
  },
  abaMeusLivros: {
    false: 'clicou_aba_meus_livros',
    true: 'clicou_aba_meus_livros'
  },
  fichaTecnica: {
    false: 'clicou_ficha_tecnica',
    true: 'clicou_ficha_tecnica'
  },
  materiais: {
    false: 'clicou_materiais',
    true: 'clicou_materiais'
  },
  secao: {
    false: 'clicou_secao',
    true: 'clicou_secao'
  },
  conteudo: {
    false: 'clicou_conteudo',
    true: 'clicou_conteudo'
  },
  abaFavoritos: {
    false: 'clicou_aba_favoritos',
    true: 'clicou_aba_favoritos'
  },
  subCategoria: {
    false: 'clicou_subcategoria_nao_logado',
    true: 'clicou_subcategoria_logado'
  },
  subCategoriaEsubCategoria: {
    false: 'clicou_subcategoria_e_subcategoria_nao_logado',
    true: 'clicou_subcategoria_e_subcategoria_logado'
  },
  card: {
    false: 'clicou_card_nao_logado',
    true: 'clicou_card_logado'
  },
  ebookCard: {
    false: 'clicou_para_pagina_de_detalhes_do_ebook_nao_logado',
    true: 'clicou_para_pagina_de_detalhes_do_ebook_logado'
  },
  openBook: {
    false: '[DA]Abriu_Livro_LD',
    true: '[DA]Abriu_Livro_LD'
  }, 
  menuSanduiche: {
    false: 'clicou_menu_sanduiche_nao_logado',
    true: 'clicou_menu_sanduiche_logado'
  },
  atualizeSeuCodigo: {
    false: 'clicou_atualize',
    true: 'clicou_atualize'
  },
  legislacaoAdicional: {
    false: 'clicou_legislacao',
    true: 'clicou_legislacao'
  },
  legislacaoAdicionalConteudo: {
    false: 'clicou_conteudo_legislacao',
    true: 'clicou_conteudo_legislacao'
  },
  atualizeSeuCodigImprimir: {
    false: 'clicou_imprimir_atualize',
    true: 'clicou_imprimir_atualize'
  },
  atualizeSeuCodigoSecao: {
    false: 'clicou_secao',
    true: 'clicou_secao'
  },
  filtrouItemsPorPagina: {
    false: 'filtrou_items_por_pagina_nao_logado',
    true: 'filtrou_items_por_pagina_logado'
  },
  filtrouFormato: {
    false: 'filtrou_formato_nao_logado',
    true: 'filtrou_formato_logado'
  },
  filtroSelo: {
    false: 'filtrou_selo_nao_logado',
    true: 'filtrou_selo_logado'
  },
  filtroBusca: {
    false: 'filtrou_busca_nao_logado',
    true: 'filtrou_busca_logado'
  },
  subiu_documento_professor: {
    false: 'subiu_documento_professor',
    true: 'subiu_documento_professor'
  },
  pulou_interesse: {
    false: 'pulou_interesse_nao_logado',
    true: 'pulou_interesse_logado'
  },
  registrou_interesses: {
    false: 'registrou_interesses_nao_logado',
    true: 'registrou_interesses_logado'
  },
  clicou_notificacao_header: {
    false: 'clicou_notificacao_header',
    true: 'clicou_notificacao_header',
  },
  clicou_notificacao_email: {
    false: 'clicou_notificacao_email',
    true: 'clicou_notificacao_email'
  },
  accessedMyEbooks: {
    false:'acessou_meus_ebooks_nao_logado',
    true: 'acessou_meus_ebooks'
  },
  myMaterials: {
    false:'acessou_meus_materiais_nao_logado',
    true: 'acessou_meus_materiais'
  },
  simulated: {
    false:'acessou_acessou_simulado_nao_logado',
    true: 'acessou_simulados'
  },
  ativou_ebook: {
    false: 'ativou_ebook',
    true: 'ativou_ebook'
  },
  realizou_simulados: {
    false: 'realizou_simulados',
    true: 'realizou_simulados'
  },
  navegou_simulados: {
    false: 'navegou_simulados',
    true: 'navegou_simulados'
  },
  refez_simulados: {
    false: 'refez_simulados',
    true: 'refez_simulados'
  },
  abandonou_simulado: {
    false: 'abandonou_simulado',
    true: 'abandonou_simulado'
  },
  acessou_simulados: {
    false: 'acessou_simulados',
    true: 'acessou_simulados'
  },
  clicou_banner:{
    false: 'clicou_banner',
    true: 'clicou_banner'
  },
  abriu_chatbot:{
    false: 'abriu_chatbot',
    true: 'abriu_chatbot'
  },
  enviou_pergunta:{
    false: 'enviou_pergunta',
    true: 'enviou_pergunta'
  },
  clicou_interesse_premium:{
    false: 'clicou_interesse_premium',
    true: 'clicou_interesse_premium'
  }
}

export default dictionary
