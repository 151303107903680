import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox, ArrowDropDownCircle } from '@material-ui/icons'
import useStyles from './styles'
import useMaterialStyles from '../styles'

const Chapter = ({
  statistics,
  step,
  stepStatus,
  handleChangeState,
  questionsStates
}) => {
  const classes = useStyles()
  const materialClasses = useMaterialStyles()

  const errors = statistics.total_answers - statistics.correct_answers
  const correctPercentage = Math.round(statistics.correct_percentage * 100)
  const barStyle = { width: `${correctPercentage}%` }
  const stepButtonText =
    stepStatus === 'not_started'
      ? 'Iniciar questionário'
      : 'Continuar de onde parou'

  return (
    <div className={materialClasses.mainIframe} style={{ textAlign: 'center' }}>
      <h1 className={classes.chapterTitle}>{step.name}</h1>

      <div className={classes.performance}>
        <div className={classes.performanceText}>
          Percentual de conhecimento adquirido nesse banco de questões
        </div>
        <div className={classes.performanceMeter}>
          <span className={classes.performanceBar} style={barStyle} />
          <span className={classes.performanceBarPercent}>
            {correctPercentage} %
          </span>
        </div>
        <ul className={classes.stats}>
          <li>{statistics.question_count} questões</li>
          <li>{statistics.correct_answers} acertos</li>
          <li>{errors} erro</li>
          <li>{statistics.total_answers} tentativas</li>
        </ul>
      </div>

      <div className={classes.options}>
        <div className={classes.buttonContent}>
          <button
            type="button"
            className={classes.stepButton}
            disabled={stepStatus === 'not_started'}
            onClick={() => handleChangeState(questionsStates.REVIEW)}
            data-testid="bookactived-question-see-answer"
          >
            <CheckBox />
            <span>Conferir suas respostas</span>
          </button>
        </div>
        <div
          className={classes.buttonContent}
          data-testid="bookactived-question-start"
        >
          <button
            type="button"
            className={classes.stepButton}
            onClick={() => handleChangeState(questionsStates.QUIZ)}
          >
            <ArrowDropDownCircle className={classes.svgPlay} />
            <span>{stepButtonText}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

Chapter.propTypes = {
  statistics: PropTypes.oneOfType([PropTypes.object]),
  step: PropTypes.oneOfType([PropTypes.object]),
  stepStatus: PropTypes.string,
  handleChangeState: PropTypes.func,
  questionsStates: PropTypes.string
}

Chapter.defaultProps = {
  statistics: {},
  step: {},
  stepStatus: '',
  handleChangeState: () => null,
  questionsStates: ''
}

export default Chapter
