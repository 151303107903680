import {
  AUTHENTICATED_ERROR,
  PROFILE_INTERESTS_LOADING,
  PROFILE_INTERESTS_ADD_ERROR,
  PROFILE_INTERESTS_ADD_SUCCESS
} from '../../../utils/actionTypes'
import { TrackingsService, Users, UserService } from '../../../services'
import { isAuthenticated } from '../../../auth'
import Messages from '../../../config/Messages'

export const addInterestsAction = async (dispatch, payload = {}) => {
  let statusOk = true

  if (isAuthenticated()) {
    dispatch({
      type: PROFILE_INTERESTS_LOADING
    })

    await TrackingsService.sendTrack(
      'registrou_interesses',
      {},
      {
        areas_seleconadas: payload.interests.join()
      }
    )

    const response = await UserService.setUserInterests(
      Users.getUserId(),
      payload
    )

    const result = await response.json()

    if (response.ok) {
      dispatch({
        type: PROFILE_INTERESTS_ADD_SUCCESS,
        payload: result.data
      })
    } else {
      statusOk = false

      dispatch({
        type: PROFILE_INTERESTS_ADD_ERROR,
        payload: result.errors
      })
    }
  } else {
    statusOk = false

    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }

  return {
    ok: statusOk,
    isAuthenticated: isAuthenticated()
  }
}
