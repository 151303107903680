import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core'
import { Button } from '@grupogen/saraiva-ui'
import Loading from '../../../Loading'
import { useStyles } from './styles'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import {
  addInterestsAction,
  addInterestsNotificationAction,
  addProfileTypeAction,
  getCategoriesAction
} from '../../../../contexts/actions'
import { Links, Messages } from '../../../../config'
import { normalizeString, sortByName } from '../../../../utils'
import { GeneralContext } from '../../../../contexts/GeneralContext'

export default function InterestStep({ profilesSelected, setSnackbar }) {
  const classes = useStyles()
  const history = useHistory()

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const { data: categories, loading: categoriesLoading } =
    globalState.categories

  const [interestsState, setInterestsState] = useState([])
  const [shouldDisableButton, setShouldDisableButton] = useState(false)

  const disableButton = () => {
    return !Object.keys(interestsState).some(
      (interest) => interestsState[interest].selected
    )
  }

  const setProfileType = async () => {
    const response = await addProfileTypeAction(
      globalDispatch,
      profilesSelected
    )

    return response.ok
  }

  const setInterestsNotification = async () => {
    const response = await addInterestsNotificationAction(
      globalDispatch,
      profilesSelected
    )

    return response.ok
  }

  const handleCloseInterest = async () => {
    try {
      const data = await setInterestsNotification()

      if (!data) {
        throw new Error(Messages.profile.setInterestsError)
      }
    } catch (err) {
      setErrorMessage(err.message)
      history.push(Links.home)
    } finally {
      const profileData = await setProfileType()

      if (!profileData) {
        setSnackbar(Messages.profile.setProfileError)
      }

      setSuccessMessage(Messages.profile.setProfileSuccess)

      setTimeout(() => {
        history.push(Links.home)
      }, 2000)
    }
  }

  const handleChangeInterests = (event) => {
    setInterestsState((prevState) => {
      const newState = prevState.map((interest) => {
        if (interest.key === event.target.id) {
          return { ...interest, selected: event.target.checked }
        }

        return interest
      })

      return newState
    })
  }

  const handleSubmitPreferences = async () => {
    const interests = interestsState
      .filter((interest) => interest.selected)
      .map((interest) => interest.id)

    const interestsPayload = { interests }

    const profileData = await setProfileType()

    if (!profileData) {
      throw new Error(Messages.profile.setProfileError)
    }

    try {
      const interestsResponse = await addInterestsAction(
        globalDispatch,
        interestsPayload
      )

      if (!interestsResponse.ok) {
        throw new Error(interestsResponse.status)
      }

      setShouldDisableButton(true)
      setSuccessMessage(Messages.profile.setProfileSuccess)

      setTimeout(() => {
        history.push(Links.home)
      }, 3000)
    } catch (err) {
      setErrorMessage(Messages.profile.setProfileError)
    }
  }

  useEffect(() => {
    if (!categories.length) {
      getCategoriesAction(globalDispatch)
    } else {
      const interestList = categories.map((category) => {
        return {
          key: normalizeString(category.name, '-'),
          id: category.id,
          name: category.name,
          selected: false
        }
      })

      setInterestsState(interestList.sort(sortByName))
    }
  }, [categories, globalDispatch])

  if (categoriesLoading || !categories.length) {
    return <Loading />
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Typography component="h1" className={classes.title}>
          Áreas de interesse:
        </Typography>

        <Typography component="h2" className={classes.subTitle}>
          Escolha áreas de interesse para receber recomendações de livros de
          acordo com o seu gosto
        </Typography>

        <FormGroup className={classes.formGroup} row>
          {Object.keys(interestsState).map((interest) => (
            <FormControlLabel
              key={interestsState[interest].key}
              control={
                <Checkbox
                  checked={interestsState[interest].selected}
                  onChange={handleChangeInterests}
                  name={interestsState[interest].id}
                  id={interestsState[interest].key}
                  color="primary"
                />
              }
              label={interestsState[interest].name}
              className={classes.formControlLabel}
            />
          ))}
        </FormGroup>
      </Box>

      <Box className={classes.buttonWrapper}>
        <Button color="secondary" onClick={() => handleCloseInterest()}>
          pular
        </Button>

        <Button
          color="primary"
          onClick={() => handleSubmitPreferences()}
          variant="contained"
          disabled={disableButton() || shouldDisableButton}
          className={classes.buttonSubmit}
        >
          Próximo
        </Button>
      </Box>
    </>
  )
}

InterestStep.propTypes = {
  profilesSelected: PropTypes.shape({
    profiles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  setSnackbar: PropTypes.func.isRequired
}
