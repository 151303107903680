import React from 'react'
import { Typography, Link, useMediaQuery, useTheme } from '@material-ui/core'
import { useStyles } from './style'

export default function EmptyEbooksLibrary() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.descriptionContaineer}>
      <Typography
        variant={isMobile ? 'body1' : 'h6'}
        data-testid="ebook-empty-library"
        className={classes.emptyEbookDescription}
      >
        Você ainda não possui e-book. Acesse nosso e-commerce para adquirir as
        obras disponiveis dos selos Saraiva
      </Typography>
      <div className={classes.linkContainer}>
        <Link
          href="https://www.editoradodireito.com.br/"
          target="_blank"
          variant="body1"
        >
          Ir para o e-commerce
        </Link>
      </div>
    </div>
  )
}
