import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  stepWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  textHighlight: {
    color: theme.palette.primary.light,
    fontWeight: 500
  },
  introText: {
    color: theme.palette.text.secondary,
    ...theme.typography.body2,

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body1
    }
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3)
    },

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4)
    }
  },
  image: {
    width: 166,
    height: 34
  },
  steps: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3)
    },

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4)
    }
  },
  step: {
    display: 'flex',

    '& $stepText': {
      padding: theme.spacing(1, 0)
    },

    '&:first-child': {
      '& $stepText': {
        paddingTop: 0
      },

      '& $stepDashed': {
        height: '50%'
      }
    },

    '&:last-child': {
      '& $stepText': {
        paddingBottom: 0
      },

      '& $stepDashed': {
        height: '50%',
        bottom: 'initial',
        top: 0
      }
    }
  },
  stepDecorator: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  stepDashed: {
    position: 'absolute',
    borderLeft: `1px dashed ${theme.palette.divider}`,
    height: '100%',
    width: 1,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },
  stepText: {
    color: theme.palette.text.secondary,
    ...theme.typography.body2,
    marginLeft: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body1
    }
  }
}))

export default useStyles
