import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  menu: {
    minWidth: 195,
    marginRight: 24,
    padding: 21,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 24
    }
  },
  emptySelection: {
    width: '100%',
    padding: '14px 24px',
    textAlign: 'center'
  },
  contentHTML: {
    marginBottom: 57
  },
  printButton: {
    display: 'flex',
    background: 'none',
    outline: 'inherit',
    cursor: 'pointer',
    height: 36,
    alignItems: 'center',
    flexDirection: 'row',
    width: 122,
    textAlign: 'center',
    border: '1px solid rgba(98, 76, 146, 0.5)',
    borderRadius: 8,
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 6,
    textTransform: 'uppercase',
    color: '#624C92',
    fontWeight: 500,
    marginTop: 18,
    marginLeft: 'auto',
    fontSize: 14,
    marginRight: 'auto'
  },
  menuItems: {
    '& li > div': {
      padding: 0,
      marginBottom: 4
    }
  }
}))

export default useStyles
