import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 28,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 21,
      paddingRight: 20,
      paddingBottom: 51
    }
  },
  tabs: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 32
    }
  },
  tableCell: {
    width: 242,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 0
  },
  DatasheetTitle: {
    marginTop: 26,
    marginBottom: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  DatasheetInfo: {
    color: theme.palette.secondary.dark,
    padding: 0,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  otherInfos: {
    marginTop: 36,
    marginBottom: 14
  },
  copyIcon: {
    marginLeft: 17,
    fontSize: 15,
    fontWeight: 500,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5
    }
  }
}))

export default useStyles
