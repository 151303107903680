import React, { useState, useEffect } from 'react'
import { SupportButton, TextInput, Button } from '@grupogen/saraiva-ui'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { Typography } from '@material-ui/core'
import useStyles from './styles'
import { SupportService, TrackingsService, Users } from '../../services'
import Messages from '../../config/Messages'
import { isAuthenticated } from '../../auth'

export default function SupportButtonForm() {
  const classes = useStyles()
  const INITIAL_STATE = {
    name: isAuthenticated() ? Users.getFullName() : '',
    email: isAuthenticated() ? Users.getEmail() : '',
    phone: '',
    title_book: '',
    edition_book: '',
    ticket_activation: '',
    subject: '',
    message: ''
  }
  const [data, setData] = useState(INITIAL_STATE)
  const [sent, setSent] = useState(false)
  const [trySend, setTrySend] = useState(false)
  const [error, setError] = useState(false)
  const updateField = (evt) => {
    setData({
      ...data,
      [evt.target.name]: evt.target.value
    })
  }

  const [disabledButton, setDisabledButton] = useState(true)
  useEffect(() => {
    setDisabledButton(!data.name || !data.email || !data.message)
  }, [data])

  const clearState = () => {
    setData(INITIAL_STATE)
    setSent(false)
    setTrySend(false)
    setError(false)
  }

  const successMessage = (
    <div className={classes.wrapped}>
      <div>
        <CheckCircleIcon className={classes.iconSuccess} />
      </div>
      <Typography variant="subtitle1" className={classes.text}>
        {Messages.messageSent.success}
      </Typography>
      <Typography className={classes.secondaryText}>
        {Messages.messageSent.contactSupport}
      </Typography>
    </div>
  )

  const errorMessage = (
    <>
      <div className={classes.wrapped}>
        <CancelIcon className={classes.iconError} />
        <Typography variant="subtitle1" className={classes.text}>
          {Messages.messageSent.error}
        </Typography>
        <Typography className={classes.secondaryText}>
          {Messages.messageSent.systemError}

          <a
            target="_top"
            href={`mailto:${Messages.messageSent.systemErrorEmail}?subject=${Messages.messageSent.systemErrorEmailSubject}`}
            className={classes.email}
          >
            {Messages.messageSent.systemErrorEmail}
          </a>
        </Typography>
      </div>
    </>
  )

  const sendDoubt = async () => {
    const payload = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      title_book: data.titleBook,
      edition_book: data.editionBook,
      ticket_activation: data.ticketActivation,
      subject: data.subject,
      message: data.message
    }
    setTrySend(true)
    try {
      const response = await SupportService.sendDoubt(payload)
      if (!response.ok) {
        TrackingsService.sendTrack('suporteError', {})
        const errors = await response.json()
        throw errors
      } else {
        TrackingsService.sendTrack(
          'suporte',
          {},
          {
            user_email: data.email,
            user_nome: data.name,
            mensagem: data.message,
            cupom_utilizado: data.ticketActivation,
            nome_livro: data.titleBook,
            edicao_livro: data.editionBook,
            assunto_livro: data.subject
          }
        )
      }
      setSent(true)
    } catch (err) {
      setError(true)
    }
  }

  return (
    <SupportButton
      color="primary"
      classCustom={classes.supportButton}
      clearChildrenStates={clearState}
    >
      {trySend && sent ? successMessage : <div />}
      {trySend && error ? errorMessage : <div />}
      {!trySend ? (
        <>
          <TextInput
            required
            color="primary"
            label="Nome"
            name="name"
            size="full"
            value={data.name}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            color="primary"
            label="Telefone"
            name="phone"
            size="full"
            value={data.phone}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            required
            color="primary"
            label="Email"
            name="email"
            size="full"
            value={data.email}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            color="primary"
            label="Título do Livro"
            name="titleBook"
            size="full"
            value={data.titleBook}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            color="primary"
            label="Edição do livro"
            name="editionBook"
            size="full"
            value={data.editionBook}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            color="primary"
            label="Cupom de ativação"
            name="ticketActivation"
            size="full"
            value={data.ticketActivation}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            color="primary"
            label="Assunto"
            name="subject"
            size="full"
            value={data.subject}
            onChange={updateField}
            className={classes.inputForm}
          />
          <TextInput
            required
            color="primary"
            label="Mensagem"
            name="message"
            size="full"
            multiline
            rows={6}
            value={data.message}
            onChange={updateField}
            className={classes.inputForm}
          />
          <Button
            disabled={disabledButton}
            color="primary"
            onClick={sendDoubt}
            variant="contained"
            className={classes.buttonForm}
          >
            Enviar
          </Button>
        </>
      ) : (
        <></>
      )}
    </SupportButton>
  )
}
