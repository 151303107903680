import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

export default function SEO({ title, description, metatags = [] }) {
  const metaTagDescription = {
    name: `description`,
    content: description
  }

  const getMetaTags = () => {
    if (metatags.length) {
      const metaTagList = metatags.map((tag) => {
        return {
          name: tag.name,
          content: tag.content
        }
      })

      return [metaTagDescription, ...metaTagList]
    }

    return [metaTagDescription]
  }

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: 'pt-BR' }}
      meta={getMetaTags()}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  metatags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ).isRequired
}
