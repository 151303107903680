import httpClient from '../gateway/generalHttpRequest'

export const SupportService = {
  async sendDoubt(payload) {
    const { REACT_APP_FUSION_ORIGIN } = process.env
    const path = `/support_email`
    let headers = {
      'application-token':
        REACT_APP_FUSION_ORIGIN === 'https://stg.fusion.saraivaeducacao.com.br'
          ? 't7yd9mq0ehz3nkw9l7ztunhm97uhb8m0'
          : 'm2bsh50foh5ndh5zjebgk9ew8ho9teja'
    } // TO-DO
    headers = httpClient.buildHeader(headers)
    const response = httpClient.post(path, payload, headers)
    return response
  }
}
