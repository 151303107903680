function getDeviceType() {
  const ua = navigator.userAgent

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }

  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const isTablet = getDeviceType() === 'tablet'
const isMobile = getDeviceType() === 'mobile'
const isDesktop = getDeviceType() === 'desktop'

function returnSizeByDeviceType() {
  const typesDevice = {
    desktop: 'large',
    tablet: 'medium',
    mobile: 'small'
  }

  return typesDevice[getDeviceType()]
}

function returnSiblingCountByDeviceType() {
  if (getDeviceType() === 'mobile') return 1
  return 3
}

async function getBrowser() {
  return window.navigator.appCodeName
}

export {
  isTablet,
  isMobile,
  isDesktop,
  getDeviceType,
  returnSizeByDeviceType,
  returnSiblingCountByDeviceType,
  getBrowser
}
