import queryString from 'query-string'

export const getSessionToken = () => {
  const searchValues = queryString.parse(window.location.search)

  return searchValues.sessionToken || localStorage.getItem('sessionToken')
}

export const setSessionToken = (sessionToken) => {
  localStorage.setItem('sessionToken', sessionToken)
}

export const getApplicationToken = () => {
  return process.env.REACT_APP_TOKEN
}

export const isAuthenticated = () => {
  return getSessionToken() !== null
}

export const isUnauthorized = (status) => {
  return status === 401
}

export const parseJwt = (token) => {
  if (!token) {
    return {}
  }

  const base64Url = token.split('.')[1]

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const redirectToLogin = () => {
  const { REACT_APP_TOKEN, REACT_APP_SSO_URL } = process.env

  const previousUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

  const loginUrl = `${REACT_APP_SSO_URL}/auto-login?previousUrl=${previousUrl}&applicationToken=${REACT_APP_TOKEN}`

  window.location = loginUrl
}
