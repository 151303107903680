import React, { useContext } from 'react'
import { Dialog } from '@grupogen/saraiva-ui'
import { GeneralContext } from '../../contexts/GeneralContext'
import {
  IntroStep,
  FirstTutorial,
  SecondTutorial,
  FinalStep
} from './components'
import paperStyles from './styles'

export default function HelpActivationDialog() {
  const { dialogHelpActivationBook, setDialogHelpActivationBook } =
    useContext(GeneralContext)

  const paperClasses = paperStyles()

  const PaperProps = {
    classes: paperClasses
  }

  const steppers = [
    <IntroStep />,
    <FirstTutorial />,
    <SecondTutorial />,
    <FinalStep />
  ]

  const handleClose = () => setDialogHelpActivationBook(false)

  return (
    <Dialog
      open={dialogHelpActivationBook}
      title="Como ativar meus materiais complementares"
      primaryButton="próximo"
      secondaryButton="anterior"
      size="lg"
      handleClose={() => handleClose()}
      stepper={steppers}
      PaperProps={PaperProps}
    />
  )
}
