import React, { useState, useContext } from 'react'
import { useContextSelector } from 'use-context-selector'
import PropTypes from 'prop-types'
import { Grid, Typography, Divider } from '@material-ui/core'
import SnackBar from '../SnackBar'

import { GlobalContext } from '../../contexts/GlobalContext'
import { GeneralContext } from '../../contexts/GeneralContext'
import {
  removeFavoriteBookAction,
  addFavoriteBookAction
} from '../../contexts/actions'

import { getDeviceType, getBookFavoriteStatus } from '../../utils'
import Messages from '../../config/Messages'
import { isAuthenticated } from '../../auth'

import {
  useStyles,
  useCardStyles,
  useMediaStyles,
  useContentStyles
} from './styles'
import CustomCard from '../Card'
import Loading from '../Loading'
import { Users } from '../../services'

export default function Recommended({
  libraryBooks,
  favorites,
  favoriteLoading,
  loading,
  recommendedBooks
}) {
  const classes = useStyles()
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })

  const { globalDispatch } = useContextSelector(GlobalContext, (state) => state)

  const {
    setSuccessMessage,
    setErrorMessage,
    setDialogNotAuthenticated,
    setDialogAuthClickTracking
  } = useContext(GeneralContext)

  const cardClasses = useCardStyles()
  const imageClasses = useMediaStyles()
  const contentClasses = useContentStyles()

  const handleClose = () => {
    setSnackbar({ children: '', severity: 'success', show: false })
  }

  if (loading || favoriteLoading) {
    return <Loading />
  }

  const onFavorite = async (id) => {
    if (isAuthenticated()) {
      const params = {
        favorite_type: 'cae_id',
        favorite_id: id,
        user_id: Users.getUserId()
      }
      const favoriteId = getBookFavoriteStatus(favorites, id)

      if (favoriteId) {
        const response = await removeFavoriteBookAction(
          globalDispatch,
          favoriteId
        )
        if (!response.ok) setErrorMessage(Messages.favorite.unfavoriteError)
        else setSuccessMessage(Messages.favorite.unfavorite)
      } else {
        const response = await addFavoriteBookAction(globalDispatch, params)
        if (!response.ok) setErrorMessage(Messages.favorite.unfavoriteError)
        else setSuccessMessage(Messages.favorite.success)
      }
    } else {
      setDialogAuthClickTracking('favoritarLogin')
      setDialogNotAuthenticated(Messages.favorite.notAuthenticate)
    }
  }

  if (recommendedBooks && !recommendedBooks.length) {
    return null
  }

  return (
    <>
      <Typography
        variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
        className={classes.subTitle}
        data-testid="recommended-title-for-you"
      >
        PARA VOCÊ
      </Typography>

      <Typography
        variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
        className={classes.body}
        data-testid="recommended-title"
      >
        Livros que você pode se interessar
      </Typography>

      <Divider className={classes.headerDivider} />

      <div className={classes.containerBox}>
        <>
          <div className={classes.containerCatalog}>
            <Grid container className={classes.headerGrid}>
              {recommendedBooks &&
                recommendedBooks.map((book) => (
                  <Grid
                    item
                    key={book.productCaeId}
                    className={classes.grid}
                    data-testid="recommended-item"
                  >
                    <CustomCard
                      book={book}
                      libraryBooks={libraryBooks}
                      favorites={favorites}
                      onFavorite={onFavorite}
                      cardClass={cardClasses}
                      imageClass={imageClasses}
                      contentClass={contentClasses}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </>

        <SnackBar
          severity={snackbar.severity}
          show={snackbar.show}
          handleClose={handleClose}
        >
          {snackbar.children}
        </SnackBar>
      </div>
    </>
  )
}

Recommended.propTypes = {
  libraryBooks: PropTypes.arrayOf.isRequired,
  favorites: PropTypes.arrayOf.isRequired,
  favoriteLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  recommendedBooks: PropTypes.arrayOf.isRequired
}
