import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import { SEO } from '../../components'
import { Links } from '../../config'
import BackgroundImage from '../../static/lp-saraivajur-background.png'
import LogoHeaderSaraiva from '../../static/logoHeaderSaraiva.svg'
import { useStyles } from './styles'

export default function LPSaraivaJur() {
  const classes = useStyles({ backgroundImage: BackgroundImage })
  const history = useHistory()

  const handleHeaderLogoClick = (event) => {
    event.preventDefault()

    history.push(Links.home)
  }

  const handleSubmit = () => {
    window.open('https://forms.office.com/r/vy4g6M360T', '_blank')
  }

  return (
    <>
      <SEO
        title="Lesgilação adicional no Saraiva Conecta!"
        description="O recurso da Legislação Adicional, que antes existia na plataforma Saraiva Jur, agora se encontra no Saraiva Conecta!"
      />

      <header className={classes.header}>
        <button
          type="button"
          onClick={handleHeaderLogoClick}
          className={classes.headerButton}
        >
          <img src={LogoHeaderSaraiva} alt="Logo Saraiva Conecta do Header" />
        </button>
      </header>

      <Box className={classes.container}>
        <Box className={classes.backgroundWrapper} />

        <Box className={classes.content}>
          <Typography component="h1" className={classes.title}>
            Lesgilação adicional agora está no Saraiva Conecta!
          </Typography>

          <Typography component="p" paragraph className={classes.text}>
            O recurso da Legislação Adicional, que antes existia na plataforma
            Saraiva Jur, agora se encontra no Saraiva Conecta!
          </Typography>

          <Typography component="p" paragraph className={classes.text}>
            Essa mudança unifica, em uma única plataforma, o acesso aos demais
            materiais complementares de todos os livros, as atualizações de
            código e agora a Legislação Adicional.
          </Typography>

          <Typography component="p" paragraph className={classes.text}>
            O Saraiva Conecta, além de ser um ambiente com navegação fluída,
            design atualizado e o acesso ao catálogo Saraiva completo, possui
            funcionalidades que facilitam a navegação entre os livros.
          </Typography>

          <Box className={classes.form}>
            <Box className={classes.formContent}>
              <Typography component="h2" className={classes.formTitle}>
                Quer ter acesso ao recurso de Legislação Adicional do seu livro?
              </Typography>

              <Typography component="p" className={classes.formSubTitle}>
                Basta clicar no botão e fazer o cadastro
              </Typography>
            </Box>

            <Box className={classes.buttonWrapper}>
              <Button onClick={handleSubmit} className={classes.button}>
                Realizar cadastro
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
