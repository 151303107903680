import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

export const UserService = {
  async sendCouponCode(payload) {
    const path = `/tickets/api/v1/activate`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async activeLinkBook(payload) {
    const path = `/accounts/api/v1/activate_book`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async getProfileType(userId) {
    const path = `/accounts/api/v1/users/profiles/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async getUserInterestsExists(userId) {
    const path = `/accounts/api/v1/users/interests/exists/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = httpClient.get(path, null, headers)
    return response
  },

  async setUserProfileType(userId, payload) {
    const path = `/accounts/api/v1/users/profiles/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async setUserInterests(userId, payload) {
    const path = `/accounts/api/v1/users/interests/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async getUserInterestsNotification(userId) {
    const path = `/accounts/api/v1/users/interests_notifications/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },

  async setUserInterestsNotification(userId) {
    const path = `/accounts/api/v1/users/interests_notifications/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, null, headers)
    return response
  },

  async getEbookLibrary(userId, updateAt) {
    const path = `/accounts/api/v1/users/${userId}/library_ebooks/${updateAt}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },

  async ebookActivationLink(purchaseHash) {
    const path = `/accounts/api/v1/activate_ebook/${purchaseHash}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },

  async getLastBooks() {
    const path = `accounts/api/v1/recently_opens`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  },
  async recoverPasswordEmail(payload) {
    const path = `accounts/api/v1/password`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.post(path, payload, headers)
    return response
  },
  async editUserInfo(payload, userId) {
    const path = `/accounts/api/v1/user_management/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.put(path, payload, headers)
    return response
  },
  async getUserInfo(userId) {
    const path = `/accounts/api/v1/user_management/${userId}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.get(path, null, headers)
    return response
  }
}
