import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'inherit',
    paddingBottom: 36,

    '& .MuiAlert-root': { width: 944, top: '118px' }
  },
  title: {
    color: theme.palette.text.secondary,
    marginBottom: 16,
    height: 24,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8
    }
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: 16,
    height: 22,
    [theme.breakpoints.down(375)]: {
      marginBottom: 40
    }
  },
  divider: {
    marginBottom: 20
  },
  img: {
    objectFit: 'fill',
    width: '124px !important',
    height: '170px !important',
    [theme.breakpoints.down(426)]: {
      width: '100% !important',
      height: '150px !important'
    }
  },
  grid: {
    position: 'relative',
    flex: `0 1 50%`,
    marginBottom: theme.spacing(2.25),

    [theme.breakpoints.up(532)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up('sm')]: {
      flex: `0 1 50%`
    },

    [theme.breakpoints.up(856)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up('md')]: {
      flex: `0 1 50%`
    },

    [theme.breakpoints.up(1064)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up(1280)]: {
      flex: `0 1 25%`
    }
  }
}))

export const useCardStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 156,
    height: 196,
    margin: '0 auto',
    padding: theme.spacing(2, 2, 1.5),

    [theme.breakpoints.up('sm')]: {
      maxWidth: 216,
      height: 290,
      margin: 'initial',
      padding: theme.spacing(3, 3, 2)
    }
  }
}))

export const useMediaStyles = makeStyles((theme) => ({
  root: {
    height: 167,

    [theme.breakpoints.up('sm')]: {
      height: 235
    }
  }
}))

export const useContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.25)
    }
  }
}))
