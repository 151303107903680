import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: 6,
    marginTop: 16,
    '@media screen and (max-width: 962px)': {
      border: 0,
      borderRadius: 0
    },
    padding: theme.typography.pxToRem(24)
  },
  feedbackCard: {
    width: '30%',
    height: '30%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: 6,
    marginRight: 16,
    marginTop: 16,
    '@media screen and (max-width: 962px)': {
      width: '100%',
      border: 0,
      borderRadius: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      padding: 0,
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24)
    },
    '@media screen and (max-width: 603px)': {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24)
    },
    padding: theme.typography.pxToRem(16),
    position: 'relative'
  },
  questionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (min-width: 963px)': {
      marginBottom: theme.typography.pxToRem(16)
    },
    '@media screen and (max-width: 962px)': {
      padding: theme.typography.pxToRem(16)
    }
  },
  questionsTitle: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14)
  },
  questionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: theme.typography.pxToRem(8),

    '@media screen and (max-width: 962px)': {
      display: 'flex',
      gap: theme.typography.pxToRem(16),
      overflow: 'auto',
      paddingTop: 0,
      padding: theme.typography.pxToRem(16),

      '& span': {
        flex: 1,
        maxWidth: 32,
        minWidth: 32
      }
    }
  },
  questionItem: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.23)',
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none'
  },
  questionItemActive: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.23)',
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: '#245A93',
    color: '#fff'
  },
  questionItemAnswered: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.23)',
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: '#e0e0e0'
  },
  questionItemCorrect: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.23)',
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: '#8FC2B1'
  },
  questionItemIncorrect: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.23)',
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: '#D29598'
  },
  questionBadge: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(14),
    width: 'fit-content',
    textTransform: 'uppercase',
    padding: theme.typography.pxToRem(5),
    lineHeight: '15px',
    verticalAlign: 'center',
    borderRadius: 8
  },
  questionRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  prevNextButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8
  },
  nextMockButton: {
    display: 'flex',
    justifyContent: 'end',
    position: 'relative',
    marginTop: 8
  },
  alternatives: {
    marginBottom: 24
  },
  alternativeCorrect: {
    width: 'calc(8px + 100%)',
    backgroundColor: '#E9F3EF',
    borderRadius: 8,
    marginBottom: 24,
    marginTop: 24,
    paddingLeft: 8,
    position: 'relative',
    right: 8
  },
  alternativeIncorrect: {
    width: '100%',
    backgroundColor: '#F6EAEA',
    borderRadius: 8,
    marginBottom: 24,
    marginTop: 24,
    paddingLeft: 8,
    position: 'relative',
    right: 8
  },
  justificationHeader: {
    display: 'flex',
    margin: '16px 32px 16px 28px',
    alignItems: 'end'
  },
  justificationIcon: {
    marginRight: 8
  },
  justification: {
    marginLeft: 32,
    marginBottom: 24,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: 0.15
  },
  correctPercentage: {
    display: 'flex',
    backgroundColor: '#E4E9ED',
    marginTop: 16,
    height: 62,
    alignItems: 'center',
    borderRadius: 8
  },
  percentageIcon: {
    marginLeft: 16,
    marginRight: 8
  },
  container: {
    display: 'flex',
    '@media screen and (max-width: 962px)': {
      display: 'block'
    }
  },
  dialogTitle: {
    marginBottom: 8,
    color: 'black'
  },
  yearSource: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
