import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chapterTitle: {
    color: '#444',
    fontSize: '1.5rem',
    lineHeight: '32px',
    fontWeight: 'normal'
  },
  performance: {
    padding: '100px 0'
  },
  performanceText: {
    color: '#444',
    marginBottom: '20px'
  },
  performanceMeter: {
    height: '15px',
    position: 'relative',
    background: '#cdcdcd',
    borderRadius: 25
  },
  performanceBar: {
    display: 'block',
    height: '100%',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    overflow: 'hidden',
    width: '25%'
  },
  performanceBarPercent: {
    fontSize: '0.9rem',
    position: 'absolute',
    color: '#000',
    top: 0
  },
  stats: {
    display: 'block',
    textAlign: 'right',
    marginTop: 20,
    paddingLeft: 0,
    listStyleType: 'none',
    '& li': {
      display: 'inline-block',
      paddingLeft: 20
    },
    '& li:first-of-type': {
      paddingRight: 20,
      borderRight: '2px solid #cdcdcd'
    }
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      flexDirection: 'column'
    }
  },
  buttonContent: {
    width: '50%',
    marginLeft: 'auto',
    left: 'auto',
    right: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 15
    }
  },
  stepButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    height: 'auto',
    lineHeight: 'unset',
    width: 'auto',
    textAlign: 'center',
    boxShadow: 'unset',
    borderRadius: 3,
    border: 'none',
    padding: '16px 25px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    cursor: 'pointer',
    '&:disabled': {
      background: '#ccc'
    },
    '& span': {
      marginLeft: 5
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    }
  },
  svgPlay: {
    transform: 'rotate(270deg)'
  }
}))

export default useStyles
