import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  content: {
    background: 'white',
    padding: '32px 25px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 16px'
    }
  },
  titleDialog: {
    color: 'black',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '12px'
    }
  },
  textDialog: {
    marginBottom: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px'
    }
  },
  baseAlert: {
    display: 'flex',
    padding: '18px 20px',
    alignItems: 'center',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #624C92',
    borderRadius: '4px'
  },
  iconContent: {
    marginRight: '10px',
    display: 'flex',
    '& svg': {
      color: '#624C92',
      transform: 'rotate(180deg)'
    }
  },
  baseAlertText: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  BoxAlertUpload: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      flexDirection: 'column'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px'
    }
  },
  BoxAlert: {
    marginRight: 24,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: 24
    }
  },
  uploadContent: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  uploadBox: {
    width: 286,
    border: '0.5px dashed #5E4E8E',
    borderRadius: 8,
    padding: 20,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  uploadIcon: {
    fontSize: 82,
    color: '#624C92'
  },
  uploadTextPrimary: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  uploadFileNamePrimary: {
    color: 'rgba(0, 0, 0, 0.54)',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    '& svg': {
      marginLeft: 11,
      transform: 'rotate(90deg)'
    }
  },
  uploadTextRenewPrimary: {
    color: '#624C92',
    textTransform: 'uppercase'
  },
  uploadTextSecondary: {
    color: '#624C92'
  },
  buttonContent: {
    textAlign: 'center'
  },
  boxHoverStyle: {
    width: '100%',
    display: 'block'
  }
}))

export default useStyles
