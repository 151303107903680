import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 'auto',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      margin: 'initial',
      padding: 0
    }
  },
  title: {
    ...theme.typography.h5,

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h4
    }
  },
  subTitle: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h5,
      marginTop: theme.spacing(3)
    }
  },
  text: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1.5),
    marginBottom: '82px',

    [theme.breakpoints.up('md')]: {
      ...theme.typography.body1,
      marginTop: theme.spacing(3),
      marginBottom: 'initial'
    }
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: `calc(40px + env(safe-area-inset-bottom))`,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      position: 'initial',
      marginTop: theme.spacing(9),
      marginBottom: 0,
      padding: 0
    }
  }
}))
