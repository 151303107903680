import {
  FAVORITE_BOOKS_LOADING,
  FAVORITE_BOOKS_SUCCESS,
  FAVORITE_BOOKS_ERROR,
  FAVORITE_BOOK_ADD_SUCCESS,
  FAVORITE_BOOK_REMOVE_SUCCESS
} from '../../../utils/actionTypes'

const favoriteBooksReducer = (state, { payload, type }) => {
  switch (type) {
    case FAVORITE_BOOKS_LOADING:
      return {
        ...state,
        error: false,
        loading: true
      }

    case FAVORITE_BOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case FAVORITE_BOOK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...[payload]]
      }

    case FAVORITE_BOOK_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(
          (item) => item.favorite_id !== payload.favorite_id
        )
      }

    case FAVORITE_BOOKS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default favoriteBooksReducer
