import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    background: 'rgba(0, 0, 0, 0.04)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8
  },
  textContent: {
    padding: 20,
    display: 'flex',
    alignItems: 'center'
  },
  textStyle: {
    marginRight: 16
  },
  imageContent: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  buttonContent: {
    padding: 12,
    background: 'white',
    borderRadius: 8
  },
  printButton: {
    display: 'flex',
    background: 'none',
    outline: 'inherit',
    cursor: 'pointer',
    height: 36,
    alignItems: 'center',
    flexDirection: 'row',
    width: 145,
    textAlign: 'center',
    border: '1px solid rgba(98, 76, 146, 0.5)',
    borderRadius: 8,
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 6,
    textTransform: 'uppercase',
    color: '#624C92',
    fontWeight: 500,
    marginLeft: 'auto',
    fontSize: 14,
    marginRight: 'auto',
    fontFamily: 'Roboto'
  }
}))

export default useStyles
