import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  breadCrumb: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 78,
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      border: 'none',
      padding: 0,
      marginBottom: theme.spacing(4)
    }
  },
  breadCrumbItem: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.subtitle2
  },
  breadCrumbSeparator: {
    height: 1,
    width: 30,
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(0, 1),

    [theme.breakpoints.up('md')]: {
      width: 84
    }
  },
  breadCrumbNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    ...theme.typography.caption,
    lineHeight: '28px',
    color: theme.palette.common.white
  },
  breadCrumbNumberActive: {
    backgroundColor: theme.palette.primary.main
  },
  breadCrumbText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1)
  },
  breadCrumbTextActive: {
    color: theme.palette.text.primary
  }
}))
