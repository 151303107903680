import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  emptyEbookDescription: {
    color: theme.palette.text.secondary
  },
  descriptionContaineer: {
    textAlign: 'center',
    height: 480,
    padding: '200px 120px 240px 120px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 0 240px 0'
    }
  },
  linkContainer: {
    marginTop: 16
  }
}))
