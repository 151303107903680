import { makeStyles } from '@material-ui/core/styles'
import BackgroundImage from '../../static/background.png'

export const useStyles = makeStyles((theme) => ({
  containerBranding: {
    backgroundImage: `url(${BackgroundImage})`
  },
  logo: {
    width: 210,
    height: 'auto',
    marginBottom: theme.spacing(3)
  },
  mobileLogo: {
    height: 32
  },
  columnBranding: {
    color: theme.palette.common.white,
    height: '100vh',
    paddingRight: '20%',
    paddingLeft: '20%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  columnContent: {
    minHeight: '100vh',
    backgroundColor: theme.palette.grey[100]
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      margin: `0 15%`
    }
  },
  stepHeaderMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 78,
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  stepHeaderMobileLogo: {
    width: 126,
    height: 'auto'
  },
  stepWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      padding: theme.spacing(0)
    }
  }
}))
