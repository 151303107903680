import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  inputForm: {
    width: '100%',
    marginBottom: 12
  },
  buttonForm: {
    marginBottom: 16
  },
  wrapped: {
    margin: '30px auto 30px auto'
  },
  iconSuccess: {
    color: theme.palette.success.light,
    height: '3em',
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  iconError: {
    color: theme.palette.error.main,
    height: '3em',
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  text: {
    textAlign: 'center',
    padding: '32px 42px 8px 42px'
  },
  secondaryText: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    padding: '0 16px 0 16px'
  },
  supportButton: {
    zIndex: 1300
  },
  email: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.main,

    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export default useStyles
