import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

import { Favorite, FavoriteBorder } from '@material-ui/icons'
import { ProductCard } from '@grupogen/saraiva-ui'

import { BookFormat } from './BookFormat'
import { CategoryButton } from './CategoryButton'
import { CardRating } from './CardRating'

import {
  getBookFavoriteStatus,
  isTheBookActive,
  searchToObject,
  toCamelCase
} from '../../utils'

import { BookService, TrackingsService } from '../../services'
import { isAuthenticated } from '../../auth'

import { useStyles, useIconButtonStyles } from './styles'

export default function CustomCard({
  book,
  libraryBooks,
  categoria,
  imageHoverText,
  totalItems,
  favorites,
  isSearch,
  onFavorite,
  cardClass,
  imageClass,
  contentClass,
  showFavorite
}) {
  const {
    authors,
    category,
    imageUrl,
    titleCover,
    productCaeId,
    rating,
    productCategory,
    edition,
    editionYear
  } = book

  const history = useHistory()
  const location = useLocation()

  const isFavorite = getBookFavoriteStatus(favorites, productCaeId)

  const classes = useStyles()
  const iconButtonClasses = useIconButtonStyles({ isFavorite })

  async function reportLastBook(bookId) {
    try {
      const response = await BookService.reportLastBook(bookId)
      await response.json()
    } catch (err) {
      console.error(err)
    }
  }

  const redirectToBook = (caeId, livro, e) => {
    if (e.target.src) {
      const queryString = searchToObject(location.search)
      const termOfSearch = isSearch && queryString.q ? queryString.q : null

      const payload = {
        termo_busca: termOfSearch,
        numero_resultados: totalItems,
        categoria,
        esta_ativo: isTheBookActive(caeId, libraryBooks)
      }

      TrackingsService.sendTrack('card', livro, payload)

      if (isAuthenticated() && isTheBookActive(Number(caeId), libraryBooks)) {
        history.push(`/livro-ativado/${caeId}`, { from: 'biblioteca' })
        if (location.pathname === '/biblioteca') reportLastBook(caeId)
      } else {
        history.push(`/livro/${caeId}`)
      }
    }
  }

  const FavoriteIconActive = withStyles((theme) => ({
    root: {
      color: theme.palette.common.white
    }
  }))(Favorite)

  const displayEditionAndYear = () => {
    return (edition && editionYear) || false
  }

  return (
    <span data-testid="card-item">
      <ProductCard
        image={imageUrl}
        imageHoverText={imageHoverText}
        showFavorite={showFavorite}
        favoritePosition="topRight"
        favoriteIconActive={
          <FavoriteIconActive
            data-testid="card-item-favorite"
            color="primary"
          />
        }
        favoriteIconDisabled={
          <FavoriteBorder data-testid="card-item-favorite" />
        }
        isFavorite={isFavorite}
        onFavorite={() => onFavorite(productCaeId)}
        cardClass={cardClass}
        imageClass={imageClass}
        contentClass={contentClass}
        iconButtonClass={iconButtonClasses}
        flag={<BookFormat formato={productCategory} />}
        onMediaClick={(e) => redirectToBook(productCaeId, book, e)}
        data-testid="card-item"
      >
        <Typography
          component="h2"
          variant="body1"
          className={classes.bookTitle}
          data-testid="card-item-title"
        >
          {toCamelCase(titleCover)}
        </Typography>

        <Typography
          component="p"
          variant="body2"
          className={classes.bookText}
          data-testid="card-item-authors"
        >
          {authors}
        </Typography>

        <Typography
          component="p"
          variant="body2"
          className={classes.bookText}
          data-testid="card-item-authors"
        >
          {displayEditionAndYear() && `${edition}ª edição | ${editionYear}`}
        </Typography>

        <CategoryButton category={category} />

        <CardRating rating={rating} />
      </ProductCard>
    </span>
  )
}
const bookShape = PropTypes.shape({
  authors: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.string.isRequired,
  titleCover: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  rating: PropTypes.shape({
    book_id: PropTypes.number,
    rating: PropTypes.number,
    total_comments: PropTypes.number
  }),
  productCategory: PropTypes.string.isRequired,
  productCaeId: PropTypes.number.isRequired,
  edition: PropTypes.string,
  editionYear: PropTypes.string
})
CustomCard.propTypes = {
  book: bookShape.isRequired,
  categoria: PropTypes.string,
  libraryBooks: PropTypes.arrayOf(bookShape).isRequired,
  imageHoverText: PropTypes.string,
  totalItems: PropTypes.number,
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      favorite_id: PropTypes.number,
      favorite_type: PropTypes.string,
      id: PropTypes.number,
      user_id: PropTypes.number
    })
  ).isRequired,
  isSearch: PropTypes.bool,
  onFavorite: PropTypes.func.isRequired,
  cardClass: PropTypes.shape({ root: PropTypes.string.isRequired }),
  imageClass: PropTypes.shape({ root: PropTypes.string.isRequired }),
  contentClass: PropTypes.shape({ root: PropTypes.string.isRequired }),
  showFavorite: PropTypes.bool
}

CustomCard.defaultProps = {
  imageHoverText: 'DETALHES',
  isSearch: false,
  categoria: '',
  cardClass: null,
  imageClass: null,
  contentClass: null,
  totalItems: 0,
  showFavorite: true
}
