/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import { isAuthenticated, setSessionToken } from './auth'
import Book from './pages/Book'
import Category from './pages/Category'
import CatalogPage from './pages/CatalogPage'
import Layout from './layouts'
import Library from './pages/Library'
import Links from './config/Links'
import Search from './pages/Search'
import Subscription from './pages/Subscription'
import ActivatedBook from './pages/ActivatedBook'
import Wishlist from './pages/Wishlist'
import ErrorPage from './pages/ErrorPage'
import MaintenancePage from './pages/MaintenancePage'
import LPSaraivaJur from './pages/LPSaraivaJur'
import HomePage from './pages/HomePage'
import Reader from './pages/Reader'

import { GeneralProvider } from './contexts/GeneralContext'
import { GlobalProvider } from './contexts/GlobalContext'
import { TrackingsService, Users } from './services'
import EbookLibrary from './pages/EbookLibrary'
import LinkActivated from './pages/LinkActivated'
import UserArea from './pages/UserArea'

const sendTrackAndRemoveURLToken = async (searchValues) => {
  try {
    await TrackingsService.sendTrack(
      'login',
      {},
      {
        user_email: Users.getEmail(),
        user_id: Users.getUserId(),
        user_name: Users.getFullName(),
        session_token: searchValues.sessionToken
      }
    )
  } catch {
    return null
  }

  setSessionToken(searchValues.sessionToken)
  sessionStorage.removeItem('viewInterests')
  window.location.search = ''
  return null
}

const PrivateRoute = ({
  component: Component,
  showMenu,
  showLayout,
  ...routeParams
}) => {
  const searchValues = queryString.parse(routeParams.location.search)

  if (searchValues.sessionToken) {
    sendTrackAndRemoveURLToken(searchValues)
  }

  const history = useHistory()

  return (
    <Route
      {...routeParams}
      render={(props) => {
        if (isAuthenticated()) {
          if (showLayout) {
            return (
              <Layout showMenu={showMenu}>
                <Component {...props} />
              </Layout>
            )
          }

          return <Component {...props} />
        }

        history.push(`/`)

        return null
      }}
    />
  )
}

const PublicRoute = ({
  component: Component,
  showMenu,
  showLayout,
  ...routeParams
}) => {
  const searchValues = queryString.parse(routeParams.location.search)

  if (searchValues.sessionToken) {
    sendTrackAndRemoveURLToken(searchValues)
  }

  return (
    <Route
      {...routeParams}
      render={(props) => {
        if (showLayout) {
          return (
            <Layout showMenu={showMenu}>
              <Component {...props} />
            </Layout>
          )
        }

        return <Component {...props} />
      }}
    />
  )
}

export default function Routes() {
  return (
    <GlobalProvider>
      <GeneralProvider>
        <Router>
          <Switch>
            <PublicRoute
              exact
              path={Links.book}
              component={(routeProps) => <Book {...routeProps} />}
            />

            <PublicRoute
              exact
              path={Links.categoria}
              component={(routeProps) => <Category {...routeProps} />}
            />

            <PublicRoute
              exact
              path={Links.subcategoria}
              component={(routeProps) => <Category {...routeProps} />}
            />

            <PublicRoute
              exact
              path={Links.busca}
              component={(routeProps) => <Search {...routeProps} />}
            />

            <PublicRoute
              exact
              path={Links.inscricao}
              component={(routeProps) => <Subscription {...routeProps} />}
            />

            <PublicRoute
              exact
              path={Links.inscricaoQrCode}
              component={(routeProps) => <Subscription {...routeProps} />}
            />

            <PublicRoute
              exact
              path={Links.home}
              component={(routeProps) => {
                if (isAuthenticated()) {
                  return <HomePage {...routeProps} />
                }

                return <CatalogPage {...routeProps} />
              }}
            />

            <PrivateRoute
              exact
              path={Links.library}
              component={(routeProps) => <Library {...routeProps} />}
            />

            <PrivateRoute
              exact
              path={Links.ebooks}
              component={(routeProps) => <EbookLibrary {...routeProps} />}
            />

            <PublicRoute
              exact
              path="/categories"
              component={(routeProps) => <HomePage {...routeProps} />}
            />

            <PrivateRoute
              exact
              path={Links.wishlist}
              component={(routeProps) => <Wishlist {...routeProps} />}
            />

            <PrivateRoute
              exact
              path={Links.livroAtivado}
              component={(routeProps) => <ActivatedBook {...routeProps} />}
            />

            <PrivateRoute
              showMenu={false}
              exact
              path="/epub/:caeId"
              component={Reader}
            />

            <PublicRoute
              showMenu={false}
              showLayout={false}
              path={Links.lpSaraivaJur}
              component={(routeProps) => <LPSaraivaJur {...routeProps} />}
            />

            <PublicRoute
              showMenu={false}
              path={Links.maintenance}
              component={(routeProps) => <MaintenancePage {...routeProps} />}
            />
            <PublicRoute
              exact
              path={Links.catalog}
              component={(routeProps) => <CatalogPage {...routeProps} />}
            />

            <PublicRoute
              showMenu={false}
              path="/linkActivated"
              component={() => <LinkActivated />}
            />
            <PublicRoute path="/users" component={() => <UserArea />} />
            <PublicRoute
              showMenu={false}
              path="*"
              component={(routeProps) => <ErrorPage {...routeProps} />}
            />
          </Switch>
        </Router>
      </GeneralProvider>
    </GlobalProvider>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
  showLayout: PropTypes.bool
}

PrivateRoute.defaultProps = {
  showMenu: true,
  showLayout: true
}

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
  showLayout: PropTypes.bool
}

PublicRoute.defaultProps = {
  showMenu: true,
  showLayout: true
}
