import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const header = {
  'session-token': getSessionToken(),
  'application-token': process.env.REACT_APP_TOKEN
}
const buildedHeader = httpClient.buildHeader(header)

export const BookService = {
  async getBook(bookId) {
    const path = `contents/api/v1/library/${bookId}`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },

  async getFavorites() {
    const path = `/interactions/api/v1/favorites?favorite_type=cae_id`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },

  async favoriteBook(params) {
    const path = `/interactions/api/v1/favorites`
    const response = httpClient.post(path, params, buildedHeader)
    return response
  },

  async unfavoriteBook(caeId) {
    const path = `/interactions/api/v1/favorites/${caeId}`

    const response = httpClient.delete(path, null, buildedHeader)
    return response
  },

  async rateBook(params) {
    const path = `/interactions/api/v1/comments`
    const response = httpClient.post(path, params, buildedHeader)
    return response
  },

  async getAllRatesByBook(bookId) {
    const path = `/interactions/api/v1/comments/book/${bookId}`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },

  async getBooksRate(booksId) {
    const path = `/interactions/api/v1/comments/books/rating`
    const response = httpClient.post(path, { book_ids: booksId }, buildedHeader)
    return response
  },

  async reportReview(params) {
    const path = `/interactions/api/v1/complaints`
    const response = httpClient.post(path, params, buildedHeader)
    return response
  },

  async reportLastBook(caeId) {
    const path = `accounts/api/v1/recently_opens`
    const response = httpClient.post(
      path,
      { book_id: caeId, material: false },
      buildedHeader
    )
    return response
  }
}
