import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import LogoConecta from '../../static/saraiva-conecta-mobile.png'
import LogoConectaWhite from '../../static/conecta-logo-white.png'
import SnackBar from '../SnackBar'
import InterestComplete from './InterestComplete'

export default function Interets({ setViewInterests }) {
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })

  const classes = useStyles()

  const handleCloseSnackBar = () => {
    setSnackbar((prev) => ({
      ...prev,
      children: '',
      show: false
    }))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            className={`${classes.columnBranding} ${classes.containerBranding}`}
            display={{ xs: 'none', md: 'flex' }}
          >
            <img
              src={LogoConectaWhite}
              alt="Saraiva Educação Logo"
              className={classes.logo}
            />

            <Box>
              <Typography variant="h5">
                O conhecimento abre caminho para a descoberta de novas
                possibilidades
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid container item xs={12} md={6} className={classes.columnContent}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.stepHeaderMobile}>
              <img
                className={classes.stepHeaderMobileLogo}
                src={LogoConecta}
                alt="Logo Conecta"
              />
            </Box>

            <Box className={classes.stepWrapper}>
              <InterestComplete setViewInterests={setViewInterests} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <SnackBar
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={handleCloseSnackBar}
      >
        {snackbar.children}
      </SnackBar>
    </>
  )
}

Interets.propTypes = {
  setViewInterests: PropTypes.func.isRequired
}
