import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Clear, Check, ExpandMore } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import useStyles from './styles'
import useMaterialStyles from '../styles'
import { MaterialsService } from '../../../../../../services'
import { Loading } from '../../../../../../components'
import QuestionList from '../QuestionList'

const Review = ({ step, statistics, pathname }) => {
  const classes = useStyles()
  const materialClasses = useMaterialStyles()
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(true)
  const [questionOpenId, setQuestionOpenId] = useState()

  useEffect(() => {
    const getReview = async () => {
      setLoading(true)

      try {
        const answersResponse = await MaterialsService.getQuestionsAnswered(
          pathname
        )

        if (answersResponse.ok) {
          const answersData = await answersResponse.json()
          setAnswers(answersData.data)
        }

        setLoading(false)
      } catch {
        setLoading(false)
      }
    }

    getReview()
  }, [pathname])

  const getQuestionStatus = (question) => {
    const [questionSelected] = question.alternatives.filter(
      (alternative) => alternative.id === question.user_alternative_id
    )
    return questionSelected.correct ? (
      <>
        <Check className={`${classes.statusGridSvg} success`} />
        <span className={`${classes.statusGridText} success`}>Acertou</span>
      </>
    ) : (
      <>
        <Clear className={`${classes.statusGridSvg} error`} />
        <span className={`${classes.statusGridText} error`}>Errou</span>
      </>
    )
  }

  const getSelectedAnswer = (question) => {
    const [questionSelected] = question.alternatives.filter(
      (alternative) => alternative.id === question.user_alternative_id
    )
    return questionSelected
  }

  const getCorrectAnswer = (question) => {
    const [correctAnswer] = question.alternatives.filter(
      (alternative) => alternative.correct
    )
    return correctAnswer
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={materialClasses.mainIframe}>
      <div className={classes.boxTitle}>
        <div>
          <h1 className={classes.tilte}>{step.name}</h1>
        </div>
        <div className={classes.questionsQuantity}>
          <span>Você respondeu </span>
          <span>{statistics.total_answers}</span>
          <span> questões</span>
        </div>
      </div>

      {answers.map((answer, key) => (
        <div className={classes.questionsGrid}>
          <Grid container className={classes.quetionsNumberGrid}>
            <Grid item xs={6}>
              <h5 className={classes.questionNumber}>Questão {key + 1}</h5>
              <div>
                <span className={classes.questionSeeDetails}>
                  Solução dessa questão:
                </span>
                <button
                  type="button"
                  className={classes.questionSeeDetailsButton}
                  onClick={() =>
                    setQuestionOpenId(questionOpenId ? null : answer.id)
                  }
                >
                  <ExpandMore
                    style={{
                      transform:
                        questionOpenId === answer.id ? 'rotate(180deg)' : 'none'
                    }}
                  />
                </button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.statusGrid}>
                {getQuestionStatus(answer)}
              </div>
            </Grid>
          </Grid>

          <div>
            <span
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: answer.contents }} />
          </div>

          <div
            style={{ display: questionOpenId === answer.id ? 'block' : 'none' }}
          >
            <QuestionList
              selectedAnswer={getSelectedAnswer(answer)}
              correctAnswer={getCorrectAnswer(answer)}
              currentQuestion={answer}
              answerSent
              setSelectedAnswer={() => null}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

Review.propTypes = {
  statistics: PropTypes.oneOfType([PropTypes.object]),
  step: PropTypes.oneOfType([PropTypes.object]),
  pathname: PropTypes.string.isRequired
}

Review.defaultProps = {
  statistics: {},
  step: {}
}

export default Review
