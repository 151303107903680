import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 278,
    marginRight: 24,
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    maxHeight: 500,
    paddingBottom: 15,
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 24
    }
  },
  indiceContainer: {
    padding: '12px',
    borderBottom: '1px solid #E0E0E0',
    marginBottom: 20,
    position: 'sticky',
    width: '100%',
    background: 'white',
    zIndex: 1,
    top: 0
  },
  indiceTitle: {
    color: '#624C92'
  },
  aditionalTitle: {
    color: '#624C92',
    marginTop: 24,
    marginBottom: 8,
    paddingLeft: 12
  }
}))

export default useStyles
