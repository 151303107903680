/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { GlobalContext } from '../contexts/GlobalContext'
import {
  getLibraryBooksSimpleAction,
  getFavoriteBooksAction,
  getBooksAction
} from '../contexts/actions'

import { getDefaultSearhParams } from '../utils'

const DEFAULT_PARAMS = getDefaultSearhParams()

export function useWishlist() {
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const {
    data: booksData,
    loading: loadingBooks,
    error: errorBooks
  } = globalState.books

  const { data: libraryBooks, loading: libraryLoading } =
    globalState.libraryBooksSimple

  const { data: favorites, loading: favoriteLoading } =
    globalState.favoriteBooks

  const [page, setPage] = useState(1)

  const handleClickPage = (_event, value) => {
    const currentPage = value
    if (currentPage !== page) {
      setPage(currentPage)
    }
  }

  const getData = useCallback(async () => {
    const favoritesResponse = await getFavoriteBooksAction(globalDispatch)

    if (favoritesResponse.ok) {
      if (Array.isArray(favorites) && favorites.length) {
        await getLibraryBooksSimpleAction(globalDispatch)
        await getBooksAction(globalDispatch, {
          page,
          itemsPerPage: DEFAULT_PARAMS.itemsPerPage,
          orderBy: DEFAULT_PARAMS.orderBy,
          filters: DEFAULT_PARAMS.filters,
          categoria: null,
          subcategoria: null,
          termOfSearch: null,
          caeIds: favoritesResponse.data
        })
      }
    }
  }, [globalDispatch, page])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    getData()
  }, [getData, page])

  return {
    books: favorites.length ? booksData.books : null,
    page: booksData.page,
    totalPages: booksData.totalPages,
    totalItems: booksData.totalElements,
    favorites,
    loadingBooks,
    favoriteLoading,
    libraryLoading,
    libraryBooks,
    errorBooks,
    handleClickPage
  }
}
