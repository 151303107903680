import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { ReactReader } from 'react-reader'
import {
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import { Button, Breadcrumbs } from '@grupogen/saraiva-ui'
import { Favorite, FavoriteBorder, InsertDriveFile } from '@material-ui/icons'

import { TrackingsService, Users } from '../../services'
import { GeneralContext } from '../../contexts/GeneralContext'
import { GlobalContext } from '../../contexts/GlobalContext'
import {
  getBookAction,
  getFavoriteBooksAction,
  addFavoriteBookAction,
  removeFavoriteBookAction
} from '../../contexts/actions'

import { isAuthenticated } from '../../auth'
import {
  getDeviceType,
  toCamelCase,
  stripHtml,
  getBookFavoriteStatus
} from '../../utils'
import Messages from '../../config/Messages'
import { useWindowSize, useError } from '../../hooks'

import {
  BookDatasheet,
  BookTabsHeader,
  BookAvaliacoes,
  ShareButton,
  Loading,
  BookTabsBody,
  SEO
} from '../../components'
import BookDescrion from './BookDescription'

import useStyles from './styles'

export default function Book() {
  const { caeId } = useParams()
  const { redirectOnError } = useError()

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (book) => book
  )
  const {
    data: book,
    loading: loadingBook,
    error: errorBook
  } = globalState.book
  const { data: favoriteBooks, loading: loadingFavorite } =
    globalState.favoriteBooks

  const [tabValue, setTabValue] = useState(0)
  const favoriteId = getBookFavoriteStatus(favoriteBooks, Number(caeId))

  const history = useHistory()

  const {
    setDialogNotAuthenticated,
    setSuccessMessage,
    setErrorMessage,
    setDialogAuthClickTracking
  } = useContext(GeneralContext)

  const classes = useStyles()
  const [sampleDialog, setSampleDialog] = useState(false)
  const [locationEpub, setLocationEpub] = useState(null)
  const locationEpubChanged = (epubcifi) => {
    setLocationEpub(epubcifi)
  }

  const openSampleDialogVisualizar = () => {
    if (isAuthenticated()) {
      setSampleDialog(true)
    } else {
      setDialogAuthClickTracking('visualizarAmostraLogin')
      setDialogNotAuthenticated(Messages.notAuthenticate.preview)
    }
  }

  const getSampleBook = () => {
    if (book.sampleBook.includes('pdf')) {
      return (
        <iframe
          className={classes.sampleBook}
          title="Amostra"
          src={book.sampleBook}
        />
      )
    }
    if (book.sampleBook.includes('epub')) {
      return (
        <div style={{ height: '90vh', position: 'relative' }}>
          <ReactReader
            showToc={false}
            locationEpub={locationEpub}
            locationEpubChanged={locationEpubChanged}
            url={book.sampleBook}
          />
        </div>
      )
    }
    return null
  }

  const tabsComponents = () => {
    return [
      {
        label: 'Ficha Técnica',
        content: <BookDatasheet book={book} />,
        trackingEvent: 'fichaTecnica',
        testid: 'book-datasheet'
      },
      {
        label: 'Avaliações',
        content: <BookAvaliacoes caeId={caeId} />,
        trackingEvent: 'avaliacoes',
        testid: 'book-avaliacoes'
      }
    ]
  }

  const handleClickOnFavorite = async () => {
    if (isAuthenticated()) {
      const userId = Users.getUserId()
      const params = {
        favorite_type: 'cae_id',
        favorite_id: caeId,
        user_id: userId
      }
      if (favoriteId) {
        const status = await removeFavoriteBookAction(
          globalDispatch,
          favoriteId
        )
        if (status.ok) {
          setSuccessMessage(Messages.favorite.unfavorite)
        } else setErrorMessage(Messages.error.unfavorite)
      } else {
        const status = await addFavoriteBookAction(globalDispatch, params)
        if (status.ok) {
          setSuccessMessage(Messages.favorite.success)
        } else setErrorMessage(Messages.error.favorite)
      }
    } else {
      setDialogAuthClickTracking('favoritarLogin')
      setDialogNotAuthenticated(Messages.favorite.notAuthenticate)
    }

    TrackingsService.sendTrack(
      'favoritar',
      {},
      {
        favorites_id: caeId
      }
    )
  }

  const handleTrackCategorie = (isSubcategorie, url) => {
    TrackingsService.sendTrack(
      isSubcategorie ? 'subCategoriaBreadcrumb' : 'categoriaBreadcrumb',
      book
    )

    history.push(url)
  }

  const getData = useCallback(
    async (cae) => {
      const status = await getBookAction(globalDispatch, { caeId: cae })

      if (status) {
        if (status.ok) {
          if (isAuthenticated()) {
            getFavoriteBooksAction(globalDispatch)
          }

          setTabValue(0)
        } else {
          redirectOnError({ status: 400 })
        }
      }
    },
    [setTabValue, globalDispatch, redirectOnError]
  )

  useWindowSize()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    getData(caeId)
  }, [caeId, getData])

  redirectOnError(errorBook)

  if (loadingBook || loadingFavorite || !book) {
    return <Loading />
  }

  return (
    <>
      <SEO
        title={`Saraiva Conecta - ${book.titleCover}`}
        description={stripHtml(book.description)}
      />

      <Breadcrumbs color="primary" className={classes.breadcrumps}>
        {[
          {
            label: `${book.category}`,
            onClick: () =>
              handleTrackCategorie(false, `/categoria/${book.category}`)
          },
          {
            label: `${book.subcategory}`,
            onClick: () =>
              handleTrackCategorie(
                true,
                `/categoria/${book.category}/${book.subcategory}`
              )
          },
          {
            label: `${book.titleCover}`
          }
        ]}
      </Breadcrumbs>

      <Card
        className={classes.container}
        variant="outlined"
        iconSelected={favoriteId}
      >
        <ShareButton book={book} />

        <Grid className={classes.main}>
          <Grid item className={classes.bookMain}>
            {book.availableSite && (
              <IconButton
                title={favoriteId ? 'Desfavoritar Livro' : 'Favoritar Livro'}
                className={classes.favoriteButton}
                style={{ backgroundColor: favoriteId && '#CD444B' }}
                onClick={() => {
                  handleClickOnFavorite()
                }}
              >
                {favoriteId ? (
                  <Favorite
                    className={classes.favoriteIcon}
                    style={{ color: 'white' }}
                  />
                ) : (
                  <FavoriteBorder className={classes.favoriteIcon} />
                )}
              </IconButton>
            )}

            <img
              className={classes.bookCover}
              alt={toCamelCase(book.titleCover)}
              src={book.imageUrl}
            />

            {/* Esta verificação está sendo feita devido a um erro no Tesseract, que
            envia o arquivo do Epub com o formato .epub+zip, impossibilitando a
            utilização do mesmo */}
            {book.sampleBook && !book.sampleBook.includes('.epub+zip') ? (
              <Button
                className={classes.buttonPreview}
                variant="contained"
                color="primary"
                startIcon={<InsertDriveFile />}
                onClick={() => openSampleDialogVisualizar()}
              >
                <Typography variant="caption">Visualizar amostra</Typography>
              </Button>
            ) : null}
          </Grid>

          <BookDescrion book={book} />
        </Grid>

        <BookTabsHeader
          screenSize={getDeviceType()}
          tabValue={tabValue}
          book={book}
          setTabValue={setTabValue}
        >
          {tabsComponents()}
        </BookTabsHeader>
      </Card>

      <BookTabsBody tabValue={tabValue} book={book}>
        {tabsComponents()}
      </BookTabsBody>

      <Dialog
        fullWidth
        maxWidth={false}
        open={sampleDialog}
        onClose={() => setSampleDialog(false)}
      >
        <DialogTitle>Amostra</DialogTitle>
        <iframe
          className={classes.sampleBook}
          title="Amostra"
          src={book.sampleBook}
        />
        <DialogActions>
          <Button color="primary" onClick={() => setSampleDialog(false)}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {book.sampleBook ? (
        <Dialog
          fullWidth
          maxWidth={false}
          open={sampleDialog}
          onClose={() => setSampleDialog(false)}
        >
          <DialogTitle>Amostra</DialogTitle>
          <Divider />
          {getSampleBook()}
          <Divider />
          <DialogActions>
            <Button color="primary" onClick={() => setSampleDialog(false)}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}
