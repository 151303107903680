import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'right',
      marginBottom: 'initial'
    }
  },
  title: {
    fontSize: '3rem',
    lineHeight: '56px',
    marginBottom: '22px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '6rem',
      lineHeight: '112px'
    }
  },
  pictureContainer: {
    position: 'relative',
    right: '-10px',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      top: '-10px',
      right: 'initial',
      justifyContent: 'initial'
    }
  },
  text: {
    fontSize: '1rem',
    lineHeight: '18px',
    fontWeight: '400',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '23px',
      fontWeight: '500'
    },

    '& br': {
      display: 'none',

      [theme.breakpoints.up('xs')]: {
        display: 'block'
      }
    }
  }
}))
