module.exports = {
  home: '/',
  catalog: '/catalogo',
  book: '/livro/:caeId',
  categoria: '/categoria/:categoria',
  subcategoria: '/categoria/:categoria/:subcategoria',
  busca: '/busca',
  politicas: '/politicas',
  termos: '/termos',
  library: '/biblioteca',
  inscricao: '/inscricao/:hash',
  inscricaoQrCode: '/qrcode/:hash',
  livroAtivado: '/livro-ativado/:caeId',
  wishlist: '/lista-de-desejos',
  error: '/error',
  maintenance: '/manutencao',
  lpSaraivaJur: '/saraiva-jur',
  ebooks: '/meus-ebooks'
}
