import { makeStyles } from '@material-ui/core'

const mobileBottomNavigationHeight = '56px'
const mobileHeaderHeight = '56px'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: '100%',
    alignItems: 'flex-start',
    padding: '16px 0 36px 0'
  },
  categoryList: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',

    '& .MuiListItem-root': {
      ...theme.typography.body1,
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
      padding: '6px 16px',

      '&:focus, &:hover': {
        backgroundColor: theme.palette.action.hover
      }
    }
  },
  subCategoriesList: {
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: `calc(100% - ${mobileBottomNavigationHeight})`,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'inset 0 -4px 4px rgba(0, 0, 0, 0.25)',
    overflow: 'auto',
    zIndex: theme.zIndex.drawer,
    paddingTop: mobileHeaderHeight,

    '& .MuiListSubheader-root': {
      padding: '16px 0',
      marginBottom: '16px',
      backgroundColor: theme.palette.grey[100],

      '& button': {
        display: 'flex',
        alignItems: 'center',
        ...theme.typography.button,
        fontSize: '0.938rem',
        lineHeight: '26px',
        border: 'none',
        background: 'none',
        color: theme.palette.primary.main,
        outline: 'transparent',
        WebkitTapHighlightColor: 'transparent',
        padding: '8px 16px'
      }
    },

    '& .MuiListItem-root': {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      padding: '6px 16px',

      '&:focus, &:hover': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }
}))

export default useStyles
