import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'
import { StarRounded } from '@material-ui/icons'
import { Rating } from '@material-ui/lab'

import { useRatingStyles } from './styles'

export function CardRating({ rating }) {
  const ratingClasses = useRatingStyles()

  return (
    <Box className={ratingClasses.ratingWrapper} data-testid="card-item-rating">
      <Rating
        className={ratingClasses.rating}
        name="rating-books"
        value={rating.rating}
        readOnly
        icon={<StarRounded className={ratingClasses.starRounded} />}
      />

      <Typography
        className={ratingClasses.totalComments}
        variant="body2"
        color="textSecondary"
        component="span"
      >
        ({rating.total_comments || 0})
      </Typography>
    </Box>
  )
}

CardRating.propTypes = {
  rating: PropTypes.shape({
    book_id: PropTypes.number,
    rating: PropTypes.number,
    total_comments: PropTypes.number
  })
}

CardRating.defaultProps = {
  rating: {
    book_id: null,
    rating: 0,
    total_comments: 0
  }
}
