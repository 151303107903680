import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './style'
import notFoundImage from '../../../static/notFound.png'
import { getDeviceType } from '../../../utils'

export default function NotFound() {
  const classes = useStyles()

  function variantTypographyByDevice() {
    if (getDeviceType() === 'mobile') return 'h6'
    return 'h5'
  }

  return (
    <>
      <div className={classes.containerNotFound} data-testid="catalog-notfound">
        <img
          src={notFoundImage}
          className={classes.imageNotFound}
          alt="Livro não enconrado"
        />
        <Box className={classes.titleContent}>
          <Typography
            variant={variantTypographyByDevice()}
            className={classes.title}
            data-testid="catalog-notfound-title"
          >
            Nenhum resultado foi encontrado
          </Typography>
        </Box>
        <Box className={classes.subTitleContent}>
          <Typography
            variant="subtitle1"
            className={classes.subtitle}
            data-testid="catalog-notfound-subtitle"
          >
            Tente buscar por outro título, autor ou ISBN
          </Typography>
        </Box>
      </div>
    </>
  )
}
