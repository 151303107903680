/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { PrivacyFooter } from '@grupogen/saraiva-ui'
import { useContextSelector } from 'use-context-selector'
import Header from './Header'
import MenuDrawer from './Drawer'

import {
  CoockieBar,
  DialogLogin,
  Interests,
  Loading,
  Preferences,
  SnackBar,
  SupportButtonForm
} from '../components'

import { GeneralContext } from '../contexts/GeneralContext'
import { redirectToLogin, isAuthenticated } from '../auth'
import Links from '../config/Links'
import { TrackingsService } from '../services'

import { useStyles, noMenuStyles, useFixedMobileHeader } from './styles'

import { GlobalContext } from '../contexts/GlobalContext'
import {
  getInterestsNotificationAction,
  getProfileTypeAction
} from '../contexts/actions'
import { dateIsUpToDate, formatEnDate } from '../utils'
import { useSessionStorage } from '../hooks'

export default function Layout({ children, showMenu }) {
  // Contexts
  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setInfoMessage,
    infoMessage,
    setErrorMessage
  } = useContext(GeneralContext)

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => {
      return state
    }
  )

  const { data: userProfileType, loading: userProfileTypeLoading } =
    globalState.userProfileType

  const {
    data: userInterestsNotification,
    loading: userInterestsNotificationLoading
  } = globalState.userInterestsNotification

  // States
  const [isOpenSandwich, setIsOpenSandwich] = useState(false)
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)
  const [viewInterests, setViewInterests] = useSessionStorage(
    'viewInterests',
    false
  )

  const [bottomNavLayout, setBottomNavLayout] = useState({
    showSearch: false,
    hideBottomBar: false,
    hideNotifications: false,
    hideUser: false,
    expandMiddleWidget: false,
    showMobileDrawerSearch: false
  })

  const [bottomNavItemsControl, setBottomNavItemsControl] = useState({
    isHomeActive: false,
    isSearchActive: false,
    isCategoriesActive: false
  })

  // React Router
  const history = useHistory()
  const location = useLocation()

  // Generic variables
  const { scrollTo, localStorage } = window

  // Styles
  const classes = useStyles()
  const noMenuClasses = noMenuStyles()
  const fixedMobileHeader = useFixedMobileHeader()

  const layoutContainerClasses = showMenu
    ? classes.layoutContainer
    : noMenuClasses.layoutContainer

  const contentClasses = showMenu
    ? classes.contentContainer
    : noMenuClasses.contentContainer

  const privacyFooterClasses = showMenu
    ? classes.privacyFooter
    : noMenuClasses.privacyFooter

  // Renders
  const snackBarsList = [
    {
      severity: 'success',
      message: successMessage,
      handleClose: () => setSuccessMessage('')
    },
    {
      severity: 'error',
      message: errorMessage,
      handleClose: () => setErrorMessage('')
    },
    {
      severity: 'info',
      message: infoMessage,
      handleClose: () => setInfoMessage('')
    }
  ]

  // Handlers
  const showSearchFromBottomNav = () => {
    setBottomNavLayout((prev) => ({
      ...prev,
      hideBottomBar: true,
      hideNotifications: true,
      showSearch: true,
      hideUser: true,
      expandMiddleWidget: true
    }))
  }

  const hideSearchFromBottomNav = () => {
    setBottomNavLayout((prev) => ({
      ...prev,
      hideBottomBar: false,
      hideNotifications: false,
      showSearch: false,
      hideUser: false,
      expandMiddleWidget: false,
      showMobileDrawerSearch: false
    }))
  }

  const handleShowMobileDrawerSearch = () => {
    setBottomNavLayout((prev) => ({
      ...prev,
      showMobileDrawerSearch: true
    }))
  }

  const handleOnSearch = (termToSearch) => {
    const { filterName, filterTerm } = termToSearch

    if (filterName === 'todos') {
      history.push(`/busca?q=${filterTerm}`)
    } else {
      history.push(`/busca?${filterName}=${filterTerm}`)
    }

    scrollTo(0, 0)
  }

  const handleToggleMobileDrawer = () => {
    history.push('/categories')
    setIsMobileDrawerOpen(true)
  }

  const onExit = () => {
    const { REACT_APP_SSO_URL } = process.env
    localStorage.removeItem('saToken')
    localStorage.removeItem('sessionToken')
    localStorage.removeItem('lgpd')
    sessionStorage.removeItem('storedNotifications')
    const locationUpdated = `${REACT_APP_SSO_URL}/logout?previousUrl=${window.location.origin}`
    window.location.assign(locationUpdated)
  }

  const onEnter = () => {
    redirectToLogin()
  }

  const onClickPrivacyLink = async () => {
    await TrackingsService.sendTrack('politicaPrivacidade')
  }

  const hasInterestsNotification = (date) => {
    return dateIsUpToDate(formatEnDate(date))
  }

  const hasInterests = () => {
    if (userProfileType.length) {
      const { interests } = userProfileType[0]

      return Boolean(interests.length)
    }

    return false
  }

  const shouldRenderInterests = (notification) => {
    if (
      notification &&
      !viewInterests &&
      hasInterestsNotification(notification.next_notification_date) &&
      !hasInterests()
    ) {
      return true
    }

    return false
  }

  useEffect(() => {
    const { pathname } = location

    switch (pathname) {
      case '/categories':
        setBottomNavItemsControl((prev) => ({
          ...prev,
          isHomeActive: false,
          isSearchActive: false,
          isCategoriesActive: true
        }))
        setIsMobileDrawerOpen(true)
        break
      case Links.home:
        setBottomNavItemsControl((prev) => ({
          ...prev,
          isHomeActive: true,
          isSearchActive: false,
          isCategoriesActive: false
        }))
        setIsMobileDrawerOpen(false)
        break
      default:
        setBottomNavItemsControl((prev) => ({
          ...prev,
          isHomeActive: false,
          isSearchActive: false,
          isCategoriesActive: false
        }))
        setIsMobileDrawerOpen(false)
        break
    }
  }, [location, setBottomNavItemsControl])

  useEffect(() => {
    if (isAuthenticated()) {
      getProfileTypeAction(globalDispatch)
      getInterestsNotificationAction(globalDispatch)
    }
  }, [globalDispatch])

  if (isAuthenticated()) {
    if (userInterestsNotificationLoading || userProfileTypeLoading) {
      return <Loading />
    }

    if (!userProfileType.length) {
      return <Preferences />
    }

    if (shouldRenderInterests(userInterestsNotification)) {
      return <Interests setViewInterests={setViewInterests} />
    }
  }

  return (
    <>
      <Grid
        data-testid="layout-grid-container"
        container
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        {location.pathname === '/' && (
          <>
            <div className={classes.customBackground} />
            <div className={classes.customBackgroundBaixo} />
          </>
        )}

        <Grid
          item
          className={
            isMobileDrawerOpen
              ? `${classes.layoutHeader} ${fixedMobileHeader.root}`
              : classes.layoutHeader
          }
        >
          {window.location.pathname.indexOf('epub') !== 1 && (
            <Header
              showMenu={showMenu}
              bottomNavLayout={bottomNavLayout}
              showSearchFromBottomNav={showSearchFromBottomNav}
              onBackDropClick={hideSearchFromBottomNav}
              isOpenSandwich={isOpenSandwich}
              setIsOpenSandwich={setIsOpenSandwich}
              searchCallback={handleOnSearch}
              closeBottomNavigation={hideSearchFromBottomNav}
              onLogin={onEnter}
              onLogout={onExit}
              bottomNavItemsControl={bottomNavItemsControl}
              handleToggleMobileDrawer={handleToggleMobileDrawer}
              handleShowMobileDrawerSearch={handleShowMobileDrawerSearch}
            />
          )}
        </Grid>

        {showMenu ? (
          <Grid data-testid="layout-grid-drawers">
            <MenuDrawer
              isMobileDrawerOpen={isMobileDrawerOpen}
              toggleDrawer={handleToggleMobileDrawer}
            />
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          className={
            window.location.pathname.indexOf('epub') !== 1
              ? layoutContainerClasses
              : noMenuClasses.containerReader
          }
        >
          <div className={contentClasses}>{children}</div>
        </Grid>

        {window.location.pathname.indexOf('epub') !== 1 && (
          <footer
            data-testid="layout-footer"
            className={privacyFooterClasses}
            aria-hidden="true"
            onClick={onClickPrivacyLink}
          >
            <PrivacyFooter />
          </footer>
        )}
      </Grid>

      {snackBarsList.map((snack) => (
        <SnackBar
          severity={snack.severity}
          show={!!snack.message}
          handleClose={snack.handleClose}
        >
          {snack.message}
        </SnackBar>
      ))}

      <CoockieBar />
      {window.location.pathname.indexOf('epub') !== 1 && <SupportButtonForm />}
      <DialogLogin />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showMenu: PropTypes.bool
}

Layout.defaultProps = {
  showMenu: true
}
