import { getSessionToken, isAuthenticated } from '../auth'

export const TeacherService = {
  getHeaders() {
    let headers = {}

    if (isAuthenticated()) {
      headers = {
        'session-token': getSessionToken()
      }
    }

    return {
      'application-token': process.env.REACT_APP_TOKEN,
      ...headers
    }
  },

  async uploadFile(payload) {
    const self = this
    const url = `${process.env.REACT_APP_FUSION_ORIGIN}/accounts/api/v1/receipt_of_teacher`
    const response = fetch(url, {
      method: 'POST',
      headers: self.getHeaders(),
      body: payload
    })

    return response
  }
}
