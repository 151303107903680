import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Toolbar, List, ListItem, ListSubheader } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import useStyles from './styles'
import { TrackingsService } from '../../../services'

export default function MobileDrawer({ toggleDrawer, categories }) {
  const classes = useStyles()
  const history = useHistory()
  const [subCategory, setSubCategory] = useState('')

  const changeCategory = (categoria, subcategoria = null) => {
    let path = `/categoria/${encodeURIComponent(categoria)}`
    let event = 'subCategoria'

    if (subcategoria) {
      path += `/${encodeURIComponent(subcategoria)}`
      event = 'subCategoriaEsubCategoria'
    }

    toggleDrawer()

    TrackingsService.sendTrack(
      event,
      {},
      {
        categoria,
        subcategoria
      }
    )

    history.push(path)

    window.scrollTo(0, 0)
  }

  const openSubCategory = (categoryName) => {
    setSubCategory(categoryName)
  }

  const closeSubCategory = (event) => {
    event.stopPropagation()
    setSubCategory('')
  }

  const renderCategoriesList = () => {
    const categoryList = categories.map((category) => {
      if (!category.subcategoria) {
        return (
          <ListItem
            key={category.id}
            onClick={() => changeCategory(category.name)}
          >
            {category.name}
          </ListItem>
        )
      }

      return (
        <ListItem
          key={category.id}
          onClick={() => openSubCategory(category.name)}
          className={classes.itemHasSubCategories}
        >
          {category.name}
          <ArrowRightIcon color="action" />

          <List
            style={{ display: subCategory === category.name ? 'flex' : 'none' }}
            className={classes.subCategoriesList}
            subheader={
              <ListSubheader component="div" id="subcategories-subheader">
                <button type="button" onClick={closeSubCategory}>
                  <ChevronLeftIcon color="primary" />
                  <span>Voltar</span>
                </button>
              </ListSubheader>
            }
          >
            {category.subcategoria && category.subcategoria.length > 1 && (
              <ListItem
                key="ver-todas"
                onClick={() => changeCategory(category.name)}
              >
                Ver todas
              </ListItem>
            )}
            {category.subcategoria.map((subcategory) => (
              <ListItem
                key={subcategory.id}
                onClick={() => changeCategory(category.name, subcategory.name)}
              >
                {subcategory.name}
              </ListItem>
            ))}
          </List>
        </ListItem>
      )
    })

    return categoryList
  }

  return (
    <Toolbar className={classes.toolbar} disableGutters>
      {categories && categories.length ? (
        <List className={classes.categoryList} disablePadding>
          {renderCategoriesList()}
        </List>
      ) : null}
    </Toolbar>
  )
}

MobileDrawer.propTypes = {
  toggleDrawer: PropTypes.func,
  categories: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    ordem: PropTypes.number.isRequired,
    subcategoria: PropTypes.arrayOf({
      id: PropTypes.number,
      name: PropTypes.string,
      ordem: PropTypes.number
    })
  })
}

MobileDrawer.defaultProps = {
  toggleDrawer: () => {},
  categories: []
}
