import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useContextSelector } from 'use-context-selector'
import { useReactToPrint } from 'react-to-print'
import { Typography } from '@material-ui/core'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import {
  getLibraryTreeviewArticlesAction,
  getLibraryTreeviewArticleContentAction,
  getLibraryTreeviewMenuAction,
  getLibraryTreeviewNormsYearAction,
  getLibraryTreeviewNormsAction
} from '../../../../contexts/actions'
import { getDeviceType } from '../../../../utils'
import { TrackingsService } from '../../../../services'

import useStyles, { contentCSS } from './styles'
import { Loading } from '../../../../components'
import PrintContent from '../PrintContent'
import Menu from './Menu'
import DefaultContent from './DefaultContent'

const AdditionalLegislation = ({ book }) => {
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (libraryTreeview) => libraryTreeview
  )
  const { menu, years, norms, articles, loading } = globalState.libraryTreeview

  const componentRef = useRef()
  const classes = useStyles()
  const componentContentRef = useRef()
  const contentRef = useRef()
  const [articleHtml, setArticleHtml] = useState()
  const [loadingArticle, setLoadingArticle] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const handleContentPrint = useReactToPrint({
    content: () => componentContentRef.current
  })

  const handleGetNormsYears = async (menuData) => {
    getLibraryTreeviewNormsYearAction(globalDispatch, menuData.id)
  }

  const handleGetArticles = async (norm) => {
    getLibraryTreeviewArticlesAction(globalDispatch, norm.id)
  }

  const handleGetNorms = async (data) => {
    getLibraryTreeviewNormsAction(globalDispatch, data)
  }

  const getTackingData = (parentData) => {
    let data = []
    if (parentData.parentData) {
      data.push(parentData.name ? parentData.name : parentData.title)

      if (parentData.year) {
        data.push(parentData.year)
      }

      data = [...data, ...getTackingData(parentData.parentData)]
    } else {
      data.push(parentData.name ? parentData.name : parentData.title)

      if (parentData.year) {
        data.push(parentData.year)
      }
    }

    return data
  }

  const handlePrepareArticle = async (article) => {
    setLoadingArticle(true)

    const tranckingData = getTackingData(article)
    const tranckingDataFormated = {}

    tranckingData.reverse().forEach((data, key) => {
      tranckingDataFormated[`nome_arvore_${key + 1}`] = data
    })

    await TrackingsService.sendTrack('legislacaoAdicionalConteudo', book, {
      ...tranckingDataFormated,
      children: null
    })
    const { data: articleHTML } = await getLibraryTreeviewArticleContentAction(
      globalDispatch,
      article,
      articles
    )
    setArticleHtml(articleHTML)
    setLoadingArticle(false)
  }

  const contentRender = () => {
    if (loadingArticle) {
      return <Loading />
    }

    return (
      <>
        {articleHtml && (
          <div
            id="append-artcile"
            ref={contentRef}
            style={{
              marginBottom: 28,
              maxHeight: 500,
              maxWidth: 580
            }}
          >
            <PrintContent
              contentData={{
                content: contentCSS + articleHtml
              }}
              componentRef={componentContentRef}
              handlePrint={handleContentPrint}
              contentLoading={false}
              handleTrackingPrint={() => null}
            />
          </div>
        )}

        <DefaultContent
          contentData="contentData"
          componentRef={componentRef}
          handlePrint={handlePrint}
          contentLoading={false}
          handleTrackingPrint={() => null}
        />
      </>
    )
  }

  useEffect(() => {
    getLibraryTreeviewMenuAction(globalDispatch)
  }, [globalDispatch])

  if (!menu.length) {
    return null
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography
          variant={getDeviceType() === 'mobile' ? 'h6' : 'h5'}
          className={classes.sectionTitle}
        >
          Legislação Adicional
        </Typography>
      </div>
      <div>
        <Typography variant="body1" className={classes.sectionSubTitle}>
          Acesse o índice para encontrar a legislação desejada
        </Typography>
      </div>
      <div className={classes.sectionContent}>
        <Menu
          menu={menu}
          years={years}
          norms={norms}
          articles={articles}
          loading={loading}
          handleGetNormsYears={handleGetNormsYears}
          handleGetNorms={handleGetNorms}
          handleGetArticles={handleGetArticles}
          handlePrepareArticle={handlePrepareArticle}
        />
        <div style={{ flex: 1 }}>{contentRender()}</div>
      </div>
    </div>
  )
}

AdditionalLegislation.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default AdditionalLegislation
