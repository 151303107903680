import {
  UPLOAD_TEACHER_FILE_LOADING,
  UPLOAD_TEACHER_FILE_SUCCESS,
  UPLOAD_TEACHER_FILE_ERROR
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const uploadTeacherFileReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    UPLOAD_TEACHER_FILE_LOADING,
    UPLOAD_TEACHER_FILE_SUCCESS,
    UPLOAD_TEACHER_FILE_ERROR
  ])
}

export default uploadTeacherFileReducer
