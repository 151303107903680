import React from 'react'
import { Card, Typography } from '@material-ui/core'
import useStyles from './styles'
import { getDeviceType } from '../../../../../utils'

const DefaultContent = () => {
  const classes = useStyles()

  return (
    <Card className={classes.emptySelection} variant="outlined">
      <Typography variant="body1">
        Acesse o índice {getDeviceType() === 'desktop' ? 'ao lado' : 'abaixo'}{' '}
        para encontrar o conteúdo desejado
      </Typography>
      <Typography variant="body2" className={classes.textSecondary}>
        A atualização de código será realizada até o mês de outubro do ano
        vigente
      </Typography>
    </Card>
  )
}

export default DefaultContent
