import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'

import { useLocation } from 'react-router-dom'
import { GeneralContext } from '../../contexts/GeneralContext'
import { useLibrary } from '../../hooks'

import {
  useStyles,
  useCardStyles,
  useMediaStyles,
  useContentStyles
} from './styles'

import { SEO, Loading, CustomCard, DialogActivateBook } from '../../components'
import EmptyLibrary from './EmptyLibrary'
import { ContentsService } from '../../services'

export default function Library() {
  const classes = useStyles()
  const location = useLocation()
  const { from } = location.state || {}

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const { libraryBooks, libraryBooksLoading } = useLibrary(
    setSuccessMessage,
    setErrorMessage
  )

  let materialLibrary = []

  const [mock, setMock] = useState([])

  if (libraryBooks && libraryBooks.products.length) {
    materialLibrary = libraryBooks.products.filter(
      (data) => !data.perfil.includes('ebook')
    )
  }

  useEffect(() => {
    async function fetchMock() {
      try {
        const tempMocks = await Promise.all(
          materialLibrary.map(async (mocks) => {
            const response = await ContentsService.getMocks(mocks.productCaeId)
            const result = await response.json()
            return result
          })
        )
        setMock(tempMocks)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
    fetchMock()
  }, [])

  const assistArray = useMemo(
    () =>
      mock
        .filter((array) => array.length > 0)
        .flatMap((array) => array)
        .flatMap((array) => array.cae_ids),
    [mock]
  )

  const newMockArray = useMemo(
    () =>
      libraryBooks?.products.filter((data) =>
        assistArray.includes(data.productCaeId)
      ),
    [assistArray, libraryBooks?.products]
  )

  const uniqueObjectsMap = {}
  const combinedArray = materialLibrary.concat(newMockArray || [])

  const materialLibraryWithMocks = combinedArray.filter((obj) => {
    if (!uniqueObjectsMap[obj.productCaeId]) {
      uniqueObjectsMap[obj.productCaeId] = true
      return true
    }
    return false
  })

  const renderLibrary = () => {
    if (
      libraryBooks &&
      Boolean(libraryBooks.products.length) &&
      materialLibraryWithMocks.length
    ) {
      return true
    }

    return false
  }

  const cardClasses = useCardStyles()
  const imageClasses = useMediaStyles()
  const contentClasses = useContentStyles()

  if (libraryBooksLoading && materialLibraryWithMocks.length === 0) {
    return <Loading />
  }

  return (
    <div className={classes.containerBox}>
      <SEO title="Saraiva Conecta - Biblioteca" description="Meus Livros" />

      <Typography
        variant="h6"
        className={classes.title}
        data-testid="library-title"
      >
        Meus materiais complementares
      </Typography>

      <Typography variant="body1" className={classes.subTitle}>
        Aqui você poderá acessar o material complementar de seus livros.
      </Typography>

      <Divider className={classes.divider} />

      <DialogActivateBook />

      {renderLibrary() ? (
        <>
          <div className={classes.containerLibrary} data-testid="library-items">
            <Grid container>
              {materialLibraryWithMocks.map((book) => (
                <Grid
                  item
                  key={book.productCaeId}
                  className={classes.grid}
                  data-testid="library-item"
                >
                  <CustomCard
                    book={book}
                    totalItems={materialLibraryWithMocks.length}
                    libraryBooks={materialLibraryWithMocks}
                    imageHoverText={
                      from === 'biblioteca' ? 'LER LIVRO' : 'MATERIAIS'
                    }
                    cardClass={cardClasses}
                    imageClass={imageClasses}
                    contentClass={contentClasses}
                    showFavorite={false}
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          <div className={classes.containerDivider}>
            <Divider className={classes.divider} />
          </div>
        </>
      ) : (
        <EmptyLibrary />
      )}
    </div>
  )
}
