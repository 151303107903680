import {
  LIBRARY_TREEVIEW_LOADING,
  LIBRARY_TREEVIEW_MENU_SUCCESS,
  LIBRARY_TREEVIEW_ERROR
} from '../../../../utils/actionTypes'
import { LibraryTreeviewService } from '../../../../services'

export const getLibraryTreeviewMenuAction = async (dispatch) => {
  dispatch({
    type: LIBRARY_TREEVIEW_LOADING
  })

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item]
      }),
      {}
    )

  const treeviewResponse = await LibraryTreeviewService.getMenu()
  const treeviewData = await treeviewResponse.json()

  const dataGroup = groupBy(treeviewData, 'divisao_produto_titulo')

  if (treeviewResponse.ok) {
    dispatch({
      type: LIBRARY_TREEVIEW_MENU_SUCCESS,
      payload: Object.values(dataGroup)
    })
  } else {
    dispatch({
      type: LIBRARY_TREEVIEW_ERROR,
      payload: treeviewData.error
    })
  }

  return {
    ok: treeviewResponse.ok
  }
}
