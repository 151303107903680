import { makeStyles } from '@material-ui/core/styles'
import planoDeFundo from '../static/planoDeFundo.png'
import planoDeFundoBaixo from '../static/planoDeFundoBaixo.png'

export const useStyles = makeStyles((theme) => ({
  customBackground: {
    backgroundImage: `url(${planoDeFundo})`,
    position: 'absolute',
    top: '10%',
    left: '55%',
    width: 436,
    height: 262,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    zIndex: 0,

    [theme.breakpoints.up('sm')]: {
      top: '0%',
      left: '70%',
      width: 536,
      height: 322
    },

    [theme.breakpoints.up('md')]: {
      top: '-4%',
      left: '57%',
      width: 926,
      height: 557,
      position: 'absolute',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }
  },

  customBackgroundBaixo: {
    backgroundImage: `url(${planoDeFundoBaixo})`,
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 436,
    height: 262,
    top: '40%',
    left: '-47%',

    [theme.breakpoints.up('sm')]: {
      top: '46%',
      left: '-17%',
      width: 536,
      height: 322
    },
    [theme.breakpoints.up('md')]: {
      width: 1198,
      height: 720,
      top: '25%',
      left: '-16%'
    }
  },

  layoutContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(11, 2, 12.5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 2, 10, 18)
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(15, 2, 12.5, 44)
    }
  },
  layoutHeader: {
    zIndex: theme.zIndex.drawer + 1
  },
  contentContainer: {
    minWidth: 936,
    maxWidth: 936,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    }
  },
  privacyFooter: {
    width: '100%',
    marginLeft: 305,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 106
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginBottom: 56
    }
  },
  checkbox: {
    display: 'flex',
    width: '100%',
    alignItems: 'start',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      fontSize: 10
    }
  },
  text: {
    width: '68%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    marginTop: '24px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%'
    }
  }
}))

export const noMenuStyles = makeStyles((theme) => ({
  layoutContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'space-around',
    'flex-wrap': 'wrap',
    backgroundColor: theme.palette.background.default,
    padding: '190px 140px 0',

    [theme.breakpoints.down('sm')]: {
      padding: '120px 42px 0'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '128px 40px 0'
    }
  },
  contentContainer: {
    minWidth: 1160,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    }
  },
  privacyFooter: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  containerReader: {
    minHeight: '100vh'
  }
}))

export const useFixedMobileHeader = makeStyles(() => ({
  root: {
    '& .MuiAppBar-positionFixed': {
      transform: 'none !important',
      visibility: 'initial !important',
      transition: 'none !important'
    }
  }
}))
