import React, { useEffect, useState } from 'react'
import { CookieBar } from '@grupogen/saraiva-ui'

import { TrackingsService } from '../../services'

import { useStyles } from './styles'

export default function CoockieBar() {
  const [lgpd, setLgpd] = useState(false)
  const { localStorage } = window
  const classes = useStyles()

  const confirmLgpd = () => {
    TrackingsService.sendTrack('okBarraCookie')
    setLgpd(true)
    localStorage.setItem('lgpd', true)
  }

  useEffect(() => {
    setLgpd(localStorage.getItem('lgpd'))
  }, [localStorage])

  return (
    <>
      {!lgpd && (
        <CookieBar
          onClick={() => confirmLgpd()}
          className={classes.cookieBar}
        />
      )}
    </>
  )
}
