import React, { useContext } from 'react'

import { Dialog } from '@grupogen/saraiva-ui'
import { GeneralContext } from '../../contexts/GeneralContext'
import { redirectToLogin } from '../../auth'
import { getDeviceType } from '../../utils'
import { TrackingsService } from '../../services'

export default function DialogLogin() {
  const {
    dialogNotAuthenticated,
    setDialogNotAuthenticated,
    dialogAuthClickTracking,
    setDialogAuthClickTracking
  } = useContext(GeneralContext)

  const handleCloseDialog = () => {
    setDialogNotAuthenticated('')
    setDialogAuthClickTracking('')
  }

  const handleRedirectToLogin = async () => {
    if (dialogAuthClickTracking && dialogAuthClickTracking.length) {
      await TrackingsService.sendTrack(dialogAuthClickTracking)
    }

    setDialogAuthClickTracking('')
    redirectToLogin()
  }

  return (
    <Dialog
      open={Boolean(dialogNotAuthenticated)}
      title="OPS!"
      size={getDeviceType() === 'mobile' ? 'xs' : null}
      secondaryButton="Fechar"
      primaryButton="Fazer Login"
      handleClose={handleCloseDialog}
      handleConfirm={handleRedirectToLogin}
      content="Conteúdo default"
    >
      {dialogNotAuthenticated}
    </Dialog>
  )
}
