/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ModularHeader } from '@grupogen/saraiva-ui'
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core'

import {
  Favorite,
  HomeSharp,
  MenuBook,
  BookRounded,
  ExitToApp,
  SearchSharp,
  Reorder,
  ListAlt
} from '@material-ui/icons'

import {
  SandwichWidget,
  LogoWidget,
  UserMenuWidget,
  BottomBarWidget,
  NotificationWidget,
  SearchBarFilterWidget
} from '@grupogen/saraiva-ui/core/ModularHeader/widgets'

import { useContextSelector } from 'use-context-selector'

import { HelpActivationDialog } from '../../components'
import { NotificationsDialog, SandwichContent } from './components'

import {
  BookService,
  NotificationService,
  TrackingsService,
  Users
} from '../../services'
import { getNotificationsAction } from '../../contexts/actions'

import { isAuthenticated } from '../../auth'
import { Links, Messages } from '../../config'
import { getDefaultFiltersSelects, isTheBookActive } from '../../utils'

import { GlobalContext } from '../../contexts/GlobalContext'
import { GeneralContext } from '../../contexts/GeneralContext'

import { useBooks } from '../../hooks'

import LoginToApp from '../../static/LoginToApp'

import {
  useToolbarStyles,
  useMiddleWidgetStyles,
  useSandwichItemsStyles,
  useSearchBarFilterStyles,
  useNotificationsStyles,
  useUserWidgetStyles,
  useBackdropStyles
} from './styles'

import logoHeaderSaraiva from '../../static/logoHeaderSaraiva.svg'

export default function Header({
  showMenu,
  bottomNavLayout,
  onBackDropClick,
  isOpenSandwich,
  setIsOpenSandwich,
  searchCallback,
  closeBottomNavigation,
  onLogin,
  onLogout,
  bottomNavItemsControl,
  handleShowMobileDrawerSearch,
  handleToggleMobileDrawer,
  showSearchFromBottomNav
}) {
  const history = useHistory()
  const location = useLocation()

  const [openNotificationDialog, setOpenNotificationDialog] = useState(false)
  const [notificationSelected, setNotificationSelected] = useState({})

  const saraivaTheme = useTheme()
  const isMobile = useMediaQuery(saraivaTheme.breakpoints.down('xs'))

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => {
      return state
    }
  )

  const { data: notifications, loading: notificationsLoading } =
    globalState.notifications

  const { setErrorMessage } = useContext(GeneralContext)

  const { libraryBooks } = useBooks(false)

  const redirectToBook = (caeId) => {
    if (isAuthenticated() && isTheBookActive(caeId, libraryBooks)) {
      history.push(`/livro-ativado/${caeId}`)
    } else {
      history.push(`/livro/${caeId}`)
    }
  }

  const setNotification = async (notification) => {
    const { id, type } = notification

    const payload = {
      seen: true
    }
    try {
      if (type !== 'new') {
        await TrackingsService.sendTrack(
          'clicou_notificacao_header',
          {},
          {
            cae_livro: notification.new_cae_id,
            nome_livro: notification.bookTitleCover,
            edicao_livro: notification.bookEdition
          }
        )

        await NotificationService.setNotification(id, payload)
      }
    } finally {
      const updatedNotification = { ...notification, seen: true }
      const notificationsToUpdate = notifications.map((prevNotification) => {
        return { ...prevNotification }
      })
      const indexToUpdate = notificationsToUpdate.findIndex(
        (item) => item.id === updatedNotification.id
      )

      notificationsToUpdate[indexToUpdate].seen = true
      notificationsToUpdate[indexToUpdate].isUnread = false

      getNotificationsAction(globalDispatch, notificationsToUpdate)
      if (type !== 'new') {
        const bookResponse = await BookService.getBook(notification.new_cae_id)

        if (!bookResponse.ok) {
          setErrorMessage(Messages.notifications.inactiveBook)
        } else {
          redirectToBook(notification.new_cae_id)
        }
      }
    }
  }

  const handleClickNotification = (id) => {
    const targetNotification = notifications.find(
      (notification) => notification.id === id
    )

    const { type } = targetNotification

    setNotification(targetNotification)
    if (type === 'new') {
      setNotificationSelected(targetNotification)
      setOpenNotificationDialog(true)
    }
  }

  const useGlobalMenuStyles = makeStyles(() => ({
    '@global': {
      '.MuiBottomNavigation-root': {
        display: showMenu ? 'flex' : 'none'
      }
    }
  }))

  const globalMenuClass = useGlobalMenuStyles()
  const middleWidgetClass = useMiddleWidgetStyles()
  const sandwichItemsClasses = useSandwichItemsStyles()
  const searchBarFilterClasses = useSearchBarFilterStyles()
  const notificationClasses = useNotificationsStyles()
  const userClasses = useUserWidgetStyles()
  const backdropClass = useBackdropStyles()

  const CustomExitToAppIcon = withStyles((theme) => ({
    root: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(1)
    }
  }))(ExitToApp)

  const drawerItems = [
    {
      nodeIcon: (
        <div
          className={
            location.pathname === Links.catalog
              ? `${sandwichItemsClasses.wrapper} ${sandwichItemsClasses.wrapperActive}`
              : sandwichItemsClasses.wrapper
          }
        >
          <ListAlt />
          <span>Catálogo</span>
        </div>
      ),
      label: '',
      onClick: () => {
        setIsOpenSandwich(false)
        window.scrollTo(0, 0)
        history.push(Links.catalog)
      }
    },
    {
      nodeIcon: (
        <div
          className={
            location.pathname === Links.ebooks
              ? `${sandwichItemsClasses.wrapper} ${sandwichItemsClasses.wrapperActive}`
              : sandwichItemsClasses.wrapper
          }
        >
          <BookRounded />
          <span>Meus E-books</span>
        </div>
      ),
      label: '',
      onClick: () => {
        setIsOpenSandwich(false)
        window.scrollTo(0, 0)
        history.push(Links.ebooks)
      }
    },
    {
      nodeIcon: (
        <div
          className={
            location.pathname === Links.library
              ? `${sandwichItemsClasses.wrapper} ${sandwichItemsClasses.wrapperActive}`
              : sandwichItemsClasses.wrapper
          }
        >
          <MenuBook />
          <span>Meus Materiais</span>
        </div>
      ),
      label: '',
      onClick: () => {
        setIsOpenSandwich(false)
        window.scrollTo(0, 0)
        history.push(Links.library)
      }
    },
    {
      nodeIcon: (
        <div
          className={
            window.location.pathname === Links.wishlist
              ? `${sandwichItemsClasses.wrapper} ${sandwichItemsClasses.wrapperActive}`
              : sandwichItemsClasses.wrapper
          }
        >
          <Favorite />
          <span>Lista de Desejos</span>
        </div>
      ),
      label: '',
      onClick: () => {
        setIsOpenSandwich(false)
        window.scrollTo(0, 0)
        history.push(Links.wishlist)
      }
    }
  ]

  const BottomBarItems = isAuthenticated()
    ? [
        {
          label: 'Página inicial',
          onClick: () => history.push(Links.home),
          icon: HomeSharp,
          isActive: bottomNavItemsControl.isHomeActive
        },
        {
          label: 'Buscar',
          onClick: showSearchFromBottomNav,
          icon: SearchSharp,
          isActive: bottomNavItemsControl.isSearchActive
        },
        {
          label: 'Categorias',
          onClick: handleToggleMobileDrawer,
          icon: Reorder,
          isActive: bottomNavItemsControl.isCategoriesActive
        }
      ]
    : [
        {
          label: 'Catálago',
          onClick: () => history.push(Links.catalog),
          icon: ListAlt,
          isActive: bottomNavItemsControl.isHomeActive
        },
        {
          label: 'Buscar',
          onClick: showSearchFromBottomNav,
          icon: SearchSharp,
          isActive: bottomNavItemsControl.isSearchActive
        },
        {
          label: 'Categorias',
          onClick: handleToggleMobileDrawer,
          icon: Reorder,
          isActive: bottomNavItemsControl.isCategoriesActive
        }
      ]

  const filterOptions = getDefaultFiltersSelects()
    .filter((select) => !select.showFilter)
    .map(({ label, values }) => ({ label, value: values[0].value }))

  const leftWidgets = () => {
    const preLoginWidgets = [
      <LogoWidget
        logo={logoHeaderSaraiva}
        onClick={() => history.push(Links.catalog)}
        hide={bottomNavLayout.hideBottomBar}
      />
    ]

    const authenticatedWidgets = [
      <SandwichWidget
        isOpenSandwich={isOpenSandwich}
        drawerItems={drawerItems}
        hide={bottomNavLayout.hideBottomBar}
        hidden={{ smUp: true }}
      >
        <SandwichContent />
      </SandwichWidget>,
      <LogoWidget
        logo={logoHeaderSaraiva}
        onClick={() => history.push(Links.home)}
        hide={bottomNavLayout.hideBottomBar}
        hidden={{ xsDown: true }}
      />
    ]

    return isAuthenticated() ? authenticatedWidgets : preLoginWidgets
  }

  const middleWidgets = () => {
    const widgets = [
      <SearchBarFilterWidget
        filterOptions={filterOptions}
        defaultFilterOption="todos"
        searchPlaceHolder="Buscar por título, autor ou ISBN"
        handleSearchTerm={(value) => searchCallback(value)}
        inputWrapperClass={searchBarFilterClasses.searchWrapper}
        handleShowSearch={handleShowMobileDrawerSearch}
        handleHideSearch={closeBottomNavigation}
        isShowSearch={bottomNavLayout.showMobileDrawerSearch}
        isToHide={!bottomNavLayout.showSearch && isMobile}
      />
    ]

    if (isAuthenticated()) {
      widgets.push(
        <LogoWidget
          logo={logoHeaderSaraiva}
          onClick={() => history.push(Links.home)}
          hide={bottomNavLayout.hideBottomBar}
          hidden={{ smUp: true }}
        />
      )
    }

    return widgets
  }

  const rightWidgets = () => {
    const widgets = [
      <UserMenuWidget
        hide={bottomNavLayout.hideBottomBar}
        email={Users.getEmail()}
        username={Users.getFullName()}
        onLogin={onLogin}
        isUserLogged={isAuthenticated()}
        onClickUserAvatar={() => history.push('/users')}
        items={[
          {
            icon: () => <CustomExitToAppIcon />,
            label: 'Sair',
            onClick: onLogout
          }
        ]}
        logginToAppicon={<LoginToApp />}
        className={userClasses.user}
      />
    ]

    if (isAuthenticated()) {
      widgets.unshift(
        <NotificationWidget
          items={notifications}
          onClickItem={(id) => {
            handleClickNotification(id)
          }}
          hide={bottomNavLayout.hideNotifications}
          className={notificationClasses.notification}
          showBackdrop
          backdropClass={backdropClass}
        />
      )
    }

    return widgets
  }

  const bottomWidgets = () => {
    return [
      <BottomBarWidget
        hidden={{ smUp: true }}
        hide={bottomNavLayout.hideBottomBar}
        items={BottomBarItems}
      />
    ]
  }

  useEffect(() => {
    if (isAuthenticated()) {
      getNotificationsAction(globalDispatch)
    }
  }, [globalDispatch])

  if (notificationsLoading) {
    return null
  }

  return (
    <>
      <ModularHeader
        className={globalMenuClass}
        showBackDrop={bottomNavLayout.showSearch}
        onBackDropClick={onBackDropClick}
        toolbarGap="0"
        toolbarClasses={useToolbarStyles}
        middleWidgetClass={middleWidgetClass.root}
        leftWidgets={leftWidgets()}
        middleWidgets={middleWidgets()}
        rightWidgets={rightWidgets()}
        bottomWidgets={bottomWidgets()}
      />

      <NotificationsDialog
        notification={notificationSelected}
        openNotificationDialog={openNotificationDialog}
        setOpenNotificationDialog={setOpenNotificationDialog}
      />

      <HelpActivationDialog />
    </>
  )
}

Header.propTypes = {
  showMenu: PropTypes.bool,
  onBackDropClick: PropTypes.func,
  bottomNavLayout: PropTypes.shape({
    showSearch: PropTypes.bool.isRequired,
    hideBottomBar: PropTypes.bool.isRequired,
    hideNotifications: PropTypes.bool.isRequired,
    hideUser: PropTypes.bool.isRequired,
    expandMiddleWidget: PropTypes.bool.isRequired,
    showMobileDrawerSearch: PropTypes.bool.isRequired
  }).isRequired,
  isOpenSandwich: PropTypes.bool.isRequired,
  setIsOpenSandwich: PropTypes.func.isRequired,
  searchCallback: PropTypes.func,
  closeBottomNavigation: PropTypes.func,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  bottomNavItemsControl: PropTypes.shape({
    isHomeActive: PropTypes.bool,
    isSearchActive: PropTypes.bool,
    isCategoriesActive: PropTypes.bool
  }),
  showSearchFromBottomNav: PropTypes.func,
  handleShowMobileDrawerSearch: PropTypes.func,
  handleToggleMobileDrawer: PropTypes.func
}

Header.defaultProps = {
  showMenu: true,
  onBackDropClick: () => null,
  searchCallback: () => null,
  closeBottomNavigation: () => null,
  onLogin: () => null,
  onLogout: () => null,
  bottomNavItemsControl: {
    isHomeActive: false,
    isSearchActive: false,
    isCategoriesActive: false
  },
  showSearchFromBottomNav: () => null,
  handleShowMobileDrawerSearch: () => null,
  handleToggleMobileDrawer: () => null
}
