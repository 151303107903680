import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  titleCatalog: {
    marginBottom: theme.spacing(1),
    ...theme.typography.h6,
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  subTitle: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
    ...theme.typography.body1,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.text.secondary,
      marginTop: 56
    }
  },
  activeBookWrapper: {
    marginBottom: theme.spacing(2.5),

    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  helpActivationBook: {
    marginTop: theme.spacing(1)
  },
  activeBookDivider: {
    marginTop: theme.spacing(1)
  }
}))
