/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Dialog } from '@grupogen/saraiva-ui'
import { FormControl, InputLabel, makeStyles, Select, Typography } from '@material-ui/core'
import Questao from './MockExercices'
import { GeneralContext } from '../../../../contexts/GeneralContext'
import { TrackingsService, Users } from '../../../../services'

const useStyles = makeStyles(() => ({
  select: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1C4774'
    }
  },
  label: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#1C4774',
      fontWeight: 500,
      fontSize: 20
    }
  },
  dialogSubtitle: {
    color: '#624C92'
  },
  dialogText: {
    marginTop: 8,
    marginBottom: 16
  }
}))
export default function ListMocks({ mocksList }) {
  const classes = useStyles()
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedMock, setSelectedMock] = useState()
  const [disableNextMock, setDisableNextMock] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [showNextMock, setShowNextMock] = useState(false)
  const [answeredQuestions, setAnsweredQuestions] = useState({})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [selectedAlternative, setSelectedAlternative] = useState(null)
  const [userAnswers, setUserAnswers] = useState({})
  const [leftMockPopover, setLeftMockPopover] = useState(false)
  const [auxMockSelected, setAuxMockSelected] = useState({})

  const { setOpenMockPopover } = useContext(GeneralContext)

  const handleCloseDialog = () => {
    setLeftMockPopover(false)
  }

  const handleConfirmDialog = () => {
    setSelectedOption(auxMockSelected.title)
    setSelectedMock(mocksList.find((mock) => mock.title === auxMockSelected.title))
    setShowResults(false)
    setShowNextMock(false)
    setCurrentQuestionIndex(0)
    setSelectedAlternative(null)
    setLeftMockPopover(false)
  }

  const handleChange = (event) => {
    setLeftMockPopover(false)
    if (selectedOption === '') {
      setSelectedOption(event.target.value)
      setSelectedMock(mocksList.find((mock) => mock.title === event.target.value))
      TrackingsService.sendTrack('acessou_simulados', {}, {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      })
    }
    if (showResults && selectedOption) {
      setSelectedOption(event.target.value)
      setSelectedMock(mocksList.find((mock) => mock.title === event.target.value))
      setShowResults(false)
      setShowNextMock(false)
      setCurrentQuestionIndex(0)
      setSelectedAlternative(null)
      setLeftMockPopover(false)
    } if (!showResults && selectedOption) {
      setLeftMockPopover(true)
      setAuxMockSelected(mocksList.find((mock) => mock.title === event.target.value))
    }
    TrackingsService.sendTrack('navegou_simulados', {}, {
      user_name: Users.getFullName(),
      user_email: Users.getEmail()
    })
  }

  useEffect(() => {
    if (Object.keys(answeredQuestions).length === 0) {
      setOpenMockPopover(false)
    }
    if (!showResults && Object.keys(answeredQuestions).length !== 0) {
      setOpenMockPopover(true)
    } else {
      setOpenMockPopover(false)
    }

  }, [answeredQuestions, setOpenMockPopover, showResults])


  useEffect(() => {
    const currentIndex = mocksList.findIndex((mock) => mock.title === selectedOption)
    if (currentIndex === mocksList.length - 1) {
      setDisableNextMock(true)
    }

    if (currentIndex !== -1 && currentIndex < mocksList.length - 1) {
      setUserAnswers({})
      setAnsweredQuestions({})
      setCurrentQuestionIndex(0)
      setSelectedAlternative(null)
      setDisableNextMock(false)
    }
    setShowResults(false)
    setShowNextMock(false)
  }, [mocksList, selectedOption, leftMockPopover])

  const handleNextMock = () => {
    const currentIndex = mocksList.findIndex((mock) => mock.title === selectedOption)
    if (currentIndex === mocksList.length - 1) {
      setDisableNextMock(true)
    }

    if (currentIndex !== -1 && currentIndex < mocksList.length - 1) {
      const nextMock = mocksList[currentIndex + 1]
      setSelectedOption(nextMock.title)
      setSelectedMock(nextMock)

      setUserAnswers({})
      setAnsweredQuestions({})
      setCurrentQuestionIndex(0)
      setSelectedAlternative(null)
    }
    setShowResults(false)
    setShowNextMock(false)
  }

  return (
    <>
      {leftMockPopover && (
        <Dialog
          open={leftMockPopover}
          title="Sair do atual simulado"
          secondaryButton="Cancelar"
          primaryButton="Sair"
          label="Tem certeza que deseja sair do simulado?"
          handleClose={handleCloseDialog}
          handleConfirm={handleConfirmDialog}
        >
          <div>
            <Typography>
              Tem certeza que deseja sair do simulado?
            </Typography>

            <Typography className={classes.dialogText}>
              Ao sair sem finalizar as questões, seu progresso será perdido!
            </Typography>

            <Typography className={classes.dialogSubtitle}>
              Dica: Responda todas as questões e clique em VER RESULTDOS, assim suas respostas serão salvas.
            </Typography>
          </div>
        </Dialog>
      )}
      <FormControl variant="outlined" fullWidth className={classes.select}>
        <InputLabel
          htmlFor="my-select"
          style={{ color: '#1C4774', fontWeight: 500, fontSize: 20 }}
        >
          Selecione o simulado
        </InputLabel>
        <Select
          value={selectedOption}
          onChange={handleChange}
          variant="outlined"
          label="Selecione o simuladocomp"
          inputProps={{ id: 'my-select' }}
          className={classes.label}
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {mocksList.map((title) => (
            <MenuItem
              key={title.title}
              value={title.title}
            >
              {title.title}
            </MenuItem>
          ))}
        </Select>
        {selectedOption &&
          <Questao
            mockId={selectedMock.id}
            onNextMock={handleNextMock}
            disabledNextMock={disableNextMock}
            showResults={showResults}
            setShowResults={setShowResults}
            showNextMock={showNextMock}
            setShowNextMock={setShowNextMock}
            answeredQuestions={answeredQuestions}
            setAnsweredQuestions={setAnsweredQuestions}
            currentQuestionIndex={currentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            selectedAlternative={selectedAlternative}
            setSelectedAlternative={setSelectedAlternative}
            userAnswers={userAnswers}
            setUserAnswers={setUserAnswers}
          />
        }
      </FormControl>
    </>
  )
}
ListMocks.propTypes = {
  mocksList: PropTypes.instanceOf(Array).isRequired
}
