import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  menuHelp: {
    width: '100%',
    justifyContent: 'left',
    display: 'flex',
    alignItems: 'center',
    padding: (props) => (props.column ? 0 : theme.spacing(1.5, 0)),

    '& .MuiButton-label': {
      flexDirection: (props) => (props.column ? 'column' : 'row')
    }
  },
  helpIcon: {
    width: 24,
    height: 24
  },
  menuHelpText: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    textTransform: 'none',
    marginLeft: (props) => (props.column ? 0 : theme.spacing(2.5))
  }
}))

export default useStyles
