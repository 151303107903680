import { makeStyles } from '@material-ui/core'

const mobileBottomNavigationHeight = '56px'
const mobileHeaderHeight = '56px'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${mobileBottomNavigationHeight})`,
      paddingTop: mobileHeaderHeight,
      zIndex: `${theme.zIndex.drawer} !important`,

      '& .MuiDrawer-paper': {
        width: '100%',
        height: `calc(100% - ${mobileBottomNavigationHeight})`,
        boxShadow: 'inset 0 -4px 4px rgba(0, 0, 0, 0.25)',
        paddingTop: mobileHeaderHeight
      }
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper
    }
  }
}))

export default useStyles
