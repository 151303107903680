import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { Button } from '@grupogen/saraiva-ui'
import { useStyles } from './styles'

export default function WelcomeStep({ setCurrentStep }) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.wrapper}>
        <Typography component="h1" className={classes.title}>
          Boas-vindas ao Saraiva Conecta!
        </Typography>

        <Typography component="h2" className={classes.subTitle}>
          Ajude-nos a personalizar a sua experiência na nossa plataforma.
        </Typography>

        <Typography component="p" className={classes.text}>
          Suas informações ficarão seguras, de acordo com nossa{' '}
          <a
            href="https://www.canaldatransparencia.com.br/cogna/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            política de privacidade.
          </a>
        </Typography>
      </Box>

      <Box className={classes.buttonWrapper}>
        <Button
          color="primary"
          onClick={() => setCurrentStep('profile')}
          variant="contained"
        >
          começar
        </Button>
      </Box>
    </>
  )
}

WelcomeStep.propTypes = {
  setCurrentStep: PropTypes.func.isRequired
}
