import {
  LAST_BOOKS_LOADING,
  LAST_BOOKS_SUCCESS,
  LAST_BOOKS_ERROR
} from '../../../utils/actionTypes'
import { UserService } from '../../../services'

export const getLastBooksAction = async (dispatch) => {
  try {
    dispatch({
      type: LAST_BOOKS_LOADING
    })
    const response = await UserService.getLastBooks()

    if (!response.ok) {
      const errors = await response.json()
      throw errors
    }

    const result = await response.json()
    dispatch({
      type: LAST_BOOKS_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: LAST_BOOKS_ERROR,
      payload: error
    })
  }
}
