import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card
} from '@material-ui/core'
import { Button } from '@grupogen/saraiva-ui'
import { FileCopyOutlined } from '@material-ui/icons'
import { sanitize } from 'dompurify'
import useStyles from './styles'
import { GeneralContext } from '../../contexts/GeneralContext'
import Messages from '../../config/Messages'
import {
  formatAbntText,
  getAuthors,
  getDeviceType,
  stripHtml
} from '../../utils'
import { TrackingsService } from '../../services'

export default function Datasheet({ book }) {
  const classes = useStyles()
  const { setSuccessMessage } = useContext(GeneralContext)

  const clipBoard = async () => {
    await TrackingsService.sendTrack('referenciaBibliografica', book)

    const bookInfos = formatAbntText(book.abnt).replace(/<\/?strong>/g, '')
    navigator.clipboard.writeText(bookInfos)
    setSuccessMessage(Messages.abntCopy.message)
  }

  const screenSize = window.screen.width

  function getSeloName(area) {
    const areas = {
      Universitario: 'Saraiva Uni',
      Juridico: 'Saraiva Jur',
      'Ficcao / Nao Ficcao': 'Benvirá',
      'Livros Tecnicos': 'Editora Érica'
    }

    return areas[area]
  }

  return (
    <Card
      className={classes.container}
      variant="outlined"
      data-testid="book-datasheet-content"
    >
      {getAuthors(book) !== '' && (
        <>
          <Typography variant="h6" className={classes.DatasheetTitle}>
            Autores
          </Typography>
          <Typography variant="body2">{getAuthors(book)}</Typography>
        </>
      )}
      <Typography variant="h6" className={classes.DatasheetTitle}>
        Sinopse
      </Typography>

      {book.description ? (
        <Typography variant="body2" style={{ textAlign: 'justify' }}>
          {stripHtml(book.description)}
        </Typography>
      ) : null}
      <Typography variant="h6" className={classes.DatasheetTitle}>
        Referência Bibliográfica
        <Button
          color="secondary"
          onClick={() => clipBoard()}
          startIcon={<FileCopyOutlined />}
          className={classes.copyIcon}
        >
          {screenSize >= 450 && (
            <span className={classes.copyBtnLabel}>Copiar</span>
          )}
        </Button>
      </Typography>
      <Typography variant="body2">
        <span
          style={{ display: 'block' }}
          id="abnt-text"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={
            book.productCategory === 'Livro Digital'
              ? { __html: `${sanitize(book.abnt)}. <em>E-book</em>.` }
              : { __html: sanitize(book.abnt) }
          }
        />
      </Typography>
      <Typography variant="h6" className={classes.otherInfos}>
        Outras Informações
      </Typography>

      <Table>
        <TableBody>
          {book.edition ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Número da Edição
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">{`${book.edition}ª`}</Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.editionYear ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Ano da Edição
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">{book.editionYear}</Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.pageAmount ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Número de Páginas
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">{book.pageAmount}</Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.area ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Selo
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">
                  {getSeloName(book.area)}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.isbn ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  ISBN
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">{book.isbn}</Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.width ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Profundidade
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">{book.width}</Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.weight ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Peso
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">{book.weight}</Typography>
              </TableCell>
            </TableRow>
          ) : null}

          {book.productCategory ? (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
                  className={classes.DatasheetInfo}
                >
                  Formato
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle1">
                  {book.productCategory}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </Card>
  )
}

Datasheet.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}
