import React, { useEffect, useState } from 'react'
import { Typography, Divider, Grid } from '@material-ui/core'
import { ProductCard } from '@grupogen/saraiva-ui'

import { useHistory, useLocation } from 'react-router-dom/'
import { DialogActivateBook, Loading, SEO, SnackBar } from '../../components'
import {
  useStyles,
  useCardStyles,
  useMediaStyles,
  useContentStyles
} from './style'

import EmptyEbooksLibrary from './EmptyEbookLibrary'
import { getCodProducts, isTheBookActive } from '../../utils'
import { LibraryService, TrackingsService } from '../../services'
import { isAuthenticated } from '../../auth'
import { Messages } from '../../config'

export default function EbookLibrary() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [ebooks, setEbooks] = useState()
  const [loading, setLoading] = useState(true)

  const cardClasses = useCardStyles()
  const imageClasses = useMediaStyles()
  const contentClasses = useContentStyles()

  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false,
    timer: 0
  })

  const handleCloseSnackBar = () => {
    setSnackbar(() => ({
      show: false
    }))
  }

  async function bookFilter() {
    try {
      const response = await LibraryService.getLibraryBook()

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()
      const codeProducts = getCodProducts(result)

      const bookResponse = await LibraryService.loadBook(codeProducts)
      const bookData = await bookResponse.json()

      setEbooks(bookData)
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    bookFilter()
  }, [])

  useEffect(() => {
    const { state } = location

    if (state) {
      if (Object.prototype.hasOwnProperty.call(state, 'error')) {
        if (Number(state.error) === 406) {
          setSnackbar({
            children: Messages.activateEBook.hasActivatedLink,
            severity: 'error',
            show: true,
            timer: 10000
          })
        } else {
          setSnackbar({
            children: Messages.activateEBook.error,
            severity: 'error',
            show: true,
            timer: 10000
          })
        }
      }

      if (Object.prototype.hasOwnProperty.call(state, 'ebooks')) {
        setSnackbar({
          children: Messages.activateEBook.successEbookList,
          severity: 'success',
          show: true,
          timer: 10000
        })
      }

      if (Object.prototype.hasOwnProperty.call(state, 'ebook')) {
        setSnackbar({
          children: Messages.activateEBook.success,
          severity: 'success',
          show: true,
          timer: 10000
        })
      }
    }
  }, [location])

  const redirectToBook = (caeId, livro) => {
    const payload = {
      numero_resultados: ebooks,
      esta_ativo: isTheBookActive(caeId, ebooks),
      categoria: livro.category
    }

    TrackingsService.sendTrack('ebookCard', livro, payload)

    if (isAuthenticated() && isTheBookActive(caeId, ebooks)) {
      history.push(`/livro-ativado/${caeId}`)
    } else {
      history.push(`/livro/${caeId}`)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <SEO title="Saraiva Conecta - Biblioteca" description="Meus Livros" />

      <Typography
        variant="h6"
        className={classes.title}
        data-testid="ebook-title"
      >
        Meus E-books
      </Typography>

      <Typography
        variant="body1"
        data-testid="ebook-description"
        className={classes.subtitle}
      >
        Aqui você encontrará seus e-Books adquiridos.
      </Typography>

      <DialogActivateBook />

      <Divider className={classes.divider} />

      <SnackBar
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={handleCloseSnackBar}
        timer={snackbar.timer}
      >
        {snackbar.children}
      </SnackBar>

      {ebooks ? (
        <>
          <div>
            <Grid container>
              {ebooks.map((book) => (
                <Grid
                  item
                  key={book.productCaeId}
                  className={classes.grid}
                  data-testid="library-item"
                >
                  <ProductCard
                    book={book}
                    imageHoverText="VER LIVRO"
                    image={book.imageUrl}
                    cardClass={cardClasses}
                    imageClass={imageClasses}
                    contentClass={contentClasses}
                    onMediaClick={() => redirectToBook(book.productCaeId, book)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      ) : (
        <EmptyEbooksLibrary />
      )}
    </div>
  )
}
