import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tilte: {
    borderBottom: '1px solid #cdcdcd',
    paddingBottom: 20,
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: 25
  },
  questionGrid: {
    marginBottom: 30
  },
  questionBox: {
    borderBottom: '1px solid #cdcdcd',
    padding: 10,
    alignItems: 'center',
    cursor: 'pointer',
    '&.selected': {
      background: '#f8f8f8'
    },
    '&.error': {
      background: '#f2dede'
    },
    '& .error-text': {
      color: '#ee795e',
      fontWeight: 'bold'
    },
    '& .error-svg': {
      fontSize: '3rem',
      color: '#ee795e',
      textAlign: 'center',
      margin: '0 auto',
      display: 'block'
    },
    '&.success': {
      background: '#dff0d8'
    },
    '& .success-text': {
      color: '#15b69e',
      fontWeight: 'bold'
    },
    '& .success-svg': {
      color: '#30a467',
      textAlign: 'center',
      margin: '0 auto',
      display: 'block',
      fontSize: '3rem'
    }
  },
  boxLetter: {
    padding: '10px 15px',
    borderRadius: 3,
    border: '1px solid #cdcdcd',
    '&.selected': {
      background: '#929292',
      color: '#FFFFFF'
    },
    '&.error': {
      background: '#ee795e',
      color: '#FFFFFF'
    },
    '&.success': {
      background: '#30a467',
      color: '#FFFFFF'
    }
  },
  buttonsGrids: {
    textAlign: 'right',
    '&.center': {
      textAlign: 'center'
    },
    '& button:disabled': {
      background: '#ccc',
      cursor: 'auto',
      '&:hover': {
        boxShadow: 'none'
      }
    }
  },
  feedbackTitle: {
    fontWeight: 500,
    color: '#999999',
    marginTop: 20,
    marginBottom: 20
  }
}))

export default useStyles
