import {
  FAVORITE_BOOKS_LOADING,
  FAVORITE_BOOK_REMOVE_SUCCESS,
  FAVORITE_BOOKS_ERROR,
  AUTHENTICATED_ERROR
} from '../../../utils/actionTypes'
import { BookService, TrackingsService } from '../../../services'
import { isAuthenticated } from '../../../auth'
import Messages from '../../../config/Messages'

export const removeFavoriteBookAction = async (dispatch, payload) => {
  dispatch({
    type: FAVORITE_BOOKS_LOADING
  })

  TrackingsService.sendTrack('favoritar')
  let response

  if (isAuthenticated()) {
    response = await BookService.unfavoriteBook(payload)
    const favoritesData = await response.json()

    if (response.ok) {
      dispatch({
        type: FAVORITE_BOOK_REMOVE_SUCCESS,
        payload: favoritesData
      })
    } else {
      dispatch({
        type: FAVORITE_BOOKS_ERROR,
        payload: favoritesData.errors
      })
    }
  } else {
    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }
  return {
    ok: response.ok,
    isAuthenticated: isAuthenticated()
  }
}
