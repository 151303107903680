const { v4 } = require('uuid')

module.exports = [
  {
    type: 'new',
    id: v4(),
    isUnread: true,
    onClick: () => {},
    title: 'Boas-vindas às notificações',
    content: 'Veja como funcionarão as notificações no Saraiva Conecta',
    time: new Date(),
    seen: false
  }
]
