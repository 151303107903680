import React, { useState, useContext } from 'react'
import { useContextSelector } from 'use-context-selector'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Grid, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { Button, Pagination } from '@grupogen/saraiva-ui'

import { GeneralContext } from '../../contexts/GeneralContext'
import { GlobalContext } from '../../contexts/GlobalContext'
import {
  removeFavoriteBookAction,
  addFavoriteBookAction
} from '../../contexts/actions'

import {
  returnSiblingCountByDeviceType,
  returnSizeByDeviceType,
  getSearchTerm,
  getBookFavoriteStatus
} from '../../utils'
import { Users, TrackingsService } from '../../services'

import Messages from '../../config/Messages'
import { isAuthenticated } from '../../auth'

import {
  useStyles,
  useCardStyles,
  useMediaStyles,
  useContentStyles
} from './styles'
import SEO from '../SEO'
import Loading from '../Loading'
import CustomCard from '../Card'

import NotFound from './NotFound'
import ItemsCount from './ItemsCount'
import { Selects } from './Selects/index'

export default function Catalog({
  listBooks,
  libraryBooks,
  totalPages,
  totalItems,
  page,
  handleClickPage,
  showPageItems,
  isLoading,
  searchParams,
  setSearchParams,
  isSearch,
  categoria,
  selectsList,
  setSelectsList,
  favorites,
  favoriteLoading,
  showFilters
}) {
  const classes = useStyles()
  const theme = useTheme()
  const [filterOpen, setFilterOpen] = useState(false)
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const location = useLocation()

  // eslint-disable-next-line no-unused-vars
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const {
    setSuccessMessage,
    setErrorMessage,
    setDialogNotAuthenticated,
    setDialogAuthClickTracking
  } = useContext(GeneralContext)

  const cardClasses = useCardStyles()
  const imageClasses = useMediaStyles()
  const contentClasses = useContentStyles()

  const handleToggleFilter = () => {
    if (isMobile) {
      setFilterDialogOpen(!filterDialogOpen)
    } else {
      setFilterOpen(!filterOpen)
    }
  }

  const handleCloseMobileFilters = () => {
    setFilterDialogOpen(false)
  }

  const onFavorite = async (id) => {
    if (isAuthenticated()) {
      const params = {
        favorite_type: 'cae_id',
        favorite_id: id,
        user_id: Users.getUserId()
      }
      const favoriteId = getBookFavoriteStatus(favorites, id)

      if (favoriteId) {
        const response = await removeFavoriteBookAction(
          globalDispatch,
          favoriteId
        )
        if (!response.ok) setErrorMessage(Messages.favorite.unfavoriteError)
        else setSuccessMessage(Messages.favorite.unfavorite)
      } else {
        const response = await addFavoriteBookAction(globalDispatch, params)
        if (!response.ok) setErrorMessage(Messages.favorite.error)
        else setSuccessMessage(Messages.favorite.success)
      }
    } else {
      await TrackingsService.sendTrack('favoritar')
      setDialogAuthClickTracking('favoritarLogin')
      setDialogNotAuthenticated(Messages.favorite.notAuthenticate)
    }
  }

  if (isLoading || favoriteLoading) {
    return <Loading />
  }

  return (
    <div className={classes.containerBox} id="scroll-catalog">
      {isSearch && (
        <SEO
          title="Saraiva Conecta - Busca"
          description={`Resultado de busca para "${getSearchTerm(
            location.search
          )}"`}
        />
      )}

      {showPageItems ? (
        <ItemsCount
          pageActual={Number(page)}
          booksQuantity={totalItems}
          filters={searchParams}
        />
      ) : null}

      {showFilters && (
        <>
          <div
            className={classes.containerFilterButton}
            data-testid="catalog-filter-button"
          >
            <Button
              color="primary"
              className={classes.filterButton}
              startIcon={<FilterList />}
              onClick={() => handleToggleFilter()}
            >
              Filtrar
            </Button>
          </div>

          <Selects
            listSelects={selectsList}
            filterOpen={filterOpen}
            filterDialogOpen={filterDialogOpen}
            setSelects={setSelectsList}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            closeMobileFilters={handleCloseMobileFilters}
            isSearch={isSearch}
          />
        </>
      )}

      {totalItems ? (
        <>
          <div className={classes.containerCatalog}>
            <Grid container>
              {listBooks.map((book) => (
                <Grid
                  item
                  key={book.productCaeId}
                  className={classes.grid}
                  data-testid="catalog-card-item"
                >
                  <CustomCard
                    book={book}
                    categoria={categoria}
                    totalItems={totalItems}
                    libraryBooks={libraryBooks}
                    favorites={favorites}
                    onFavorite={onFavorite}
                    isSearch={isSearch}
                    cardClass={cardClasses}
                    imageClass={imageClasses}
                    contentClass={contentClasses}
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          <div className={classes.containerDivider}>
            <Divider className={classes.divider} />
          </div>

          <div className={classes.pagination} data-testid="catalog-pagination">
            <Pagination
              color="secondary"
              count={totalPages}
              onChange={handleClickPage}
              page={Number(page)}
              siblingCount={returnSiblingCountByDeviceType()}
              size={returnSizeByDeviceType(true)}
            />
          </div>
        </>
      ) : (
        <NotFound />
      )}
    </div>
  )
}

Catalog.propTypes = {
  listBooks: PropTypes.arrayOf(
    PropTypes.shape({
      authors: PropTypes.arrayOf(PropTypes.string),
      imageUrl: PropTypes.string.isRequired,
      titleCover: PropTypes.string.isRequired,
      productCaeId: PropTypes.number.isRequired
    }).isRequired
  ),
  libraryBooks: PropTypes.oneOfType([PropTypes.object]).isRequired,
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleClickPage: PropTypes.func.isRequired,
  showPageItems: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchParams: PropTypes.shape({
    orderBy: PropTypes.string || null,
    itemsPerPage: PropTypes.number || null,
    filters: PropTypes.oneOfType(
      PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string
        })
      ),
      null
    )
  }).isRequired,
  setSearchParams: PropTypes.func.isRequired,
  isSearch: PropTypes.bool.isRequired,
  categoria: PropTypes.string,
  selectsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      input: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          text: PropTypes.string,
          value: PropTypes.number
        })
      )
    })
  ).isRequired,
  setSelectsList: PropTypes.func.isRequired,
  favorites: PropTypes.arrayOf.isRequired,
  favoriteLoading: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool
}

Catalog.defaultProps = {
  listBooks: PropTypes.array,
  categoria: '',
  showFilters: true
}
