import React from 'react'
import { Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import useStyles from './styles'

const UploadSucess = () => {
  const classes = useStyles()

  return (
    <div className={classes.successContent}>
      <Typography variant="subtitle1" className={classes.titleDialog}>
        Nosso suporte recebeu sua solicitação e você receberá um email em breve
      </Typography>
      <CheckCircle />
    </div>
  )
}

export default UploadSucess
