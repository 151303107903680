import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 998,
    margin: 'auto',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginLeft: theme.spacing(5),
      flex: '0 1 592px'
    }
  },
  image: {
    [theme.breakpoints.up('sm')]: {
      order: 1,
      flex: '0 1 361px'
    }
  },
  img: {
    maxWidth: '100%'
  },

  title: {
    marginBottom: theme.spacing(4),
    fontSize: '24px',

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
      fontSize: '34px'
    }
  },
  body: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      fontSize: '20px',
      width: '592px'
    }
  },
  footer: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}))
