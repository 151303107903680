import React from 'react'
import PropTypes from 'prop-types'
import { Box, withStyles } from '@material-ui/core'

export function BookFormat({ formato }) {
  const CustomBox = withStyles((theme) => ({
    root: {
      width: 56,
      height: 25,
      lineHeight: '25px',
      fontSize: 10,
      textAlign: 'center',
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.white,
      backgroundColor:
        formato === 'Livro Digital'
          ? theme.palette.primary.main
          : theme.palette.secondary.main
    }
  }))(Box)

  const tipoLivro = formato === 'Livro Digital' ? 'Digital' : 'Impresso'

  return <CustomBox>{tipoLivro}</CustomBox>
}

BookFormat.propTypes = {
  formato: PropTypes.string.isRequired
}
