/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  FormControl,
  Box,
  Typography
} from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { SelectSaraiva, DropDownItem, Dialog } from '@grupogen/saraiva-ui'
import {
  Favorite,
  ListAlt,
  MenuBook,
  HomeSharp,
  BookRounded
} from '@material-ui/icons'
import Links from '../../../config/Links'
import useStyles from './styles'
import { ActivateBook, HelpActivationBook } from '../../../components'
import { isAuthenticated } from '../../../auth'
import { TrackingsService, Users } from '../../../services'
import { GeneralContext } from '../../../contexts/GeneralContext'

export default function DesktopDrawer({ categories }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const categoryDefaultLabel = 'Todas as Categorias'
  const [categorieSelected, setCategorieSelected] =
    useState(categoryDefaultLabel)

  const [openDialog, setOpenDialog] = useState(false)
  const [trackingName, setTrackingName] = useState('')
  const [redirectTo, setRedirectTo] = useState('')

  const { openMockPopover, setOpenMockPopover } = useContext(GeneralContext)

  useEffect(() => {
    const { pathname } = location

    if (!pathname.includes('/categoria')) {
      setCategorieSelected(categoryDefaultLabel)
    }
  }, [location])

  const changeCategory = (categoria, subcategoria) => {
    let event = 'subCategoria'
    let path = `/categoria/${encodeURIComponent(categoria)}`

    if (subcategoria) {
      event = 'subCategoriaEsubCategoria'
      path += `/${encodeURIComponent(subcategoria)}`
      setCategorieSelected(subcategoria)
    } else {
      setCategorieSelected(categoria)
    }

    TrackingsService.sendTrack(
      event,
      {},
      {
        categoria,
        subcategoria
      }
    )

    history.push(path)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      setOpenMockPopover(false)
    })
    return () => {
      unlisten()
    }
  }, [history])

  function isSelected(page) {
    if (window.location.pathname === page) return classes.menuItemSelected
    return classes.menuItem
  }

  const renderDropdownCategories = () => {
    const categoryContent = categories.map((category) => {
      if (!category.subcategoria) {
        return (
          <DropDownItem cascaded text={category.name} key={category.name} />
        )
      }
      const subcategoriaContent = category.subcategoria.map((subcategoria) => {
        return (
          <DropDownItem
            key={subcategoria.name}
            text={subcategoria.name}
            onClick={() => changeCategory(category.name, subcategoria.name)}
          />
        )
      })

      if (category.subcategoria && category.subcategoria.length > 1) {
        subcategoriaContent.unshift(
          <DropDownItem
            key="ver-todas"
            text="Ver todas"
            onClick={() => changeCategory(category.name)}
          />
        )
      }

      return (
        <DropDownItem
          cascaded
          text={category.name}
          key={category.name}
          onClick={() => changeCategory(category.name)}
        >
          {subcategoriaContent}
        </DropDownItem>
      )
    })

    return categoryContent
  }

  const handleTracking = (eventName) => {
    window.scrollTo(0, 0)
    TrackingsService.sendTrack(
      eventName,
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  const handleConfirmDialog = () => {
    setOpenDialog(false)
    setOpenMockPopover(false)
    handleTracking(trackingName)
    history.push(redirectTo)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = (action, to) => {
    setOpenDialog(true)
    setTrackingName(action)
    setRedirectTo(to)
  }

  return (
    <List className={classes.drawerList}>
      <Box className={classes.activeBookWrapper}>
        <ActivateBook />

        <Box className={classes.helpActivationBook}>
          <HelpActivationBook />
        </Box>
      </Box>
      <Divider variant="middle" className={classes.divider} />

      <Dialog
        open={openDialog}
        title="Sair do atual simulado"
        secondaryButton="Cancelar"
        primaryButton="Sair"
        label="Tem certeza que deseja sair do simulado?"
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDialog}
      >
        <div>
          <Typography>Tem certeza que deseja sair do simulado?</Typography>

          <Typography className={classes.dialogText}>
            Ao sair sem finalizar as questões, seu progresso será perdido!
          </Typography>

          <Typography className={classes.dialogSubtitle}>
            Dica: Responda todas as questões e clique em VER RESULTADOS, assim
            suas respostas serão salvas.
          </Typography>
        </div>
      </Dialog>

      {isAuthenticated() ? (
        <ListItem
          button
          onClick={() => {
            openMockPopover
              ? handleOpenDialog('abaInicial', Links.home)
              : handleTracking('abaInicial')
          }}
          className={isSelected(Links.home)}
          component={openMockPopover ? 'button' : Link}
          to={!openMockPopover ? Links.home : null}
        >
          <HomeSharp style={{ marginRight: 32 }} />
          <ListItemText>Página inicial</ListItemText>
        </ListItem>
      ) : null}

      <ListItem
        button
        onClick={() => {
          openMockPopover
            ? handleOpenDialog('abaCatalogo', Links.catalog)
            : handleTracking('abaCatalogo')
        }}
        className={isSelected(Links.catalog)}
        component={openMockPopover ? 'button' : Link}
        to={!openMockPopover ? Links.catalog : null}
      >
        <ListAlt style={{ marginRight: 32 }} />
        <ListItemText>Catálogo</ListItemText>
      </ListItem>

      {isAuthenticated() ? (
        <ListItem
          button
          className={isSelected(Links.ebooks)}
          component={openMockPopover ? 'button' : Link}
          onClick={() => {
            openMockPopover
              ? handleOpenDialog('accessedMyEbooks', Links.ebooks)
              : handleTracking('accessedMyEbooks')
          }}
          to={!openMockPopover ? Links.ebooks : null}
        >
          <BookRounded style={{ marginRight: 32 }} />
          <ListItemText>Meus E-books</ListItemText>
        </ListItem>
      ) : null}

      {isAuthenticated() ? (
        <ListItem
          button
          className={isSelected(Links.library)}
          component={openMockPopover ? 'button' : Link}
          onClick={() => {
            openMockPopover
              ? handleOpenDialog('myMaterials', Links.library)
              : handleTracking('myMaterials')
          }}
          to={!openMockPopover ? Links.library : null}
        >
          <MenuBook style={{ marginRight: 32 }} />
          <ListItemText>Meus Materiais</ListItemText>
        </ListItem>
      ) : null}
      {isAuthenticated() ? (
        <ListItem
          button
          className={isSelected(Links.wishlist)}
          component={openMockPopover ? 'button' : Link}
          onClick={() => {
            openMockPopover
              ? handleOpenDialog('abaFavoritos', Links.wishlist)
              : handleTracking('abaFavoritos')
          }}
          to={!openMockPopover ? Links.wishlist : null}
        >
          <Favorite style={{ marginRight: 32 }} />
          <ListItemText>Lista de Desejos</ListItemText>
        </ListItem>
      ) : null}
      <Divider variant="middle" className={classes.divider} />
      {categories && categories.length ? (
        <>
          <ListSubheader component="div" className={classes.textSubHeader}>
            Categorias de livros
          </ListSubheader>

          <ListItem>
            <FormControl variant="outlined" fullWidth>
              <SelectSaraiva
                label={categorieSelected}
                onChange={null}
                className={classes.select}
                size="full"
                error={false}
              >
                {renderDropdownCategories()}
              </SelectSaraiva>
            </FormControl>
          </ListItem>
        </>
      ) : null}
    </List>
  )
}

DesktopDrawer.propTypes = {
  categories: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    ordem: PropTypes.number.isRequired,
    subcategoria: PropTypes.arrayOf({
      id: PropTypes.number,
      name: PropTypes.string,
      ordem: PropTypes.number
    })
  })
}

DesktopDrawer.defaultProps = {
  categories: []
}
