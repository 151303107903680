import React from 'react'
import { Redirect } from 'react-router-dom'
import { Box, Divider, Typography } from '@material-ui/core'

import { useBooks, useError } from '../../hooks'
import { isAuthenticated } from '../../auth'
import { getDeviceType } from '../../utils'

import { useStyles } from './styles'
import { Catalog, Loading } from '../../components'

import { Links } from '../../config'

export default function CatalogPage() {
  const classes = useStyles()
  const { redirectOnError } = useError()

  const {
    books,
    loadingBooks,
    totalPages,
    totalItems,
    page,
    showPageItems,
    searchParams,
    isSearch,
    categoria,
    subcategoria,
    selectsList,
    libraryBooks,
    libraryLoading,
    favorites,
    favoriteLoading,
    handleClickPage,
    setSearchParams,
    setSelectsList,
    errorBooks
  } = useBooks(false)

  redirectOnError(errorBooks)

  if (loadingBooks || libraryLoading || favoriteLoading) {
    return <Loading />
  }

  if (Array.isArray(books) && books.length === 1) {
    return <Redirect to={Links.maintenance} />
  }

  return (
    <>
      {isAuthenticated() && (
        <Box>
          <Typography className={classes.titleCatalog}>Catálogo</Typography>
          <Typography className={classes.subTitle}>
            Aqui você encontrará indicações de livros do selo Saraiva baseado no
            seu perfil.
          </Typography>
          <Divider className={classes.activeBookDivider} />
        </Box>
      )}

      <Typography
        variant={getDeviceType() === 'mobile' ? 'subtitle1' : 'h6'}
        className={classes.title}
        data-testid="home-title-catalog"
      >
        Todo o Catálogo
      </Typography>

      <Catalog
        listBooks={books}
        libraryBooks={libraryBooks}
        totalPages={totalPages}
        totalItems={totalItems}
        page={page}
        handleClickPage={handleClickPage}
        showPageItems={showPageItems}
        isLoading={loadingBooks}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        favorites={favorites}
        isSearch={isSearch}
        categoria={categoria}
        subcategoria={subcategoria}
        selectsList={selectsList}
        setSelectsList={setSelectsList}
      />
    </>
  )
}
