import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  cookieBar: {
    left: 336,
    right: 32,
    position: 'fixed',
    bottom: 32,
    zIndex: 1300,

    [theme.breakpoints.down(960)]: {
      right: 32,
      left: 139
    },

    [theme.breakpoints.down('xs')]: {
      right: 12,
      left: 12,
      bottom: 62
    }
  }
}))
