import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core'
import { Button } from '@grupogen/saraiva-ui'
import Loading from '../../Loading'
import { useStyles } from './styles'
import { GlobalContext } from '../../../contexts/GlobalContext'
import {
  addInterestsAction,
  getCategoriesAction
} from '../../../contexts/actions'
import { Links, Messages } from '../../../config'
import { normalizeString, sortByName } from '../../../utils'
import { GeneralContext } from '../../../contexts/GeneralContext'
import { TrackingsService } from '../../../services'

export default function InterestComplete({ setViewInterests }) {
  const classes = useStyles()
  const history = useHistory()

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)

  const { data: categories, loading: categoriesLoading } =
    globalState.categories

  const [interestsState, setInterestsState] = useState([])
  const [shouldDisableButton, setShouldDisableButton] = useState(false)

  const disableButton = () => {
    return !Object.keys(interestsState).some(
      (interest) => interestsState[interest].selected
    )
  }

  const handleCloseInterest = () => {
    TrackingsService.sendTrack('pulou_interesse')
    setViewInterests(true)
  }

  const handleChangeInterests = (event) => {
    setInterestsState((prevState) => {
      const newState = prevState.map((interest) => {
        if (interest.key === event.target.id) {
          return { ...interest, selected: event.target.checked }
        }

        return interest
      })

      return newState
    })
  }

  const handleSubmitPreferences = async () => {
    const interests = interestsState
      .filter((interest) => interest.selected)
      .map((interest) => interest.id)

    const interestsPayload = { interests }

    try {
      const interestsResponse = await addInterestsAction(
        globalDispatch,
        interestsPayload
      )

      if (!interestsResponse.ok) {
        throw new Error(interestsResponse.status)
      }

      setShouldDisableButton(true)
      setViewInterests(true)
      setSuccessMessage(Messages.profile.setProfileSuccess)

      history.push(Links.home)
    } catch (err) {
      setErrorMessage(Messages.profile.setProfileError)
    }
  }

  useEffect(() => {
    if (!categories.length) {
      getCategoriesAction(globalDispatch)
    } else {
      const interestList = categories.map((category) => {
        return {
          key: normalizeString(category.name, '-'),
          id: category.id,
          name: category.name,
          selected: false
        }
      })

      setInterestsState(interestList.sort(sortByName))
    }
  }, [categories, globalDispatch])

  if (categoriesLoading || !categories.length) {
    return <Loading />
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Typography component="h1" className={classes.title}>
          Complete seu perfil
        </Typography>

        <Typography component="h2" className={classes.subTitle}>
          Escolha suas áreas de interesse para receber recomendações de livros
          de acordo com o seu gosto
        </Typography>

        <Typography component="p" className={classes.text}>
          Suas informações ficarão seguras, de acordo com nossa{' '}
          <a
            href="https://www.canaldatransparencia.com.br/cogna/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            política de privacidade.
          </a>
        </Typography>

        <FormGroup className={classes.formGroup} row>
          {Object.keys(interestsState).map((interest) => (
            <FormControlLabel
              key={interestsState[interest].key}
              control={
                <Checkbox
                  checked={interestsState[interest].selected}
                  onChange={handleChangeInterests}
                  name={interestsState[interest].id}
                  id={interestsState[interest].key}
                  color="primary"
                />
              }
              label={interestsState[interest].name}
              className={classes.formControlLabel}
            />
          ))}
        </FormGroup>
      </Box>

      <Box className={classes.buttonWrapper}>
        <Button color="secondary" onClick={() => handleCloseInterest()}>
          pular
        </Button>

        <Button
          color="primary"
          onClick={() => handleSubmitPreferences()}
          variant="contained"
          disabled={disableButton() || shouldDisableButton}
          className={classes.buttonSubmit}
        >
          Próximo
        </Button>
      </Box>
    </>
  )
}

InterestComplete.propTypes = {
  setViewInterests: PropTypes.func.isRequired
}
