import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  breadcrumps: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    marginBottom: 24
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 28,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 21,
      paddingRight: 20,
      paddingTop: 28
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
      flexDirection: 'column'
    }
  },
  bookMain: {
    maxWidth: 240,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      margin: '0 auto',
      maxWidth: '100%',
      width: '100%'
    }
  },
  favoriteButton: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    top: -15,
    right: 10,
    width: 36,
    height: 36,
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    },
    [theme.breakpoints.down('sm')]: {
      right: 10
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 15,
      left: 100,
      marginTop: -15
    }
  },
  favoriteIcon: {
    margin: 'auto',
    color: theme.palette.text.primary
  },
  bookCover: {
    width: 202,
    height: 271,
    marginBottom: 16,
    marginRight: 20,
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20
    }
  },
  buttonPreview: {
    width: 202,
    marginBottom: 32,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      width: 202
    }
  },

  bookDescription: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 230px)',
    position: 'relative',
    marginLeft: 14,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0
    }
  },
  title: {
    color: theme.palette.text.primary,
    marginRight: 40,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  authors: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 40
    }
  },
  bookProperties: {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '4px 0px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 40,
      margin: '4px 0px'
    }
  },
  divider: {
    margin: '20px 0px 20px 0px'
  },
  publisherLogo: {
    maxWidth: 86,
    height: 'auto',
    objectFit: 'contain',
    marginRight: 20,
    [theme.breakpoints.down(1053)]: {
      marginBottom: 20
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  seloAdiquirirBox: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'baseline'
    }
  },
  priceContainer: {
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginBottom: 15
    }
  },
  price: {
    color: theme.palette.text.secondary
  },
  buttonBuy: {
    width: 145,
    height: 45,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20
    }
  },
  buttonBuyOnlyDesktop: {
    width: 145,
    height: 45,
    [theme.breakpoints.down(1053)]: {
      margin: '0px 0px 40px 0px'
    }
  },
  buttonBuyOnly: { width: '100%' },
  ratingLabel: {
    fontFamily: 'Roboto',
    fontSize: 16,
    color: theme.palette.text.primary,
    marginLeft: 4,
    marginBottom: 4
  },
  ratingStar: {
    maxHeight: 20,
    maxWidth: 19.28,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 18.77,
      maxWidth: 20
    }
  },
  sampleBook: {
    height: '90vh'
  },
  item: {
    margin: '0px 20px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  readButton: {
    marginBottom: 24
  },
  seloMobile: {
    height: 46
  },
  chatBotButton: {
    borderColor: '#187A86',
    color: '#187A86',
    marginBottom: 24
  },
  tagBeta: {
    borderRadius: 4,
    background: '#187A86',
    marginLeft: 8,
    display: 'flex',
    padding: '4px 5px',
    alignItems: 'center',
    width: 34,
    height: 14
  },
  betaText: {
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 500
  },
  buttonsContainer: {
    display: 'inline-grid',
    '@media screen and (max-width: 600px)': {
      display: 'block'
    }
  },
  endTimeButton: {
    marginLeft: 16,
    float: 'inline-end',
    backgroundColor: '#187A86',
    borderRadius: 4,
    color: '#fff',
    '@media screen and (max-width: 600px)': {
      marginLeft: 0
    },
    '&:hover': {
      backgroundColor: '#104c5b'
    }
  },
  refuseButton: {
    color: '#187A86'
  },
  dialogContentContainer: {
    display: 'flex'
  },
  endTimeDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 624
    }
  },
  endCalendar: {
    width: 63,
    height: 63,
    flexShrink: 0,
    color: '#187A86',
    marginRight: 16
  },
  endDialogActions: {
    '@media screen and (max-width: 600px)': {
      display: 'block'
    }
  },
  endDialogButtonsContainer: {
    padding: 12,
    '@media screen and (max-width: 600px)': {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  }
}))

export default useStyles
