import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import EpubReader from '@grupogen/epub-reader'
import { Users } from '../../services'
import { getApplicationToken, getSessionToken } from '../../auth'
import { toCamelCase } from '../../utils'

const bookTitleFromSearch = (search) => {
  const filteredSearch = search
    .split('?title=')[1]
    .replaceAll('%20', ' ')
    .replaceAll('%C3%87', 'ç')

  return toCamelCase(filteredSearch)
}

export default function Reader({ history, match }) {
  const { caeId } = match.params
  const { search } = history.location

  let historyParam = history

  historyParam = useHistory()

  const { goBack } = historyParam

  return (
    <EpubReader
      bookTitle={bookTitleFromSearch(search)}
      caeId={caeId}
      userId={Users.getUserId()}
      applicationToken={getApplicationToken()}
      sessionToken={getSessionToken()}
      backToBds={() => goBack()}
      integrationType={Users.getIntegrationType()}
    />
  )
}
Reader.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      key: PropTypes.string
    })
  }).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      caeId: PropTypes.string
    }),
    path: PropTypes.string,
    url: PropTypes.string
  }).isRequired
}
