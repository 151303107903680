import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, Typography, Box } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { Button, Tooltip } from '@grupogen/saraiva-ui'
import { TrackingsService } from '../../../services'
import {
  getDeviceType,
  formatDate,
  formatDate2,
  getAuthors,
  toCamelCase,
  activeIsbn
} from '../../../utils'

import saraivajur from '../../../static/saraivajur.png'
import saraivauni from '../../../static/saraivauni.png'
import erica from '../../../static/erica.png'
import benvira from '../../../static/benvira.png'

import useStyles from '../styles'
import Messages from '../../../config/Messages'

export default function BookDescription({ book }) {
  const [openModal, setOpenModal] = useState(false)
  const classes = useStyles()
  const {
    titleCover,
    productCategory,
    edition,
    editionYear,
    isbn,
    area,
    label,
    closingDate,
    subtitle
  } = book

  const editoraIsbn = activeIsbn.filter(
    (livros) => livros?.active_isbn_digital === isbn
  )

  const redirectAquisition = async () => {
    await TrackingsService.sendTrack('queroAdquirir', book)
    if (productCategory === 'Livro Impresso')
      window.open(`https://www.editoradodireito.com.br/${isbn}`)
    else if (
      productCategory === 'Livro Digital' &&
      editoraIsbn[0]?.active_isbn_digital === isbn
    )
      window.open(`https://www.editoradodireito.com.br/${isbn}`)
    else window.open(`https://www.amazon.com.br/s?k=${isbn}`)
  }

  function getSelo(areaOjb) {
    const areas = {
      Universitario: saraivauni,
      Juridico: saraivajur,
      'Ficcao / Nao Ficcao': benvira,
      'Livros Tecnicos': erica
    }

    return areas[areaOjb]
  }

  const renderMobileLayout = () => {
    return (
      <div>
        {area && (
          <img
            src={getSelo(area)}
            alt={label}
            title={`${label}${' '}${area}`}
            className={classes.publisherLogo}
          />
        )}
        <Divider className={classes.divider} />
        <Button
          className={classes.buttonBuyOnly}
          variant="contained"
          color="secondary"
          onClick={() => redirectAquisition()}
        >
          <Typography variant="caption">Quero Adquirir</Typography>
        </Button>
      </div>
    )
  }

  const renderDesktopLayout = () => {
    return (
      <div>
        <Divider className={classes.divider} />
        <Grid container className={classes.seloAdiquirirBox}>
          <Grid item className={classes.selo}>
            {area && (
              <img
                src={getSelo(area)}
                alt={label}
                title={`${label}${' '}${area}`}
                className={classes.publisherLogo}
              />
            )}
          </Grid>
          <Button
            className={classes.buttonBuyOnlyDesktop}
            variant="contained"
            color="secondary"
            onClick={() => redirectAquisition()}
          >
            <Typography variant="caption">Quero Adquirir</Typography>
          </Button>
        </Grid>
      </div>
    )
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const onOpenModal = () => {
    setOpenModal(true)
  }

  const renderTooltipModal = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={2}
        width={307}
      >
        <Typography variant="body1" color="textSecondary" paragraph>
          {Messages.book.closingDateTooltip}
        </Typography>

        <Box width={64}>
          <Button color="primary" onClick={handleCloseModal}>
            ok!
          </Button>
        </Box>
      </Box>
    )
  }

  const displayEditionAndYear = () => {
    return (edition && editionYear) || false
  }

  return (
    <Grid item className={classes.bookDescription}>
      <Typography
        variant={getDeviceType() === 'mobile' ? 'body1' : 'h5'}
        className={classes.title}
        title={toCamelCase(titleCover)}
        data-testid="book-title"
      >
        {`${toCamelCase(titleCover)} ${
          subtitle && subtitle !== undefined ? `- ${toCamelCase(subtitle)}` : ``
        }`}
      </Typography>

      <Typography
        variant={getDeviceType() === 'mobile' ? 'subtitle2' : 'h6'}
        className={classes.authors}
        title={getAuthors(book)}
        data-testid="book-authors"
      >
        {getAuthors(book)}
      </Typography>

      {displayEditionAndYear() && (
        <Typography
          variant="body2"
          title={`${edition}ª edição | ${editionYear}`}
          className={classes.bookProperties}
          data-testid="book-edition"
        >
          {`${edition}ª edição | ${editionYear}`}
        </Typography>
      )}
      {closingDate && (
        <Box className={classes.bookPropertiesGroup}>
          <Typography
            variant="body2"
            title={`Data de fechamento: ${formatDate(closingDate)}`}
            className={classes.closingDate}
            data-testid="book-date"
          >
            {`Data de fechamento: ${formatDate2(closingDate)}`}
          </Typography>

          <Tooltip
            title={Messages.book.closingDateTooltip}
            custom
            breakpoint="sm"
            width={408}
            openModal={openModal}
            onCloseModal={handleCloseModal}
            onOpenModal={onOpenModal}
            modalContent={renderTooltipModal()}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
      )}
      {productCategory && (
        <Typography
          variant="body2"
          title={`Formato: ${productCategory}`}
          className={classes.bookProperties}
        >
          {`Formato: ${productCategory}`}
        </Typography>
      )}

      {getDeviceType() === 'mobile'
        ? renderMobileLayout()
        : renderDesktopLayout()}
    </Grid>
  )
}

BookDescription.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}
