import { makeStyles } from '@material-ui/core/styles'

export const useRatingStyles = makeStyles((theme) => ({
  ratingWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.25)
  },
  totalComments: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary
  },
  starRounded: {
    fontSize: 14,

    [theme.breakpoints.up('sm')]: {
      fontSize: 19
    }
  }
}))
