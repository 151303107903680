import {
  LIBRARY_ACTUALIZE_YOUR_CODE_LOADING,
  LIBRARY_ACTUALIZE_YOUR_CODE_SUCCESS,
  LIBRARY_ACTUALIZE_YOUR_CODE_ERROR
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const libraryActualizeYourCodeReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    LIBRARY_ACTUALIZE_YOUR_CODE_LOADING,
    LIBRARY_ACTUALIZE_YOUR_CODE_SUCCESS,
    LIBRARY_ACTUALIZE_YOUR_CODE_ERROR
  ])
}

export default libraryActualizeYourCodeReducer
