import React from 'react'
import { Box, Typography, withStyles, alpha } from '@material-ui/core'
import RaspadinhaTeste from '../../../../static/raspadinha-teste.png'
import useStyles from './styles'

export default function FirstTutorial() {
  const classes = useStyles()

  const Circle = withStyles((theme) => ({
    root: {
      width: 13,
      height: 13,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${alpha(theme.palette.common.white, 0.5)}`,
      zIndex: theme.zIndex.modal
    }
  }))(Box)

  return (
    <Box className={classes.stepWrapper}>
      <Typography className={classes.introText}>
        Caso o seu livro seja uma{' '}
        <span className={classes.textHighlight}>ativação via Código</span>,
        basta seguir os seguintes passos:
      </Typography>

      <Box className={classes.imageWrapper}>
        <img
          src={RaspadinhaTeste}
          alt="Exemplo de raspadinha para ativação do livro"
          className={classes.image}
        />
      </Box>

      <Box className={classes.steps}>
        <Box className={classes.step}>
          <Box className={classes.stepDecorator}>
            <Circle />
            <Box className={classes.stepDashed} />
          </Box>

          <Typography className={classes.stepText}>
            Leia as instruções na página do Conecta encontrada nas primeiras
            páginas do seu livro;
          </Typography>
        </Box>

        <Box className={classes.step}>
          <Box className={classes.stepDecorator}>
            <Circle />
            <Box className={classes.stepDashed} />
          </Box>

          <Typography className={classes.stepText}>
            Após a leitura,{' '}
            <span className={classes.textHighlight}>
              encontre o botão de ativar materiais
            </span>{' '}
            no menu ao lado esquerdo em nossa plataforma digital;
          </Typography>
        </Box>

        <Box className={classes.step}>
          <Box className={classes.stepDecorator}>
            <Circle />
            <Box className={classes.stepDashed} />
          </Box>

          <Typography className={classes.stepText}>
            Siga as instruções que aparecerem na tela para ativar o seu código;
          </Typography>
        </Box>

        <Box className={classes.step}>
          <Box className={classes.stepDecorator}>
            <Circle />
            <Box className={classes.stepDashed} />
          </Box>

          <Typography className={classes.stepText}>
            Pronto! Seu livro foi ativado e você poderá encontra-ló na página de{' '}
            <span className={classes.textHighlight}>“Meus livros”</span> em
            nossa plataforma digital.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
