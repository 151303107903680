import {
  FAVORITE_BOOKS_LOADING,
  FAVORITE_BOOKS_SUCCESS,
  FAVORITE_BOOKS_ERROR
} from '../../../utils/actionTypes'
import { BookService } from '../../../services'

export const getFavoriteBooksAction = async (dispatch) => {
  let dataToReturn = {}

  dispatch({
    type: FAVORITE_BOOKS_LOADING
  })

  const favoriteResponse = await BookService.getFavorites()
  const favoritesData = await favoriteResponse.json()

  if (favoriteResponse.ok) {
    dataToReturn = favoritesData.data
    dispatch({
      type: FAVORITE_BOOKS_SUCCESS,
      payload: favoritesData.data
    })
  } else {
    dispatch({
      type: FAVORITE_BOOKS_ERROR,
      payload: favoritesData.error
    })
  }

  return {
    ok: favoriteResponse.ok,
    data: dataToReturn
  }
}
