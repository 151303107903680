import { makeStyles } from '@material-ui/core'

const paperStyles = makeStyles(() => ({
  root: {
    margin: 12,
    maxHeight: 'none'
  }
}))

export default paperStyles
