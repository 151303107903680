/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-console */
import React, { useContext, useEffect, useCallback, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Card, Grid, IconButton } from '@material-ui/core'
import { useContextSelector } from 'use-context-selector'
import { Breadcrumbs } from '@grupogen/saraiva-ui'
import { Alert } from '@material-ui/lab'
import { Favorite, FavoriteBorder } from '@material-ui/icons'

import { GeneralContext } from '../../contexts/GeneralContext'
import { GlobalContext } from '../../contexts/GlobalContext'
import {
  getLibraryBooksAction,
  getLibraryMaterialAction,
  getFavoriteBooksAction,
  addFavoriteBookAction,
  removeFavoriteBookAction
} from '../../contexts/actions'

import { useWindowSize, useError } from '../../hooks'
import Messages from '../../config/Messages'
import {
  BookService,
  ContentsService,
  TrackingsService,
  Users
} from '../../services'
import { isAuthenticated } from '../../auth'
import {
  getDeviceType,
  toCamelCase,
  getBookFavoriteStatus,
  stripHtml,
  getBreadcrumb
} from '../../utils'

import { useStyles } from './styles'

import {
  BookDatasheet,
  BookTabsHeader,
  ShareButton,
  Loading,
  BookTabsBody,
  SEO
} from '../../components'
import BookDescriptionActivated from './components/BookDescriptionActivated'
import ActualizeYourCode from './components/ActualizeYourCode'
import BookMaterials from './components/BookMaterials'
import AdditionalLegislation from './components/AdditionalLegislation'
import { getBookReaderUrl } from '../../utils/utilsGroup/books'
import ListMocks from './components/Mocks'
// Bloco do chatbot
// import Chatbot from '../../components/Chatbot'

export default function ActivatedBook() {
  const { caeId } = useParams()
  const { redirectOnError } = useError()
  const classes = useStyles()
  const location = useLocation()
  const { from } = location.state || {}
  const [tabValue, setTabValue] = useState(0)
  const [mock, setMock] = useState([])
  const [active, setActive] = useState(false)
  const [simulationStatus, setSimulationStatus] = useState(null)
  const isMobile = window.innerWidth <= 960

  const handleMouseOut = () => {
    setActive(false)
  }

  const { setDialogNotAuthenticated, setSuccessMessage, setErrorMessage } =
    useContext(GeneralContext)

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (user) => user
  )
  const { data: libraryBooks, loading: loadingBook } = globalState.libraryBooks
  const { data: libraryMaterial, loading: loadingMaterial } =
    globalState.libraryMaterial
  const { data: favoriteBooks, loading: loadingFavorite } =
    globalState.favoriteBooks
  const favoriteId = getBookFavoriteStatus(favoriteBooks, Number(caeId))

  useWindowSize()

  // Bloco do chatbot
  // const betaTag = () => {
  //   return (
  //     <div className={classes.tagBeta}>
  //       <Typography className={classes.betaText}>beta</Typography>
  //     </div>
  //   )
  // }

  const handleClickOnFavorite = async () => {
    if (favoriteId) {
      const status = await removeFavoriteBookAction(globalDispatch, favoriteId)
      if (status.ok) {
        setSuccessMessage(Messages.favorite.unfavorite)
      } else setErrorMessage(Messages.error.unfavorite)
    } else {
      const userId = Users.getUserId()
      const params = {
        favorite_type: 'cae_id',
        favorite_id: caeId,
        user_id: userId
      }
      const status = await addFavoriteBookAction(globalDispatch, params)
      if (status.ok) {
        setSuccessMessage(Messages.favorite.success)
      } else setErrorMessage(Messages.error.favorite)
    }
  }

  const setMaterialGratuito = () => {
    return {
      categoria: 'E-pub',
      codigoexterno: 7993,
      descricao: '',
      formato: 'epub-google',
      hash: ' ',
      icone: ' ',
      id: 'ragnarok-7993',
      nome: 'E-pub - Nova lei de licitações (Lei n 14.133)',
      ordem: 1,
      organograma: null,
      origem: 'ragnarok',
      palavraschave: [],
      parent: 'ragnarok-8097',
      perfil: ['aluno'],
      produto: {
        titulo: 'E-pub - Nova lei de licitações (Lei n 14.133)',
        codigoProduto: '752220'
      },
      subcategoria: 'E-pub',
      tipo: 'epub-google',
      url: 'https://api-srv.grupogen.com.br/material/entrega/:HASH/7993',
      urlPath: '/material/entrega/:HASH/7993'
    }
  }

  const chatBotFilter = () => {
    const idsValidos = [
      '752276',
      '735246',
      '818247',
      '818248',
      '600259',
      '626983',
      '830027',
      '830028',
      '819774',
      '820199'
    ]
    const { pathname } = location

    const idChatBot = idsValidos.map((id) => pathname.includes(id))
    if (idChatBot.includes(true)) {
      return true
    }
    return false
  }
  // Bloco do chatbot
  // const chatBotComponent = (book) => {
  //   if (!isMobile) {
  //     return <Chatbot book={book} />
  //   }
  //   return null
  // }

  async function reportLastBook(bookId) {
    try {
      const response = await BookService.reportLastBook(bookId)
      await response.json()
    } catch (err) {
      console.error(err)
    }
  }

  const handleClickEbook = () => {
    const bookHeaderItem = document.getElementById('book-header')

    reportLastBook(caeId)

    if (bookHeaderItem) {
      const offset = -70
      const topPos =
        bookHeaderItem.getBoundingClientRect().top + window.scrollY + offset
      window.scrollTo({
        top: topPos,
        behavior: 'smooth'
      })
    }
  }

  const bookTabs = (book, bookMaterial) => {
    const materialsFormated = bookMaterial.material.filter((material) => {
      return (
        material.tipo !== 'atualize-seu-codigo' &&
        material.titulo !== 'Legislação Adicional'
      )
    })

    materialsFormated.forEach((material) => {
      if (
        material.titulo === 'Material gratuito' &&
        !material.materiais.length
      ) {
        material.materiais.push(setMaterialGratuito())
      }
    })

    const actualizeYourCode = bookMaterial.material.filter((material) => {
      return material.tipo === 'atualize-seu-codigo'
    })

    const aditionalLegs = bookMaterial.material.filter((material) => {
      return material.titulo === 'Legislação Adicional'
    })

    const items = [
      // Bloco do chatbot
      // {
      //   label: (
      //     <>
      //       Pergunte ao seu Livro
      //       {betaTag()}
      //     </>
      //   ),
      //   content: chatBotComponent(book),
      //   available: chatBotFilter(),
      //   testid: 'chat-bot',
      //   dontShowBodyContact: true
      // },
      {
        label: 'Ficha Técnica',
        content: <BookDatasheet book={book} />,
        available: true,
        testid: 'book-datasheet'
      },
      {
        label: 'Atualize seu código',
        content: (
          <ActualizeYourCode book={book} materials={actualizeYourCode} />
        ),
        book,
        available: actualizeYourCode.length > 0,
        dontShowBodyContact: true,
        testid: 'book-actualize-your-code'
      },
      {
        label: 'Legislação adicional',
        content: <AdditionalLegislation book={book} />,
        available: aditionalLegs.length > 0,
        dontShowBodyContact: true
      },
      {
        label: 'Ver Materiais',
        content: <BookMaterials book={book} materials={materialsFormated} />,
        available: materialsFormated.length > 0,
        testid: 'book-materials'
      },
      {
        label: 'Simulados',
        content: <ListMocks mocksList={mock} />,
        available: from && mock?.length > 0 && !!simulationStatus,
        testid: 'book-materials',
        dontShowBodyContact: true
      }
    ]

    return items.filter((item) => item.available)
  }

  const getData = useCallback(
    async (cae) => {
      try {
        const libraryResponse = await getLibraryBooksAction(globalDispatch, {
          cae
        })

        if (!libraryResponse.ok) {
          throw new Error(console.table(libraryResponse))
        }

        getFavoriteBooksAction(globalDispatch)
        getLibraryMaterialAction(globalDispatch, { cae })
      } catch (error) {
        console.log(error.message)
        redirectOnError({ libraryResponse: 400 })
      }
    },
    [globalDispatch, redirectOnError]
  )

  const filteredLibraryBooks = libraryBooks?.products.filter(
    (libraryBook) => libraryBook?.productCaeId === Number(caeId)
  )

  const libraryBooksFiltered =
    filteredLibraryBooks?.length > 0 ? filteredLibraryBooks[0] : null

  useEffect(() => {
    async function fetchMock() {
      try {
        const response = await ContentsService.getMocks(
          libraryBooksFiltered?.productCaeId
        )

        const result = await response.json()
        const filteredResult = result.filter((item) => item.status === true)
        const hasSimulationStatus = result.some((item) => item.status === true)
        setSimulationStatus(hasSimulationStatus)
        setMock(filteredResult)
      } catch (err) {
        console.error(err)
      }
    }
    fetchMock()
  }, [libraryBooksFiltered?.productCaeId])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    if (isAuthenticated()) {
      getData(caeId)
    } else setDialogNotAuthenticated(Messages.favorite.notAuthenticate)
  }, [caeId, getData, setDialogNotAuthenticated])

  if (
    loadingBook ||
    loadingMaterial ||
    loadingFavorite ||
    !libraryBooks ||
    !libraryMaterial
  ) {
    return <Loading />
  }

  const onReadButton = () => {
    const payload = {
      categoria: libraryBooksFiltered.category,
      user_name: Users.getFullName(),
      user_email: Users.getEmail()
    }
    TrackingsService.sendTrack('openBook', libraryBooksFiltered, payload)

    reportLastBook(caeId)

    window.location.assign(getBookReaderUrl(libraryBooksFiltered))
  }
  const onReadBook = () => {
    if (libraryBooksFiltered.productCategory === 'Livro Digital') {
      onReadButton()
    }
  }

  const handleMouseOver = () => {
    if (libraryBooksFiltered.productCategory === 'Livro Digital')
      setActive(true)
  }

  return (
    <>
      <SEO
        title={`Saraiva Conecta - ${libraryBooksFiltered?.titleCover}`}
        description={stripHtml(libraryBooksFiltered.description)}
      />

      <Breadcrumbs color="primary" className={classes.breadcrumps}>
        {getBreadcrumb(libraryBooksFiltered)}
      </Breadcrumbs>

      <Card
        className={classes.container}
        variant="outlined"
        style={{ overflow: 'clip' }}
      >
        <ShareButton book={libraryBooksFiltered} />

        <Grid className={classes.main}>
          <Grid item className={classes.bookMain}>
            {libraryBooksFiltered.availableSite && (
              <IconButton
                title={favoriteId ? 'Desfavoritar Livro' : 'Favoritar Livro'}
                className={classes.favoriteButton}
                style={{ backgroundColor: favoriteId && '#CD444B' }}
                onClick={() => handleClickOnFavorite()}
              >
                {favoriteId ? (
                  <Favorite
                    className={classes.favoriteIcon}
                    style={{ color: 'white' }}
                  />
                ) : (
                  <FavoriteBorder className={classes.favoriteIcon} />
                )}
              </IconButton>
            )}
            <>
              <div
                className={classes.containerImg}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <img
                  alt={toCamelCase(libraryBooksFiltered?.titleCover)}
                  className={
                    active
                      ? `${classes.bookHover} ${classes.bookCover} `
                      : `${classes.bookCover}`
                  }
                  src={libraryBooksFiltered.imageUrl}
                />

                <div
                  className={
                    active
                      ? `${classes.overlayHover} ${classes.overlay} `
                      : `${classes.overlay}`
                  }
                  aria-hidden="true"
                  onClick={() => onReadBook()}
                >
                  <div className={classes.textOverlay}>Ler Livro</div>
                </div>
              </div>
            </>
          </Grid>
          <BookDescriptionActivated book={libraryBooksFiltered} />
        </Grid>
        <div
          id="book-header"
          role="button"
          tabIndex={0}
          onClick={handleClickEbook}
          onKeyDown={handleClickEbook}
        >
          <BookTabsHeader
            tabValue={chatBotFilter && isMobile ? tabValue + 1 : tabValue}
            screenSize={getDeviceType()}
            book={libraryBooksFiltered}
            setTabValue={setTabValue}
          >
            {bookTabs(libraryBooksFiltered, libraryMaterial)}
          </BookTabsHeader>
        </div>
      </Card>
      <BookTabsBody tabValue={tabValue} book={libraryBooksFiltered}>
        {bookTabs(libraryBooksFiltered, libraryMaterial)}
      </BookTabsBody>

      {from !== 'biblioteca' && (
        <Alert className={classes.alert} severity="warning">
          O download da obra não está autorizado devido questões de direitos
          autorais
        </Alert>
      )}
    </>
  )
}
