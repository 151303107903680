import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MaterialsService } from '../../../../../services'
import { Loading } from '../../../../../components'
import Chapter from './Chapter'
import Question from './Question'
import Review from './Review'
import useStyles from './styles'

const questionsStates = {
  CHAPTER: 'chapter',
  QUIZ: 'quiz',
  REVIEW: 'review'
}

const ShowQuestions = ({ materialUrl }) => {
  const classes = useStyles()
  const [currentState, setCurrentState] = useState(questionsStates.CHAPTER)
  const [currentQuestion, setCurrentQuestion] = useState()
  const [stepStatus, setStepStatus] = useState()
  const [statistics, setStatistics] = useState()
  const [step, setStep] = useState()
  const [loading, setLoading] = useState(true)
  const [urlPath, setUrlPath] = useState()

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const { pathname } = new URL(materialUrl)
      const data = await MaterialsService.getQuestions(pathname)
      const questions = await data.json()
      setUrlPath(pathname)
      setCurrentQuestion(questions.data.current_question)
      setStatistics(questions.data.step.statistics)
      setStepStatus(questions.data.step.status)
      setStep(questions.data.step)
      setLoading(false)
    } catch {
      setStep(null)
      setLoading(false)
    }
  }, [
    setLoading,
    setCurrentQuestion,
    setStatistics,
    setStepStatus,
    setStep,
    materialUrl
  ])

  useEffect(() => {
    getData()
  }, [getData])

  const handleChangeState = (state) => {
    setCurrentState(state)
  }

  const handleNextQuestion = async () => {
    await getData()

    if (currentQuestion) {
      setCurrentState(questionsStates.QUIZ)
    } else {
      setCurrentState(questionsStates.REVIEW)
    }
  }

  const renderState = () => {
    if (!step) return null

    if (currentState === questionsStates.CHAPTER) {
      return (
        <Chapter
          statistics={statistics}
          step={step}
          stepStatus={stepStatus}
          handleChangeState={handleChangeState}
          questionsStates={questionsStates}
        />
      )
    }
    if (currentState === questionsStates.QUIZ) {
      return (
        <Question
          step={step}
          currentQuestion={currentQuestion}
          handleNextQuestion={handleNextQuestion}
          pathname={urlPath}
        />
      )
    }
    return <Review step={step} statistics={statistics} pathname={urlPath} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      {currentState !== questionsStates.CHAPTER ? (
        <div
          onClick={() => setCurrentState(questionsStates.CHAPTER)}
          className={classes.backButton}
          onKeyPress={() => null}
          role="button"
          tabIndex={0}
          data-testid="bookactived-question-back"
        >
          voltar
        </div>
      ) : null}
      {renderState()}
    </div>
  )
}

ShowQuestions.propTypes = {
  materialUrl: PropTypes.string.isRequired
}

export default ShowQuestions
