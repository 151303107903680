import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@material-ui/core'

import { useStyles } from './styles'

export default function PageCards({ title, description, icon, action }) {
  const classes = useStyles()

  return (
    <Paper onClick={action} className={classes.pageCard} variant="outlined">
      <Box className={classes.header}>
        <div className={classes.icon}>{icon}</div>
        <Typography color="primary" className={classes.title}>
          {title}
        </Typography>
      </Box>

      <Typography component="p" className={classes.description}>
        {description}
      </Typography>
    </Paper>
  )
}

PageCards.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired
}
