import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    padding: theme.spacing(1.5, 0)
  },
  headerButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    lineHeight: 0,
    padding: 0
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '#F5F5F5',
    height: `calc(100vh - 64px)`,
    paddingTop: theme.spacing(9),

    [theme.breakpoints.up('lg')]: {
      flex: '1 1 50%'
    }
  },
  backgroundWrapper: {
    flex: '1 1 100%',
    backgroundImage: (props) =>
      props.backgroundImage ? `url(${props.backgroundImage})` : 'row',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 402,

    [theme.breakpoints.up('lg')]: {
      flex: '1 1 590px',
      height: 804
    }
  },
  content: {
    flex: '1 1 100%',
    padding: theme.spacing(6, 2, 6),

    [theme.breakpoints.up('lg')]: {
      flex: '1 1 auto',
      maxWidth: 556,
      marginRight: 100,
      padding: theme.spacing(6, 0, 0)
    }
  },
  title: {
    ...theme.typography.h5,
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(6)
  },
  text: {
    ...theme.typography.body1,
    color: theme.palette.common.black
  },
  form: {
    marginTop: theme.spacing(5),
    background: theme.palette.primary.main,
    borderRadius: 8
  },
  formContent: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.common.white}`
  },
  formTitle: {
    ...theme.typography.h6,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2.5)
  },
  formSubTitle: {
    ...theme.typography.body1,
    color: theme.palette.common.white
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2.5, 0)
  },
  button: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: '6px 28px',

    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      opacity: 0.8
    }
  }
}))
