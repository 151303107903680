import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    padding: '24px 24px 40px 24px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '24px'
    }
  },
  sectionTitle: {
    color: '#624C92',
    textAlign: 'center',
    width: '100%',
    display: 'block',
    marginBottom: 12
  },
  sectionSubTitle: {
    textAlign: 'center',
    marginBottom: 40
  },
  sectionContent: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}))

export const contentCSS = `
<style>
#append-artcile .DESCRICAONORMACAPITULO{font-weight:bold;text-transform:uppercase;text-align:center;font-family:"Verdana";font-size:1.2em;color:#525050;}
#append-artcile .NORMASUBSECAO{font-weight:bold;text-transform:capitalize;text-align:center;font-family:"Verdana";font-size:1.1em;color:#525050;}
#append-artcile .PENAS{margin-left:10px;text-align:justify;}
#append-artcile .NORMAABERTURA{font-weight:bold;text-transform:uppercase;color:red;text-align:center;font-family:"Verdana";font-size:1.4em;padding:0.6em;width:100% !important;}
#append-artcile .PREAMBULO{text-align:justify;}
#append-artcile .PRESIDENTE{font-family:"Versalete",serif;text-align:right;text-transform:uppercase;}
#append-artcile .NOTAS{font-weight:bold;color:#FF0000;}
#append-artcile .NORMAPROTOCOLO{font-weight:bold;text-transform:uppercase;color:red;text-align:center;}
#append-artcile .DESCRICAONORMAEXPOSICAO{text-transform:capitalize;text-align:right;font-family:"Verdana";font-size:1.1em;color:black;}
#append-artcile .svg{height:1em;}
#append-artcile .DESCRICAONORMAABERTURA{font-weight:bold;text-transform:capitalize;text-align:center;}
#append-artcile .DESCRICAONORMALIVRO{font-weight:bold;text-align:center;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .DESCRICAONORMASECAO{text-align:center;font-family:"Verdana";font-size:1.1em;color:red;}
#append-artcile .EMENTA{font-style:italic;text-align:right;text-align:justify;}
#append-artcile .ARTIGOS{font-weight:bold;font-family:"Verdana";font-size:1.2em;color:red;}
#append-artcile .PARAGRAFOSUNICOS{font-weight:bold;color:#FF0000;margin-left:10px;text-align:justify;}
#append-artcile .ITEM{font-weight:bold;margin-left:10px;text-align:justify;}
#append-artcile .NOTA_SARAIVA_ACESSORIA{text-align:justify;font-family:"Verdana";font-size:1em;color:#525050;}
#append-artcile pre{font-family:Courier New, monospace;font-size:0.5em;width:125%;}
#append-artcile .NORMASUBTITULO{text-align:center;font-weight:bold;text-transform:uppercase;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .NORMAANEXO{font-weight:bold;text-transform:uppercase;text-align:center;font-family:"Verdana";font-size:1.1em;color:red;}
#append-artcile .DESCRICAONORMAPROTOCOLO{text-align:justify;}
#append-artcile td{font-family:"Verdana";color:black;font-size:1.1em;}
#append-artcile .NORMAPARTE{text-transform:uppercase;color:gray;font-weight:bold;text-align:center;font-family:"Verdana";font-size:1.2em;}
#append-artcile .ASSINATURA{text-transform:capitalize;font-style:italic;text-align:right;}
#append-artcile .NOTA_EDITORIAL_ACESSORIA{text-align:justify;font-family:"Verdana";font-size:1em;color:#6A6A6A;}
#append-artcile table{font-size:0.5em;}
#append-artcile div{padding:0.6em;text-align:justify;}div br{padding:0.6em;}
#append-artcile .DESCRICAONORMASUBTITULO{font-weight:bold;text-transform:uppercase;text-align:center;font-family:"Verdana";font-size:1.2em;color:black;}
#append-artcile .DESCRICAONORMADISPOSICAO{text-transform:capitalize;text-align:right;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .PARAGRAFOS{font-weight:bold;margin-left:10px;text-align:justify;}
#append-artcile .SUMULAS{font-weight:bold;color:#FF0000;text-align:justify;}
#append-artcile .image{max-width:100%;text-align:center;}
#append-artcile .NORMALIVRO{font-weight:bold;text-transform:capitalize;text-align:center;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .NORMATITULO{text-align:center;font-weight:bold;text-transform:uppercase;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .DESCRICAONORMATITULO{font-weight:bold;text-align:center;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .DESCRICAONORMAANEXO{text-align:justify;font-family:"Verdana";font-size:1em;}
#append-artcile .NORMADISPOSICAO{font-weight:bold;text-transform:capitalize;text-align:right;font-family:"Verdana";font-size:1.4em;color:black;}
#append-artcile .PREAMBULODACONSTITUICAO{color:#6b6a6a;text-transform:uppercase;text-align:center;font-weight:bold;}
#append-artcile .DESTAQUE{list-style:url(/wp-content/themes/vademecum/images/seta_vade.gif);margin:0 0 0 -360px;}
#append-artcile .RODAPE{text-align:justify;}
#append-artcile .ALINEAS{font-weight:bold;font-style:italic;margin-left:10px;text-align:justify;}
#append-artcile .BULLET{color:red;}
#append-artcile .PENASDUPLO{margin-left:20px;text-align:justify;}
#append-artcile .centerImage1{max-width:100%;max-height:100%;}
#append-artcile .CORANDEL{margin-top:0px;margin-left:0px;margin-right:0px;margin-bottom:0px;text-align:center;}
#append-artcile .ARTIGO{text-align:justify;}
#append-artcile .NOTA_SARAIVA_FUNDAMENTAL{text-align:justify;font-family:"Verdana";font-size:1em;color:#525050;}
#append-artcile .NORMACAPITULO{text-align:center;font-weight:bold;text-transform:uppercase;font-family:"Verdana";font-size:1.2em;color:#383737;}
#append-artcile .DESCRICAONORMASUBSECAO{font-style:italic;text-align:center;font-family:"Verdana";font-size:1em;color:#383737;}
#append-artcile .NORMAEXPOSICAO{font-weight:bold;text-transform:capitalize;text-align:right;font-family:"Verdana";font-size:1.4em;color:#383737;}
#append-artcile .PENASTRIPLO{margin-left:30px;text-align:justify;}
#append-artcile .DESCRICAONORMAPARTE{text-align:justify;}
#append-artcile .NORMASECAO{font-weight:bold;font-style:italic;text-transform:capitalize;text-align:center;font-family:"Verdana";font-size:1.2em;color:red;}
#append-artcile .RUBRICA{font-weight:bold;color:#FF0000;text-align:left;}
#append-artcile .INCISOS{font-weight:bold;margin-left:10px;text-align:justify;}
#append-artcile .NOTA_EDITORIAL_FUNDAMENTAL{text-align:justify;font-family:"Verdana";font-size:1em;color:#525050;}
#append-artcile IMG{padding:0.6em !important;height: auto;max-width: 100%;vertical-align: middle;border: 0;}
#append-artcile em{font-size: 12px}
#append-artcile p{margin: 0; line-height: 20px;}
</style>
`

export default useStyles
