import React from 'react'
import { Typography } from '@material-ui/core'
import { EpubService } from '../../../../../services'
import useStyles from './styles'
import epubDownload from '../../../../../static/epub-download.png'

const Menu = () => {
  const classes = useStyles()

  const handleDownloadEpub = async () => {
    const response = await EpubService.getEpubs()
    if (response.ok) {
      const data = await response.json()
      const epub = data.filter(
        (epubData) => epubData.name === 'legislacao-adicional.epub'
      )
      if (epub.length) {
        window.open(epub[0].url, '_blank')
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.textContent}>
        <Typography variant="body1" className={classes.textStyle}>
          Além de online, a Legislação Adicional também pode ser baixada no
          formato ePUB e acessada off-line. Para visualizar o conteúdo é
          necessário instalar um leitor de e-PUB compatível com o seu
          dispositivo. Caso ainda não tenha um leitor instalado, sugerimos o uso
          do Adobe Digital Editions.
        </Typography>
        <picture className={classes.imageContent}>
          <img src={epubDownload} alt="Epub Download" />
        </picture>
      </div>
      <div className={classes.buttonContent}>
        <button
          type="button"
          className={classes.printButton}
          onClick={handleDownloadEpub}
        >
          <span>BAIXAR MATERIAL</span>
        </button>
      </div>
    </div>
  )
}

export default Menu
