import httpClient from '../gateway/legacyHttpRequest'

export const CategoriesService = {
  async getCategories() {
    const path = `produto/busca/lista-categorias/tipo/categoria`
    const headers = httpClient.buildHeader({})
    const response = httpClient.get(path, null, headers)
    return response
  }
}
