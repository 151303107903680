import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  shareButton: {
    zIndex: 1,
    color: theme.palette.primary.main,
    float: 'right',
    position: 'absolute',
    right: 30,
    padding: 3,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: -10,
      right: -4,
      padding: 0
    }
  },
  cardShare: {
    padding: 10,
    width: 220,
    height: 63
  },
  iconShare: {
    marginRight: 4,
    marginLeft: 4
  }
}))

export default useStyles
