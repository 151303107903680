import {
  LIBRARY_ACTUALIZE_YOUR_CODE_LOADING,
  LIBRARY_ACTUALIZE_YOUR_CODE_SUCCESS,
  LIBRARY_ACTUALIZE_YOUR_CODE_ERROR
} from '../../../utils/actionTypes'
import { MaterialsService } from '../../../services'

export const getLibraryActualizeYourCode = async (dispatch, payload = {}) => {
  dispatch({
    type: LIBRARY_ACTUALIZE_YOUR_CODE_LOADING
  })

  const hash = MaterialsService.getURLHash()
  const urlTemp = payload.url.replace(':HASH', hash)

  const response = await fetch(urlTemp, {
    method: 'GET'
  })

  const responseData = await response.text()

  if (response.ok) {
    dispatch({
      type: LIBRARY_ACTUALIZE_YOUR_CODE_SUCCESS,
      payload: {
        content: responseData
      }
    })
  } else {
    dispatch({
      type: LIBRARY_ACTUALIZE_YOUR_CODE_ERROR,
      payload: responseData.error
    })
  }

  return {
    ok: responseData.ok
  }
}
