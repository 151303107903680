import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: '100%',
    width: '100%',
    margin: 0,
    background: 'rgba(129, 106, 177, 0.3)',
    color: theme.palette.primary.dark,

    '&:hover': {
      backgroundColor: 'rgba(129, 106, 177, 0.6)'
    },

    [theme.breakpoints.up('sm')]: {
      maxWidth: 95
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: 256
    }
  },
  divider: {
    marginTop: 8,
    marginBottom: 20
  }
}))

export default useStyles
