import { isUnauthorized, redirectToLogin } from '../auth'

function RequestHeaderBuilder() {
  const DEFAULT_REQUEST_HEADER = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    idapplication: process.env.REACT_APP_SARAIVA_DIGITAL_API_TOKEN
  }

  function decorate(customRequestHeader = {}) {
    return { ...DEFAULT_REQUEST_HEADER, ...customRequestHeader }
  }

  return {
    buildHeader(headers) {
      return decorate(headers)
    }
  }
}

function RequestBuilder() {
  // const { REACT_APP_SARAIVA_DIGITAL_API } = process.env

  function normalizePath(path) {
    if (path.startsWith('/')) {
      return path.slice(1)
    }

    return path
  }

  function buildUrl(path) {
    return `https://api-srv.grupogen.com.br/${normalizePath(path)}`
  }

  function buildBody(payload) {
    if (!payload) {
      return {}
    }
    return {
      body: JSON.stringify(payload)
    }
  }

  async function verifyResponse(result) {
    const response = await result
    if (isUnauthorized(response.status)) {
      redirectToLogin()
    }
  }

  function build(path, method, payload, headers) {
    try {
      let options = {
        method,
        headers,
        mode: 'cors'
      }

      if (method !== 'GET') {
        options = { ...options, ...buildBody(payload) }
      }
      const result = fetch(buildUrl(path), options)
      verifyResponse(result)
      return result
    } catch (error) {
      return error
    }
  }

  return {
    get(path, payload, headers) {
      return build(path, 'GET', payload, headers)
    },

    post(path, payload, headers) {
      return build(path, 'POST', payload, headers)
    },

    patch(path, payload, headers) {
      return build(path, 'PATCH', payload, headers)
    },

    put(path, payload, headers) {
      return build(path, 'PUT', payload, headers)
    },

    delete(path, payload, headers) {
      return build(path, 'DELETE', payload, headers)
    }
  }
}

export default { ...RequestHeaderBuilder(), ...RequestBuilder() }
