import { stopwords, siglas } from '../exceptions'

function stripHtml(html) {
  const tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

function searchToObject(search) {
  const pairs = search.substring(1).split('&')
  const obj = {}
  let pair = null

  Object.keys(pairs).forEach((i) => {
    if (pairs[i] !== '') {
      pair = pairs[i].split('=')
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }
  })
  return obj
}

function customBase64Decode(str) {
  try {
    if (str) {
      return decodeURIComponent(
        Array.prototype.map
          .call(atob(str), (c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
          })
          .join('')
      )
    }
    return null
  } catch (e) {
    return null
  }
}

function sortByName(a, b) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

function getTodaysDate() {
  const today = new Date()
  const date = `${today.getDate()}/${
    today.getMonth() + 1
  }/${today.getFullYear()}`
  return date
}

function formatDate(date) {
  if (date) {
    const newDate = new Date(date)
    if (newDate.getTime()) {
      const formatedDate = `${newDate.getDate()}/${
        newDate.getMonth() + 1
      }/${newDate.getFullYear()}`
      return formatedDate
    }
  }
  return ''
}

function formatDate2(date) {
  if (date) {
    const newDate = new Date(date)
    if (!Number.isNaN(newDate.getTime())) {
      newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60)
      const day = newDate.getDate()
      const month = (newDate.getMonth() + 1).toString().padStart(2, '0')
      const year = newDate.getFullYear().toString()
      return `${day}/${month}/${year}`
    }
  }
  return ''
}

function getSearchTerm(searchObject) {
  const queryString = searchToObject(searchObject)
  return queryString.q ? queryString.q : null
}

function getBreadcrumb(book) {
  return [
    {
      label: `${book.category}`,
      href: `/categoria/${book.category}`
    },
    {
      label: `${book.subcategory}`,
      href: `/categoria/${book.category}/${book.subcategory}`
    },
    {
      label: `${book.titleCover}`
    }
  ]
}

function clearNullFromObject(obj) {
  return Object.keys(obj).filter((key) => {
    return obj[key] != null
  })
}

function updateObjectInArray(arr, key, newValue) {
  const index = arr.findIndex((item) => item[key] === newValue[key])

  if (index > -1) {
    arr[index] = newValue
  } else {
    arr.push(newValue)
  }
}

function compareTwoObjects(obj1, obj2) {
  return Object.entries(obj1).toString() === Object.entries(obj2).toString()
}

function objectIsEmpty(obj) {
  return Object.keys(obj).length === 0
}

function getOffset(element) {
  const rect = element.getBoundingClientRect()

  return {
    x: rect.left + window.scrollX,
    y: rect.top + window.scrollY
  }
}

const arrayToObject = (arr, key) => {
  return arr.reduce((obj, item) => {
    obj[item[key]] = item
    return obj
  }, {})
}

const normalizeString = (str, separator) => {
  return str.trim().toLowerCase().replace().replace(/\s/g, separator)
}

const formatEnDate = (date) => {
  const splitDate = date.split('-')
  splitDate[1] = Number(splitDate[1]) - 1 // Fix index month param
  return new Date(splitDate[0], splitDate[1], splitDate[2])
}

const dateIsUpToDate = (date) => {
  const today = new Date()
  const dateObj = new Date(date)

  return dateObj.getTime() <= today.getTime()
}

function testSpecialChars(param, list) {
  if (param.includes(',') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes('.') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes('(') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes(')') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  if (param.includes(':') && list.includes(param.replace(/[.,()]/g, '')))
    return true
  return false
}

function toCamelCase(string) {
  const words = string.split(' ')
  const lWords = words.map((word) => word.toLowerCase())

  const formatedTitle = lWords
    .map((word) => {
      if (siglas.includes(word) || testSpecialChars(word, siglas))
        return word.toUpperCase()
      return stopwords.includes(word)
        ? word
        : word && word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

  return formatedTitle.charAt(0).toUpperCase() + formatedTitle.slice(1)
}

export {
  stripHtml,
  searchToObject,
  customBase64Decode,
  sortByName,
  getTodaysDate,
  getSearchTerm,
  formatDate,
  formatDate2,
  getBreadcrumb,
  clearNullFromObject,
  updateObjectInArray,
  compareTwoObjects,
  objectIsEmpty,
  getOffset,
  arrayToObject,
  normalizeString,
  formatEnDate,
  dateIsUpToDate,
  toCamelCase
}
