import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'

const header = {
  'session-token': getSessionToken(),
  'application-token': process.env.REACT_APP_TOKEN
}

const buildedHeader = httpClient.buildHeader(header)

export const NotificationService = {
  async getNotification() {
    const path = `/contents/api/v1/notifications`
    const response = httpClient.get(path, null, buildedHeader)
    return response
  },

  async setNotification(id, params) {
    const path = `/contents/api/v1/notifications/${id}`
    let headers = { 'session-token': getSessionToken() }
    headers = httpClient.buildHeader(headers)
    const response = await httpClient.put(path, params, headers)
    return response
  }
}
