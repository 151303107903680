import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  itemsCountContent: {
    width: '100%'
  },
  countTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 0,
    marginTop: 0
  },
  containerDivider: {
    [theme.breakpoints.down('xl')]: {
      width: '100%'
    }
  },
  divider: {
    width: '100%',
    marginTop: 17,
    marginBottom: 14,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    }
  }
}))
