import {
  FAVORITE_BOOKS_LOADING,
  FAVORITE_BOOK_ADD_SUCCESS,
  FAVORITE_BOOKS_ERROR,
  AUTHENTICATED_ERROR
} from '../../../utils/actionTypes'
import { BookService, TrackingsService } from '../../../services'
import { isAuthenticated } from '../../../auth'
import Messages from '../../../config/Messages'

export const addFavoriteBookAction = async (dispatch, payload = {}) => {
  let statusOk = true

  await TrackingsService.sendTrack('favoritar')

  if (isAuthenticated()) {
    dispatch({
      type: FAVORITE_BOOKS_LOADING
    })

    const response = await BookService.favoriteBook(payload)
    const favoritesData = await response.json()

    if (response.ok) {
      dispatch({
        type: FAVORITE_BOOK_ADD_SUCCESS,
        payload: favoritesData
      })
    } else {
      statusOk = false

      dispatch({
        type: FAVORITE_BOOKS_ERROR,
        payload: favoritesData.errors
      })
    }
  } else {
    statusOk = false

    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }

  return {
    ok: statusOk,
    isAuthenticated: isAuthenticated()
  }
}
