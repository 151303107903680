import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { SelectSaraiva, Dialog, Button } from '@grupogen/saraiva-ui'
import {
  MenuItem,
  InputLabel,
  FormControl,
  Hidden,
  Box
} from '@material-ui/core'
import { useStyles } from './style'
import {
  getParamEntries,
  updateObjectInArray,
  getDefaultSearhParams,
  getDefaultFiltersSelects,
  getTrackingFiltersNames
} from '../../../utils'
import { TrackingsService } from '../../../services'

export function Selects({
  listSelects,
  filterOpen,
  filterDialogOpen,
  setSelects,
  searchParams,
  setSearchParams,
  closeMobileFilters,
  isSearch
}) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [selectedParams, setSelectedParams] = useState([])

  const DEFAULT_PARAMS = getDefaultSearhParams()
  const trackingFiltersNames = getTrackingFiltersNames()

  const updateSelectLabel = (type, value) => {
    const updatedSelectsList = [...listSelects]

    updatedSelectsList.forEach((select) => {
      if (type === select.type) {
        const [chosenValue] = select.values.filter((selectValue) => {
          return selectValue.value === value
        })

        select.label = chosenValue.text
      }
    })

    return updatedSelectsList
  }

  const clearFiltersParams = (filters) => {
    let cleanFilters = filters

    if (Array.isArray(filters)) {
      const filtersWithValue = cleanFilters.filter(
        (item) => item.value !== null
      )

      if (filtersWithValue.length) {
        cleanFilters = filtersWithValue
      } else {
        cleanFilters = null
      }
    }

    return cleanFilters
  }

  const clearNullParams = (searhParamsObj) => {
    Object.keys(searhParamsObj).forEach((param) => {
      if (searhParamsObj[param] === null) {
        delete searhParamsObj[param]
      }
    })

    return searhParamsObj
  }

  const removeParamsDefault = (searchParamsObj) => {
    const searchParamsUpdated = JSON.parse(JSON.stringify(searchParamsObj))

    Object.keys(searchParamsUpdated).forEach((key) => {
      if (!Array.isArray(searchParamsUpdated[key])) {
        if (DEFAULT_PARAMS[key] === searchParamsUpdated[key]) {
          delete searchParamsUpdated[key]
        }
      }
    })

    return searchParamsUpdated
  }

  const createUrlSearchParamsString = (searchParamsObj) => {
    const newSearchParamsString = new URLSearchParams()
    const currentSearchParamsString = new URLSearchParams(location.search)
    const searchParamsUpdated = removeParamsDefault(searchParamsObj)

    if (isSearch) {
      const searchTerm = currentSearchParamsString.get('q')

      if (searchTerm) {
        newSearchParamsString.set('q', searchTerm)
      }
    }

    Object.keys(searchParamsUpdated).forEach((param) => {
      if (Array.isArray(searchParamsUpdated[param])) {
        searchParamsUpdated[param].forEach((filter) => {
          newSearchParamsString.set(filter.type, filter.value)
        })
      } else if (searchParamsUpdated[param] !== null) {
        newSearchParamsString.set(param, searchParamsUpdated[param])
      }
    })

    return newSearchParamsString.toString()
  }

  const trackingFilter = (type, value) => {
    const filterType = type.includes('filter')
      ? getParamEntries(type).value
      : type

    TrackingsService.sendTrack(
      trackingFiltersNames[filterType],
      {},
      {
        filtro_tipo: filterType,
        filtro_valor: value || 'default'
      }
    )
  }

  const handleChangeFilter = (type, value) => {
    trackingFilter(type, value)

    const updatedSelectsList = updateSelectLabel(type, value)
    const updatedSearchParams = JSON.parse(JSON.stringify(searchParams))

    if (type.includes('filter')) {
      const filterType = getParamEntries(type).value

      if (Array.isArray(updatedSearchParams.filters)) {
        updateObjectInArray(updatedSearchParams.filters, 'type', {
          type: filterType,
          value
        })
      } else {
        updatedSearchParams.filters = [{ type: filterType, value }]
      }
    } else {
      updatedSearchParams[type] = value
    }

    updatedSearchParams.filters = clearFiltersParams(
      updatedSearchParams.filters
    )

    const cleanSearhParams = clearNullParams(updatedSearchParams)
    const updatedUrlSearchParams = createUrlSearchParamsString(cleanSearhParams)

    history.push({
      pathname: location.pathname,
      search: `?${updatedUrlSearchParams}`
    })

    setSelects(updatedSelectsList)
    setSearchParams(cleanSearhParams)
  }

  const handleSelectFilter = (type, value) => {
    trackingFilter(type, value)

    const updatedSelectsList = updateSelectLabel(type, value)

    setSelects(updatedSelectsList)

    setSelectedParams((prevState) => {
      if (prevState.find((param) => param.type === type)) {
        prevState.find((param) => param.type === type).value = value
        return [...prevState]
      }

      return [...prevState, { type, value }]
    })
  }

  const handleSetFilters = () => {
    const updatedSearchParams = JSON.parse(JSON.stringify(searchParams))

    selectedParams.forEach((selectedParam) => {
      if (selectedParam.type.includes('filter')) {
        const filterType = getParamEntries(selectedParam.type).value

        if (Array.isArray(updatedSearchParams.filters)) {
          updateObjectInArray(updatedSearchParams.filters, 'type', {
            type: filterType,
            value: selectedParam.value
          })
        } else {
          updatedSearchParams.filters = [
            { type: filterType, value: selectedParam.value }
          ]
        }
      } else {
        updatedSearchParams[selectedParam.type] = selectedParam.value
      }
    })

    updatedSearchParams.filters = clearFiltersParams(
      updatedSearchParams.filters
    )

    const cleanSearhParams = clearNullParams(updatedSearchParams)
    const updatedUrlSearchParams = createUrlSearchParamsString(cleanSearhParams)

    history.push({
      pathname: location.pathname,
      search: `?${updatedUrlSearchParams}`
    })

    closeMobileFilters()
    setSearchParams(cleanSearhParams)
  }

  const resetParams = () => {
    if (isSearch) {
      const searchParamsString = new URLSearchParams(location.search)
      const searchTerm = searchParamsString.get('q')

      history.push({
        pathname: location.pathname,
        search: `?q=${searchTerm}`
      })
    } else {
      history.push({
        pathname: location.pathname,
        search: ''
      })
    }

    setSearchParams(DEFAULT_PARAMS)
  }

  const handleRemoveFilters = () => {
    setSelectedParams([])
    setSelects(getDefaultFiltersSelects())
    resetParams()
  }

  return (
    <>
      <Hidden smUp>
        <span style={{ width: '100%' }}>
          <Dialog
            open={filterDialogOpen}
            title="Filtrar"
            secondaryButton="FECHAR"
            primaryButton="APLICAR"
            handleClose={closeMobileFilters}
            handleConfirm={handleSetFilters}
          >
            <Box className={classes.containerMobile}>
              {listSelects.map(
                ({ label, input, values, id: idSelect, type }) => (
                  <div
                    key={idSelect}
                    className={classes.selectItem}
                    data-testid="catalog-selects"
                  >
                    <InputLabel>{input}</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <SelectSaraiva label={label} size="full" error={false}>
                        {values.map(({ id, text, value }) => (
                          <MenuItem
                            key={id}
                            onClick={() => handleSelectFilter(type, value)}
                            data-testid="catalog-select-item"
                          >
                            {text}
                          </MenuItem>
                        ))}
                      </SelectSaraiva>
                    </FormControl>
                  </div>
                )
              )}

              <Box
                className={classes.removeFiltersContainer}
                data-testid="catalog-reset-filters"
              >
                <Button
                  type="button"
                  color="secondary"
                  onClick={handleRemoveFilters}
                >
                  Remover filtros
                </Button>
              </Box>
            </Box>
          </Dialog>
        </span>
      </Hidden>

      {filterOpen && (
        <Hidden xsDown>
          <span data-testid="catalog-selects" style={{ width: '100%' }}>
            <div className={classes.containerSelects}>
              {listSelects
                .filter((select) => select.showFilter)
                .map(({ label, input, values, id: idSelect, type }) => (
                  <div
                    className={classes.selectItem}
                    key={idSelect}
                    data-testid="catalog-select"
                  >
                    <InputLabel className={classes.input}>{input}</InputLabel>
                    <FormControl className={classes.select}>
                      <SelectSaraiva label={label} size="full" error={false}>
                        {values.map(({ id, text, value }) => (
                          <MenuItem
                            key={id}
                            data-testid="catalog-select-item"
                            onClick={() => handleChangeFilter(type, value)}
                          >
                            {text}
                          </MenuItem>
                        ))}
                      </SelectSaraiva>
                    </FormControl>
                  </div>
                ))}
            </div>

            <Box
              className={classes.removeFiltersContainer}
              data-testid="catalog-reset-filters"
            >
              <Button
                type="button"
                color="secondary"
                className={classes.removeFilterButton}
                onClick={handleRemoveFilters}
              >
                Remover filtros
              </Button>
            </Box>
          </span>
        </Hidden>
      )}
    </>
  )
}

const shapeBooks = PropTypes.shape({
  id: PropTypes.number,
  text: PropTypes.string
})

const shapeListSelects = PropTypes.shape({
  size: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.arrayOf(shapeBooks),
  input: PropTypes.string,
  idSelect: PropTypes.number
})

Selects.propTypes = {
  listSelects: PropTypes.arrayOf(shapeListSelects).isRequired,
  filterOpen: PropTypes.bool.isRequired,
  filterDialogOpen: PropTypes.bool.isRequired,
  setSelects: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    orderBy: PropTypes.string || null,
    itemsPerPage: PropTypes.number || null,
    filters: PropTypes.oneOfType(
      PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string
        })
      ),
      null
    )
  }).isRequired,
  setSearchParams: PropTypes.func.isRequired,
  closeMobileFilters: PropTypes.func.isRequired,
  isSearch: PropTypes.bool.isRequired
}
