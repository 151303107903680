export const AUTHENTICATED_ERROR = 'AUTHENTICATED_ERROR'

export const BOOK_LOADING = 'BOOK_LOADING'
export const BOOK_SUCCESS = 'BOOK_SUCCESS'
export const BOOK_ERROR = 'BOOK_ERROR'

export const BOOKS_LOADING = 'BOOKS_LOADING'
export const BOOKS_SUCCESS = 'BOOKS_SUCCESS'
export const BOOKS_ERROR = 'BOOKS_ERROR'

export const CATALOG_BOOKS_LOADING = 'CATALOG_BOOKS_LOADING'
export const CATALOG_BOOKS_SUCCESS = 'CATALOG_BOOKS_SUCCESS'
export const CATALOG_BOOKS_ERROR = 'CATALOG_BOOKS_ERROR'

export const FAVORITE_BOOKS_LOADING = 'FAVORITE_BOOKS_LOADING'
export const FAVORITE_BOOKS_SUCCESS = 'FAVORITE_BOOKS_SUCCESS'
export const FAVORITE_BOOKS_ERROR = 'FAVORITE_BOOKS_ERROR'

export const FAVORITE_BOOK_ADD_SUCCESS = 'FAVORITE_BOOK_ADD_SUCCESS'
export const FAVORITE_BOOK_REMOVE_SUCCESS = 'FAVORITE_BOOK_REMOVE_SUCCESS'

export const LIBRARY_ACTUALIZE_YOUR_CODE_LOADING =
  'LIBRARY_ACTUALIZE_YOUR_CODE_LOADING'
export const LIBRARY_ACTUALIZE_YOUR_CODE_SUCCESS =
  'LIBRARY_ACTUALIZE_YOUR_CODE_SUCCESS'
export const LIBRARY_ACTUALIZE_YOUR_CODE_ERROR =
  'LIBRARY_ACTUALIZE_YOUR_CODE_ERROR'

export const LIBRARY_BOOKS_LOADING = 'LIBRARY_BOOKS_LOADING'
export const LIBRARY_BOOKS_SUCCESS = 'LIBRARY_BOOKS_SUCCESS'
export const LIBRARY_BOOKS_ERROR = 'LIBRARY_BOOKS_ERROR'

export const LIBRARY_BOOKS_SIMPLE_LOADING = 'LIBRARY_BOOKS_SIMPLE_LOADING'
export const LIBRARY_BOOKS_SIMPLE_SUCCESS = 'LIBRARY_BOOKS_SIMPLE_SUCCESS'
export const LIBRARY_BOOKS_SIMPLE_ERROR = 'LIBRARY_BOOKS_SIMPLE_ERROR'

export const LIBRARY_MATERIAL_LOADING = 'LIBRARY_MATERIAL_LOADING'
export const LIBRARY_MATERIAL_SUCCESS = 'LIBRARY_MATERIAL_SUCCESS'
export const LIBRARY_MATERIAL_ERROR = 'LIBRARY_MATERIAL_ERROR'

export const LIBRARY_TREEVIEW_LOADING = 'LIBRARY_TREEVIEW_LOADING'
export const LIBRARY_TREEVIEW_ARTICLE_SUCCESS =
  'LIBRARY_TREEVIEW_ARTICLE_SUCCESS'
export const LIBRARY_TREEVIEW_CONTENT_SUCCESS =
  'LIBRARY_TREEVIEW_CONTENT_SUCCESS'
export const LIBRARY_TREEVIEW_ERROR = 'LIBRARY_TREEVIEW_ERROR'
export const LIBRARY_TREEVIEW_MENU_SUCCESS = 'LIBRARY_TREEVIEW_MENU_SUCCESS'
export const LIBRARY_TREEVIEW_NORMS_YEAR_SUCCESS =
  'LIBRARY_TREEVIEW_NORMS_YEAR_SUCCESS'
export const LIBRARY_TREEVIEW_NORMS_SUCCESS = 'LIBRARY_TREEVIEW_NORMS_SUCCESS'

export const RECOMMENDED_BOOKS_LOADING = 'RECOMMENDED_BOOKS_LOADING'
export const RECOMMENDED_BOOKS_SUCCESS = 'RECOMMENDED_BOOKS_SUCCESS'
export const RECOMMENDED_BOOKS_ERROR = 'RECOMMENDED_BOOKS_ERROR'

export const CATEGORIES_LOADING = 'CATEGORIES_LOADING'
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS'
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR'

export const WISHLIST_LOADING = 'WISHLIST_LOADING'
export const WISHLIST_SUCCESS = 'WISHLIST_SUCCESS'
export const WISHLIST_ERROR = 'WISHLIST_ERROR'

export const UPLOAD_TEACHER_FILE_LOADING = 'UPLOAD_TEACHER_FILE_LOADING'
export const UPLOAD_TEACHER_FILE_SUCCESS = 'UPLOAD_TEACHER_FILE_SUCCESS'
export const UPLOAD_TEACHER_FILE_ERROR = 'UPLOAD_TEACHER_FILE_ERROR'

export const PROFILE_TYPE_LOADING = 'PROFILE_TYPE_LOADING'
export const PROFILE_TYPE_SUCCESS = 'PROFILE_TYPE_SUCCESS'
export const PROFILE_TYPE_ERROR = 'PROFILE_TYPE_ERROR'

export const PROFILE_TYPE_ADD_SUCCESS = 'PROFILE_TYPE_ADD_SUCCESS'
export const PROFILE_TYPE_ADD_ERROR = 'PROFILE_TYPE_ADD_ERROR'

export const PROFILE_INTERESTS_LOADING = 'PROFILE_INTERESTS_LOADING'
export const PROFILE_INTERESTS_SUCCESS = 'PROFILE_INTERESTS_SUCCESS'
export const PROFILE_INTERESTS_ERROR = 'PROFILE_INTERESTS_ERROR'

export const PROFILE_INTERESTS_ADD_SUCCESS = 'PROFILE_INTERESTS_ADD_SUCCESS'
export const PROFILE_INTERESTS_ADD_ERROR = 'PROFILE_INTERESTS_ADD_ERROR'

export const PROFILE_INTERESTS_NOTIFICATION_LOADING =
  'PROFILE_INTERESTS_NOTIFICATION_LOADING'
export const PROFILE_INTERESTS_NOTIFICATION_SUCCESS =
  'PROFILE_INTERESTS_NOTIFICATION_SUCCESS'
export const PROFILE_INTERESTS_NOTIFICATION_ERROR =
  'PROFILE_INTERESTS_NOTIFICATION_ERROR'

export const PROFILE_INTERESTS_NOTIFICATION_ADD_SUCCESS =
  'PROFILE_INTERESTS_NOTIFICATION_ADD_SUCCESS'
export const PROFILE_INTERESTS_NOTIFICATION_ADD_ERROR =
  'PROFILE_INTERESTS_NOTIFICATION_ADD_ERROR'

export const PROFILE_INTERESTS_EXIST_LOADING = 'PROFILE_INTERESTS_EXIST_LOADING'
export const PROFILE_INTERESTS_EXIST_SUCCESS = 'PROFILE_INTERESTS_EXIST_SUCCESS'
export const PROFILE_INTERESTS_EXIST_ERROR = 'PROFILE_INTERESTS_EXIST_ERROR'

export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR'

export const LAST_BOOKS_LOADING = 'LAST_BOOKS_LOADING'
export const LAST_BOOKS_SUCCESS = 'LAST_BOOKS_SUCCESS'
export const LAST_BOOKS_ERROR = 'LAST_BOOKS_ERROR'
