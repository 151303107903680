import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { GeneralContext } from '../../contexts/GeneralContext'
import { isAuthenticated, redirectToLogin } from '../../auth'
import { customBase64Decode, searchToObject } from '../../utils'
import Messages from '../../config/Messages'
import Loading from '../../components/Loading'
import {
  Users,
  UserService,
  LibraryService,
  TrackingsService,
  BookService
} from '../../services'
import Links from '../../config/Links'

export default function Subscription() {
  const { setSuccessMessage, setErrorMessage } = useContext(GeneralContext)
  const { hash } = useParams()
  const history = useHistory()
  const decodedHash = customBase64Decode(hash)
  const activationType = decodedHash ? 'material' : 'ebook'

  useEffect(() => {
    const redirectEbook = (cae_ids) => {
      if (cae_ids.length > 1) {
        history.push(Links.ebooks, { ebooks: cae_ids })
      } else {
        history.push(`/livro-ativado/${cae_ids[0]}`, { ebook: cae_ids[0] })
      }
    }

    const activateLink = async () => {
      try {
        const data = searchToObject(decodedHash)

        localStorage.setItem('searchToObjectTest', JSON.stringify(data))

        const bookResponse = await BookService.getBook(data.id)

        if (!bookResponse.ok) {
          const errors = bookResponse.status
          throw errors
        }

        if (data.objectid) {
          localStorage.setItem('materialId', data.objectid)
        }

        const params = {
          user_id: Users.getUserId(),
          cae_id: data.id || data.course
        }

        const responseLibrary = await LibraryService.getLibrary()
        const responseInscricaoJson = await responseLibrary.json()

        const [product] = responseInscricaoJson.products.filter(
          (productInList) =>
            productInList.cod_produto === data.id ||
            productInList.cod_produto === data.course
        )

        if (product) {
          history.push(
            `${Links.livroAtivado.replace(':caeId', product.cod_produto)}`
          )
          return
        }

        const response = await UserService.activeLinkBook(params)
        const responseInscricao = await response.json()

        localStorage.setItem(
          'responseInscricaoTest',
          JSON.stringify(responseInscricao)
        )

        if (
          !responseInscricao.errors &&
          (responseInscricao.id ||
            (responseInscricao.product &&
              responseInscricao.product.cod_produto))
        ) {
          setSuccessMessage(Messages.activateBook.linkSuccess)

          TrackingsService.sendTrack(
            'ativacaoLivroPorLink',
            {},
            {
              cae_livro: data.id || data.course,
              status_ativacao: true,
              user_name: Users.getFullName(),
              user_email: Users.getEmail()
            }
          )
        }

        history.push(`${Links.library}`)
      } catch (error) {
        if (error === 404 || error.message === 'Not available') {
          setErrorMessage(Messages.activateBook.invalidBook)
        } else {
          setErrorMessage(Messages.activateBook.linkError)
        }

        history.push(`${Links.library}`)
      }
    }

    const ebookLinkActivation = async () => {
      try {
        const activationResponse = await UserService.ebookActivationLink(hash)

        if (!activationResponse.ok) {
          throw new Error(activationResponse.status)
        }

        const ebookActivationData = await activationResponse.json()

        const data = ebookActivationData.cae_ids[0]

        if (
          ebookActivationData &&
          Object.prototype.hasOwnProperty.call(ebookActivationData, 'cae_ids')
        ) {
          TrackingsService.sendTrack(
            'ativou_ebook',
            {},
            {
              cae_livro: data,
              status_ativacao: true,
              user_name: Users.getFullName(),
              user_email: Users.getEmail()
            }
          )
          redirectEbook(ebookActivationData.cae_ids)
        } else {
          history.push(Links.ebooks, { error: 'CAE IDs not found' })
        }
      } catch (error) {
        history.push(Links.ebooks, { error: error.message })
      }
    }

    if (!isAuthenticated()) {
      redirectToLogin()
    } else if (activationType === 'material') {
      activateLink()
    } else {
      ebookLinkActivation()
    }
  }, [
    setSuccessMessage,
    setErrorMessage,
    decodedHash,
    history,
    activationType,
    hash
  ])

  return <Loading />
}
