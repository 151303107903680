import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  returnButton: {
    display: 'flex',
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.primary.main,
    alignItems: 'center',
    margin: '26px 0 25px 5px',
    cursor: 'pointer'
  }
}))

export default useStyles
