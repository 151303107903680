import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerFilterButton: {
    display: 'flex'
  },
  filterButton: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column'
  },
  containerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    width: '100%'
  },
  title: {
    color: theme.palette.text.secondary,
    marginBottom: 20
  },
  containerCatalog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2.5),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 16,
    marginTop: 40,
    [theme.breakpoints.down(200)]: {
      width: 10,
      marginLeft: 5
    }
  },
  button: {
    height: 24,
    width: 170,
    background: '#f0f0f0',
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      width: 60
    }
  },
  rating: {
    margin: '10px 0 16px 0',
    fontSize: 19
  },
  grid: {
    position: 'relative',
    marginBottom: theme.spacing(2.25),
    flex: `0 1 50%`,

    [theme.breakpoints.up(532)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up('sm')]: {
      flex: `0 1 50%`
    },

    [theme.breakpoints.up(856)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up('md')]: {
      flex: `0 1 50%`
    },

    [theme.breakpoints.up(1064)]: {
      flex: `0 1 33.333333%`
    },

    [theme.breakpoints.up(1280)]: {
      flex: `0 1 25%`
    }
  },
  textSubHeader: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '12px'
  },
  containerDivider: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: 1000,
      width: '100%'
    }
  },
  divider: {
    marginTop: 60,
    maxWidth: 1300,
    [theme.breakpoints.down('xl')]: {
      width: '100%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 4
    }
  },
  pagination: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '22px 0 58px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}))

export const useCardStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 156,
    height: 361,
    margin: '0 auto',
    padding: theme.spacing(2, 2, 1.5),

    [theme.breakpoints.up('sm')]: {
      maxWidth: 216,
      height: 474,
      margin: 'initial',
      padding: theme.spacing(3, 3, 2)
    }
  }
}))

export const useMediaStyles = makeStyles((theme) => ({
  root: {
    height: 167,

    [theme.breakpoints.up('sm')]: {
      height: 235
    }
  }
}))

export const useContentStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.25)
    }
  }
}))
