import { makeStyles } from '@material-ui/core/styles'

const useMaterialStyles = makeStyles((theme) => ({
  mainIframe: {
    width: '100%',
    border: '1px solid #444',
    background: '#FFFFFF',
    padding: '50px',
    margin: '0 auto'
  },
  stepButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    height: 'auto',
    lineHeight: 'unset',
    width: 'auto',
    marginBottom: 20,
    textAlign: 'center',
    boxShadow: 'unset',
    borderRadius: 3,
    border: 'none',
    padding: '11px 20px',
    cursor: 'pointer',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    }
  },
  backButton: {
    textAlign: 'right',
    marginBottom: 15,
    cursor: 'pointer',
    color: theme.palette.primary.main
  }
}))

export default useMaterialStyles
