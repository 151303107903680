const base = {
  loading: false,
  data: null,
  error: null
}

export default {
  book: {
    ...base
  },
  books: {
    ...base,
    data: {
      books: null,
      page: 1,
      totalPages: 1,
      totalElements: 0
    }
  },
  catalogBooks: {
    ...base
  },
  libraryActualizeYourCode: {
    ...base
  },
  libraryBooks: {
    ...base
  },
  libraryBooksSimple: {
    ...base
  },
  libraryMaterial: {
    ...base
  },
  favoriteBooks: {
    ...base,
    data: []
  },
  recommendedBooks: {
    ...base,
    data: []
  },
  libraryTreeview: {
    ...base,
    menu: [],
    years: [],
    divisions: [],
    norms: [],
    articles: []
  },
  teacherUpload: {
    ...base
  },
  categories: {
    ...base,
    data: []
  },
  userProfileType: {
    ...base,
    data: []
  },
  userInterests: {
    ...base,
    data: []
  },
  userInterestsNotification: {
    ...base,
    data: []
  },
  userInterestsExist: {
    ...base
  },
  notifications: {
    ...base,
    data: []
  },
  lastBooks: {
    ...base,
    data: []
  }
}
