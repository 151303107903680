import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  breadcrumps: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    marginBottom: 24
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 28,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 21,
      paddingRight: 20,
      paddingTop: 28
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
      flexDirection: 'column'
    }
  },
  bookMain: {
    maxWidth: 240,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      margin: '0 auto',
      maxWidth: '100%',
      width: '100%'
    }
  },
  favoriteButton: {
    zIndex: 99,
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    top: -15,
    right: 10,
    width: 36,
    height: 36,
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    },
    [theme.breakpoints.down('sm')]: {
      right: 10
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 15,
      left: 100,
      marginTop: -15
    }
  },
  favoriteIcon: {
    margin: 'auto',
    color: theme.palette.text.primary
  },

  containerImg: {
    '&:hover .overlay': { opacity: 0.3 }
  },
  bookCover: {
    width: 202,
    height: 271,
    marginBottom: 16,
    marginRight: 20,
    borderRadius: 8,

    [theme.breakpoints.down('xs')]: {
      marginLeft: 20
    }
  },
  bookHover: {
    filter: 'brightness(50%)'
  },

  overlay: {
    color: '#f1f1f1',
    width: '91%',
    bottom: '0',
    padding: '20px',
    position: 'absolute',
    fontSize: '20px',
    textAlign: 'center',
    top: '0px',
    height: '93%',
    borderRadius: '8px',
    opacity: 0
  },

  overlayHover: {
    opacity: 1,
    cursor: 'pointer'
  },
  textOverlay: {
    color: 'white',
    font: 'roboto',

    fontWeight: 500,
    lineHeight: 26,
    letterSpacing: '0.46000000834465027px',
    fontSize: 18,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    opacity: 1
  },

  buttonPreview: {
    width: 202,
    marginBottom: 70,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      width: 202
    }
  },

  bookDescription: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 230px)',
    position: 'relative',
    marginLeft: 14,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0
    }
  },
  title: {
    color: theme.palette.text.primary,
    marginRight: 40,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  authors: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 8,
    marginBottom: 14,
    [theme.breakpoints.down('sm')]: {
      marginRight: 40
    }
  },
  divider: {
    marginBottom: 14
  },
  publisherLogo: {
    width: 86,
    height: 26,
    marginTop: 16,
    marginBottom: 20
  },
  price: {
    color: theme.palette.primary.dark,
    marginRight: 20
  },
  buttonBuy: {
    width: 145,
    height: 45,
    marginTop: 3,
    [theme.breakpoints.down('sm')]: {
      float: 'right',
      position: 'relative'
    }
  },
  ratingLabel: {
    fontFamily: 'Roboto',
    fontSize: 16,
    color: theme.palette.text.primary,
    marginLeft: 4,
    marginBottom: 4
  },
  ratingStar: {
    maxHeight: 20,
    maxWidth: 19.28,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 18.77,
      maxWidth: 20
    }
  },
  alert: {
    marginTop: 32,
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      color: 'black',
      marginTop: 16
    }
  },
  tagBeta: {
    borderRadius: 4,
    background: '#187A86',
    marginLeft: 8,
    display: 'flex',
    padding: '4px 8px',
    alignItems: 'center'
  },
  betaText: {
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 500
  }
}))
