import {
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_SUCCESS
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const notificationsReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_ERROR
  ])
}

export default notificationsReducer
