import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TrackingsService } from '../../../../services'

import BookAccordionCategory from '../BookAccordionCategory'
import BookAccordionItem from '../BookAccordionItem'
import ShowMaterial from './ShowMaterial'

export default function BookMaterials({ book, materials }) {
  const [lastAccordionOpen, setLastAccordionOpen] = useState()
  const [materialUrl, setMaterialUrl] = useState()
  const [categoriesNames, setCategoriesNames] = useState()
  const [materialType, setMaterialType] = useState()

  const handleClickSection = (title, id) => {
    TrackingsService.sendTrack('secao', book, {
      nome_secao: title
    })
    setLastAccordionOpen(id)
  }

  const renderTopicContainer = (section) => {
    const checkIfHasLinkMaterial = (materialsMap) => {
      let data = null
      materialsMap.forEach((material) => {
        if (material.materiais && material.materiais.length > 0) {
          const findData = checkIfHasLinkMaterial(material.materiais)
          if (findData) {
            material.children = findData
            data = material
          }
        }

        if (material.id === `ragnarok-${localStorage.getItem('materialId')}`) {
          material.isOpen = true
          data = material
        }
      })

      return data
    }

    section.materiais.forEach((material) => {
      if (material.materiais && material.materiais.length > 0) {
        const findData = checkIfHasLinkMaterial(material.materiais)

        if (findData) {
          material.children = findData
          material.isOpen = true
          section.isOpen = true
        }
      }

      if (material.id === `ragnarok-${localStorage.getItem('materialId')}`) {
        material.isOpen = true
        section.isOpen = true
      }
    })

    return (
      <BookAccordionCategory
        section={section}
        name={section.titulo}
        handleClickSection={handleClickSection}
        lastAccordionOpen={lastAccordionOpen}
        key={section.id}
        setMaterialType={setMaterialType}
        setCategoriesNames={setCategoriesNames}
        setMaterialUrl={setMaterialUrl}
        book={book}
      />
    )
  }

  const renderUncategorizedMaterial = (secao) => {
    return (
      <BookAccordionItem
        material={secao}
        categories={secao.titulo}
        key={secao.id}
        setMaterialType={setMaterialType}
        setCategoriesNames={setCategoriesNames}
        setMaterialUrl={setMaterialUrl}
        book={book}
      />
    )
  }

  const renderMaterialItemWithCategories = () => (
    <ShowMaterial
      categoriesNames={categoriesNames}
      lastAccordionOpen={lastAccordionOpen}
      handleClickSection={handleClickSection}
      materialType={materialType}
      materialUrl={materialUrl}
      setMaterialUrl={setMaterialUrl}
    />
  )

  const handleShowTopicsOrMaterial = () => {
    if (materials.length === 0)
      return <span>O livro não possui materiais complementares</span>
    if (materialUrl) return renderMaterialItemWithCategories()

    return materials.map((material) => {
      if (material.tipo === 'customizado' || material.tipo === 'topico') {
        return renderTopicContainer(material)
      }

      return renderUncategorizedMaterial(material)
    })
  }

  return <>{handleShowTopicsOrMaterial()}</>
}

BookMaterials.propTypes = {
  materials: PropTypes.instanceOf(Array).isRequired,
  book: PropTypes.oneOfType([PropTypes.object]).isRequired
}
