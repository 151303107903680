import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerSelects: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0, 0, 0),

    [theme.breakpoints.up(1228)]: {
      margin: theme.spacing(3, 0, 0, 0)
    }
  },
  selectItem: {
    paddingTop: theme.spacing(2),

    '&:first-child': {
      paddingTop: 0,

      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2)
      },

      [theme.breakpoints.up(1228)]: {
        paddingTop: 0
      }
    },

    [theme.breakpoints.up('sm')]: {
      flex: '0 1 216px',
      marginRight: theme.spacing(3)
    },

    [theme.breakpoints.up(1228)]: {
      paddingTop: 0
    }
  },
  select: {
    display: 'flex'
  },
  input: {
    ...theme.typography.subtitle1,
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(1)
  },
  removeFiltersContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(3)
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end'
    }
  },
  removeFilterButton: {
    ...theme.typography.button
  },
  containerMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}))
