import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { SaraivaTheme } from '@grupogen/saraiva-ui'
import './index.css'
import { SEO } from './components'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'

Bugsnag.start({
  apiKey: 'e395063e906d710611be7e69e20924f2',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <SaraivaTheme>
      <SEO
        title="Saraiva Conecta"
        description="Plataforma da Saraiva Educação"
      />
      <Routes />
    </SaraivaTheme>
  </ErrorBoundary>,
  document.getElementById('root')
)

reportWebVitals()
