import {
  BOOKS_LOADING,
  BOOKS_SUCCESS,
  BOOKS_ERROR
} from '../../../utils/actionTypes'
import { ContentsService, BookService } from '../../../services'
import { formatAuthorsName } from '../../../utils'

export const getBooksAction = async (dispatch, payload = {}) => {
  let hasError = false
  let dataToReturn = {}

  dispatch({
    type: BOOKS_LOADING
  })

  try {
    const response = await ContentsService.getCatalog(
      payload.page,
      payload.itemsPerPage,
      payload.categoria,
      payload.subcategoria,
      payload.termOfSearch,
      payload.orderBy,
      payload.filters,
      payload.caeIds
    )

    if (!response.ok) {
      const errors = await response.json()
      hasError = true

      dispatch({
        type: BOOKS_ERROR,
        payload: errors
      })
    }

    const data = await response.json()

    const getBooksRate = await BookService.getBooksRate(
      data && data.items.reduce((arr) => arr).map((i) => i.productCaeId)
    )

    const ratesByBook = await getBooksRate.json()

    ratesByBook.data.map((rate) => {
      data.items
        .reduce((i) => i)
        .filter((book) => {
          if (book.productCaeId === rate.book_id) book.rating = rate
          return null
        })
      return null
    })

    const [books] = data.items
    const booksCatalog = formatAuthorsName(books)
    dataToReturn = data

    dispatch({
      type: BOOKS_SUCCESS,
      payload: {
        books: booksCatalog,
        page: data.page,
        totalElements: data.totalElements,
        totalPages: data.totalPages
      }
    })
  } catch (error) {
    hasError = true

    dispatch({
      type: BOOKS_ERROR,
      payload: error
    })
  }

  return {
    ok: !hasError,
    data: dataToReturn
  }
}
