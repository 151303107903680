import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerNotFound: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 650,
    paddingTop: 105,
    margin: '0 auto',
    '& > img': {
      maxWidth: '100%',
      width: 408,
      height: 250,
      [theme.breakpoints.down('xs')]: {
        width: 266,
        height: 163,
        margin: '0 auto'
      }
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 85
    }
  },
  titleContent: {
    paddingTop: 34
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      fontWeight: 500
    }
  },
  subTitleContent: {
    paddingTop: 8
  },
  subtitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))
