import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  breadcrumps: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    marginBottom: 24
  }
}))

export default useStyles
