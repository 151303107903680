import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { GeneralContext } from '../../contexts/GeneralContext'
import { isAuthenticated } from '../../auth'
import DialogActivateBook from '../DialogActivateBook'
import { TrackingsService } from '../../services'
import Messages from '../../config/Messages'
import useStyles from './styles'

export default function ActivateBook() {
  const {
    setDialogActivateBook,
    setDialogNotAuthenticated,
    setDialogAuthClickTracking
  } = useContext(GeneralContext)

  const classes = useStyles()

  function verifyIsAuthenticated() {
    TrackingsService.sendTrack('ativacaoLivro')

    if (isAuthenticated()) {
      return setDialogActivateBook(true)
    }

    setDialogAuthClickTracking('ativacaoLivroLogin')

    return setDialogNotAuthenticated(Messages.notAuthenticate.activateCoupon)
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        fullWidth
        onClick={() => verifyIsAuthenticated()}
      >
        ATIVAR MATERIAIS
      </Button>

      <DialogActivateBook />
    </>
  )
}
