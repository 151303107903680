import {
  LIBRARY_MATERIAL_LOADING,
  LIBRARY_MATERIAL_SUCCESS,
  LIBRARY_MATERIAL_ERROR
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const libraryMaterialReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    LIBRARY_MATERIAL_LOADING,
    LIBRARY_MATERIAL_SUCCESS,
    LIBRARY_MATERIAL_ERROR
  ])
}

export default libraryMaterialReducer
