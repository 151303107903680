import {
  RECOMMENDED_BOOKS_ERROR,
  RECOMMENDED_BOOKS_LOADING,
  RECOMMENDED_BOOKS_SUCCESS
} from '../../../utils/actionTypes'
import baseReducer from '../../../utils/baseReducer'

const recommendedBooksReducer = (state, { payload, type }) => {
  return baseReducer(state, payload, type, [
    RECOMMENDED_BOOKS_LOADING,
    RECOMMENDED_BOOKS_SUCCESS,
    RECOMMENDED_BOOKS_ERROR
  ])
}

export default recommendedBooksReducer
