import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './styles'

export default function ShowVideos({ materialUrl }) {
  const classes = useStyles()

  return (
    <>
      <iframe
        className={classes.iframe}
        title="video-or-audio-material"
        src={materialUrl}
        allowFullScreen
      />
    </>
  )
}

ShowVideos.propTypes = {
  materialUrl: PropTypes.string.isRequired
}
