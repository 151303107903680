import {
  LIBRARY_TREEVIEW_LOADING,
  LIBRARY_TREEVIEW_MENU_SUCCESS,
  LIBRARY_TREEVIEW_NORMS_YEAR_SUCCESS,
  LIBRARY_TREEVIEW_NORMS_SUCCESS,
  LIBRARY_TREEVIEW_ARTICLE_SUCCESS,
  LIBRARY_TREEVIEW_CONTENT_SUCCESS,
  LIBRARY_TREEVIEW_ERROR
} from '../../../utils/actionTypes'

const libraryTreeviewReducer = (state, { payload, type }) => {
  switch (type) {
    case LIBRARY_TREEVIEW_LOADING:
      return {
        ...state,
        error: false,
        loading: true
      }

    case LIBRARY_TREEVIEW_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: payload
      }

    case LIBRARY_TREEVIEW_NORMS_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        years: [...state.years, payload]
      }

    case LIBRARY_TREEVIEW_NORMS_SUCCESS:
      return {
        ...state,
        loading: false,
        norms: [...state.norms, payload]
      }

    case LIBRARY_TREEVIEW_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: [...state.articles, payload]
      }

    case LIBRARY_TREEVIEW_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        contentFull: payload
      }

    case LIBRARY_TREEVIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default libraryTreeviewReducer
