import React from 'react'
import PropTypes from 'prop-types'
import { LocalPrintshop } from '@material-ui/icons'
import { Card, Divider, Grid } from '@material-ui/core'

import { Loading } from '../../../../components'

import useStyles from './styles'

const PrintContent = ({
  contentData,
  componentRef,
  handlePrint,
  contentLoading,
  handleTrackingPrint
}) => {
  const classes = useStyles()

  if (contentLoading) {
    return (
      <Grid style={{ textAlign: 'center', margin: '0 auto' }}>
        <Loading />
      </Grid>
    )
  }

  return (
    <Card
      className={classes.content}
      variant="outlined"
      onClick={handleTrackingPrint}
    >
      <>
        <div className={classes.contentHTML}>
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: contentData.content }}
            ref={componentRef}
          />
        </div>
        <Divider />

        <button
          type="button"
          className={classes.printButton}
          onClick={handlePrint}
        >
          <LocalPrintshop style={{ marginRight: 10 }} />
          <span>Imprimir</span>
        </button>
      </>
    </Card>
  )
}

PrintContent.propTypes = {
  handleTrackingPrint: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
  componentRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  contentData: PropTypes.oneOfType([PropTypes.object]),
  contentLoading: PropTypes.bool.isRequired
}

PrintContent.defaultProps = {
  contentData: null
}

export default PrintContent
