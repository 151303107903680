import { makeStyles } from '@material-ui/core/styles'

export const useNotificationStyles = makeStyles((theme) => ({
  notificationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap'
    }
  },
  notificationIcon: {
    fontSize: 65,
    alignSelf: 'center'
  },
  notificationContent: {
    alignSelf: 'center',
    marginTop: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginTop: 0
    }
  }
}))
