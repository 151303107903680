import { makeStyles } from '@material-ui/core/styles'

const useMaterialStyles = makeStyles((theme) => ({
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 35,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  tilte: {
    borderBottom: '1px solid #cdcdcd',
    paddingBottom: 20,
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: 0
  },
  questionsGrid: {
    marginBottom: 30,
    borderBottom: '1px solid #cdcdcd',
    paddingBottom: 20
  },
  questionsQuantity: {
    border: '2px solid #cdcdcd',
    borderRadius: 10,
    padding: '5px 10px',
    color: '#444',
    minWidth: 250,
    textAlign: 'center',
    '& span': {
      fontSize: '1rem'
    }
  },
  quetionsNumberGrid: {
    marginBottom: 15
  },
  questionNumber: {
    fontSize: '1.64rem',
    fontWeight: 400,
    marginTop: 0,
    marginBottom: '5px'
  },
  questionSeeDetails: {
    fontSize: '1rem',
    fontWeight: 500
  },
  questionSeeDetailsButton: {
    marginLeft: 5,
    width: '15%',
    marginBottom: 'unset',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    textAlign: 'center',
    boxShadow: 'unset',
    borderRadius: 3,
    border: 'none',
    verticalAlign: 'middle',
    cursor: 'pointer'
  },
  statusGrid: {
    textAlign: 'right'
  },
  statusGridSvg: {
    paddingRight: 10,
    width: 50,
    verticalAlign: 'middle',
    '&.success': {
      color: '#30a467'
    },
    '&.error': {
      color: '#ee795e'
    }
  },
  statusGridText: {
    color: 'white',
    display: 'inline-block',
    padding: 5,
    width: 100,
    textAlign: 'center',
    fontSize: '1rem',
    borderRadius: 3,
    '&.success': {
      backgroundColor: '#30a467'
    },
    '&.error': {
      backgroundColor: '#ee795e'
    }
  }
}))

export default useMaterialStyles
