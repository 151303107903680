export const RecommendedService = {
  async getRecommendedBooks(payload) {
    const result = await fetch(
      'https://us-central1-stone-facility-241418.cloudfunctions.net/baas_recommender',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }
    )
    return result
  }
}
