/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FileUploader } from 'react-drag-drop-files'
import { CloudUpload, AttachFile } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import useStyles from './styles'
import { getDeviceType } from '../../../../../utils'

const fileTypes = ['JPG', 'PNG', 'PDF', 'JPEG']

const DragFileUpload = ({ setFile }) => {
  const classes = useStyles()
  const [fileName, setFileName] = useState()

  const handleChangeImage = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    setFileName(file.name)
    setFile(formData)
  }

  const renderChildren = () => {
    return (
      <div>
        <CloudUpload className={classes.uploadIcon} />
        {getDeviceType() !== 'mobile' && !fileName && (
          <Typography variant="subtitle1" className={classes.uploadTextPrimary}>
            Arraste seu arquivo aqui ou
          </Typography>
        )}
        {!fileName && (
          <Typography
            variant="subtitle1"
            className={classes.uploadTextSecondary}
          >
            BUSQUE NO SEU{' '}
            {getDeviceType() === 'mobile' ? 'CELULAR' : 'COMPUTADOR'}
          </Typography>
        )}
        {fileName && (
          <>
            <Typography
              variant="subtitle1"
              className={classes.uploadFileNamePrimary}
            >
              {fileName} <AttachFile />
            </Typography>
            <Typography
              variant="caption"
              className={classes.uploadTextRenewPrimary}
            >
              Substituir Arquivo
            </Typography>
          </>
        )}

        {!fileName && (
          <Typography variant="subtitle1" className={classes.uploadTextPrimary}>
            (Máx/ 2 MB)
          </Typography>
        )}
      </div>
    )
  }

  return (
    <>
      <FileUploader
        handleChange={handleChangeImage}
        hoverTitle=" "
        classes={classes.boxHoverStyle}
        name="file"
        types={fileTypes}
        children={renderChildren()}
      />
    </>
  )
}

DragFileUpload.propTypes = {
  setFile: PropTypes.func.isRequired
}

export default DragFileUpload
