import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useContextSelector } from 'use-context-selector'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import useStyles from './styles'
import AccordionSubcategory from '../BookAccordionSubcategory'
import BookProfessorRequest from '../BookProfessorRequest'
import { GlobalContext } from '../../../../contexts/GlobalContext'

const BookAccordionCategory = ({
  section,
  name,
  handleClickSection,
  lastAccordionOpen,
  accordionOpen,
  ...props
}) => {
  let hasPermission = true
  const classes = useStyles()
  const { caeId } = useParams()
  const { globalState } = useContextSelector(GlobalContext, (user) => user)
  const { data: libraryBooks } = globalState.libraryBooks

  if (section.perfil && section.perfil.indexOf('professor') > -1) {
    const [book] = libraryBooks.products.filter(
      (libraryBook) => libraryBook.productCaeId === Number(caeId)
    )
    hasPermission = book && book.perfil?.indexOf('material-professor') > -1
  }

  const hasExpanded = section.materiais.filter(
    (materialFilter) =>
      materialFilter.id === lastAccordionOpen || materialFilter.isOpen
  )

  return (
    <>
      <Accordion
        className={classes.mainAccordion}
        classes={{ root: classes.line }}
        defaultExpanded={hasExpanded.length > 0 || accordionOpen}
      >
        <AccordionSummary
          className={classes.categoryLabel}
          expandIcon={<ExpandMoreRounded className={classes.expandIcon} />}
          data-testid="bookactived-accordion-summary"
        >
          <Typography className={classes.categoryName}>
            {section.titulo}
          </Typography>
        </AccordionSummary>
        {hasPermission && section.materiais ? (
          <AccordionSubcategory
            subSection={section.materiais}
            name={name}
            handleClickSection={handleClickSection}
            lastAccordionOpen={lastAccordionOpen}
            accordionOpen={accordionOpen}
            {...props}
          />
        ) : (
          <BookProfessorRequest {...props} />
        )}
      </Accordion>
    </>
  )
}

BookAccordionCategory.propTypes = {
  section: PropTypes.oneOfType([PropTypes.object]),
  name: PropTypes.string,
  handleClickSection: PropTypes.func.isRequired,
  lastAccordionOpen: PropTypes.string,
  accordionOpen: PropTypes.bool
}

BookAccordionCategory.defaultProps = {
  section: {},
  name: '',
  lastAccordionOpen: null,
  accordionOpen: false
}

export default BookAccordionCategory
