import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const GeneralContext = createContext()

function GeneralProvider({ children }) {
  const [dialogNotAuthenticated, setDialogNotAuthenticated] = useState('')
  const [dialogAuthClickTracking, setDialogAuthClickTracking] = useState('')
  const [dialogActivateBook, setDialogActivateBook] = useState(false)
  const [dialogHelpActivationBook, setDialogHelpActivationBook] =
    useState(false)
  const [openMockPopover, setOpenMockPopover] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [openChatBotDrawer, setOpenChatBotDrawer] = useState(false)

  return (
    <GeneralContext.Provider
      value={{
        dialogNotAuthenticated,
        setDialogNotAuthenticated,

        dialogAuthClickTracking,
        setDialogAuthClickTracking,

        dialogHelpActivationBook,
        setDialogHelpActivationBook,

        successMessage,
        setSuccessMessage,

        errorMessage,
        setErrorMessage,

        infoMessage,
        setInfoMessage,

        dialogActivateBook,
        setDialogActivateBook,

        openMockPopover,
        setOpenMockPopover,

        openChatBotDrawer,
        setOpenChatBotDrawer
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

GeneralProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { GeneralContext, GeneralProvider }
