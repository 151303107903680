import {
  AUTHENTICATED_ERROR,
  PROFILE_TYPE_LOADING,
  PROFILE_TYPE_ADD_SUCCESS,
  PROFILE_TYPE_ADD_ERROR
} from '../../../utils/actionTypes'
import { Users, UserService } from '../../../services'
import { isAuthenticated } from '../../../auth'
import Messages from '../../../config/Messages'

export const addProfileTypeAction = async (dispatch, payload = {}) => {
  let statusOk = true

  if (isAuthenticated()) {
    dispatch({
      type: PROFILE_TYPE_LOADING
    })

    const response = await UserService.setUserProfileType(
      Users.getUserId(),
      payload
    )
    const data = await response.json()

    if (response.ok) {
      dispatch({
        type: PROFILE_TYPE_ADD_SUCCESS,
        payload: data
      })
    } else {
      statusOk = false

      dispatch({
        type: PROFILE_TYPE_ADD_ERROR,
        payload: data.errors
      })
    }
  } else {
    statusOk = false

    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }

  return {
    ok: statusOk,
    isAuthenticated: isAuthenticated()
  }
}
