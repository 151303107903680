import { Link } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ContentsService, TrackingsService, Users } from '../../services'

const BannerHome = () => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth)
  const [banner, setBanner] = useState({})

  useEffect(() => {
    const handleWindowResize = () => {
      const newWidth = document.getElementById('responsive-link')?.clientWidth
      if (newWidth) {
        setContainerWidth(newWidth)
      }
    }

    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    async function getBanner() {
      try {
        const response = await ContentsService.getCampaignBanner()
        const result = await response.json()
        if (result.length > 0) {
          setBanner(result)
        }
      } catch (err) {
        console.error(err)
      }
    }
    getBanner()
  }, [])

  const eventTrack = () => {
    TrackingsService.sendTrack(
      'clicou_banner',
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  let imageToShow

  if (containerWidth <= 422) {
    imageToShow = banner[0]?.mobile_image
  } else if (containerWidth > 422 && containerWidth < 600) {
    imageToShow = banner[0]?.tablet_image
  } else {
    imageToShow = banner[0]?.web_image
  }

  return banner.length > 0 ? (
    <Link
      href={banner[0]?.redirection_url}
      id="responsive-link"
      style={{ display: 'inline-block', width: '100%', marginTop: 65 }}
      target="_blank"
      onClick={eventTrack}
    >
      <img
        src={imageToShow}
        alt="Responsive"
        style={{ maxWidth: '100%', height: 'auto', borderRadius: 11 }}
      />
    </Link>
  ) : null
}

export default BannerHome
