import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  bookTitle: {
    ...theme.typography.body2,
    lineHeight: '18px',
    color: theme.palette.text.primary,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    height: 54,

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.body1,
      height: 72
    }
  },
  bookText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: '14px',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    minHeight: 14,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '20px',
      minHeight: 20
    }
  }
}))

export const useIconButtonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) =>
      props.isFavorite ? '#cd444b' : theme.palette.background.default,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    padding: theme.spacing(0.5),
    right: theme.spacing(-1),
    top: theme.spacing(-1),

    [theme.breakpoints.up('sm')]: {
      padding: 6,
      right: theme.spacing(-1.5),
      top: theme.spacing(-1.5)
    },

    '& svg': {
      fontSize: 16,

      [theme.breakpoints.up('sm')]: {
        fontSize: 24
      }
    },

    '&:hover': {
      backgroundColor: (props) =>
        props.isFavorite ? '#cd444b' : theme.palette.grey[300]
    }
  }
}))
