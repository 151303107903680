import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, withStyles } from '@material-ui/core'
import { TrackingsService } from '../../../services'

export function CategoryButton({ category }) {
  const history = useHistory()

  const CustomButton = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.grey[200],
      padding: theme.typography.pxToRem(6),
      marginTop: theme.spacing(1.5),
      maxWidth: '100%'
    },
    label: {
      fontSize: theme.typography.pxToRem(8),
      fontWeight: 400,
      lineHeight: '8px',
      letterSpacing: '0.015em',
      textTransform: 'uppercase',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(10),
        lineHeight: '12px'
      }
    }
  }))(Button)

  const buttonStyle = {
    whiteSpace: `${category && category.length >= 25 ? 'wrap' : 'nowrap'}`
  }

  const changeCategory = (categoria) => {
    TrackingsService.sendTrack(
      'tagCategoria',
      {},
      {
        categoria
      }
    )

    history.push(`/categoria/${categoria}`)
    window.scroll(0, 0)
  }

  return (
    <CustomButton
      onClick={() => changeCategory(category)}
      color="secondary"
      style={buttonStyle}
      data-testid="card-item-category"
    >
      {category}
    </CustomButton>
  )
}

CategoryButton.propTypes = {
  category: PropTypes.string
}

CategoryButton.defaultProps = {
  category: ''
}
