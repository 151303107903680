/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Typography, Divider } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import {
  Button,
  Dialog,
  Snackbar,
  TextInput,
  Tooltip
} from '@grupogen/saraiva-ui'
import Avatar from '@material-ui/core/Avatar'
import { useStyles } from './styles'
import { UserService, Users } from '../../services'
import Messages from '../../config/Messages'

export default function UserArea() {
  const classes = useStyles()
  const fullName = Users.getFullName().split(' ')
  const name = fullName[0]
  const lastName = fullName[fullName.length - 1]
  const avatarName = `${name[0]}${lastName[0]}`
  const [editButton, setEditButton] = useState(false)
  const [alterPassword, setAlterPassword] = useState(false)
  const [editRole, setEditRole] = useState('')
  const [newInfo, setNewInfo] = useState('')
  const [error, setError] = useState('')
  const [userInfo, setUserInfo] = useState({})
  const [isValid, setIsValid] = useState(false)
  const [snackbar, setSnackbar] = useState({
    children: '',
    severity: 'success',
    show: false
  })

  const onEditChanges = (info) => {
    setEditButton(true)
    setEditRole(info)
    setNewInfo('')
    setError('')
  }

  const onEditPassword = () => {
    setAlterPassword(true)
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    let newError = ''

    if (editRole === 'Nome' || editRole === 'Sobrenome') {
      if (/\d/.test(inputValue) || /[!@#$%^&*(),.?":{}|<>]/.test(inputValue)) {
        newError = 'Não é possível inserir números ou símbolos nesse campo'
      }
    } else if (editRole === 'e-mail') {
      if (!inputValue.includes('@') || !inputValue.includes('.com')) {
        newError = `${event.target.value} não é um e-mail válido`
      }
    }

    setError(newError)
    setNewInfo(inputValue)

    setIsValid(newError === '' && inputValue.length > 0)
  }

  async function getUserInfo() {
    const userId = Users.getUserId()
    try {
      const response = await UserService.getUserInfo(userId)
      const result = await response.json()
      if (response.status === 200) {
        setUserInfo(result.information.User)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [editButton])

  async function editUser() {
    const userId = Users.getUserId()
    let data = {}
    switch (editRole) {
      case 'Nome':
        data = { first_name: newInfo, last_name: '', email: '', cpf: '' }
        break
      case 'Sobrenome':
        data = { first_name: '', last_name: newInfo, email: '', cpf: '' }
        break
      case 'e-mail':
        data = { first_name: '', last_name: '', email: newInfo, cpf: '' }
        break
      default:
        data = { first_name: '', last_name: '', email: '', cpf: '' }
    }
    try {
      const response = await UserService.editUserInfo(data, userId)
      if (response.status === 200) {
        setSnackbar({
          children: Messages.editUser.editSuccess,
          severity: 'success',
          show: true
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function sendEmail() {
    const data = {
      email: newInfo,
      integration_type: 'email_password'
    }
    try {
      const response = await UserService.recoverPasswordEmail(data)
      if (response.status === 204) {
        setSnackbar({
          children: Messages.passwordRecovery.emailSent,
          severity: 'success',
          show: true
        })
      }
      if (response.status === 401 || response.status === 404) {
        setSnackbar({
          children: Messages.passwordRecovery.incorrectData,
          severity: 'error',
          show: true
        })
      } else if (response.status === 503) {
        setSnackbar({
          children: Messages.passwordRecovery.systemUnavailable,
          severity: 'error',
          show: true
        })
      } else if (
        (response.status !== 204 && response.status === 401) ||
        (response.status === 404 && response.status !== 503)
      ) {
        setSnackbar({
          children: Messages.passwordRecovery.systemUnavailable,
          severity: 'error',
          show: true
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const closeSnackbar = () => {
    setSnackbar({ children: '', severity: 'info', show: false })
  }

  return (
    <>
      <Snackbar
        // eslint-disable-next-line
        children={snackbar.children}
        severity={snackbar.severity}
        show={snackbar.show}
        handleClose={closeSnackbar}
      />
      {editButton && (
        <Dialog
          open={editButton}
          title={`Alterar ${editRole}`}
          primaryButton="Alterar"
          secondaryButton="Cancelar"
          isDisabled={!isValid}
          handleConfirm={() => {
            editUser()
            setEditButton(false)
            setNewInfo('')
            setError('')
          }}
          handleClose={() => {
            setEditButton(false)
            setNewInfo('')
            setError('')
          }}
        >
          <div>
            <div style={{ display: 'grid' }}>
              <Typography
                color="textSecondary"
                component="span"
                className={classes.editDialogLabel}
              >
                Atual {editRole}
              </Typography>
              <Typography variant="body1" className={classes.editDialogInfo}>
                {editRole === 'Nome'
                  ? userInfo[0]?.first_name
                  : editRole === 'Sobrenome'
                  ? userInfo[0]?.last_name
                  : userInfo[0]?.email}
              </Typography>
            </div>
            <Divider style={{ marginTop: 8, marginBottom: 16, width: 552 }} />
            <TextInput
              size="full"
              label={`Novo ${editRole}`}
              value={newInfo}
              onChange={handleInputChange}
              error={error !== ''}
              helperText={error}
            />
          </div>
        </Dialog>
      )}
      {alterPassword && (
        <Dialog
          open={alterPassword}
          title="Redefinir Senha"
          primaryButton="Receber Instruções"
          secondaryButton="Cancelar"
          handleConfirm={() => {
            sendEmail()
            setAlterPassword(false)
            setNewInfo('')
            setError('')
          }}
          handleClose={() => {
            setAlterPassword(false)
            setNewInfo('')
            setError('')
          }}
        >
          <div>
            <Typography
              style={{ marginBottom: 24 }}
              variant="body1"
              color="textPrimary"
            >
              Insira seu e-mail associado à esta conta para receber instruções
              de redefinição de senha
            </Typography>
            <TextInput
              size="full"
              label="E-mail"
              value={newInfo}
              onChange={handleInputChange}
              error={error !== ''}
              helperText={error}
            />
          </div>
        </Dialog>
      )}
      <div className={classes.titleContainer}>
        <Avatar className={classes.avatar}>{avatarName}</Avatar>
        <span className={classes.subContainer}>
          <Typography className={classes.title}>
            {`Olá, ${userInfo[0]?.first_name} ${userInfo[0]?.last_name}!`}
          </Typography>
          <Typography className={classes.subTitle} variant="body2">
            Gerencie e visualize aqui suas informações de cadastro
          </Typography>
        </span>
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.infoCard}>
          <Typography>Nome</Typography>
          <Typography variant="body1" className={classes.userInfo}>
            {userInfo[0]?.first_name}
          </Typography>
        </div>
        <Tooltip title="Alterar informação" placement="top">
          <Button
            onClick={() => onEditChanges('Nome')}
            className={classes.editButtons}
          >
            <EditIcon className={classes.editIcons} />
          </Button>
        </Tooltip>
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.infoCard}>
          <Typography>Sobrenome</Typography>
          <Typography variant="body1" className={classes.userInfo}>
            {userInfo[0]?.last_name}
          </Typography>
        </div>
        <Tooltip title="Alterar informação" placement="top">
          <span>
            <Button
              className={classes.editButtons}
              onClick={() => onEditChanges('Sobrenome')}
            >
              <EditIcon className={classes.editIcons} />
            </Button>
          </span>
        </Tooltip>
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.infoCard}>
          <Typography>Email</Typography>
          <Typography variant="body1" className={classes.userInfo}>
            {userInfo[0]?.email}
          </Typography>
        </div>
        <Tooltip title="Alterar informação" placement="top">
          <span>
            <Button
              className={classes.editButtons}
              onClick={() => onEditChanges('e-mail')}
            >
              <EditIcon className={classes.editIcons} />
            </Button>
          </span>
        </Tooltip>
      </div>
      <Button
        startIcon={<VpnKeyIcon />}
        className={classes.changePasswordButton}
        onClick={() => onEditPassword()}
      >
        Redefinir Senha
      </Button>
    </>
  )
}
