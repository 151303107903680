import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { SEO } from '../../components'
import badRequestImageDesktop from '../../static/bad-request-error-image-desktop.png'
import badRequestImageMobile from '../../static/bad-request-error-image-mobile.png'
import { useStyles } from './styles'

export default function MaintenancePage() {
  const classes = useStyles()
  const location = useLocation()
  const [statuscode, setStatusCode] = useState(400)

  useEffect(() => {
    const code = location.state ? Number(location.state.code) : 0

    if (code === 500) {
      setStatusCode(500)
    } else {
      setStatusCode(400)
    }
  }, [location])

  return (
    <>
      <SEO title="Saraiva Conecta - Erro" description={`ERRO ${statuscode}`} />

      <Box className={classes.titleContainer}>
        <Box className={classes.titleContent}>
          <Typography
            variant="h1"
            color="primary"
            className={classes.title}
            data-testid="error-page-title"
          >
            O Conecta está com uma instabilidade.
          </Typography>
          <Typography variant="h6" className={classes.text}>
            Mas não se preocupe, já identificamos o problema e não estamos
            medindo esforços para solucioná-lo o mais breve possível!
          </Typography>
          <Typography variant="body1">
            Em nome de toda a equipe Saraiva, pedimos desculpas pelo
            incoveniente!
          </Typography>
        </Box>
      </Box>

      <Box className={classes.pictureContainer}>
        <picture>
          <source media="(min-width: 600px)" srcSet={badRequestImageDesktop} />
          <img src={badRequestImageMobile} alt="" />
        </picture>
      </Box>
    </>
  )
}
