import React, { useContext, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Button, Dialog, TextInput } from '@grupogen/saraiva-ui'
import { GeneralContext } from '../../contexts/GeneralContext'
import { getDeviceType } from '../../utils'
import Messages from '../../config/Messages'
import activateBook from '../../static/activateBook.png'
import useStyles from './style'
import { Users, UserService, TrackingsService } from '../../services'
import Links from '../../config/Links'

export default function DialogActivateBook() {
  const {
    dialogActivateBook,
    setDialogActivateBook,
    setSuccessMessage,
    setDialogHelpActivationBook
  } = useContext(GeneralContext)

  const [codeInput, setCodeInput] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleClickHelp = () => {
    setDialogHelpActivationBook(true)
  }

  const handleSendCode = async (event) => {
    event.preventDefault()

    if (!codeInput) return null

    const userId = Users.getUserId()

    const payload = {
      user_id: userId,
      code: codeInput,
      activated_at: new Date()
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '')
    }

    try {
      const response = await UserService.sendCouponCode(payload)

      if (!response.ok) {
        TrackingsService.sendTrack(
          'ativacaoLivroPorCupomError',
          {},
          {
            cupom_utilizado: codeInput,
            status_ativacao: false
          }
        )

        throw new Error(response.status)
      }

      const result = await response.json()

      TrackingsService.sendTrack(
        'ativacaoLivroPorCupom',
        {},
        {
          cae_livro: result.data.product.cod_produto,
          cupom_utilizado: codeInput,
          status_ativacao: true,
          user_name: Users.getFullName(),
          user_email: Users.getEmail()
        }
      )

      setDialogActivateBook(false)
      setSuccessMessage(Messages.activateBook.success)

      window.location = Links.library
    } catch (err) {
      if (err.message === '404') {
        setMessage(Messages.activateBook.notFound)
      } else if (err.message === '422') {
        setMessage(Messages.activateBook.noActivations)
      }

      setError(true)
    }

    return null
  }

  const instructionsBox = () => {
    return (
      <Box className={classes.instructionsBox}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.instructionsIntro}
        >
          {Messages.activateBook.instructionsIntro}
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.instructionsComplement}
        >
          {Messages.activateBook.instructionsComplement}
        </Typography>

        <ul className={classes.instructionsList}>
          {Messages.activateBook.instructionsSteps.map((step) => (
            <li key={step.id}>
              <Typography variant="body1" color="textSecondary">
                {step.label}
              </Typography>
            </li>
          ))}
        </ul>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.instructionsMiddle}
        >
          {Messages.activateBook.instructionsMiddle}
          <span>
            {Messages.activateBook.instructionsHelp}{' '}
            <Button
              color="secondary"
              onClick={handleClickHelp}
              className={classes.helpButton}
            >
              {Messages.activateBook.instructionsHelpButton}
            </Button>
          </span>
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.instructionsEnd}
        >
          {Messages.activateBook.instructionsEnd}
        </Typography>

        <TextInput
          color="primary"
          label="Insira aqui o código de ativação"
          value={codeInput}
          error={error}
          helperText={message}
          className={classes.textInput}
          size={getDeviceType() === 'mobile' ? 'full' : 'lg'}
          onChange={(event) => setCodeInput(event.target.value)}
        />
      </Box>
    )
  }

  return (
    <Dialog
      open={Boolean(dialogActivateBook)}
      title="Ativando os materiais complementares por código"
      size={isMobile ? 'full' : 'lg'}
      secondaryButton="FECHAR"
      primaryButton="ATIVAR MATERIAIS"
      handleConfirm={(event) => handleSendCode(event)}
      handleClose={() => {
        setDialogActivateBook(false)
        setCodeInput('')
        setMessage('')
        setError(false)
      }}
    >
      <div className={classes.containerActivateBook}>
        <div className={classes.imageDiv}>
          <img
            src={activateBook}
            className={classes.imageActivateBook}
            alt="Mulher sentada, pesquisando no computador"
          />
        </div>

        <div className={classes.dialogContent}>{instructionsBox()}</div>
      </div>
    </Dialog>
  )
}
