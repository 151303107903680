import React from 'react'
import { Box, Typography } from '@material-ui/core'
import SaraivaConecta from '../../../../static/saraivaconecta.png'
import useStyles from './styles'

export default function IntroStep() {
  const classes = useStyles()

  return (
    <Box className={classes.stepWrapper}>
      <Box className={classes.imageWrapper}>
        <img
          src={SaraivaConecta}
          alt="Logo do Saraiva Conecta"
          className={classes.image}
        />
      </Box>

      <Box className={classes.contentWrapper}>
        <Typography className={classes.introText}>
          Para realizar a ativação de seus livros é bem simples.
        </Typography>

        <Typography className={classes.middleText}>
          O primeiro passo é identificar qual o tipo de ativação do livro. No
          Conecta{' '}
          <span className={classes.textHighlight}>
            os livros podem ser ativados de duas maneiras:
          </span>
          <ul className={classes.list}>
            <li>Código de ativação</li>
            <li>Link</li>
          </ul>
        </Typography>

        <Typography className={classes.endText}>
          Para saber qual o seu tipo procure na página do Conecta que{' '}
          <span className={classes.textHighlight}>
            pode ser encontrada nas primeiras páginas do seu livro.
          </span>
        </Typography>
      </Box>
    </Box>
  )
}
