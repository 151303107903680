import {
  AUTHENTICATED_ERROR,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ERROR
} from '../../../utils/actionTypes'
import { NotificationService, LibraryService } from '../../../services'
import { Messages, systemNotifications } from '../../../config'
import { isAuthenticated } from '../../../auth'

export const getNotificationsAction = async (dispatch, dataToStore) => {
  let hasError = false
  let data

  const handleError = (response) => {
    if (!response.ok) {
      hasError = true
      throw response.status
    }
  }

  if (isAuthenticated()) {
    dispatch({
      type: NOTIFICATIONS_LOADING
    })

    const storedNotifications = sessionStorage.getItem('storedNotifications')

    if (dataToStore) {
      sessionStorage.setItem('storedNotifications', JSON.stringify(dataToStore))

      dispatch({
        type: NOTIFICATIONS_SUCCESS,
        payload: dataToStore
      })
    } else if (storedNotifications) {
      data = JSON.parse(storedNotifications)

      data = data.map((item) => {
        return { ...item, time: new Date(item.time), onClick: () => null }
      })

      dispatch({
        type: NOTIFICATIONS_SUCCESS,
        payload: data
      })
    } else {
      try {
        const response = await NotificationService.getNotification()

        handleError(response)

        const notifications = await response.json()

        const activeNotifications = notifications.filter((book) => book.active)

        const caeIds = activeNotifications.map((notification) => {
          return notification.new_cae_id
        })

        // eslint-disable-next-line
        if (caeIds.length) {
          const catalogResponse = await LibraryService.loadBook(
            caeIds.join(',')
          )

          const bookList = await catalogResponse.json()

          const booksNotifications = activeNotifications.reduce(
            (acc, notification) => {
              const notificationBook =
                bookList.find(
                  (book) => book.productCaeId === notification.new_cae_id
                ) || null

              if (notificationBook) {
                return [...acc, { ...notification, book: notificationBook }]
              }

              return [...acc]
            },
            []
          )

          data = booksNotifications.map((notification) => {
            const { book, new_cae_id, seen } = notification

            return {
              type: 'content',
              id: notification.id,
              isUnread: !notification.seen,
              onClick: () => null,
              title: `Nova edição | ${book.titleCover} ${book.editionYear}`,
              content: `Veja a ${book.edition}ª edição do livro ${book.titleCover}`,
              time: new Date(),
              new_cae_id,
              seen,
              bookTitleCover: book.titleCover,
              bookEdition: book.edition
            }
          })
        } else {
          data = []
        }

        const totalNotifications = [...systemNotifications, ...data]

        sessionStorage.setItem(
          'storedNotifications',
          JSON.stringify(totalNotifications)
        )

        dispatch({
          type: NOTIFICATIONS_SUCCESS,
          payload: totalNotifications
        })
      } catch (error) {
        hasError = true

        dispatch({
          type: NOTIFICATIONS_ERROR,
          payload: error.message
        })
      }
    }
  } else {
    hasError = true

    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }

  return {
    ok: !hasError,
    data
  }
}
