import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import BookAccordionCategory from '../../BookAccordionCategory'
import ReturnButton from '../ReturnButton'
import ShowAudios from '../ShowAudios'
import ShowVideos from '../ShowVideos'
import ShowQuestions from '../ShowQuestions'

export default function ShowMaterial({
  materialType,
  categoriesNames,
  materialUrl,
  setMaterialUrl
}) {
  const myRef = useRef()

  const renderMaterial = (type) => {
    if (type === 'audio') {
      return <ShowAudios materialUrl={materialUrl} />
    }
    if (type === 'banco-de-questoes') {
      return <ShowQuestions materialUrl={materialUrl} />
    }
    return <ShowVideos materialUrl={materialUrl} />
  }

  useEffect(() => {
    if (myRef.current !== null) {
      window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop })
    }
  }, [myRef])

  return (
    <div ref={myRef}>
      <BookAccordionCategory
        section={categoriesNames}
        name={categoriesNames.titulo}
        handleClickSection={() => null}
        accordionOpen
      />
      <ReturnButton setMaterialUrl={setMaterialUrl} />
      {renderMaterial(materialType)}
    </div>
  )
}

ShowMaterial.propTypes = {
  categoriesNames: PropTypes.oneOfType([PropTypes.object]),
  materialType: PropTypes.string.isRequired,
  materialUrl: PropTypes.string.isRequired,
  setMaterialUrl: PropTypes.func.isRequired
}

ShowMaterial.defaultProps = {
  categoriesNames: {}
}
