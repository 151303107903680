import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from '@material-ui/core'
import { getDeviceType } from '../../utils'
import useStyles from './styles'
import { TrackingsService } from '../../services'

const BookTabsBody = ({ children, book, tabValue }) => {
  const classes = useStyles()

  const [selectedContent] = children.filter((tab, index) => tabValue === index)

  const handleTrackClick = () => {
    TrackingsService.sendTrack('reportarErro', book)
  }

  return (
    <>
      <div className={classes.containerContentTabs}>
        {children.map((tab, index) => (
          <div key={tab.label}>
            {tabValue === index && <div>{tab.content}</div>}
          </div>
        ))}
      </div>
      {selectedContent && !selectedContent.dontShowBodyContact && (
        <Card className={classes.contato} variant="outlined">
          <Typography
            variant={getDeviceType() === 'mobile' ? 'caption' : 'body1'}
          >
            Encontrou algum erro na edição do seu livro? Conte-nos enviando uma
            mensagem no email abaixo: <br />
          </Typography>

          <Typography
            variant={getDeviceType() === 'mobile' ? 'caption' : 'body1'}
            style={{ marginTop: 12 }}
          >
            <a
              target="_top"
              onClick={handleTrackClick}
              href="mailto:sac.sets@saraivaeducacao.com.br"
              className={classes.email}
            >
              sac.sets@saraivaeducacao.com.br
            </a>
          </Typography>
        </Card>
      )}
    </>
  )
}

BookTabsBody.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tabValue: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.element.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
}

export default BookTabsBody
