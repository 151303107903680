import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  errorContent: {
    paddingTop: 10,
    paddingBottom: 10
  },
  titleText: {
    marginBottom: 20
  },
  iconContent: {
    marginBottom: 20,
    '& svg': {
      color: '#A42C31',
      fontSize: 52
    }
  },
  linkText: {
    color: '#624C92',
    cursor: 'pointer'
  }
}))

export default useStyles
