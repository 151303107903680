import {
  BOOK_LOADING,
  BOOK_SUCCESS,
  BOOK_ERROR
} from '../../../utils/actionTypes'

const bookReducer = (state, { payload, type }) => {
  switch (type) {
    case BOOK_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      }

    case BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case BOOK_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default bookReducer
