import {
  LIBRARY_TREEVIEW_LOADING,
  LIBRARY_TREEVIEW_NORMS_SUCCESS,
  LIBRARY_TREEVIEW_ERROR
} from '../../../../utils/actionTypes'
import { LibraryTreeviewService } from '../../../../services'

export const getLibraryTreeviewNormsAction = async (dispatch, payload) => {
  dispatch({
    type: LIBRARY_TREEVIEW_LOADING
  })

  const treeviewResponse = await LibraryTreeviewService.getNorms(
    payload.parent_id,
    payload.id
  )
  const treeviewData = await treeviewResponse.json()

  if (treeviewResponse.ok) {
    treeviewData.forEach((data) => {
      const div = document.createElement('div')
      div.innerHTML = data.titulo
      data.titulo = div.innerText
      data.parent_id = payload.parent_id
    })

    dispatch({
      type: LIBRARY_TREEVIEW_NORMS_SUCCESS,
      payload: {
        data: treeviewData,
        key: `${payload.parent_id}-${payload.id}`
      }
    })
  } else {
    dispatch({
      type: LIBRARY_TREEVIEW_ERROR,
      payload: treeviewData.error
    })
  }

  return {
    ok: treeviewResponse.ok
  }
}
