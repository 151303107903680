import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useContextSelector } from 'use-context-selector'
import { useReactToPrint } from 'react-to-print'

import { GlobalContext } from '../../../../contexts/GlobalContext'
import {
  getLibraryActualizeYourCode,
  resetLibraryActualizeYourCode
} from '../../../../contexts/actions'
import { TrackingsService } from '../../../../services'

import { getDeviceType } from '../../../../utils'

import Menu from './Menu'
import DefaultContent from './DefaultContent'
import PrintContent from '../PrintContent'

import useStyles from './styles'

const ActualizeYourCode = ({ book, materials }) => {
  const componentRef = useRef()
  const contentRef = useRef()
  const classes = useStyles()
  const [secao, setSecao] = useState()
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (libraryActualizeYourCode) => libraryActualizeYourCode
  )

  const { data: contentData, loading: contentLoading } =
    globalState.libraryActualizeYourCode

  const handleSelectContent = async (event, data) => {
    event.preventDefault()

    if (getDeviceType() !== 'desktop') {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    await TrackingsService.sendTrack('atualizeSeuCodigoSecao', book, data)
    getLibraryActualizeYourCode(globalDispatch, { url: data.url })
    setSecao(data)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const handleTrackingPrint = () => {
    TrackingsService.sendTrack('atualizeSeuCodigImprimir', book, secao)
  }

  useEffect(() => {
    resetLibraryActualizeYourCode(globalDispatch)
  }, [globalDispatch])

  return (
    <div className={classes.container}>
      {getDeviceType() !== 'desktop' && !contentData && !contentLoading ? (
        <DefaultContent />
      ) : null}

      <Menu handleSelectContent={handleSelectContent} materials={materials} />

      <div
        ref={contentRef}
        style={{
          width: '100%',
          display: contentData || contentLoading ? 'block' : 'none',
          maxHeight: 500
        }}
      >
        {contentData || contentLoading ? (
          <PrintContent
            contentData={contentData}
            componentRef={componentRef}
            handlePrint={handlePrint}
            contentLoading={contentLoading}
            handleTrackingPrint={handleTrackingPrint}
          />
        ) : null}
      </div>

      {getDeviceType() === 'desktop' && !contentData && !contentLoading ? (
        <DefaultContent />
      ) : null}
    </div>
  )
}

ActualizeYourCode.propTypes = {
  book: PropTypes.oneOfType([PropTypes.object]).isRequired,
  materials: PropTypes.instanceOf(Array).isRequired
}

export default ActualizeYourCode
