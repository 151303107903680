import { useEffect } from 'react'
import { useContextSelector } from 'use-context-selector'

import { GlobalContext } from '../contexts/GlobalContext'
import {
  getLibraryBooksAction,
  getFavoriteBooksAction,
  removeFavoriteBookAction,
  addFavoriteBookAction
} from '../contexts/actions'

import { Users } from '../services'
import { getBookFavoriteStatus } from '../utils'
import Messages from '../config/Messages'

export function useLibrary(setSuccessMessage, setErrorMessage) {
  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const { data, loading } = globalState.libraryBooks
  const { data: favoriteBooks, loading: favoriteLoading } =
    globalState.favoriteBooks

  const onFavorite = async (productCaeId) => {
    const params = {
      favorite_type: 'cae_id',
      favorite_id: productCaeId,
      user_id: Users.getUserId()
    }
    const favoriteId = getBookFavoriteStatus(favoriteBooks, productCaeId)

    if (favoriteId) {
      const response = await removeFavoriteBookAction(
        globalDispatch,
        favoriteId
      )
      if (!response.ok) setErrorMessage(Messages.favorite.unfavoriteError)
      else setSuccessMessage(Messages.favorite.unfavorite)
    } else {
      const response = await addFavoriteBookAction(globalDispatch, params)
      if (!response.ok) setErrorMessage(Messages.favorite.unfavoriteError)
      else setSuccessMessage(Messages.favorite.success)
    }
  }

  // console.log(globalState)

  useEffect(() => {
    getLibraryBooksAction(globalDispatch)
    getFavoriteBooksAction(globalDispatch)
  }, [globalDispatch])

  return {
    libraryBooks: data,
    libraryBooksLoading: loading,
    favoriteBooks,
    favoriteLoading,
    onFavorite
  }
}
