import { getSessionToken, parseJwt } from '../auth'

export const Users = {
  getFullName() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    if (user) return `${user.first_name} ${user.last_name}`
    return ''
  },

  getEmail() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    if (user) return user.email
    return ''
  },

  getUserId() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    if (user) return user.id
    return null
  },

  getIntegrationType() {
    const token = getSessionToken()
    const { user } = parseJwt(token)

    if (user) return user.integration_type
    return null
  }
}
