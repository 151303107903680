import React from 'react'

import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { MenuBook, Edit, BookRounded, ListAlt } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { PageCards } from './components'
import {
  useCardStyles,
  useContentStyles,
  useMediaStyles,
  useStyles
} from './styles'
import { TrackingsService, Users } from '../../services'
import { CustomCard, Recommended } from '../../components'
import { isAuthenticated } from '../../auth'
import { useBooks, useRecommendations, useLastBooks } from '../../hooks'
import BannerHome from '../../components/BannersHome'

const Homepage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const cardClasses = useCardStyles()
  const imageClasses = useMediaStyles()
  const contentClasses = useContentStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const { libraryBooks, favorites, favoriteLoading } = useBooks(false)
  const { recommendedBooks, loadingRecommendedBooks } = useRecommendations()
  // eslint-disable-next-line no-unused-vars
  const { lastBooks, loadingLastBooks } = useLastBooks()

  const history = useHistory()

  const handleTracking = (eventName) => {
    TrackingsService.sendTrack(
      eventName,
      {},
      {
        user_name: Users.getFullName(),
        user_email: Users.getEmail()
      }
    )
  }

  const openSimuladosInNewTab = () => {
    handleTracking('simulated')
    const url =
      'https://materiais.saraivaeducacao.com.br/novidades-exclusivas-simulados-conecta'
    const win = window.open(url)
    win.focus()
  }

  const pageCardList = [
    {
      title: 'Meus materiais',
      description: 'Ative e acesse o material complementar de seus livros.',
      action: () => {
        handleTracking('myMaterials')
        history.push('/biblioteca')
      },
      icon: <MenuBook />
    },
    {
      title: 'Simulados',
      description: 'Faça simulados sobre os conteúdos dos seus livros.',
      action: openSimuladosInNewTab,
      icon: <Edit />
    },
    {
      title: 'Meus e-Books',
      description: 'Acesse e leia seus e-Books no nosso leitor digital.',
      action: () => {
        handleTracking('accessedMyEbooks')
        history.push('/meus-ebooks')
      },
      icon: <BookRounded />
    },
    {
      title: 'Catálogo Saraiva',
      description: 'Confira os livros disponíveis em nosso catálogo.',
      action: () => {
        handleTracking('abaCatalogo')
        history.push('/catalogo')
      },
      icon: <ListAlt />
    }
  ]

  const text = isMobile
    ? 'Conteúdos exclusivos dos livros da Saraiva'
    : 'No conecta você tem acesso a conteúdos exclusivos dos livros da Saraiva'

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title}>Boas-vindas ao</Typography>
          <Typography className={classes.titleConecta}>
            Saraiva Conecta
          </Typography>
        </Box>

        <Typography className={classes.subTitle}>DIFERENCIAIS</Typography>
        <Typography className={classes.body}>{text}</Typography>
        <Divider className={classes.divider} />

        <Box className={classes.cardcontainer}>
          <Box className={classes.cards}>
            {pageCardList.map((card) => (
              <PageCards
                title={card.title}
                description={card.description}
                action={card.action}
                icon={card.icon}
                onClick={() => handleTracking(card.title)}
              />
            ))}
          </Box>
        </Box>

        {lastBooks.length !== 0 ? (
          <>
            <Box style={{ marginTop: '64px' }}>
              <Typography className={classes.subTitle}>
                SEUS MATERIAIS
              </Typography>
              <Typography className={classes.body}>
                Continue seus estudos e leituras
              </Typography>
            </Box>
            <Divider className={classes.divider} />

            <div style={{ display: 'flex', gap: '37px', overflow: 'overlay' }}>
              {lastBooks.map((book) => (
                <CustomCard
                  book={book}
                  libraryBooks={lastBooks}
                  imageHoverText={
                    book.productCategory === 'Livro Digital'
                      ? 'VER LIVRO'
                      : 'MATERIAIS'
                  }
                  image={book.imageUrl}
                  cardClass={cardClasses}
                  imageClass={imageClasses}
                  contentClass={contentClasses}
                  showFavorite={false}
                />
              ))}
            </div>
          </>
        ) : (
          <div>
            <></>
          </div>
        )}

        <BannerHome />

        {isAuthenticated() && (
          <Recommended
            libraryBooks={libraryBooks}
            favorites={favorites}
            favoriteLoading={favoriteLoading}
            recommendedBooks={recommendedBooks}
            loading={loadingRecommendedBooks}
          />
        )}
      </Box>
    </>
  )
}

export default Homepage
