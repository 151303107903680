import React from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs } from '@grupogen/saraiva-ui'

import { useBooks, useError } from '../../hooks'
import { TrackingsService } from '../../services'

import useStyles from './styles'
import { Catalog, Loading, SEO } from '../../components'

export default function Category() {
  const contentBreadCrumbs = [
    {
      label: `Catálogo`,
      href: `/`
    }
  ]

  const classes = useStyles()
  const history = useHistory()
  const { redirectOnError } = useError()

  const {
    books,
    loadingBooks,
    totalPages,
    totalItems,
    page,
    showPageItems,
    searchParams,
    isSearch,
    categoria,
    subcategoria,
    selectsList,
    libraryBooks,
    libraryLoading,
    favorites,
    favoriteLoading,
    handleClickPage,
    setSearchParams,
    setSelectsList,
    errorBooks
  } = useBooks(false, true)

  const handleTrackCategorie = (isSubcategorie, path) => {
    TrackingsService.sendTrack(
      isSubcategorie ? 'categoriaBreadcrumb' : 'subCategoriaBreadcrumb'
    )

    history.push(path)
  }

  const getDecodedCategoryName = () => {
    return subcategoria
      ? decodeURIComponent(subcategoria)
      : decodeURIComponent(categoria)
  }

  redirectOnError(errorBooks)

  if (categoria) {
    contentBreadCrumbs.push({
      label: `${decodeURIComponent(categoria)}`,
      onClick: () => handleTrackCategorie(false, `/categoria/${categoria}`)
    })
  }

  if (subcategoria) {
    contentBreadCrumbs.push({
      label: `${decodeURIComponent(subcategoria)}`,
      onClick: () =>
        handleTrackCategorie(true, `/categoria/${categoria}/${subcategoria}`)
    })
  }

  if (loadingBooks || libraryLoading || favoriteLoading) {
    return <Loading />
  }

  return (
    <div data-testid="categorie-page">
      <SEO
        title={`Saraiva Conecta - ${getDecodedCategoryName()}`}
        description={`Catálogo - ${getDecodedCategoryName()}`}
      />

      <div data-testid="breadcrumb">
        <Breadcrumbs color="primary" className={classes.breadcrumps}>
          {contentBreadCrumbs}
        </Breadcrumbs>
      </div>

      <Catalog
        listBooks={books}
        libraryBooks={libraryBooks}
        totalPages={totalPages}
        totalItems={totalItems}
        page={page}
        handleClickPage={handleClickPage}
        showPageItems={showPageItems}
        isLoading={loadingBooks}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        favorites={favorites}
        isSearch={isSearch}
        categoria={categoria}
        subcategoria={subcategoria}
        selectsList={selectsList}
        setSelectsList={setSelectsList}
      />
    </div>
  )
}
