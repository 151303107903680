import { makeStyles } from '@material-ui/core/styles'

import { darken } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(6.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    padding: theme.spacing(0, 2),
    maxHeight: 'calc(100vh - 332px)',
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      marginTop: 'initial',
      marginBottom: 'initial',
      padding: 0,
      maxHeight: 'initial'
    }
  },
  title: {
    ...theme.typography.h5,
    color: '#5E4E8E',

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h4
    }
  },
  subTitle: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h5,
      marginTop: theme.spacing(3)
    }
  },
  text: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1.5),
    marginBottom: '82px',

    [theme.breakpoints.up('md')]: {
      ...theme.typography.body1,
      marginTop: theme.spacing(3),
      marginBottom: 'initial'
    }
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: `calc(40px + env(safe-area-inset-bottom))`,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      position: 'initial',
      marginTop: theme.spacing(6),
      marginBottom: 0,
      padding: 0
    }
  },
  formGroup: {
    maxHeight: '100%',
    maxWidth: 676,
    marginTop: theme.spacing(2.5),
    marginBottom: '100px',
    paddingLeft: theme.spacing(1.5),
    overflowY: 'scroll',

    [theme.breakpoints.up('md')]: {
      marginBottom: 'initial',
      maxHeight: 206,
      marginTop: theme.spacing(5)
    },

    '&::-webkit-scrollbar': {
      width: 10
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D9D9D9',
      borderRadius: 4,
      height: 42,

      [theme.breakpoints.up('md')]: {
        height: 54
      }
    },

    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: darken('#D9D9D9', 0.2)
    }
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1.75)
    },

    '&:first-child': {
      marginTop: 0
    }
  },
  buttonSubmit: {
    marginLeft: theme.spacing(1.5),

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3)
    }
  }
}))
