import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useContextSelector } from 'use-context-selector'

import { Drawer, Hidden, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import { GlobalContext } from '../../contexts/GlobalContext'
import { getCategoriesAction } from '../../contexts/actions/categories/getCategoriesAction'

import TabletDrawer from './TabletDrawer'
import DesktopDrawer from './DesktopDrawer'
import MobileDrawer from './MobileDrawer'
import useStyles from './styles'

export default function MenuDrawer({ isMobileDrawerOpen, toggleDrawer }) {
  const classes = useStyles()
  const theme = useTheme()

  const { globalState, globalDispatch } = useContextSelector(
    GlobalContext,
    (state) => state
  )

  const { data: categories, loading: categoriesLoading } =
    globalState.categories

  const variant = useMediaQuery(theme.breakpoints.up('sm'))
    ? 'permanent'
    : 'temporary'

  useEffect(() => {
    if (!categories.length) {
      getCategoriesAction(globalDispatch)
    }
  }, [categories, globalDispatch])

  if (categoriesLoading) {
    return null
  }

  return (
    <Drawer
      variant={variant}
      className={classes.root}
      open={isMobileDrawerOpen}
      onClose={toggleDrawer}
      ModalProps={{ hideBackdrop: true }}
      elevation={0}
      SlideProps={{ direction: 'up' }}
      transitionDuration={theme.transitions.duration.drawer}
    >
      <Hidden smUp>
        <MobileDrawer toggleDrawer={toggleDrawer} categories={categories} />
      </Hidden>
      <Hidden xsDown mdUp>
        <TabletDrawer
          data-testid="layout-drawer-tablet"
          categories={categories}
        />
      </Hidden>
      <Hidden smDown>
        <DesktopDrawer categories={categories} />
      </Hidden>
    </Drawer>
  )
}

MenuDrawer.propTypes = {
  isMobileDrawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func
}

MenuDrawer.defaultProps = {
  isMobileDrawerOpen: true,
  toggleDrawer: () => {}
}
