import { useCallback, useState } from 'react'

export function useSessionStorage(key, initialValue = '') {
  const [state, setState] = useState(() => {
    try {
      const storedValue = sessionStorage.getItem(key)
      return storedValue ? JSON.parse(storedValue) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = useCallback(
    (value) => {
      try {
        setState(value)
        sessionStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.log(error)
      }
    },
    [key]
  )

  return [state, setValue]
}
