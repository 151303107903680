import {
  PROFILE_INTERESTS_NOTIFICATION_LOADING,
  PROFILE_INTERESTS_NOTIFICATION_SUCCESS,
  PROFILE_INTERESTS_NOTIFICATION_ERROR,
  PROFILE_INTERESTS_NOTIFICATION_ADD_SUCCESS,
  PROFILE_INTERESTS_NOTIFICATION_ADD_ERROR
} from '../../../utils/actionTypes'

const userInterestsNotificationReducer = (state, { payload, type }) => {
  switch (type) {
    case PROFILE_INTERESTS_NOTIFICATION_LOADING:
      return {
        ...state,
        error: false,
        loading: true
      }

    case PROFILE_INTERESTS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_INTERESTS_NOTIFICATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_INTERESTS_NOTIFICATION_ADD_ERROR:
      return {
        ...state,
        loading: false,
        data: payload
      }

    case PROFILE_INTERESTS_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}

export default userInterestsNotificationReducer
