import React from 'react'
import PropTypes from 'prop-types'
import { TreeView } from '@grupogen/saraiva-ui'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'

import { getOffset } from '../../../../../utils'

import useStyles from './styles'

const LoadingItem = [
  {
    id: 'loading',
    name: 'Carregando...'
  }
]

const Menu = ({
  menu,
  years,
  norms,
  articles,
  handleGetArticles,
  handlePrepareArticle,
  handleGetNormsYears,
  handleGetNorms
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleGetData = (event, data) => {
    if (event.target.parentNode.parentElement.ariaExpanded === 'true') return
    if (data.type === 'menu') {
      const [hasYear] = years.filter((year) => year.key === data.id)
      if (!hasYear) {
        handleGetNormsYears(data)
      }
    } else if (data.type === 'year') {
      const [hasNorms] = norms.filter(
        (item) => item.key === `${data.parent_id}-${data.id}`
      )
      if (!hasNorms) {
        handleGetNorms(data)
      }
    } else if (data.type === 'norm') {
      const [article] = articles.filter((item) => item.key === data.id)
      if (!article) {
        handleGetArticles(data)
      }
    } else if (data.type === 'article') {
      if (document.getElementById('append-artcile')) {
        const { y } = getOffset(document.getElementById('append-artcile'))
        const titleOffset = isMobile ? 48 : 124
        window.scroll(0, y - titleOffset)
      }

      handlePrepareArticle(data)
    }
  }

  const mountArticlesTree = (normItem) => {
    const [articlesFinded] = articles.filter((item) => item.key === normItem.id)

    if (articlesFinded) {
      articlesFinded.data.map((norm) => {
        norm.name = norm.titulo
        norm.parent_id = normItem.id
        norm.type = 'article'
        return norm
      })

      return articlesFinded.data
    }

    return null
  }

  const mountNormTree = (year) => {
    const [normsByYear] = norms.filter(
      (norm) => norm.key === `${year.tipo_id}-${year.ano}`
    )

    if (normsByYear) {
      return normsByYear.data.map((data) => {
        const articlesFinded = mountArticlesTree(data)

        return {
          id: data.id,
          name: data.titulo,
          parent_id: year.ano,
          type: 'norm',
          children: articlesFinded || LoadingItem
        }
      })
    }

    return null
  }

  const mountYearTree = (menuItem) => {
    const [yearsByMenu] = years.filter((year) => year.key === menuItem.tipo_id)

    if (yearsByMenu) {
      return yearsByMenu.data.map((data) => {
        const normsFinded = mountNormTree(data)

        return {
          id: data.ano,
          name: data.ano,
          parent_id: menuItem.tipo_id,
          type: 'year',
          children: normsFinded || LoadingItem
        }
      })
    }

    return null
  }

  const itemsTree = (data) => {
    return data.map((menuItem) => {
      const yearsByMenu = mountYearTree(menuItem)

      return {
        id: menuItem.tipo_id,
        name: menuItem.titulo,
        type: 'menu',
        children: yearsByMenu || LoadingItem
      }
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.indiceContainer}>
        <Typography variant="subtitle1" className={classes.indiceTitle}>
          Índice:
        </Typography>
      </div>
      <TreeView
        data={itemsTree(menu[0])}
        onLabelClick={handleGetData}
        parentWithClick
      />
      <Typography variant="subtitle1" className={classes.aditionalTitle}>
        Legislação Adicional
      </Typography>
      <TreeView
        data={itemsTree(menu[1])}
        onLabelClick={handleGetData}
        parentWithClick
      />
    </div>
  )
}

Menu.propTypes = {
  menu: PropTypes.instanceOf(Array).isRequired,
  years: PropTypes.instanceOf(Array).isRequired,
  norms: PropTypes.instanceOf(Array).isRequired,
  articles: PropTypes.instanceOf(Array).isRequired,
  handleGetNormsYears: PropTypes.func.isRequired,
  handleGetNorms: PropTypes.func.isRequired,
  handleGetArticles: PropTypes.func.isRequired,
  handlePrepareArticle: PropTypes.func.isRequired
}

export default Menu
