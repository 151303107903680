import {
  AUTHENTICATED_ERROR,
  PROFILE_INTERESTS_NOTIFICATION_LOADING,
  PROFILE_INTERESTS_NOTIFICATION_SUCCESS,
  PROFILE_INTERESTS_NOTIFICATION_ERROR
} from '../../../utils/actionTypes'
import { Users, UserService } from '../../../services'
import { isAuthenticated } from '../../../auth'
import Messages from '../../../config/Messages'

export const getInterestsNotificationAction = async (dispatch) => {
  let statusOk = true

  if (isAuthenticated()) {
    dispatch({
      type: PROFILE_INTERESTS_NOTIFICATION_LOADING
    })

    const response = await UserService.getUserInterestsNotification(
      Users.getUserId()
    )

    const result = await response.json()

    if (response.ok) {
      dispatch({
        type: PROFILE_INTERESTS_NOTIFICATION_SUCCESS,
        payload: result.data
      })
    } else {
      statusOk = false

      dispatch({
        type: PROFILE_INTERESTS_NOTIFICATION_ERROR,
        payload: result.errors
      })
    }
  } else {
    statusOk = false

    dispatch({
      type: AUTHENTICATED_ERROR,
      payload: {
        statusCode: Messages.status[403].code,
        responseMessage: Messages.status[403]
      }
    })
  }

  return {
    ok: statusOk,
    isAuthenticated: isAuthenticated()
  }
}
