module.exports = {
  status: {
    403: 'Usuário não autenticado',
    code: '403'
  },
  notAuthenticate: {
    preview:
      'Para visualizar a amostra deste livro você precisa realizar o login na plataforma',
    rating:
      'Para avaliar este livro você precisa realizar o login na plataforma',
    activateCoupon:
      'Para inserir o cupom e ativar os materiais complementares, você precisa realizar o login na plataforma'
  },
  loadingNotification: {
    error: 'Não foi possível carregar o livro. Tente novamente'
  },
  messageSent: {
    success: 'Sua mensagem foi enviada com sucesso!',
    error: 'OPS!',
    contactSupport:
      'Logo mais você receberá uma resposta por email do nosso Suporte.',
    systemError:
      'Houve um erro no sistema e sua mensagem não foi enviada. Tente novamente mais tarde ou envie sua dúvida para o e-mail:',
    systemErrorEmail: 'sac@grupogen.com.br',
    systemErrorEmailSubject: 'Contato com suporte - Saraiva Educação'
  },
  activateBook: {
    instructionsIntro:
      'Temos 2 livros em nosso catálogo com ativação de materiais via código.',
    instructionsComplement: 'São eles:',
    instructionsSteps: [
      { id: 1, label: 'Direito Constitucional Esquematizado' },
      { id: 2, label: 'Vade Mecum Tradicional' }
    ],
    instructionsMiddle:
      'Todos os outros livros com materiais complementares são ativados via link, que pode ser encontrado nas primeiras páginas de seu livro.',
    instructionsEnd: 'Para ativação via código, insira no campo abaixo:',
    instructionsHelp:
      ' Caso ainda esteja com dúvida confira nosso tutorial de ',
    instructionsHelpButton: 'Como ativar meus materiais.',
    alert:
      'Caso tenha algum problema com a ativação de seus materiais, seja por Cupom ou por Link, entre em contato com o nosso suporte no botão a direita da sua tela',
    success: 'Cupom ativado com sucesso!',
    linkSuccess: 'Livro ativado com sucesso!',
    totalAvailable: 'O cupom informado já foi utilizado',
    notFound:
      'Código não reconhecido. Favor tentar novamente. Se o erro persistir, entre em contato com o nosso Suporte',
    noActivations:
      'Esse código de ativação já foi utilizado. Confira se seu livro já aparece na página de "Meus Livros".',
    linkError:
      'Não foi possível ativar o livro no momento. Tente novamente mais tarde. Caso o erro persista entre em contato com o nosso suporte',
    invalidBook:
      'O livro não está disponível. Para mais informações entre em contato com o nosso suporte'
  },
  activateEBook: {
    error:
      'Não foi possível ativar o e-book no momento. Tente novamente mais tarde. Caso o erro persista entre em contato com o nosso suporte',
    cancelledPurchase:
      'Não foi possível ativar o(s) e-book(s) já que a sua compra foi cancelada. Para maiores informações acesse a página de seus pedidos no site da Editora do Direito.',
    hasActivatedLink: 'Este link de ativação de e-book já foi utilizado',
    success: 'E-book ativado com sucesso!',
    successEbookList: "Seus e-book's foram ativados com sucesso!"
  },
  favorite: {
    success: 'Livro adicionado a lista de desejos',
    unfavorite: 'Livro removido da lista de desejos',
    error: 'Ocorreu um erro. Tente novamente',
    unfavoriteError:
      'Não foi possível desfavoritar este livro. Tente novamente mais tarde',
    notAuthenticate:
      'Para favoritar este livro você precisa realizar o login na plataforma'
  },
  rating: {
    success: 'Sua avaliação foi postada com sucesso',
    error: 'Não foi possível postar sua avaliação',
    limit: 'Você já inseriu dois comentários para esse livro'
  },
  abntCopy: {
    message: 'Referência bibliográfica copiada para área de transferência.'
  },
  error: {
    catalog: 'Não foi possível carregar o catálogo. Tente novamente',
    library: 'Não foi possível carregar a biblioteca. Tente novamente',
    wishlist: 'Não foi possível carregar a lista de desejos. Tente novamente'
  },
  commentReport: {
    success: 'Sua denúncia foi enviada para nosso suporte',
    unauthorized: 'Você já denunciou esse comentário e ele está em análise',
    notAuthenticate:
      'Para denunciar o comentário você precisa realizar o login na plataforma'
  },
  material: {
    professorNotAllowed:
      'Caro professor, envie comprovante atual de docência para o email',
    professorNotAllowed2: 'sac@grupogen.com.br',
    professorNotAllowed3: 'e tenha acesso ao material exclusivo desta obra.'
  },
  book: {
    closingDateTooltip:
      'A data de fechamento indica até que momento a obra foi atualizada.'
  },
  profile: {
    setProfileSuccess: 'Perfil atualizado com sucesso!',
    setProfileError: 'Não foi possível atualizar o perfil.',
    setInterestsError: 'Não foi possível atualizar os interesses.'
  },
  notifications: {
    inactiveBook: 'Não foi possível carregar o livro.'
  },
  passwordRecovery: {
    emailSent:
      'E-mail para redefinição de senha enviado. Confira sua caixa de entrada!',
    incorrectData: 'E-mail não encontrado. Verifique se o e-mail está correto.',
    systemUnavailable: 'Sistema indisponível. Tente novamente mais tarde.'
  },
  editUser: {
    editSuccess: 'Informação pessoal alterada com sucesso'
  }
}
