import {
  CATEGORIES_LOADING,
  CATEGORIES_SUCCESS,
  CATEGORIES_ERROR
} from '../../../utils/actionTypes'
import { CategoriesService } from '../../../services'
import { sortByName } from '../../../utils'

export const getCategoriesAction = async (dispatch) => {
  dispatch({
    type: CATEGORIES_LOADING
  })

  const categoriesResponse = await CategoriesService.getCategories()
  const categoriesData = await categoriesResponse.json()

  if (categoriesResponse.ok) {
    let categoriesSorted = categoriesData.result.sort(sortByName)

    categoriesSorted = categoriesSorted.map((sortedItem) => {
      sortedItem.subcategoria = sortedItem.subcategoria.sort(sortByName)
      return sortedItem
    })

    dispatch({
      type: CATEGORIES_SUCCESS,
      payload: categoriesSorted
    })
  } else {
    dispatch({
      type: CATEGORIES_ERROR,
      payload: categoriesData.error
    })
  }

  return {
    ok: categoriesResponse.ok
  }
}
