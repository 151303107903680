/* eslint-disable jsx-a11y/anchor-is-valid */ /* eslint-disable no-console */ /* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Grid, Link, Typography } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { StarRounded } from '@material-ui/icons'
import { Dialog, TextInput } from '@grupogen/saraiva-ui'
import { isAuthenticated } from '../../auth'
import { GeneralContext } from '../../contexts/GeneralContext'
import Avaliacao from './Avaliacao'
import Messages from '../../config/Messages'
import { Users, BookService, TrackingsService } from '../../services'
import useStyles from './Avaliacao/styles'
import { getDeviceType } from '../../utils'

export default function BookAvaliacoes({ caeId }) {
  const [ratingDialog, setRatingDialog] = useState(false)
  const [ratingValue, setRatingValue] = useState(null)
  const [ratingText, setRatingText] = useState('')
  const [ratings, setRatings] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const {
    setDialogNotAuthenticated,
    setSuccessMessage,
    setErrorMessage,
    setDialogAuthClickTracking
  } = useContext(GeneralContext)

  const getAllRates = async () => {
    setLoading(true)
    try {
      const rates = await BookService.getAllRatesByBook(parseInt(caeId, 10))
      const result = await rates.json()
      setRatings(
        result &&
          result.data &&
          result.data.sort(
            (a, b) => new Date(b.inserted_at) - new Date(a.inserted_at)
          )
      )
      setLoading(false)
    } catch (e) {
      setErrorMessage('Ocorreu um erro com as buscas', e)
      setRatings([])
      setLoading(false)
    }
  }

  const openRatingDialog = async () => {
    await TrackingsService.sendTrack('fazerAvaliacao')

    if (isAuthenticated()) {
      setRatingDialog(true)
    } else {
      setDialogAuthClickTracking('fazerAvaliacaoLogin')
      setDialogNotAuthenticated(Messages.notAuthenticate.rating)
    }
  }

  const setRatePayload = () => {
    return {
      user_id: Users.getUserId(),
      comment: ratingText,
      book_id: parseInt(caeId, 10),
      ip: '127.0.0.1',
      rating: ratingValue,
      active: true
    }
  }

  const resetRate = (ratingObj) => {
    setRatings([ratingObj])
    setRatingValue(null)
    setRatingText('')
    setSuccessMessage(Messages.rating.success)
    setRatingDialog(false)
  }

  const sendRating = async () => {
    setLoading(true)
    try {
      if (isAuthenticated()) {
        const payload = setRatePayload()
        const response = await BookService.rateBook(payload)
        const result = await response.json()
        if (result.errors) setErrorMessage(Messages.rating.limit)
        if (result) resetRate(result)

        setLoading(false)
        getAllRates()

        TrackingsService.sendTrack(
          'fazerAvaliacao',
          {},
          {
            comments_id: result.id
          }
        )
      }
    } catch (e) {
      setLoading(false)
      setErrorMessage(Messages.rating.error)
    }

    return null
  }

  useEffect(() => {
    getAllRates()
  }, [])

  if (loading) {
    return null
  }

  return (
    <>
      <Card
        className={classes.container}
        variant="outlined"
        data-testid="book-avaliations-content"
      >
        {ratings &&
          ratings.filter((c) => c.user_id === Users.getUserId()).length !==
            2 && (
            <>
              <div className={classes.headRating}>
                <Typography
                  variant={getDeviceType() === 'mobile' ? 'subtitle2' : 'h6'}
                  style={{ marginRight: 10 }}
                >
                  Já leu o livro?
                </Typography>

                <Link
                  component="button"
                  variant={getDeviceType() === 'mobile' ? 'subtitle2' : 'h6'}
                  onClick={() => openRatingDialog()}
                  data-testid="book-avaliations-newavaliation"
                >
                  Escreva uma avaliação
                </Link>
              </div>
              <Divider className={classes.divider} />
            </>
          )}

        {ratings && ratings.length ? (
          ratings.map((avaliacao, index) => {
            return (
              <div key={`tab-${index + Math.random()}`}>
                <Avaliacao
                  avaliacao={avaliacao}
                  key={`tab-${index + Math.random()}`}
                  id={`tab-${index + Math.random()}`}
                />
                <Divider
                  className={classes.divider}
                  key={`tab-${index + Math.random()}`}
                  id={`tab-${index + Math.random()}`}
                />
              </div>
            )
          })
        ) : (
          <Typography
            variant={getDeviceType() === 'mobile' ? 'body2' : 'body1'}
            className={classes.emptyRatings}
          >
            Esse livro ainda não possui nenhuma avaliação
          </Typography>
        )}
      </Card>

      <Dialog
        open={ratingDialog}
        size={
          getDeviceType() === 'mobile' || getDeviceType() === 'tablet'
            ? 'md'
            : 'lg'
        }
        title="Escreva sua avaliação"
        primaryButton="Enviar"
        secondaryButton="Cancelar"
        handleConfirm={() => sendRating()}
        handleClose={() => setRatingDialog(false)}
        content="Conteúdo default"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.textDialog}>
              Qual a sua classificação para esse livro?
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ margin: '8px 0px 4px 0px' }}>
            <Rating
              value={ratingValue}
              title="Avaliar Livro"
              onChange={(e, newValue) => {
                setRatingValue(newValue)
              }}
              size="small"
              data-testid="book-avaliations-inputrating"
              icon={<StarRounded className={classes.ratingStar} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.textDialog}>
              Conte-nos o que achou do conteúdo do livro
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textArea}>
            <span data-testid="book-avaliations-inputmessage">
              <TextInput
                size="full"
                multiline
                rows={5}
                label="Adicione seu comentário"
                value={ratingText}
                onChange={(event) => setRatingText(event.target.value)}
              />
            </span>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

BookAvaliacoes.propTypes = {
  caeId: PropTypes.string.isRequired
}
