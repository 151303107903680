function getParamEntries(filterString) {
  const [type, value] = filterString.split('.')
  return { type, value }
}

const getDefaultSearhParams = () => {
  return {
    orderBy: null,
    itemsPerPage: 12,
    filters: null
  }
}

const getDefaultFiltersSelects = () => {
  return [
    {
      id: 1,
      showFilter: true,
      input: 'Mostrar',
      label: '12 Livros por página',
      type: 'itemsPerPage',
      values: [
        { id: 1, text: '8 livros por página', value: 8 },
        { id: 2, text: '12 livros por página', value: 12 },
        { id: 3, text: '16 livros por página', value: 16 }
      ]
    },
    {
      id: 2,
      showFilter: true,
      input: 'Selo',
      label: 'Todos',
      type: 'filter.area',
      values: [
        { id: 1, text: 'Todos', value: null },
        { id: 2, text: 'Saraiva Jur', value: 'Juridico' },
        { id: 3, text: 'Saraiva Uni', value: 'Universitario' },
        { id: 4, text: 'Benvirá', value: 'Ficcao / Nao Ficcao' },
        { id: 5, text: 'Editora Érica', value: 'Livros Tecnicos' }
      ]
    },
    {
      id: 3,
      showFilter: true,
      input: 'Formato',
      label: 'Todos',
      type: 'filter.product_category',
      values: [
        { id: 1, text: 'Todos', value: null },
        { id: 2, text: 'Livro Impresso', value: 'Livro Impresso' },
        { id: 3, text: 'Livro Digital', value: 'Livro Digital' }
      ]
    },
    {
      id: 4,
      showFilter: false,
      input: 'Título',
      label: 'Título',
      type: 'filter.title_cover',
      values: [{ id: 1, text: 'Título', value: 'title_cover' }]
    },
    {
      id: 5,
      showFilter: false,
      input: 'Autor',
      label: 'Autor',
      type: 'filter.authors',
      values: [{ id: 1, text: 'Autor', value: 'authors' }]
    },
    {
      id: 6,
      showFilter: false,
      input: 'ISBN',
      label: 'ISBN',
      type: 'filter.isbn',
      values: [{ id: 1, text: 'ISBN', value: 'isbn' }]
    }
  ]
}

const checkSearchParams = (location, selectsList) => {
  const queryParams = new URLSearchParams(location.search)
  const queryParamsObj = Object.fromEntries([...queryParams])

  if (Object.keys(queryParamsObj).length) {
    const updatedParams = {}

    const isFilterParam = getDefaultFiltersSelects()
      .filter((select) => select.type.includes('filter'))
      .reduce((acc, select) => {
        return [...acc, getParamEntries(select.type).value]
      }, [])

    Object.keys(queryParamsObj).forEach((param) => {
      if (isFilterParam.includes(param)) {
        if (Object.prototype.hasOwnProperty.call(updatedParams, 'filters')) {
          updatedParams.filters.push({
            type: param,
            value: queryParamsObj[param]
          })
        } else {
          updatedParams.filters = [
            {
              type: param,
              value: queryParamsObj[param]
            }
          ]
        }
      } else {
        updatedParams[param] = queryParamsObj[param]
      }
    })

    const selectsListToUpdate = selectsList.filter(
      (select) => select.showFilter
    )

    selectsListToUpdate.forEach((select) => {
      if (select.type.includes('filter')) {
        const filterName = getParamEntries(select.type).value

        if (Object.prototype.hasOwnProperty.call(queryParamsObj, filterName)) {
          select.label = select.values.find(
            (option) => String(option.value) === queryParamsObj[filterName]
          ).text
        }
      } else if (
        Object.prototype.hasOwnProperty.call(queryParamsObj, select.type)
      ) {
        select.label = select.values.find(
          (option) => String(option.value) === queryParamsObj[select.type]
        ).text
      }
    })

    return {
      selectsListToUpdate,
      updatedParams
    }
  }

  return {
    updatedParams: getDefaultSearhParams(),
    selectsListToUpdate: getDefaultFiltersSelects()
  }
}

function getTrackingFiltersNames() {
  return {
    itemsPerPage: 'filtrouItemsPorPagina',
    product_category: 'filtrouFormato',
    area: 'filtroSelo'
  }
}

export {
  getParamEntries,
  checkSearchParams,
  getDefaultSearhParams,
  getDefaultFiltersSelects,
  getTrackingFiltersNames
}
