import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: ({ isCenterPosition }) => (!isCenterPosition ? '50px' : '25px'),
    marginBottom: ({ isCenterPosition }) =>
      !isCenterPosition ? '50px' : '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > span': {
      marginTop: 10
    }
  }
}))

export default useStyles
