import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainAccordion: {
    marginBottom: 24,
    boxShadow: 'none',
    borderRadius: 8,
    background: 'transparent'
  },
  line: {
    '&:before': {
      display: 'none'
    }
  },
  categoryLabel: {
    backgroundColor: '#e4e9ed',
    borderRadius: 8,
    height: 64,
    [theme.breakpoints.down('xs')]: {
      height: 52
    }
  },
  categoryName: {
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: 20,
    marginLeft: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginLeft: 0
    }
  },
  subcategoryAccordion: {
    boxShadow: 'none',
    borderRadius: 8,
    marginBottom: 15,
    '&:before': {
      display: 'none'
    }
  },
  subcategoryLabel: {
    margin: 0,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  subcategoryName: {
    color: theme.palette.secondary.dark,
    backgroundColor: '#FFFFFF',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: 20,
    marginLeft: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginLeft: 0
    }
  },
  contentAccordion: {
    borderRadius: 8,
    width: '100%',
    cursor: 'pointer',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 0
    },
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    paddingRight: 40,
    '& span': {
      textAlign: 'initial'
    },
    [theme.breakpoints.down('sm')]: {
      boxSizing: 'content-box',
      paddingBottom: 0,
      paddingTop: 0,
      paddingRight: 0,
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      paddingTop: 0,
      paddingRight: 0,
      marginLeft: 0
    }
  },
  contentText: {
    display: 'flex',
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  icon: {
    alignSelf: 'center',
    marginLeft: 24,
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      marginRight: 16
    }
  },
  expandIcon: {
    color: theme.palette.secondary.dark
  },
  subCategoryExpandIcon: {
    color: theme.palette.text.primary
  },
  contentButton: {
    display: 'flex',
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    outline: 'inherit',
    cursor: 'pointer',
    height: 54,
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 16,
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notAllowedToAccess: {
    width: '50%',
    margin: '20px auto',
    border: '1px solid #ccc',
    padding: 15,
    textAlign: 'center',
    borderRadius: 8,
    '& a': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  }
}))

export default useStyles
