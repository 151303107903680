import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    marginBottom: 8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 20
    }
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 16
  },
  divider: {
    marginBottom: 32
  }
}))
