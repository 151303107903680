import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      height: 'auto',
      maxWidth: '100%',
      marginTop: 100,
      [theme.breakpoints.down('md')]: {
        marginTop: 20,
        width: 350,
        height: 'auto'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        width: 283,
        height: 'auto'
      }
    }
  }
}))
