import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    marginBottom: 8
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 40
  },
  avatar: {
    height: 69,
    width: 69
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  subContainer: {
    marginLeft: 20
  },
  infoCard: {
    display: 'flex',
    width: 936,
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 12
  },
  userInfo: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'baseline',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    marginBottom: 20
  },
  editButtons: {
    width: 42,
    height: 42,
    borderRadius: 32,
    padding: '32px 0',
    '&:hover': {
      width: 42,
      height: 42,
      borderRadius: 32,
      padding: '32px 0'
    }
  },
  editIcons: {
    color: 'rgba(98, 76, 146, 1)'
  },
  changePasswordButton: {
    color: 'rgba(98, 76, 146, 1)'
  },
  editDialogLabel: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  editDialogInfo: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  tooltip: {
    '[x-placement*="top"] .tooltip-arrow': {
      bottom: '-8'
    },
    '[x-placement*="top"] .tooltip-inner': {
      marginTop: '-8'
    }
  }
}))
