import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  iframe: {
    width: '100%',
    minHeight: 500,
    border: 'none',
    fontSize: '1.07rem'
  }
}))

export default useStyles
