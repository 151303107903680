import * as crypto from 'crypto-browserify'
import httpClient from '../gateway/legacyHttpRequest'
import { getSessionToken } from '../auth'
import { Users } from './users'

const DEFAULT_APPROVA_PASS = 'SaraivaushdsuhdsFFF56'
const DEFAULT_APPLICATION_NAME = 'saraivacd'
const DEFAULT_APPLICATION_ENV = 'prod'
const DEFAULT_TENANT = 'conecta'
const REACT_APP_SARAIVA_APROVA = 'https://srp.somoseditoras.com.br'

export const MaterialsService = {
  async getMaterials(caeId) {
    const path = `materiais/conteudo/codigo_produto/${caeId}?mode=treeview`
    const headers = httpClient.buildHeader({})
    const response = httpClient.get(path, null, headers)
    return response
  },

  async getQuestions(questionPath) {
    const saToken = localStorage.getItem('saToken')
    const path = `${REACT_APP_SARAIVA_APROVA}/knowledge/api/v1${questionPath}`
    const response = fetch(path, {
      headers: {
        authorization: `Token ${saToken}`,
        'application-name': DEFAULT_APPLICATION_NAME,
        'application-env': DEFAULT_APPLICATION_ENV,
        'x-tenant': DEFAULT_TENANT,
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    })
    return response
  },

  async sentQuestions(questionId, alternativeId, questionPath) {
    const saToken = localStorage.getItem('saToken')
    const postData = new URLSearchParams()
    postData.append('step[answer][question_id]', questionId)
    postData.append('step[answer][alternative_id]', alternativeId)

    const path = `${REACT_APP_SARAIVA_APROVA}/knowledge/api/v1${questionPath}/create`
    const response = fetch(path, {
      method: 'POST',
      headers: {
        authorization: `Token ${saToken}`,
        'application-name': DEFAULT_APPLICATION_NAME,
        'application-env': DEFAULT_APPLICATION_ENV,
        'x-tenant': DEFAULT_TENANT,
        'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      },
      body: postData
    })
    return response
  },

  async loginUser() {
    const path = `${REACT_APP_SARAIVA_APROVA}/api/v2/sign_in`
    const response = fetch(path, {
      method: 'POST',
      headers: {
        'application-name': DEFAULT_APPLICATION_NAME,
        'application-env': DEFAULT_APPLICATION_ENV,
        'x-tenant': DEFAULT_TENANT,
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        email: Users.getEmail(),
        password: DEFAULT_APPROVA_PASS
      })
    })
    return response
  },

  async createUser() {
    const path = `${REACT_APP_SARAIVA_APROVA}/api/v2/sign_up`
    const response = fetch(path, {
      method: 'POST',
      headers: {
        'application-name': DEFAULT_APPLICATION_NAME,
        'application-env': DEFAULT_APPLICATION_ENV,
        'x-tenant': DEFAULT_TENANT,
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        app_name: DEFAULT_APPLICATION_NAME,
        app_env: 'homolog',
        name: Users.getFullName(),
        email: Users.getEmail(),
        password: DEFAULT_APPROVA_PASS
      })
    })
    return response
  },

  async getQuestionsAnswered(questionPath) {
    const saToken = localStorage.getItem('saToken')
    const path = `${REACT_APP_SARAIVA_APROVA}/knowledge/api/v1${questionPath}/questions_answered`
    const response = fetch(path, {
      method: 'GET',
      headers: {
        authorization: `Token ${saToken}`,
        'application-name': DEFAULT_APPLICATION_NAME,
        'application-env': DEFAULT_APPLICATION_ENV,
        'x-tenant': DEFAULT_TENANT,
        'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      }
    })
    return response
  },

  encrypt(secretKey, value) {
    // eslint-disable-next-line
    const key = crypto.createHash('sha256').update(String(secretKey)).digest()
    const iv = crypto.randomBytes(16).toString('hex').slice(0, 16)
    const cipher = crypto.createCipheriv('aes-256-ctr', key, iv)
    const encrypted =
      cipher.update(String(value), 'utf8', 'hex') + cipher.final('hex')
    return iv + encrypted
  },

  getURLHash() {
    const token = getSessionToken()
    const secretKey = process.env.REACT_APP_RAGNAROK_SECRET_KEY
    const value =
      process.env.REACT_APP_RAGNAROK_ID_APPLICATION +
      process.env.REACT_APP_RAGNAROK_APPLICATION +
      token
    const hash = this.encrypt(secretKey, value)
    return hash
  }
}
