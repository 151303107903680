/* eslint-disable */
import { getSessionToken } from '../auth'
import httpClient from '../gateway/generalHttpRequest'
import { Users } from './users'
import { getDeviceType, getUserIp } from '../utils'
import { isAuthenticated } from '../auth'
import dictionary from './trackings/dictionary'

const PATH = 'trackings';

export const TrackingsService = {
  getHeaders() {
    let headers = {}

    if (isAuthenticated()) {
      headers = {
        'session-token': getSessionToken()
      }
    }

    const { REACT_APP_FUSION_ORIGIN } = process.env
    
    return {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'application-token': REACT_APP_FUSION_ORIGIN === 'https://stg.fusion.saraivaeducacao.com.br' ? 't7yd9mq0ehz3nkw9l7ztunhm97uhb8m0' : 'm2bsh50foh5ndh5zjebgk9ew8ho9teja', //TODO
      ...headers
    }
  },

  getBookData(book) {
    if (!book.productCaeId) {
      return {}
    }

    return {
      cae_livro: book.productCaeId,
      nome_livro: book.titleCover,
      categoria: book.category,
      subcategoria: book.subcategory,
      editora_livro: book.subarea,
      edicao_livro: book.editionYear,
      formato_livro: book.productCategory,
    }
  },

  async formatData(data) {
    const payload = {
      event: data.event,
      event_text: data.event,
      user_id: data.user_id,
      payload: {
        ...data,
        event_text: data.event,
        context_screen: window.screen.width,
        context_locale: navigator.language,
        context_page_path: window.location.pathname,
        context_page_referrer: document.referrer,
        context_page_url: window.location.href,
        context_user_agent: navigator.userAgent,
        context_ip: await getUserIp(),
        device_category: getDeviceType()
      }
    }

    return payload
  },

  async sendTrack(event, book = {}, payload = {}) {
    try {
      const self = this;
      let data = {
        event: dictionary[event][isAuthenticated()],
        user_id: null,
        ...self.getBookData(book),
        ...payload
      };

      if (isAuthenticated()) {
        data.user_id = Users.getUserId();
      }

      const requestPayload = await self.formatData(data);
      const response = await httpClient.post(PATH, requestPayload, self.getHeaders())
      return response
    } catch (e) {
      return null
    }
  },
}
