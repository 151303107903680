import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import useStyles from './styles'
import AccordionItem from '../BookAccordionItem'

const AccordionSubcategory = ({
  subSection,
  name,
  handleClickSection,
  lastAccordionOpen,
  accordionOpen,
  ...props
}) => {
  const classes = useStyles()
  const saveCategories = (title) => {
    return {
      titulo: name || title,
      materiais: [{ titulo: title }]
    }
  }

  return (
    <>
      {subSection
        .sort((a, b) => a.ordem - b.ordem)
        .map((item, index) => {
          if (item.materiais && item.tipo !== 'playlist') {
            const { titulo: title, materiais: subMaterials } = item
            const categoriesNames = saveCategories(title)
            const subMaterialsFormated = subMaterials
              .sort((a, b) => a.ordem - b.ordem)
              .sort((a, b) => {
                if (b.tipo === 'playlist') {
                  return -100
                }
                if (a.tipo === 'playlist') {
                  return 100
                }
                return a.ordem - b.ordem
              })

            return (
              <Accordion
                key={item.id + item.parent}
                className={classes.subcategoryAccordion}
                defaultExpanded={
                  lastAccordionOpen === item.id || accordionOpen || item.isOpen
                }
              >
                <AccordionSummary
                  className={classes.subcategoryLabel}
                  onClick={() => handleClickSection(title, item.id)}
                  expandIcon={
                    <ExpandMoreRounded
                      className={classes.subCategoryExpandIcon}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  data-testid="bookactived-accordion-subcategory"
                >
                  <Typography className={classes.subcategoryName}>
                    {title}
                  </Typography>
                </AccordionSummary>
                <div>
                  {subMaterialsFormated.map((subItem, i) => (
                    <AccordionItem
                      // eslint-disable-next-line
                      key={subItem.id + subItem.nome}
                      material={subItem}
                      index={i + 1}
                      length={subMaterials.length}
                      categories={categoriesNames}
                      {...props}
                      hasChildren
                    />
                  ))}
                </div>
              </Accordion>
            )
          }
          const categoriesNames = saveCategories(item.nome)
          return (
            <AccordionItem
              material={item}
              key={item.id}
              index={index + 1}
              length={subSection.length}
              categories={categoriesNames}
              hasChildren={false}
              {...props}
            />
          )
        })}
    </>
  )
}

AccordionSubcategory.propTypes = {
  subSection: PropTypes.instanceOf(Array).isRequired,
  name: PropTypes.string,
  handleClickSection: PropTypes.func.isRequired,
  lastAccordionOpen: PropTypes.string,
  accordionOpen: PropTypes.bool
}

AccordionSubcategory.defaultProps = {
  name: '',
  accordionOpen: false,
  lastAccordionOpen: null
}

export default AccordionSubcategory
